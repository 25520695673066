import axios from "axios";
import environment from "../../env";

const ssoServer = axios.create({
  baseURL:
    environment.backendType === "firebase"
      ? environment.service[environment.env].sso
      : environment.service[environment.env]["china"].sso,
});

const config = {
  token: "rG5kXk0CDbhgF4RBlNoV",
};

export async function getCheckIdpByEmail(email) {
  try {
    const requestBody = {
      token: config.token,
      email: email,
    };
    const { data } = await ssoServer.post("/check-idp", requestBody);
    return { ...data, ok: true };
  } catch (error) {
    console.log(error);
    return { ok: false, error };
  }
}

export async function getCheckIdpByHostname(hostname) {
  try {
    const requestBody = Object.assign({
      token: config.token,
      hostname: hostname,
    });
    const { data } = await ssoServer.post("/check-idp", requestBody);
    return { ...data, ok: true };
  } catch (error) {
    console.log(error);
    return { ok: false, error };
  }
}
