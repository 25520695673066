module.exports = {
  ad: {
    highlights: "Особенности",
    highlightsIntro: "Здесь у вас всегда будет быстрый просмотр новых выпусков функций, как этот. Кроме того, Стефани напомнит вам и поможет отслеживать ваши текущие тренировки навыков.",
    recommendations: "Рекомендации",
    recommendationsIntro: "Стефани порекомендует рабочие процессы на основе вашей деятельности здесь. Она узнает на все взаимодействие, которое вы имеете с ней!",
    favoritesIntro: "Вы когда-нибудь удивлялись, насколько удивительнее было бы иметь все рабочие процессы, которые вам нужно больше всего, прямо ждет вас?! Теперь у вас есть особое место, чтобы держать их готов к работе!",
    categoriesIntro: "Так много рабочих процессов, так мало времени ... Разве не было бы неплохо просто щелкнуть и показать категории, которые имеют отношение к вам? Теперь вы можете! :)",
    loginMsgTitle: "Обновление новой функции!",
    loginMsg: "Теперь вы можете легко найти рабочую область. Кроме того, ознакомьтесь с рекомендациями по контенту, которые помогут вам наилучшим образом использовать свою учетную запись DeepHow."
  },
  all: {
    success: "Успех",
    player: "Игрок",
    editor: "Редактор",
    admin: "Админ",
    analytics: "Аналитика",
    skills: "Навыки и умения",
    skill: "Навык",
    unpublished: "Неопубликовано",
    published: "Опубликовано",
    signOut: "выход",
    step: "шаг",
    steps: "шаги",
    numStep: "0 шаг | 1 шаг | {count} шаги",
    transcription: "Транскрипция",
    ok: "ХОРОШО",
    dontSave: "Не сохраняй",
    saveChanges: "Сохранить изменения",
    no: "Нет",
    yes: "Да",
    delete: "Удалить",
    diagram: "диаграмма",
    all: "Все",
    discard: "отказаться",
    save: "Сохранить",
    next: "Далее",
    process: "Далее",
    cancel: "Отмена",
    close: "Закрыть",
    allUsers: "Все пользователи",
    users: "Пользователи",
    user: "Пользователь",
    numUsers: " пользователи",
    numUser: " Пользователь",
    countUsers: "0 пользователь | 1 пользователь | {count} пользователей",
    workspaces: "Рабочие пространства",
    workspace: "Рабочее пространство",
    workspaceGroup: "Группа рабочих пространств",
    workflows: "Рабочие процессы",
    workflow: "Рабочий процесс",
    organization: "Организация",
    business: "Бизнес",
    create: "Создайте",
    name: "Имя",
    namePerson: "Имя",
    email: "Электронная почта",
    employeeId: "ID сотрудника",
    role: "роль",
    organizationAdmin: "Организация администратора",
    workspaceAdmin: "Администрация рабочего пространства",
    publisher: "Публикация",
    viewer: "Просмотр",
    organizationAdmins: "Организация администраторов",
    workspaceAdmins: "Рабочие пространства администраторов",
    publishers: "Издатели",
    viewers: "Зрители",
    add: "Добавить",
    attachments: "вложения",
    private: "частный",
    public: "публичный",
    content: "Содержание",
    noRecordFound: "записей не найдено",
    loadingRecords: "Загрузка записей ...",
    teams: "Команды",
    loadedAllRecords: "Все записи загружены",
    language: "Язык",
    interfaceLanguage: "Язык интерфейса",
    personalInfo: "персональная информация",
    privacyPolicy: "Политика конфиденциальности",
    team: "Команда",
    unselect: "Невысказанный",
    select: "Выбирать",
    applied: "Применяемый",
    noAccessToAdminTools: "Эта учетная запись пользователя в настоящее время не имеет доступа к инструментам администратора.",
    changePhoto: "Сменить фотографию",
    removePhoto: "Удалить фото",
    profilePhoto: "Аватар",
    takeOrUpload: "Возьми или загрузить фотографию.",
    useCamera: "Используйте камеру",
    uploadPhoto: "Загрузить фото",
    editPhoto: "Редактировать фотографию",
    takePhoto: "Сфотографировать",
    retake: "Приостановка",
    countWorkspaces: "Рабочая область | Рабочие пространства | Рабочих мест",
    whatsNew: "Что нового?",
    howToUse: "Как пользоваться",
    defaultInterfaceLanguage: "Язык интерфейса по умолчанию",
    newVersionSnackbarText: "Доступна новая версия DeepHow.",
    refresh: "освежать",
    unpublishedList: "Неопубликовано",
    publishedList: "Опубликовано",
    userAgreement: "Пользовательское Соглашение",
    numAttachments: "0 приложение | 1 приложение | {count} вложений",
    attachment: "приложение",
    knowledgeBase: "DeepHow База знаний",
    changeName: "Изменить имя",
    changePassword: "Изменить пароль",
    openInApp: "Открыт в Deephow Navigator",
    userNewToDeepHow: "Вы новичок в Deephow?",
    downloadTheApp: "Загрузите приложение",
    continueToUseBrowser: "Продолжайте использовать браузер",
    contentManager: "Контент менеджер",
    gen2Forbidden: "Как пользователь Gen2 Light, вы не можете войти в систему прямо сейчас.",
    confirm: "Подтверждать",
    myWorkspaceGroups: "Мои группы рабочих пространств",
    otherWorkspaceGroups: "Другие группы рабочих пространств",
    searchResult: "Результаты поиска",
    noResultsFound: "Результаты не найдены.",
    browseWorkspace: "Просмотр рабочего пространства...",
    version: "версия",
  },
  password: {
    createYourAccount: "Создать учетную запись",
    checkNameChoosePassword: "Проверьте свое имя и выберите пароль",
    createAccount: "Зарегистрироваться",
    createNewPassword: "Создать новый пароль",
    selectNewPassword: "Выберите новый пароль для своей учетной записи",
    newPassword: "Новый пароль",
    confirmNewPassword: "Подтвердите новый пароль",
    passwordMustMatch: "Пароли должны совпадать.",
    confirm: "Подтверждать",
    passwordCreatedMsg: "Ваш новый пароль был создан, <br> Пожалуйста, подождите, пока мы перенаправляем вас ...",
    goToDeepHowPlayer: "Перейти к DeepHow Player",
    failedResetPassword: "Не удалось сбросить пароль. Ваша учетная запись была уже создана или есть сетевая проблема",
    alertSetPasswordFailed: "Не удалось установить пароль. Ваша учетная запись была уже создана или есть проблема сети.",
    linkExpired: "Эта ссылка истекла",
    passwordExpired: "Срок действия вашего сброса пароля истек.",
    inviteExpired: "Ваше приглашение истекло.",
    resetPasswordMsg: "Нажмите кнопку ниже, чтобы запросить электронную почту сброса пароля.",
    // added 09/08/2022
    alertFailedResetPassword: "Не удалось сбросить пароль.",
    alertSuccessfullyResetPassword: "Успешно сбросить пароль, пожалуйста, подождите, пока мы перенаправляем вас снова в систему… <br><br> или нажмите кнопку ниже.",
    newPasswordNotTheSame: "Новый пароль не должен быть таким же, как старый.",
    alertCurrentPasswordIncorrect: "Текущий пароль неверный.",
    alertTooManyAttempts: "Слишком много попыток проверить текущий пароль. Попробуйте позже.",
  },
  analytics: {
    overview: "Обзор",
    engagement: "помолвка",
    activeUsers: "Активные пользователи",
    workflowsCreated: "Созданные рабочие процессы",
    workflowsPublished: "Опубликовано рабочие процессы",
    workflowViews: "Просмотры рабочего процесса",
    timeCreated: "Время создано",
    timePublished: "Время опубликовано",
    timeViewed: "Просмотрено время",
    searchWorkspace: "Поиск рабочих мест ...",
    mostUsers: "Большинство пользователей",
    mostWorkflowsCreated: "Большинство рабочих процессов созданы",
    numberOfUsers: "Количество пользователей",
    organizationOverview: "Обзор организации",
    numWorkflowsIn: "Рабочие процессы внутри",
    searchWorkflow: "Поиск рабочих процессов ...",
    createdBy: "Создан",
    usersIn: "Пользователи в",
    searchUser: "Поиск пользователей ...",
    watchTime: "Посмотреть время",
    workflowsViewed: "Просмотренные рабочие процессы",
    percentViewed: "% Просматривается",
    dateViewed: "Дата просмотрена",
    watchWorkflow: "Смотреть рабочий процесс",
    backToUsers: "Вернуться к пользователям",
    backToWorkflows: "Вернуться к рабочим процессам",
    likes: "Нравится",
    avgTimeViewed: "Авг. Просмотрено время",
    numberofViews: "Количество просмотров",
    workspacesPerPage: "Рабочие пространства на страницу",
    usersPerPage: "Пользователи на странице",
    workflowsPerPage: "Рабочие процессы на страницу",
    exportTable: "Экспортная таблица",
    TopViewers: "Лучшие зрители",
    TopPublishers: "Лучшие издатели",
    totalViews: "Всего просмотров",
    msgNoDataYet: "Данные недоступны",
    msgNoSearchResults: "Совпадающих записей не найдено",
    hms: "(H: M: S)",
    viewHistory: "Посмотреть историю",
    views: "Взгляды",
    totalViewingTime: "Общее время просмотра",
    usersViewedThisWorkflow: "<b>0</b> пользователей просмотрели этот рабочий процессv | <b>1</b> пользователь просматривал этот Workflowv | <b>{count}</b> пользователей просмотрели этот рабочий процессv",
    date: "Дата",
    // added 2022/07/11
    dashboards: "Монитоны",
    reactions: "Реакция",
    userReaction: "отзывы клиентов",
    viewAll: "Посмотреть все",
    favorites: "Избранное",
  },
  admin: {
    status: "Статус",
    active: "Активный",
    inactive: "Неактивный",
    invited: "Приглашенный",
    invitationExpired: "Срок действия приглашения",
    couldNotInvited: "Не может быть приглашен",
    hintSearchUsers: "Поиск пользователей ...",
    hintSearchWorkspaces: "Поиск рабочей области ...",
    hintSearchBusiness: "Поиск бизнеса ...",
    alertSuccessfullyInviteUser: "Успешно пригласил пользователя.",
    alertFailedInviteUser: "Не удалось пригласить пользователя.",
    alertSuccessfullyDeletedUser: "Успешно удалил пользователь.",
    alertFailedDeleteUser: "Не удалось удалить пользователя.",
    alertSuccessfullyCreatedWorkspace: "Успешно созданное рабочее пространство.",
    alertSuccessfullyEditedWorkspace: "Успешно отредактировано рабочее пространство.",
    alertSuccessfullyCreatedBusiness: "Успешно созданный бизнес.",
    alertSuccessfullyEditedBusiness: "Успешно отредактировал бизнес.",
    alertUserInvitationSuccessfullyResent: "Пользовательское приглашение успешно обижается.",
    alertFailedResendUserInvitation: "Не удалось повторно отправить приглашение пользователя.",
    alertUserInvitationSuccessfullyDelete: "Пользовательское приглашение успешно удалено.",
    alertFailedDeleteUserInvitation: "Не удалось удалить приглашение пользователя.",
    resendInvitation: "Повторю приглашение",
    popupMsgResendInvitation: "Вы уверены, что хотите повторно отправить приглашение на",
    popupBtnSend: "Да, отправьте",
    deleteInvitation: "Удалить приглашение",
    popupMsgDeleteInvitation: "Вы уверены, что хотите удалить приглашение на",
    addUser: "Добавить пользователя",
    msgNoMembersYet: "Эта организация пока не имеет членов.",
    msgNoRecordsAllUsers: "Никаких сопоставленных записей не найдено во всех пользователях.",
    createWorkspace: "Создать рабочее пространство",
    msgNoWorkspacesYet: "Эта организация пока не имеет рабочих веществ.",
    msgNoRecordsWorkspaces: "В рабочих пространствах не найдено совпадающих записей.",
    msgNoRecordsWorkspacesGroupAdmin: "Эта учетная запись пока не имеет рабочих веществ.",
    createBusiness: "Создать бизнес",
    msgNoBusinessYet: "Эта организация еще не имеет бизнеса.",
    msgNoRecordsBusiness: "В бизнесе не найдено совпадающих записей.",
    usersIn: "Пользователи в",
    tableMsgNoUsersYet: "Это рабочее пространство пока нет членов.",
    tableMsgNoRecordsThisWorkspace: "В этом рабочем пространстве нет соответствующих записей.",
    memberAt: "Член",
    alerBusinessAlreadyInSystem: "Бизнес уже в системе",
    alerFailedAddBusiness: "Не удалось добавить бизнес.",
    alertFailedEditBusiness: "Не удалось редактировать бизнес.",
    alertWorkspaceAlreadyInSystem: "Это имя рабочего пространства уже в системе.",
    aletFailedCreateWorkspace: "Не удалось создать рабочее пространство.",
    alertFailedEditWorkspace: "Не удалось отредактировать рабочее пространство.",
    resendAllInvites: "Отправлять все приглашения",
    resendAllInvitesMsg: "Вы отправите приглашение на все ожидающие и просматриваемые приглашения. Уверены ли вы?",
    yesSendInvites: "Да, отправить приглашения",
    businessesPerPage: "Предприятия на странице",
    //add org user
    popupTitleAddOrgUser: "Добавить администрацию администратора пользователя",
    invite: "Пригласить",
    popupTitleNewUserInvited: "Новый пользователь приглашен!",
    popupMsgNewUserInvitedOrg: " был приглашен. <br>После подтверждения учетной записи он / она будет иметь доступ к этой организации.",
    popupTitleExistingUserInvited: "Существующий пользователь добавлен!",
    popupMsgExistingUserInvitedOrg: "был добавлен в эту организацию.",
    popupBtnAddAnotherUser: "Добавить другого пользователя",
    alertFailedCheckPendingInvitations: "Не удалось проверить, имеет ли пользователь в ожидании приглашений.",
    alertFailedAddExistingUserAsOrganizationAdmin: "Не удалось добавить существующего пользователя в качестве администрации организации",
    alertAddExistingUserAsOrganizationAdmin: "Добавьте существующий пользователь в качестве администрации организации.",
    alertUserAlreadyOrganizationAdmin: "Пользователь уже в системе как администрация организации.",
    alertFailedCheckUserAlreadyInSystem: "Не удалось проверить, если пользователь уже в системе.",
    //Add user to workspace
    addUserToWorkspace: "Добавить пользователя в рабочее пространство",
    companyEmail: "E-mail компании",
    employeeIdOnly: "Только ID сотрудника",
    fullName: "Полное имя",
    workspaceName: "Название рабочего пространства",
    businessName: "Наименование фирмы",
    popupMsgNewUserInvited: " был приглашен. <br> После подтверждения учетной записи он / она будет иметь доступ к этому рабочему пространству.",
    popupMsgExistingUserInvited: "был добавлен к этому рабочему пространству.",
    popupTitleNewUserCreated: "Существующий пользователь добавлен!",
    popupTitleUserAlreadyinSystem: "Пользователь уже в системе",
    alertUserIsAlreadyInWorkspace: "Этот пользователь ({info}) уже в этом рабочем пространстве.",
    alertPendingInvitation: "У пользователя есть ожидающие приглашения. Пожалуйста, подождите, пока пользователь примет текущее приглашение.",
    searchingforUsers: "Поиск пользователей ...",
    noResultsFound: "Результаты не найдены.",
    // Change User Role
    popupTitleChangeUserRole: "Изменить роль пользователя",
    popupMsgChangeUser: "Вы уверены, что хотите изменить роль <b>{name}</b> с <b>{oldRole}</b> на <b>{newRole}</b>?",
    popupBtnChange: "Да, изменить",
    alertUpdatedUserSettings: "Удачно обновленные пользовательские настройки.",
    // Remove User
    popupTitleRemoveUser: "Удалить пользователя из рабочей области",
    popupMsgRemoveUserFromWorkspace: "Вы уверены, что хотите удалить <b> {name} </b> с рабочего пространства?",
    popupBtnRemove: "Да, удалить",
    alertRemoveUser: "Успешно удалил пользователя из рабочей области.",
    // Delete User
    popupTitleDeleteUser: "Удалить пользователя из системы",
    popupMsgDeleteUserFromAllWorkspaces: "Если вы сделаете это, вы потеряете все записи относительно этого пользователя <b> {имя} </b>, и он будет удален из всех рабочих пространств и ролей, которые он назначен.",
    popupBtnDelete: "Да, удалить",
    tooltipEditUser: "Редактировать пользователя",
    tooltipResendInvite: "Повторно пригласить",
    tooltipCancelInvite: "Отмена пригласить",
    tooltipRemoveUser: "Удалить пользователя",
    // settings
    settings: "Настройки",
    location: "место расположения",
    hireDate: "Дата приема на работу",
    department: "отделение",
    position: "должность",
    site: "сайт",
    userId: "Идентификатор пользователя",
    ssoMapping: "SSO отображение",
    ssoMappingNoData: "Еще нет данных на карте. <br/> Вам нужно загрузить CSV через FTP, пожалуйста, свяжитесь с поддержкой.",
    attributes: "Атрибуты",
    oneAttributeToMap: "Вы можете выбрать только один атрибут для карт пользователей в рабочие пространства",
    selectAttribute: "Выберите атрибут",
    changeAttribute: "Изменить атрибут",
    discardChanges: "Отменить изменения",
    applyChanges: "Применить изменения",
    applyChangesMsg: "Вы собираетесь внести изменения в следующие рабочие пространства, вы уверены?",
    mappingValuesUpdated: "Обновленные значения картирования",
    mappingValuesUpdatedSubtitle: "Чтобы успешно обновить сопоставление, вам необходимо применить изменения. Вы можете сделать это, когда вы закончите редактирование всех рабочих пространств.",
    values: "Ценности",
    clickPlusToAddValues: "Нажмите на значок плюс, чтобы добавить значения",
    selectValues: "Выберите значения",
    searchForValues: "Поиск ценностей ...",
    assign: "Назначать",
    assignValues: "Назначить значения",
    addUsersBtn: "Да, добавить пользователей",
    YesChangeBtn: "Да, смена",
    numMore: "{count} Больше",
    removeValues: "Удалить значения",
    remove: "Удалять",
    changeAttributeMessage: "Вы уверены, что хотите изменить атрибут? <br/> Вы потеряете все фактическое отображение, которое у вас есть для этого атрибута.",
    discardChangesMessage: "Вы собираетесь потерять все изменения, которые вы сделали с вашего последнего сохранения, вы уверены?",
    discardChangesBtn: "Да, отказаться от изменения",
    applyChangesBtn: "Да, применить изменения",
    alertDiscardedSuccess: "Сопоставление успешно отбрасывается.",
    alertAppliedSuccess: "Отображение успешно применено.",
    alertAppliedFailed: "Не удалось применить отображение.",
    //permissions
    permissions: "Разрешения",
    products: "DeepHow Products",
    roleAccessToProduct: "Определите ниже, какие роли пользователей смогут получить доступ к каждому продукту",
    analytics: "Аналитика",
    skillsManager: "Управление навыками",
    skillsPlayer: "Игрок навыков",
    workflowCapture: "Захват рабочего процесса",
    workflowEditor: "Редактор рабочего процесса",
    organizationAdmin: "Организация администратора",
    groupAdmin: "Администрация рабочего пространства",
    publisher: "Издатели",
    viewer: "Зрители",
    rolesPermission: "Разрешение ролей",
    rolesPermissionMessage: "Вы уверены, что хотите изменить разрешение на роль на этот продукт? <br/> Изменения будут сделаны немедленно.",
    inviteUser: "Пригласить пользователя",
    workflowSharing: "Распределение рабочего процесса",
    allowUserstoSharePublicLinks: "Разрешить пользователям обмениваться публичными ссылками",
    changeWorkflowSharingExplanation: "Отключая обмен общественными связями, общедоступные рабочие процессы будут доступны только пользователями, которые подписываются на платформу.",
    allowWorkflowSharingMessage: "Вы уверены, что хотите включить совместные общественные ссылки?",
    preventWorkflowSharingMessage: "Вы уверены, что хотите отключить общедоступные ссылки? Изменения будут сделаны немедленно.",
    workflowDownload: "Рабочий процесс загрузки",
    allowUsersToDownloadWorkflows: "Разрешить пользователям загружать рабочие процессы",
    allowDownloadWorkflowsMessage: "Вы уверены, что хотите позволить пользователям загружать рабочие процессы?",
    preventDownloadWorkflowsMessage: "Вы уверены, что хотите предотвратить загрузку пользователей?",
    autoLogout: "Автого выхода",
    autoLogoutAfterSelectedTime: "Пользователи будут автоматически выходить в систему после выбранного времени.",
    numMinutes: "0 минут | 1 минута | {count} минуты",
    changeLogoutTimeMessage: "Вы уверены, что хотите изменить автоматически зарегистрированное время на {num} минут?",
    enableAutoLogoutMessage: "Вы уверены, что хотите включить авто выйти?",
    disableAutoLogoutMessage: "Вы уверены, что хотите отключить авто выйти?",
    changesMadeImmediately: "Изменения будут сделаны немедленно.",
    sessionIsAboutToExpire: "Ваша сессия истекает.",
    youWillBeLoggedOutNumSeconds: "Вы войдете в систему в 0 секунды. | Вы войдете в систему за 1 секунду. | Вы выйдете из системы через {count} секунд",
    continueSession: "Продолжить сессию",
    //system languages
    languages: "Языки",
    systemLanguage: "Язык системы",
    searchForWorkspaces: "Поиск рабочих мест ...",
    workspacesPerPage: "Рабочие пространства на страницу",
    workspace: "Рабочее пространство",
    workspaceLanguage: "язык",
    changeLanguage: "Изменить язык",
    popupMsgChangeLanguage: "Изменение языка повлияет на все пользователи внутри этого рабочего пространства, вы уверены, что хотите изменить?",
    alertLanguageSuccessfullyChanged: "Успешно изменил язык",
    alertFailedChangeLanguage: "Не удалось изменить язык",
    // Categories
    categories: "Категории",
    alertCategoriesNotSaved: "Категории не сохранены, попробуйте еще раз позже.",
    noCategoryResult: "Нет результатов поиска категории",
    noCategoriesAddedYet: "Категории еще не добавлены",
    categoriesAssignment: "Категории назначение",
    categoryPlaceholder: "Категории поиска и подкатегории ...",
    previouslyUsed: "Бывшего употребления",
    allCategories: "Все категории",
    workflowCategories: "Категории рабочего процесса",
    searchForCategories: "Поиск категорий ...",
    categoryItems: "Предметы",
    categoryItemsNum: "Предметы",
    createCategory: "Создать категорию",
    editCategory: "Редактировать категорию",
    categoryName: "название",
    noCategoriesCreatedYet: "Категории созданы еще не.",
    noItemsCreatedYet: "Никакие предметы созданы еще.",
    categoriesPerPage: "Категории на страницу",
    itemsPerPage: "пункты на странице",
    alertCategoryAlreadyInSystem: "Категория уже в системе.",
    alertFailedAddCategory: "Не удалось добавить категорию.",
    alertFailedEditCategory: "Не удалось редактировать категорию.",
    deleteCategory: "Удалить категорию",
    popupMsgDeleteCategory: "Вы уверены, что хотите удалить категорию",
    itemsIn: "Предметы внутри",
    searchForItems: "Поиск предметов ...",
    createItem: "Создать элемент",
    editItem: "Редактировать элемент",
    deleteItem: "Удалить пункт",
    itemName: "название",
    alertItemAlreadyInSystem: "Пункт уже в системе.",
    alertFailedAddItem: "Не удалось добавить элемент.",
    alertSuccessAddItem: "Успешно созданный товар.",
    alertFailedEditItem: "Не удалось редактировать элемент.",
    alertSuccessEditItem: "Успешно отредактированный товар.",
    // Teams// Teams
    teamsPerPage: "Команды на страницу",
    max1000UsersPerTeam: "* Макс 1000 пользователей на команду.",
    usersSelected: "0 пользователь выбран | 1 выбран пользователем | {count} Пользователи выбраны",
    selectAll: "Выбрать все",
    removeAll: "Убрать все",
    createTeam: "Создать команду",
    editTeam: "Редактировать команду",
    teamName: "название",
    teamsIn: "Команды внутри",
    searchForTeams: "Поиск команд ...",
    searchForUsers: "Поиск пользователей ...",
    noUsersAddedYet: "Ни один пользователи пока не добавили в свою команду.",
    dateAdded: "Дата добавления",
    alertTeamAlreadyInSystem: "Команда уже в системе.",
    alertFailedEditTeam: "Не удалось редактировать команду.",
    deleteTeam: "Удалить команду",
    popupMsgDeleteTeam: "Вы удалите команду <b>{name}</b> Вы уверены, что хотите это сделать?",
    noTeamsCreatedYet: "Команды созданы еще не созданы.",
    popupMsgDeleteItem: "Вы уверены, что хотите удалить элемент",
    removeUser: "Удалить пользователя",
    popupMsgRemoveUser: "Вы удалите <b>{name}</b> из этой команды. Вы уверены, что хотите это сделать?",
    alertErrorGettingWorkspaceList: "Ошибка получения списка рабочей области.",
    alertSuccessfullyCreatedTeam: "Успешно создал команду",
    alertFailedCreatedTeam: "Не удалось создать команду.",
    alertSuccessfullyUpdatedTeam: "Успешно обновил команду.",
    alertSuccessfullyRemovedUserFromTeam: "Успешно удалил пользователь из команды.",
    alertFailedRemovedUserFromTeam: "Не удалось удалить пользователь из команды.",
    alertSuccessfullyAddedUserToTeam: "Успешно добавил пользователя в команду.",
    alertFailedAddedUserToTeam: "Не удалось добавить пользователя в команду.",
    alertWarningAddedUserToTeamAlready: "Пользователь уже был добавлен в команду.",
    // TODO added 2022/02/03:
    alertAllInvitationResentSuccess: "Все приглашения успешно обижаются.",
    alertAllInvitationResentFailed: "Не удалось отправить все приглашения.",
    alertCheckUserInSystemFailed: "Не удалось проверить, уже есть ли пользователь в системе.",
    alertOrganizationAdminAssignedFailed: "Администрация организации не может быть назначена рабочей области",
    // TODO added 2022/02/07:
    alertFailedDeleteItem: "Не удалось удалить элемент.",
    alertSuccessfullyDeletedItem: "Успешно удаленный элемент.",
    alertCategoryDeleteFailed: "Не удалось удалить категорию.",
    alertSuccessfullyDeletedCategory: "Успешно удаленная категория.",
    // TODO added 2022/02/08:
    upgradeToOrgAdmin: "Обновление до орг. Админ",
    yesUpgrade: "Да, обновление",
    popupMsgUpgradeToOrgAdmin: "Вы уверены, что хотите обновить <b>{name}</ b>? Org. Администраторы имеют полный доступ для настройки настроек вашей организации и многое другое ...",
    alertFailedUpgradeToOrg: "Не удалось обновить до ОРГ. Админ.",
    alertSuccessfullyUpgradeToOrg: "Успешно обновлен до ОРГ. Админ.",
    // TODO added 2022/02/17
    userActive: "Активный?",
    profile: "профиль",
    // TODO: added 2022/02/09 settings > Master Password
    masterPassword: "Основной пароль",
    enableMasterPassword: "Включить мастер-пароль",
    disableMasterPassword: "Отключить мастер-пароль",
    resetMasterPassword: "Сбросить главный пароль",
    employeeIDAccess: "Доступ к идентификатору сотрудника",
    employeeIDAccessMsg: "Если вы включите эту функцию, пользователи, которые доступа только к идентификатору работника, придется ввести этот пароль для доступа к Deephow.",
    disableMasterPasswordMsg: "Если вы выключите эту функцию, пользователи, которые доступа только к идентификатору работника, не понадобится ввести пароль для доступа к Deephow.",
    resetMasterPasswordMsg: "Пользователи, которые доступны только с идентификатором сотрудника, должны будут использовать этот новый пароль для доступа к Deephow.",
    insertPassword: "Вставить пароль",
    alertEnablePasswordFailed: "Не удалось включить основной пароль.",
    alertEnablePasswordSuccess: "Успешно включенный главный пароль.",
    alertDisablePasswordFailed: "Не удалось отключить основной пароль.",
    alertDisablePasswordSuccess: "Успешно отключенный главный пароль.",
    savePasswordMsg: "Эта функция не будет включена, пока ваш пароль не будет успешно сохранен.",
    resetPasswordMsg: "Введите новый мастер-пароль и сохраните.",
    currentPassword: "Текущий пароль",
    yesEnable: "Да, включить",
    yesDisable: "Да, отключить",
    downgradeOrgAdmin: "Понизить орг. Админ",
    popupMsgDowngradeOrgAdmin: "Вы уверены, что хотите понизить границу <b>{name}</b>? Он будет понижен к роли, которую вы выбираете во всех рабочих пространствах, к которым он принадлежит.",
    selectRole: "Выберите роль для этого пользователя",
    yesDowngrade: "Да, понижение",
    alertFailedDowngradeToOrg: "Не удалось понизить до {userRole}.",
    alertSuccessfullyDowngradeToOrg: "Успешно понизить до {userRole}.",
    lastActivity: "Последний Войти",
    coverColor: "Цвет крышки",
    defaultLanguage: "Язык по умолчанию",
    blue: "Синий",
    red: "красный",
    green: "Зеленый",
    yellow: "Желтый",
    purple: "Пурпурный",
    belongsToWorkspaces: "Принадлежит к рабочим пространствам",
    alertFailedSaveChanges: "Не удалось сохранить изменения.",
    // TODO: added 04/02
    alertFailedRemoveUser: "Не удалось удалить пользователя.",
    // TODO: added 0608
    privateOrnot: "частный?",
    privateWorkspaceInfo: "Это рабочее пространство будет видно только участниками, назначенными ему ...",
    noPermission: "Вам не разрешено видеть это.",
    noPermissionContact: "Доступ к странице, к которой вы пытаетесь получить доступ, ограничен. <br>Если вы считаете, что это ошибка, обратитесь к администратору.",
    privateCannotChanged: "Частная настройка не может быть изменена после создания рабочего пространства.",
    // Shared Account
    sharedAccount: "Общая учетная запись",
    addManually: "Добавить вручную",
    addASharedAccount:"Добавить общую учетную запись",
    addASharedAccountMsg: "Общие учетные записи не могут использоваться для просмотра рабочих процессов индивидуально, они используются только для пользователей идентификаторов сотрудника для самостоятельных учетных записей в Deephow, а также отслеживают каждого пользователя в общих устройствах ...",
    accountName: "Название аккаунта",
    alertFailedAddShared: "Не удалось добавить общую учетную запись",
    alertSuccessfullyCreatedShared: "Успешно создал общую учетную запись",
    alertSuccessfullyAddExistingShared: "Успешно добавил существующую общую учетную запись",
    accountId: "Идентификатор учетной записи",
    addSharedAccount:"Добавьте общую учетную запись",
    sharedAccountId:"Общий идентификатор учетной записи",
    unselectedTeam: "Невыразившая команда",
    // TODO: added 2022/06/22
    workflowReview: "Обзор рабочего процесса",
    workflowReviewDescription: "Если эта опция включена, администраторы Workspace смогут выбрать, требуют ли их рабочие пространства обзор рабочего процесса или нет",
    preventReviewWorkflowsMessage: "Вы уверены, что хотите не дать пользователям просмотреть рабочие процессы?",
    allowReviewWorkflowsMessage: "Вы уверены, что хотите позволить пользователям просматривать рабочие процессы?",
    // TODO: added 2022/06/29
    addAsReviewer: "Добавить в качестве рецензента",
    removeAsReviewer: "Удалить в качестве рецензента",
    addAsReviewerSuccessMessage: "Добавить {user} в качестве рецензента успешно.",
    addAsReviewerFailedMessage: "Не удалось добавить {user} в качестве рецензента.",
    removeAsReviewerSuccessMessage: "Удалить {user} как рецензент успешно.",
    removeAsReviewerFailedMessage: "Не удалось удалить {user} в качестве рецензента.",
    // Shared Account Admin settings 07/27
    allowsSharedAccountMsg: "Позвольте администратору организации и рабочего пространства создавать общие учетные записи. Пользователи смогут самоотвердиться, используя учетные записи своих идентификаторов сотрудника в общем устройстве.",
    disableSharedAccountMsg: "Вы уверены, что хотите отключить общую учетную запись? <br> Изменения будут внесены немедленно.",
    enableSharedAccountMsg: "Вы уверены, что хотите включить общую учетную запись? <br> Изменения будут внесены немедленно.",
    alertEnableSharedAccountFailed: "Не удалось включить общую учетную запись.",
    alertEnableSharedAccountSuccess: "Успешно включил общую учетную запись.",
    alertDisableSharedAccountFailed: "Не удалось отключить общую учетную запись.",
    alertDisableSharedAccountSuccess: "Успешно отключил общую учетную запись.",
    // QR Code & Watermark
    qrCodeAndWatermark: "QR -код и водяной знак",
    insertYourBrand: "Вставьте свой бренд",
    uploadYourLogo: "Загрузите свой логотип",
    uploadingYourLogo: "Загрузка вашего логотипа",
    logoSuccessfullyUploaded: "Логотип успешно загружен",
    errorInUpload: "Ошибка в вашей загрузке",
    errorInUploadMsg: "Тип файла не поддерживается. Пожалуйста, выберите следующий тип файла: .png",
    uploadLogoMsg: "Перетащите A <b>.png</b> с максимальным <b>3 MB</b> или <span>forse</span> с компьютера.",
    uploadLogoReplaceMsg: "Перетащите или <span>Просмотрите</span>, чтобы заменить изображением с компьютера или <span id='DUploadImage_removelogo'>удалить</span> текущее изображение.",
    simulation: "Симуляция",
    simulationMsg: "Смотрите ниже, как ваш логотип будет представлен в Videos Watermark и в QR -кодах.",
    workflowTitle: "Название рабочего процесса",
    logoAlreadyUploaded: "Логотип уже загружен",
    alertLogoSuccessfullyRemoved: "Логотип успешно удален.",
    alertLogoFailedRemoved: "Не удалось удалить логотип.",
    alertUserInOtherOrg: "Этот пользователь уже существует в другой организации.",
    // added 10/13 delete workspace
    deleteWorkspace: "Удалить рабочее пространство",
    popupMsgDeleteWorkspace: "Вы уверены, что хотите удалить это рабочее пространство ({name})?",
    alertDeleteGroupSuccess: "Успешно удалить это рабочее пространство.",
    alertDeleteGroupFailedWorkflows: "Есть рабочие процессы, назначенные в этом рабочем пространстве, поэтому вы не можете удалить его.",
    alertDeleteGroupFailedSkills: "Есть навыки, присвоенные для этого рабочего пространства, поэтому вы не можете удалить его.",
    alertDeleteGroupFailedTeams: "Есть команды, назначенные в это рабочее пространство, поэтому вы не можете удалить его.",
    // added 11/3 delete team
    alertDeleteTeamSuccess: "Успешно удалил эту команду.",
    alertDeleteTeamFailedSkills: "Этой команде не разрешается удалять из -за существующих навыков, назначенных.",
    alertDeleteTeamFailed: "Не удалось удалить эту команду.",
    // Change Profile Name
    changeProfileName: "Измените имя профиля",
    allowsChangeProfileNameMsg: "Пользователям разрешено изменять свои имена в своих профилях.",
    disableChangeProfileNameMsg: "Вы уверены, что хотите отключить имя профиля изменения? <br> Изменения будут внесены немедленно.",
    enableChangeProfileNameMsg: "Вы уверены, что хотите включить имя профиля изменения? <br> Изменения будут внесены немедленно.",
    alertEnableChangeProfileNameSuccess: "Успешно включено изменение имени профиля.",
    alertEnableChangeProfileNameFailed: "Не удалось включить имя профиля изменения.",
    alertDisableChangeProfileNameSuccess: "Успешно отключил имя профиля изменения.",
    alertDisableChangeProfileNameFailed: "Не удалось отключить имя профиля изменения.",
    // Workflow Comments
    workflowComments: "Рабочий процесс комментариев",
    allowsWorkflowCommentsMsg: "Пользователям разрешено оставлять комментарии в опубликованных рабочих процессах.",
    disableWorkflowCommentsMsg: "Вы уверены, что хотите отключить комментарии рабочего процесса? <br> Изменения будут внесены немедленно.",
    enableWorkflowCommentsMsg: "Вы уверены, что хотите включить комментарии рабочего процесса? <br> Изменения будут внесены немедленно.",
    alertEnableWorkflowCommentsSuccess: "Успешно включил комментарии рабочего процесса.",
    alertEnableWorkflowCommentsFailed: "Не удалось включить комментарии рабочего процесса.",
    alertDisableWorkflowCommentsSuccess: "Успешно отключенные рабочие процессы комментарии.",
    alertDisableWorkflowCommentsFailed: "Не удалось отключить комментарии рабочего процесса.",
    // Workspace Groups
    workspaceGroupsNum: "Рабочая группа Группа | Рабочая группа Группа | группы рабочей области",
    searchWorkspaceGroups: "Поиск групп рабочего пространства ...",
    noWorkspaceGroupsYet: "Никакая группа рабочей области еще не создана.",
    createWorkspaceGroup: "Создать группу рабочей области",
    editWorkspaceGroup: "Редактировать группу рабочей области",
    workspaceGroupName: "Название группы рабочей области",
    editWorkspace: "Редактировать рабочее пространство",
    userNum: "пользователь | пользователь | пользователей",
    teamNum: "Команда | Команда | Команды",
    defaultLanguage: "Язык по умолчанию",
    deleteWorkspaceGroup: "Удалить группу рабочей области",
    alertCreateWorkspaceGroupSuccess: "Успешно созданная группа рабочей области.",
    alertWorkspaceGroupNameAlreadyExists: "Это имя группы рабочей области уже в системе.",
    alertEditWorkspaceGroupSuccess: "Успешно отредактированная группа рабочей области.",
    alertEditWorkspaceGroupFailed: "Не удалось отредактировать группу рабочей области.",
    alertDeleteWorkspaceGroupSuccess: "Успешно удалил эту группу рабочей области.",
    alertDeleteWorkspaceGroupFailed: "Не удалось удалить эту группу рабочей области.",
    alertWorkspaceAssignedDeleteGroupFailed: "В этой группе назначено рабочее пространство, поэтому вы не можете удалить его.",
    deleteWorkspaceGroupMessage: "Вы уверены, что хотите удалить эту группу рабочей области (<b>{name}</b>)?",
    alertWorkspaceNameAlreadyExists: "Имя рабочей области уже существует.",
    limitIDLoginToSharedAccount: "Ограничьте идентификатор сотрудника только для общих устройств.",
    allowEmployeesCreateIDAccount: "Позвольте сотрудникам создавать свои собственные счета в общем устройстве.",
    disableDHLoginMsg: "Вы уверены, что хотите ограничить вход для идентификатора сотрудников только на общие устройства?",
    enableDHLoginMsg: "Вы уверены, что не хотите ограничивать вход для идентификатора сотрудников только на общие устройства?",
    enableEmployeeCreationMsg: "Вы уверены, что хотите позволить сотрудникам создавать свои собственные учетные записи в общем устройстве?",
    disableEmployeeCreationMsg: "Вы уверены, что не хотите позволять сотрудникам создавать свои собственные учетные записи в общем устройстве?",
    alertSharedDeviceSuccessfullyChanged: "Общее настройка устройства успешно изменилась.",
    alertSharedDeviceFailedChanged: "Не удалось изменить настройку общего устройства.",
    redefinePassword: "Переопределить пароль",
    redefinePasswordMsg: "Пожалуйста, выберите новый пароль для этой учетной записи:",
    redefine: "Переопределить",
    alertSuccessfullyResetPassword: "Успешно сбросить пароль.",
    ipRestriction: "IP -ограничение",
    allowIpRestrictionMessage: "Если вы включите эту функцию, только IP -адресат в белом списке сможет получить доступ к DeepHow.",
    enableIpRestrictionMessage: "Вы уверены, что хотите включить ограничение IP? <br> Изменения будут внесены немедленно.",
    disableIpRestrictionMessage: "Вы уверены, что хотите отключить ограничение IP? <br> Изменения будут внесены немедленно.",
    whitelistedIps: "Белый список IP",
    whitelistedIpDescription: "Описание",
    whitelistedIpsOrRange: "IPS или диапазон",
    whitelistedIpSourceFilter: "Исходный фильтр",
    searchForWhitelistedIp: "Поиск IP белого списка…",
    addWhitelistedIpRule: "Добавить правило",
    editWhitelistedRule: "Редактировать правило",
    noWhitelistedIpCreatedYet: "Пока не создан белый список IP.",
    addAnotherIp: "Добавить еще один IP",
    deleteWhitelistedIp: "Удалить белый список IP",
    popupMessageDeleteWhitelistedIp: "Вы уверены, что хотите удалить IP белого списка",
    alertEnableIpRestrictionSuccess: "Успешно включено IP -ограничение.",
    alertEnableIpRestrictionFailed: "Не удалось включить IP -ограничение.",
    alertDisableIpRestrictionSuccess: "Успешно отключил IP -ограничение.",
    alertDisableIpRestrictionFailed: "Не удалось отключить ограничение IP.",
    alertCreateWhitelistedIpSuccess: "Успешно создал белый список IP.",
    alertCreateWhitelistedIpFailed: "Не удалось создать IP.",
    alertUpdateWhitelistedIpSuccess: "Успешно обновленный белый список IP.",
    alertUpdateWhitelistedIpFailed: "Не удалось обновить белый список IP.",
    alertWhitelistedIpTypeError: "Тип источника не соответствует правилу IP.",
    alertWhitelistedCIDRError: "CIDR неверная или неожиданная ошибка.",
    alertDeleteWhitelistedIpSuccess: "Успешно удалил белый список IP.",
    alertDeleteWhitelistedIpFailed: "Не удалось удалить белый список IP.",
  },
  builder: {
    publish: "Опубликовать",
    reviewTranscription: "Просмотр транскрипции",
    viewer: "Просмотр",
    links: "Ссылки",
    trim: "Подрезать",
    translation: "Перевод",
    share: "Поделиться",
    addDiagramMsg: "Перетащите или нажмите, чтобы добавить диаграммы",
    onlyImageAccepted: "Возможно добавить только файлы-изображения",
    unsupportedImageFormats: "Неподдерживаемые форматы изображения: SVG, TIFF, XBM.",
    addDiagram: "Добавить диаграмму",
    start: "Начало",
    end: "Конец",
    resetToOriginal: "Сброс к оригиналу",
    trimStartMsg: "Выберите, где вы хотите, чтобы ваше видео начать и закончить",
    trimProcessingMsg: "Отделка недоступна, в то время как обработка рабочего процесса осуществляется. <br> Пожалуйста, вернитесь позже",
    cannotTrimLessThan3s: "Вы не можете обрезать видео короче 3 секунд",
    trimVideo: "Обрезать видео",
    trimStep: "Отрежьте шаг",
    editWorkflow: "Редактировать рабочий процесс",
    popupBtnEdit: "Да, уверен",
    popupBtnPublish: "Да, опубликовать",
    popupBtnUnpublish: "Да, уверен",
    popupMsgTrimConfirmation: "Вы уверены, что хотите обрезать?",
    popupMsgTrimWithSaveConfirmation: "У вас есть несохраненные изменения на вашем рабочем процессе. Мы сохраним изменения для вас.",
    popupBtnTrim: "Да, отделка",
    popupMsgTranscoding: "Транскодирование находится в процессе.",
    popupMsgTrimBackLater: "Вы можете возобновить редактирование рабочего процесса после завершения транскодирования.",
    popupMsgTrimSuccess: "Ваш шаг был успешно обрезан!",
    done: "Готово",
    publishWorkflow: "Опубликовать рабочий процесс",
    popupMsgEditWorkflow: "Если вы перейдете в режим редактирования, ваш рабочий процесс будет автоматически перемещен в Неопубликованные, вы уверены, что хотите продолжить?",
    popupMsgUnpublishWorkflow: "Вы собираетесь переместить свой рабочий процесс в Неопубликованные, вы уверены, что хотите это сделать?",
    alertSaveFailed: "Не удалось сохранить рабочий процесс.",
    alertTrimResetSuccess: "Шаг успешно сброшен.",
    alertTrimResetFailed: "Не удалось сбросить шаг.",
    alertTrimCanNotReset: "Этот шаг не может быть сброшен.",
    resetStep: "Сброс шага",
    popupMsgResetStep: "Вы уверены, что хотите сбросить? Диаграммы, добавленные к этому шагу, будут потеряны.",
    popupBtnReset: "Да, сброс",
    hintSearchBuilder: "Поиск рабочих процессов и ключевых слов ...",
    stepsSelected: "выбранный",
    clear: "Прозрачный",
    insert: "Вставлять",
    reset: "Перезагрузить",
    resetWorkflow: "Сбросить рабочий процесс",
    resetWorkflowAlert: "Вы потеряете всю работу в этом рабочем процессе и снова начнете с самого начала, вы уверены, что хотите продолжить?",
    resetWorkflowKeepCopy: "Сохранить копию этого рабочего процесса",
    // Transcript / Translation
    closeTranscript: "Закрыть транскрипцию",
    popupMsgCloseTranscript: "У вас есть несохраненные изменения в вашей транскрипции. Если вы продолжите, что вы будете терять все изменения </b>. Уверены ли вы?",
    stepNoTranscript: "Шаг без стенограммы",
    originalTranscript: "Оригинальная транскрипция",
    addLanguageTooltip: "Нажмите здесь, чтобы добавить новые языки на ваше видео",
    closeTranslation: "Закрыть перечас",
    popupMsgCloseTranslation: "У вас есть несохраненные изменения в вашем переводе. Если вы продолжите, что вы будете терять все изменения </b>. Уверены ли вы?",
    switchTranslation: "Перевод перевода",
    popupBtnNoSwitch: "Нет, просто переключаться",
    popupBtnYesSwitch: "Да, сохранить и переключиться",
    createAudio: "Создать аудио",
    addNewLanguageTooltip: "Нажмите здесь, чтобы добавить новый язык на ваше видео",
    popupMsgCreateAudio: "Вы уверены, что хотите создать аудио переводы для вашего рабочего процесса? Мы настоятельно рекомендуем вам делать это только один раз, поскольку Стефани обработает всю вашу транскрипцию.",
    popupBtnYesCreate: "Да, создать",
    alertAudioBeingCreated: "Ваше перевод аудио создавается.",
    alertTranscriptionSaved: "Транскрипция успешно сохранена.",
    alertTranscriptionSaveFailed: "Не удалось сохранить транскрипцию.",
    alertTranslationsSaved: "Переводы успешно сохранены.",
    alertTranslationsSaveFailed: "Не удалось сохранить переводы.",
    alertAudioCreated: "Аудио успешно создан.",
    alertAudioFailed: "Не удалось создать свой аудио перевод.",
    alertNoAudioGenerated: "Нет аудио не сгенерировано, потому что на переводе нет обновления.",
    alertErrorCreateTranscription: "Ошибка создания транскрипции.",
    //Share workflow
    shareWorkflow: "Поделиться рабочим процессом",
    fullWorkflow: "Полный рабочий процесс",
    specificSteps: "Определенные шаги",
    chooseShareType: "Выберите тип",
    qrCode: "QR код",
    link: "Ссылка на сайт",
    embedLink: "Встраиваемая ссылка",
    privateWorkflow: "Частный рабочий процесс",
    publicWorkflow: "Общедоступный рабочий процесс",
    close: "Закрыть",
    saveQRCode: "Сохранить QR код",
    download: "Скачать",
    copyLink: "Копировать ссылку",
    copied: "Скопирован",
    downloading: "Загрузка",
    videoPrivacy: "Конфиденциальность видео",
    popupMsgVideoPrivacy: "Когда вы превращаете свой рабочий процесс в общедоступный, то доступ к видео сможет получить кто-либо, даже без DeepHow Account. Вы уверены?",
    includeSubtitles: "Включают субтитры",
    noSubtitles: "Нет субтитров",
    selectLanguage: "Выберите язык",
    workflowReady: "Рабочий процесс готов!",
    prepareToDownload: "Скачать",
    processingDownloadVideoMsg: "Не закрывайте эту страницу! <br/> Через несколько минут ваш файл будет готов к загрузке.",
    processingWorkflow: "Обработка рабочего процесса ...",
    openAnotherTabMsg: "Пока вы ждете, вы можете ориентироваться на DeepHow на другой вкладке",
    openNewTab: "Открыть новую вкладку",
    alertErrorPreparingDownload: "Произошла ошибка при подготовке загрузки. Пожалуйста, попробуйте еще раз или свяжитесь с DeepHow для помощи.",
    hide: "Скрывать",
    addNewLink: "Добавить новую ссылку",
    clickToAddLinks: "Нажмите, чтобы добавить ссылки на этот шаг",
    addALink: "Добавить ссылку",
    editLink: "Редактировать ссылку",
    addLinkAddress: "Введите адрес ниже, чтобы добавить ссылку на этот шаг.",
    addLinkLable: "Вы хотите добавить этикетку?",
    clickOpenOfficialDocument: "I.E: Нажмите, чтобы открыть официальный документ",
    //settings /categories
    settings: "Настройки",
    categories: "Категории",
    noCategories: "Вы еще не создали никаких категорий.",
    selectCategoriesMessage: "Выберите хотя бы одну категорию, чтобы пользователи смогли проще фильтровать видео при поиске",
    closeSettings: "Закрыть настройки",
    popupMsgCloseSettings: "У вас есть несохраненные изменения. Если вы продолжите вас <b> потеряют все изменения </b>. Уверены ли вы?",
    workflowCategories: "Категории рабочего процесса",
    popupBtnAssignCategoriesAndPublish: "Назначить категории и опубликовать",
    popupBtnSkipAndPublish: "Пропустить этот шаг и в любом случае опубликовать",
    popupMsgworkflowCategories: "Прежде чем опубликовать, почему бы вам не выбрать некоторые категории для вашего рабочего процесса, чтобы пользователям было проще фильтровать видео при поиске?",
    alertCategoriesSaved: "Категории успешно сохранены.",
    alertCategoriesSaveFailed: "Не удалось сохранить категории.",
    searchForCategoryName: "Искать {name} ...",
    loadingCategories: "Категории погрузки ...",
    alertFailedDownloadVideo: "Не удалось скачать видео",
    // Workflow Cover
    workflowCover: "Крышка рабочего процесса",
    selectFromTheVideo: "Выберите из видео",
    browseForAFile: "Просмотреть файл",
    dropFileMessage: "Оставьте свой файл или нажмите здесь, чтобы просмотреть его.",
    selectedImage: "Выбранное изображение",
    noImageSelected: "Изображение не выбрано",
    alertSuccessfullyUpdatedCover: "Обложка рабочего процесса была успешно обновлена.",
    alertFailedUpdatedCover: "Не удалось обновить крышку рабочего процесса.",
    selectAnotherImage: "Выберите другое изображение",
    alertErrorStepLostTranscription: "В настоящее время у вас есть шаги, которые не были переведены. Пожалуйста, переведите их, прежде чем вы сможете скачать рабочий процесс.",
    annotation: "Аннотация",
    allNotes: "Все примечания",
    noNotesAddedYet: "Пока не добавлены заметки.",
    popupMsgEnterTrim: "Ваш шаг имеет аннотации. Если вы хотите обрезать видео, то эти аннотации исчезнут.",
    popupMsgEnterErase: "Ваш шаг имеет аннотации. Если вы хотите стереть видео, то эти аннотации исчезнут.",
    popupMsgEnterAnnotation: "Пожалуйста, убедитесь, что обрезать или стирайте видео перед добавлением любых аннотаций.",
    workingOn: "Работа над",
    eraseStep: "Старайте шаг",
    popupMsgEraseSuccess: "Ваш шаг был успешно стерт!",
    popupBtnYesErase: "Да, стирай",
    popupMsgEraseConfirmation: "Вы уверены, что хотите стереть?",
    eraseProcessingMsg: "Стереть недоступен, пока выполняется обработка рабочего процесса. <br> Пожалуйста, вернитесь позже",
    translateOnlyTitlesBtn: "Перевести названия и имена шагов",
    untitled: "Без названия",
    hintNoEmptyTitle: "Название не может быть пустым",
    popupMsgSwitchTranslation: "У вас есть неспасенные изменения в вашем переводе <b>{lang}</b>. Хотели бы вы сохранить их, прежде чем переключиться?",
    alertNoEmptyContent: "Названия или предложения не могут быть пустыми. Пожалуйста, введите контент.",
    PDF: "PDF",
    PDFSizeLimit: "Размер файла PDF ограничен 50 МБ",
    addPDF: "Добавить PDF",
    onlyPDFAccepted: "Приняты только файлы PDF",
    preview: "Предварительный просмотр",
    // Send to reviewer
    sendToReviewer: "Отправить рецензенту",
    alertTitleSelectReviewers: "Выберите пользователя, чтобы просмотреть свой рабочий процесс",
    alertSendToReviewSuccess: "Ваш рабочий процесс был успешно отправлен на просмотр.",
    alertSendToReviewFailed: "Не удалось отправить на просмотр",
    subtitlesSize: "Размер субтитров",
    small: "Небольшой",
    medium:"Середина",
    big: "Большой",
    yourVideoReadyToDownload: "Ваше видео готово к скачиванию.",
    yourVideoWasSuccessfullyDownloaded: "Ваш рабочий процесс был успешно загружен.",
    notAvailable: "нет в наличии",
    downloadFileFormat: "формат",
    selectDownloadWorkflowQuality: "Выберите качество видео",
    downloadSizeLimitedMsg: "Скачать SCORM на {resolution} Поддерживает до {duration} минут рабочих процессов.",
    workflowTotalDurationMsg: "Рабочий процесс Общая продолжительность: {minute} мин {second} сек.",
    // Export Compliance Wire
    export: "Экспорт",
    code: "Код",
    organization: "Организация",
    approvalDate: "Дата утверждения",
    processing: "Обработка",
    workflowShouldBePublic: "Рабочий процесс должен быть публичным.",
    alertExportComplianceWireSuccess: "Экспортный контроль документ успешно. Код: {code}",
    alertExportComplianceWireExists: "Контрольный документ уже существует. Код: {code}",
    alertExportComplianceWireFailed: "Что -то пошло не так при экспорте контрольного документа.",
    confirmedPublished: "Вы уверены, что хотите опубликовать этот рабочий процесс? <br> Если вы сделаете это, все смогут его посмотреть.",
    versionDescription: "Версия {num} Описание (необязательно):",
    versionIsDuplicated: "Версия дублируется",
  },
  editor: {
    closeWorkflow: "Закрыть рабочий процесс",
    popupMsgCloseWorkflow: "В вашем рабочем процессе есть несохраненные изменения. Если вы продолжите, то изменения не будут применены. Вы уверены?",
    popupBtnClose: "Да, уверен",
    processWorkflow: "Рабочий процесс",
    popupMsgProcessWorkflow: "Отлично! Похоже, вы закончили свой обзор, и вы хотите, чтобы Стефани обработала его. <br><br><b>У вас не будет возможности вернуться к этому экрану просмотра. Вы готовы?</b>",
    popupBtnProcess: "Да, готов(а)",
    alertIsSaving: "Экономия осуществляется.",
    alertNoEmptySentence: "Предложения не могут быть пустыми. Пожалуйста, введите или удалите предложения, затем сохраните снова.",
    alertIsSaved: "Ваши изменения были сохранены.",
    alertWasUnpublished: "Ваш рабочий процесс был успешно неопубликован.",
    alertWasPublished: "Ваш рабочий процесс был успешно опубликован.",
    alertNoMoreSteps: "Вы не можете добавить больше шагов здесь.",
    alertNoEmptyTitle: "Название не может быть пустым. Пожалуйста, введите название, затем сохраните снова.",
    scrollToVoice: "Прокрутите до голоса",
    noAudioMsg: "Мы не могли найти аудио, чтобы транскрибировать в своем рабочем процессе ...",
    youWillDeleteStep: "Вы удалите шаг",
    areYouSure: "уверены ли вы?",
    areYouSureDelete: "Вы уверены, что хотите удалить?",
    replace: "Заменить",
    keyword: "Ключевое слово",
    find: "Находить",
    replaceWith: "Заменить",
    replaceAll: "Заменить все",
    replaceAllMsg: "Вы замените все эти слова.",
    of: "из",
    msgToSteps: "Анализирую ваш рабочий процесс... <br> Пожалуйста, подождите...",
    msgToEditor1: "Теперь давайте рассмотрим рабочий процесс перед публикацией",
    msgToEditor2: "Я буду вести вас на каждом шаге без забот",
    msgToBuilder1: "Почти там, дай мне сек",
    msgToBuilder2: "Я создаю шаги для вашего рабочего процесса",
    newStep: "Новый шаг",
    hintNoEmptySentence: "Предложения не могут быть пустыми",
    hintNoEmptyTitle: "(Название не может быть пустым)",
    alertNoTranscription: "Нет транскрипции для рабочего процесса.",
    alertEmptyingTranscription: "ПРЕДУПРЕЖДЕНИЕ! Вы опорожняете транскрипцию. Вы не можете сделать это",
    alertStepCorrupted: "Шаг {count} поврежден. Пожалуйста, удалите его перед обработкой",
    // Editor 2.0
    trim: "Подрезать",
    erase: "Стереть",
    voiceOver: "Голос окончен",
    videoEditing: "Редактирование видео",
    segmentation: "Сегментация",
    popupMsgRestToOriginal: "Вы уверены, что хотите сбросить на исходное видео? Все дополнительные изменения будут потеряны.",
    trimHintMessage: "Используйте стрелки, чтобы выбрать область, которую вы хотите обрезать",
    eraseHintMessage: "Используйте стрелки, чтобы выбрать область, которую вы хотите стереть",
    voiceOverHintMessage: "Выберите область, которую вы хотите озвучить ...",
    voiceOverStartHint: "Нажмите здесь, чтобы начать свою запись ...",
    voiceOverStart: "Записывать",
    voiceOverSave: "Сохранить запись",
    voiceOverCancel: "Отказаться",
    voiceOverCancelPopupMsg: "Вы уверены, что хотите удалить свою запись?",
    voiceOverPause: "Пауза",
    voiceOverResume: "Продолжить",
    unableToGetAudioInput: "Не обнаружено устройство аудио ввода",
    restart: "начать сначала",
    editVideo: "Редактировать видео",
    popupRequestTranscription: "Не могу найти транскрипцию для рабочего процесса. Давайте создадим новый.",
    // TODO 2023-11-30 added by andy
    popupMsgEditVideoWarning: "Все изменения, сделанные во время транскрипции, будут потеряны, вы уверены, что хотите перейти к редактированию?", // reserved until new wording is confirmed
    popupMsgEditVideoWarning2WithoutTTS: "Все изменения, сделанные на этапе транскрипции, будут утеряны, если вы продолжите редактировать ваш рабочий процесс. Правки, такие как обрезка, должны быть завершены перед продолжением озвучивания. Выберите Подтвердить, чтобы продолжить, или Отменить, чтобы вернуться.",
    popupMsgEditVideoWarning2WithTTS: "Все изменения, сделанные на этапе транскрипции, будут утеряны, если вы продолжите редактировать ваш рабочий процесс. Правки, такие как обрезка, должны быть завершены перед продолжением озвучивания или записью текста в речь (TTS). Выберите Подтвердить, чтобы продолжить, или Отменить, чтобы вернуться.",
    popupMsgReTranscriptionWarning: "ПРИМЕЧАНИЕ. Если вы выполнили какие-либо действия по редактированию видео (например, Подрезать/Стереть/Голос окончен), вы не сможете использовать функцию повторного транскрипции.",
    popupMsgReTranscriptionWarningMultiEditing: "Примечание: если вы выполнили какие-либо действия по редактированию видео (например, обрезка/озвучивание), вы не сможете использовать функцию повторного расшифровки.",
    popupMsgReTranscriptionWarningTextToSpeech: "Примечание: если вы выполнили какие-либо действия по редактированию видео (например, обрезка/озвучивание/Текст в речь), вы не сможете использовать функцию повторного расшифровки.",
    popupMsgReTranscriptionWarningTextToSpeech2: "Note: Any editing actions performed (cut, voice-over, text-to-speech) you will no longer be able to change the transcription language.",
    rotate: "Вращаться",
    rotateLeft: "Поверните влево",
    rotateRight: "Вращать правильно",
    goBack: "назад",
    goBackToEditorHome: "Вернитесь в редактор домой",
    zoom: "Зум",
    // transcription new features
    transcriptionCopySuccess: "Транскрипция скопирована в ваш буфер обмена",
    keyTerms: "Основные условия",
    AILabs: "ИИ Лаборатории",
    copyTranscription: "Копировать транскрипцию",
    changeLanguage: "Изменить язык",
    copy: "копия",
    changeLanguageTitle: "Пожалуйста, выберите новый язык для вашей транскрипции",
    changeLanguageSubTitle: "Стефани должна будет повторно обрабатывать всю вашу транскрипцию.",
    reProcessingYourVideo: "Стефани перерабатывает ваше видео ...",
    goBackToEditor: "Вы можете подождать здесь или вернуться к редактору, чтобы продолжать работать!",
    // keyterms: added on 08/09/2022
    addTerm: "Добавить термин",
    removeTerm: "Удалить термин",
    termsFound: "<span>0</span> термин найден | <span>1</span> термин найден | <span>{count}</span> Условия найдены",
    loadingTerms: "Условия загрузки",
    termsInfo: "Условия могут быть очень полезными при поиске конкретного содержания в рабочих процессах.",
    // Editor 3.0
    cut: "Резать",
    tabsWarningMessages: "Предупреждение! Если вы покидаете эту страницу без сохранения, все изменения, которые вы внесли, будут потеряны.",
    versionHistory: "История версий",
    seeMore:"узнать больше...",
    seeLess:"Смотрите меньше ...",
    workflowCreated:"Рабочий процесс создан",
    createdBy: "Создано {name}",
    publishedBy: "Опубликовано {name}",
    lastUpdateBy: "Последнее обновление от {name}",
    emptyTitle: "Нет версий!",
    emptyContent: "Этот рабочий процесс еще не был опубликован, как только вы впервые опубликуете, вы начнете видеть здесь версии.",
    // added by andy 22/08/2023
    textToSpeech: "Текст в речь",
    play: "Играть",
    retry: "Повторно",
    generate: "генерировать",
    generating: "Генерирование",
    addBlock: "Добавить блок",
    addNewBlock: "Добавить новый блок",
    audioSpeed: "{value} x скорость",
    audioInputPlaceholder: "Вставьте текст ...",
    alertNeetToGenerateAllBeforeSave: "Вам нужно генерировать все блоки TTS, прежде чем сохранить ваш рабочий процесс.",
    alertNeetToSaveBeforeLeave: "У вас есть неспасенные изменения на ТТ, вы хотите сэкономить перед выходом?",
    alertExceedVideoDuration: "Общее время Audio Blocks превышает оригинальную продолжительность видео. Пожалуйста, отрегулируйте его.",
    alertTextToSpeechCreatingTitle: "Стефани создает голос в ваш текст",
    alertProcessingBackToDesc: "Вы можете подождать здесь или вернуться к редактору домой, чтобы продолжать работать!",
    alertTextToSpeechAdjustingTitle: "Стефани корректирует ваш TTS",
    alertHoldOn: "Пожалуйста, держись немного!",
    generateAllBlocks: "Генерировать все блоки",
    generateAll: "генерировать все",
    originalAudio: "Original Audio",
    originalVideo: "Оригинальное видео",
    enArial: "English - Aria",
    enEric: "English - Eric",
    enJane: "English - Jane",
    enJason: "English - Jason",
    zhXiaomeng: "Chinese - Xiaomeng",
    zhYunyang: "Chinese - Yunyang",
    frSylvie: "French - Sylvie",
    frAntoine: "French - Antoine",
    esJorge: "Spanish - Jorge",
    esLarissa: "Spanish - Larissa",
    manualTextInput: "Ввод ручного текста",
    typeYourTextManually: "Введите свой текст вручную",
    uploadFile: "Загрузить файл",
    useTxtPdfDoc: "Используйте txt, pdf, doc",
    selectAnOptionBelow: "Выберите опцию ниже:",
    startCreatingTTSFromScratch:"Начните создавать TTS с нуля",
    startATTSWillConvertAllTranscription: "Запуск TTS преобразует всю транскрипцию вашего рабочего процесса в TTS Voice.",
  },
  player: {
    loadingVideo: "Загрузка видео ...",
    loadingLanguage: "Язык погрузки ...",
    previousStep: "Предыдущий шаг",
    nextStep: "Следующий шаг",
    audio: "Аудио",
    subtitles: "Субтитры",
    autoPlay: "Автовоспроизведение",
    off: "Выкл.",
    on: "Вкл.",
    quality: "Качество",
    speed: "Скорость",
    normal: "Нормальная",
    rotatePhone: "<span class='primary--text'> Поверните </span> Ваш телефон для <br> лучше",
    alertTranscodingFailed: "Ой, выглядит как видео транскодирование не удалось. Пожалуйста, свяжитесь с DeepHow для дальнейшей помощи.",
    alertTranscodingStillProgress: "К сожалению, похоже на видео транскодирование все еще в процессе. Пожалуйста, вернитесь позже.",
    settings: "Настройки",
    openInBrowser: "Открыть в браузере",
    goToHome: "Иди домой",
    thisWorkflowWasUnpublished: "Этот рабочий процесс был неопубликован",
    thisWorkflowWasDeleted: "Этот рабочий процесс был удален",
    talkToSupervisor: "Если вы считаете, что этот рабочий процесс должен быть опубликован, поговорите с вашим руководителем",
    selectWorkspace: "Выберите рабочее пространство",
    // player2.0
    home: "Главная страница",
    myWorkflows: "Мои рабочие процессы",
    recommendedForYou: "Рекомендуется для вас",
    favorites: "Избранное",
    welcomeToDeepHowNavigator: "Добро пожаловать в DeepHow Navigator",
    numNewSkillsTrainings: "Вам назначено <span>0 новых навыков.</span> | Вам назначен <span> 1 новый навык.</span> | Вам назначено <span>{count} новых навыков.</span>",
    clickForTips: "Нажмите здесь для некоторых смартвых советов.",
    learnHow: "Узнать, как",
    goToSkills: "Перейти к навыкам",
    at: "в",
    youAreIn: "Ты в",
    loadingRecommendations: "Рекомендации по загрузке ...",
    loadingFavoriteWorkflows: "Загрузка любимых рабочих процессов ...",
    noFavoriteWorkflows: "У вас пока нет товаров в избранном.",
    canFindSavedItemsHere: "Вы можете найти все ваши сохраненные предметы здесь.",
    removeWorkflowFromFavorites: "Вы уверены, что хотите удалить этот рабочий процесс из ваших любимых?",
    yesGoAhead: "Да, начинай",
    thisWorkflowIsNotAvailable: "Этот рабочий процесс недоступен",
    dismiss: "Скрыть",
    navigator: "Навигатор",
    howToUseNavigator: "Узнайте, как использовать новый <br> <b> DeepHow navigator </b>",
    checkTheNewFeatures: "Проверьте новые функции.",
    unknownUser: "Неизвестный пользователь",
    okCloseBtn: "Хорошо, близко.",
    emptyRecommendWorkflowTitle: "Привет! Где содержимое?",
    emptyRecommendWorkflowDescription: "Не волнуйся! Как только рабочие процессы созданы, я рекомендую для вас некоторых. Возвращайся скорее!",
    thisWorkflowDoesNotExist:"Этот рабочий процесс не существует",
    // navigator
    resume: "Продолжить",
    myWorkspaces: "Мои рабочие пространства",
    otherWorkspaces: "Другие рабочие пространства",
    loading: "Загрузка ...",
    loadingfavoriteWorkspaces: "Загрузка любимых рабочих областей ...",
    removeWorkspaceFromFavorites: "Вы уверены, что хотите удалить это рабочее пространство из ваших любимых?",
    YouAreViewing: "Вы просматриваете",
    allWorkspaces: "Все рабочие пространства",
    removeFromFavorites: "Удалить из Избранного",
    favoriteThisVideo: "Фаворит это видео",
    shareThisVideo: "Поделиться этим видео",
    viewAll: "Посмотреть все",
    openFile: "Открыть файл",
    suggestedKeyterms: "Предлагаемые ключевые термины",
    // workflow player added 10/10/2022
    posted: "Опубликовано",
    views: "0 Просмотр | 1 Просмотр | {count} Представления",
    saveFavorite: "собирать",
    react: "отзывы клиентов",
    numColleaguesReact: "0 отзывов коллег | 1 отзыв коллеги | {count} отзывов коллег",
    youAndNumColleaguesReact: "Отзыв от <span>вас</span> и 0 коллег | Отзыв от <span>вас</span> и 1 коллеги | Отзыв от <span>вас</span> и {count} коллег",
    youReacted: "Отзыв от <span>вас</span>",
    userReaction: "отзывы клиентов",
    fullscreen: "Полноэкранный",
    exitFullscreen: "Выйдите из полного экрана",
    // 1/23/2023 Comments
    comments: "Комментарии",
    deleteComment: "Удалить комментарий",
    deleteCommentMsg: "Вы уверенны, что хотите удалить этот комментарий? <br> Это не может быть отменено.",
    edit: "Редактировать",
    updating: "Обновление ...",
    changesSaved: "Изменения сохранены.",
    noComments: "Комментариев пока нет…",
    makeFirstComment: "Сделайте первый комментарий ниже",
    comment: "комментарий",
    writeAComment: "комментарий",
    edited: "Написать комментарий...",
    emojiRecent: "отредактирован",
    emojiSmileys: "Часто используемый",
    emojiPeople: "Смайты и смайлика",
    emojiNature: "Люди и тело",
    emojiFoods: "Животные и природа",
    emojiActivity: "Еда, напиток",
    emojiPlaces: "Активность",
    emojiObjects: "Путешествие и места",
    emojiSymbols: "Объекты",
    emojiFlags: "Символы",
    emojiCustom: "Флаги",
    back: "назад",
    feedback: "Обратная связь",
    leaveAFeedback: "Оставить отзыв",
    leaveAFeedbackMsg: "Расскажите нам о возникших проблемах, предложениях или конструктивной критике.",
    typeYourMessageHere: "Введите ваше сообщение здесь",
    submit: "Отправить",
    thankYou: "Спасибо.",
    feedbackSuccessMsg: "Ваш отзыв успешно отправлен.",
    alertFeedbackFailedMsg: "Не удалось отправить ваш отзыв. Пожалуйста, попробуйте еще раз.",
    diagrams: "диаграммы",
    pdfFiles: "PDF -файлы",
    theaterMode: "Режим кинотеатра",
    exitTheaterMode: "Выйти из режима кинотеатра",
    // TODO: 09/08/2023
    enableWorkflowRepeat: "Включить повторение рабочего процесса",
    enableStepRepeat: "Включить шаг повторить",
    disableRepeat: "Отключить повтор",
  },
  profile: {
    // added 12/09/2021
    addPositionLocation: "Добавьте положение и местоположение",
    noWorkflowsCreated: "Еще не создано рабочие процессы.",
    comeBackLater: "Вернуться позже.",
    createdWorkflows: "Созданы рабочие процессы",
    profileOwnerNoContentMsg: "<span>Привет, {name}! Вот ваша новая страница профиля.</span> <br> В будущем вы сможете поделиться знаниями со всеми. Подожди!",
    profileColleguesNoContentMsg: "<span>это страница профиля {name}.</span> <br> Он еще не может публиковать контент, но в ближайшем будущем он будет!",
    editProfile: "Редактировать профиль",
    personalInformation: "Персональная информация",
    personalInformationMsg: "Люди, посещающие ваш профиль, увидят следующую информацию.",
    photo: "Фото",
    position: "Позиция",
    locationRegion: "Место/ регион",
    whatYouDo: "Пусть люди знают, что вы делаете в этой организации.",
    whereYouBased: "Пусть люди знают, где вы находитесь.",
    accountManagement: "Управление аккаунтом",
    accountManagementMsg: "Эта информация является частной и не будет отображаться в вашем публичном профиле.",
    identifyMyself: "Я идентифицирую себя как",
    yourPassword: "Ваш пароль",
    nonDeclared: "Не декорально (по умолчанию)",
    male: "Мужчина",
    female: "Женский",
    nonBinary: "Невоичный",
    viewProfile: "Просмотреть профиль",
    alertFailedToUpdateProfile: "Не удалось обновить ваш профиль."
  },
  explorer: {
    search: "Поиск",
    in: "",
    howTo: "Как…",
    didYouMean: "Вы имеете в виду",
    searchInTitle: "В заголовке",
    searchInContent: "В содержании",
    searchInVideo: "Предложения Стефани",
    title: "Заголовок",
    searchStartMsg1: "Что вы ищете?",
    searchStartMsg2: "Используйте окно поиска выше",
    sortBy: "Сортировать по",
    lastUpdated: "Последнее обновление",
    alphabetically: "В алфавитном порядке",
    workflowsIn: "Рабочие процессы внутри",
    tagNew: "Новый",
    tagProcessing: "Обработка",
    msgProcessing: "Стефани обрабатывает ваш рабочий процесс ... <br/> Пожалуйста, подождите :)",
    tagFailed: "Не удалось",
    msgFailed: "Ой, что-то пошло не так при обработке речи или видео. Мы снова повтореемся снова ...",
    msgVideoLimitExceeded: "Видео превысило длину, поддерживаемую DeepHow. Он будет автоматически повторно загружен в двух или более рабочих процессах.",
    msgVideoExceededMaximum: "Видео превышает максимальную длину рабочего процесса, поддерживаемую Deephow. Он будет автоматически разделен на два или более рабочих процесса",
    tagTranscoding: "Транскодирование",
    msgTranscoding: "Стефани транскодирует ваш рабочий процесс ... <br/> Пожалуйста, подождите :)",
    deleteWorkflow: "Удалить рабочий процесс",
    popupBtnDelete: "Да, удалить",
    popupMsgDeleteWorkflow: "Вы уверены, что хотите удалить этот рабочий процесс? Вы потеряете все шаги и данные, связанные с этим рабочим процессом.",
    noUnpublishedWorkflows: "У вас еще нет неопубликованных рабочих процессов ...",
    noPublishedWorkflows: "У вас еще нет публикуемых рабочих процессов ...",
    startCreatingWorkflow: "Давайте начнем создавать рабочий процесс!",
    startPublishingWorkflow: "Давайте начнем публиковать рабочий процесс!",
    noSearchResult: "Результаты не найдены…",
    trySearchAgain: "Пожалуйста, попробуйте поискать снова",
    searchingForWorkflows: "В поисках рабочих процессов ...",
    secondsAgo: "0 секунд назад | 1 секунда назад | {count} секунды назад",
    minutesAgo: "0 минут назад | 1 минуту назад | {count} минуты назад",
    hoursAgo: "0 часов назад | 1 час назад | {count} часы назад",
    daysAgo: "0 дней назад | 1 день назад | {count} дни назад",
    monthsAgo: "0 месяцев назад | 1 месяц назад | {count} месяцы назад",
    yearsAgo: "0 год назад | 1 год назад | {count} годы назад",
    sAgo: "{count} сек.",
    minAgo: "{count} мин.",
    hAgo: "{count} ч.",
    dAgo: "{count} д.",
    mAgo: "{count} мес.",
    yAgo: "{count} г.",
    renameWorkflow: "Переименовать рабочий процесс",
    moveWorkflowTo: "Переместить рабочий процесс к",
    duplicateWorkflow: "Дубликат рабочего процесса",
    unpublishWorkflow: "Переместить в Неопубликованные",
    shareKnowhow: "Поделиться ноу-хау",
    or: "Или",
    clickHere: "кликните сюда",
    andCopyMsg: "и скопировать URL ссылку на отправку по электронной почте",
    successfulSharing: "Успешный обмен.",
    send: "Отправить",
    typeHereAMessage: "Введите здесь сообщение",
    wellDone: "Отличная работа",
    numMore: "{count} Больше",
    moveWorkflow: "Переместить рабочий процесс",
    currentWorkspace: "Текущее рабочее пространство",
    whereToMsg: "Куда вы хотите переместить этот рабочий процесс?",
    move: "Переместить",
    alertSuccessfullyMoved: "Успешно переместил рабочий процесс.",
    alertFailedMoved: "Не удалось переместить рабочий процесс.",
    noNotificationsMsg: "У вас нет уведомлений",
    betaSearch: "Пожалуйста, имейте в виду, что предложение Stephanie все еще в бета -версии ...",
    relevantSteps: "Соответствующие шаги:",
    relevance: "актуальность",
    resultsFor: "{num} результатов для <b>&ldquo;{input}&rdquo;</b>",
    resultFor: "{num} результат для <b>&ldquo;{input}&rdquo;</b>",
    byPublisher: "издателем",
    alertFailedShareWorkflow: "Не удалось поделиться рабочим процессом.",
  },
  new: {
    newWorkflow: "Новый рабочий процесс",
    uploadVideo: "Загрузить видео",
    recordScreen: "Запись экрана",
    replaceVideoSubtitle: "Перетащите или нажмите, чтобы заменить ваше видео.",
    recordScreenSubtitle: "Стефани готова начать запись экрана.",
    workflowName: "Название рабочего процесса",
    workflowLanguage: "Язык рабочего процесса",
    addToWorkspace: "Добавить в рабочее пространство",
    upload: "Загрузить",
    startRecording: "Начать запись",
    cancel: "Отмена",
    wrongUploadFile: "Тип файла не поддерживается. Пожалуйста, выберите следующий тип файла:",
    limitFileDuration: "Продолжительность файла поддерживает до {duration} мин. Пожалуйста, используйте приложение DeepHow Uploader для Windows",
    limitFileDurationGen2: "Продолжительность файла поддерживает до {duration} мин.",
    uploadingVideo: "Загрузка видео",
    uploadingVideoSubtitle: "Пожалуйста подождите, пока Стефани загружает ваше видео",
    renderingVideo: "Рендеринг / сохранение видео",
    renderingVideoSubtitle: "Пожалуйста, подождите, пока Стефани оказывает ваше видео",
    almostDone: "Мы почти закончили!",
    uploadingStep1: "Шаг 1 из 2 - Анализ рабочего процесса ...",
    uploadingStep2: "Загрузка видео ...",
    successSubtitle: "Ваш рабочий процесс был успешно загружен",
    goToEditor: "Перейти в редактор",
    goToContentManger: "Перейти к менеджеру контента",
    uploadingAlert: "Закрытие вкладки браузера приведет к ошибкам в процессе загрузки.",
    recordingScreen: "Экран записи",
    support15Min: "Имейте в виду, что максимальная продолжительность записи экрана <b> 15 мин </b>.",
    hereWeGo: "Будьте готовы!",
    remember15Min: "Помните, что у вас есть 15 мин.",
    windowsAppAvailable: "Приложение Windows <br> теперь доступен.",
    windowsAppAvailableNews: "Приложение DeepHow Uploader уже доступно для Windows. Теперь вы можете загружать большие файлы, а также записать экран.",
    downloadNow: "Скачать сейчас!",
    deepHowWindowsApp: "DeepHow Windows приложение",
    downloadAndInstalltheWindowsApp: "Загрузите и установите приложение Windows.",
    notAllowedToInstall: "Если вам не разрешено устанавливать версию выше,",
    downloadThisVersion: "Скачать эту версию",
    and: " и",
    watchThisVideo: "<span>Смотреть это видео</span> на том, как его использовать.",
    // Added on 2022/01/19
    abiAuthorizationLabel: "Настоящим я признаю, что все лица, участвующие в этом видео, подписали <span>Разрешение на фото- и видеозапись</span> и Релиз, характерные для моей страны.",
    abiPrivacyLabel: "Eu concordo que li e entendi a <span>declaração de privacidade de gravação de fotografia Abinbev.</span>",
    // Added on 2022/06/15
    importFromZoom: "Импорт из Zoom",
    importFromZoomSteps: "Шаг {step} {totalStep}",
    selectVideosFrom: "Выберите видео из",
    videoSelected: "<span> 0 </span> выбран видео | <span> 1 </span> видео выбрано | <span> {count} </span> выбранные видео",
    workflowInfo: "Информация о рабочем процессе",
    newImport: "Новый импорт",
    orBackToEditor: "Или вернуться к <span>редактору</span>",
    importFromZoomSuccessMsg: "Ваши видео начнут загрузку в ближайшее время, может потребоваться некоторое время, прежде чем они будут доступны для редактирования.",
    zoomFailedUploadMsg: "Не удалось загрузить видео Zoom",
    zoomPeriodLastMonth: "прошлый месяц",
    zoomPeriodPastMonth: "прошлые {n} месяцы",
    loadingZoomRecordings: "Загрузка видео",
    zoomNoRecordingsInfo: "Никаких записей не найдено за выбранный период.",
    yourVideoExceedTwoHours: "Ваше видео превышает 2 часа и не может быть загружено.",
    yourVideoExceed3GSize: "Ваше видео превышает 3 ГБ и не может быть загружено.",
    yourVideoExceedTwoHoursAnd3GSize: "Ваше видео превышает 2 часа, 3 ГБ и не может быть загружено.",
    videoUploadWarningMessage: "Перетащите или нажмите, чтобы импортировать видео ({supportedFileTypes}) Max. Размер 3 ГБ и до 2 часов.",
    videoUploadWarningMessageGen2: "Перетащите или нажмите, чтобы импортировать видео ({supportedFileTypes}) Max. до 40 минут.",
  },
  auth: {
    aboutDeephow: "О Deephow",
    authTitle: "Первое решение AI для опыта квалифицированных сделок.",
    authIntro: "DeepHow Bridges Разрыв навыков в производстве, обслуживании и ремонте через платформу обучения на основе AI на основе интерактивных видео.",
    allRightReserved: "Все права защищены",
    logIn: "Авторизоваться",
    logOut: "Выйти",
    logInSubtitle: "Пожалуйста, введите ваши данные, чтобы войти в систему.",
    logInSubtitleSso: "Пожалуйста, нажмите ниже, чтобы войти в систему.",
    emailOrId: "E-mail или ID сотрудника",
    hello: "Привет,",
    passwordSubtitle: "Пожалуйста, введите свой пароль.",
    password: "Пароль",
    forgotPassword: "Забыли пароль",
    forgotPasswordSubtitle: "Пожалуйста, введите свой адрес электронной почты, поэтому мы можем отправить вам ссылку для создания нового пароля.",
    email: "Электронная почта",
    resetPassword: "Сброс пароля",
    alertResetSuccess: "Пожалуйста, проверьте свой почтовый ящик.",
    alertResetFailed: "Не удалось восстановить пароль.",
    alertInvalidId: "Неверный идентификатор сотрудника.",
    successMsg: "Пожалуйста, проверьте свой адрес электронной почты и нажмите на ссылку, чтобы определить новый пароль для своей учетной записи.",
    logInWithSSO: "Войти с SSO",
    loggingInWithSSO: "Войти с SSO ...",
    logInWithGoogle: "Войти с Google",
    logInWithMicrosoft: "Войдите с Microsoft",
    loadingSso: "Загрузка SSO ...",
    about: "о",
    // 12/17/2021 iframe
    loginToYourAccount: "<b>Войдите</b> в свою учетную запись",
    pleaseInputYourPassword: "Пожалуйста, введите ваш пароль.",
    backToLogin: "Пожалуйста, введите ваш пароль.",
    // added 03/02/2022
    wrongPassword: "Неправильный пароль.",
    // Shared Account
    sharedDevice: "Общее устройство",
    loginExistingAccount: "Войти в существующую учетную запись",
    createNewAccount: "Создать новый аккаунт",
    noUsersMsg: "Пользователи еще не вошли в это общее устройство ...",
    insertIDMsg: "Пожалуйста, вставьте идентификатор своего сотрудника",
    createIdUserMsg: "Мы <b>почти там</b>! <br> Пожалуйста, заполните свои данные ниже.",
    confirmEmployeeID: "Подтвердите идентификатор сотрудника",
    welcomeToDeepHow: "Добро пожаловать в Deephow, <b>{name}</b>",
    confirmWorkspaceTeamMsg: "Пожалуйста, подтвердите свою рабочую область и выберите свою команду!",
    LetsGetStarted: "Давайте начнем!",
    // added 05/26/2022
    useSsoLogin: "Вход электронной почты/пароля не включен для вашей организации. Пожалуйста, войдите в систему с SSO.",
    // added 02/17/2023
    cookieAlert: "Я принимаю использование файлов cookie, как подробно описано в нашем <a href='{pdfUrl}' target='_blank'> Политика конфиденциальности </a>.",
    useCookie: "Вы принимаете наши куки?",
    useCookieContext: "Мы используем файлы cookie для улучшения опыта и предоставления вам более персонализированных услуг. Чтобы узнать больше о файлах cookie, которые мы используем, см. Наш <a style='color: #DFE0E0;' href='{url}' target='_blank'> Политика конфиденциальности </a>.",
    useCookieContextMobile: "Мы используем файлы cookie для улучшения опыта и предоставления вам более персонализированных услуг. Чтобы узнать больше, см. Наш <a style='color: #dfe0e0;' href='{url}' target='_blank'> Политика конфиденциальности </a>.",
    privacyPolicy: "Политика конфиденциальности",
    acceptCookies: "Принять печенье",
    alertDisableDHLogin: "Вход для идентификации сотрудников не разрешен для этой организации.",
    alertPasswordFormatError: "Password format validation failed.",
    alertPasswordReused: "Password is used for the last 5 time(s).",
    alertPasswordIncludeCertainValue: "Password is including the email/name of the user.",
    alertPasswordMustUpdate: "Passwords must be changed after 90 days.",
    alertPasswordWillExpire: "password will expire in {n} day(s)",
    alertLoginFailed: "Something went wrong while logging in.",
    alertFailedToGetSharedDeviceSettings: "Something went wrong while getting Shared Account.",
    alertUserNotFound: "User is not found.",
    alertEmployeeIdInvalid: "Employee ID is invalid.",
    alertAccountIdAlreadyUsed: "Account ID is already used.",
    alertAccountIdInvalid: "Account ID is invalid.",
    alertMasterPasswordIncorrect: "Password is incorrect.",
    alertChangeUserNameFailed: 'Failed to change user name.',
    checkIpPopupTitle: "Проверка вашего IP и роли ...",
    checkIpPopupDesc: "Пожалуйста, держись немного!",
    checkIpDeephowDesc: "Ноу-хау видео платформа",
    checkIpNotAllowedTitle: "IP -адрес не разрешен",
    checkIpNotAllowedDesc: "Доступ к этому приложению разрешен только в определенных местах. Пожалуйста, свяжитесь с вашим администратором для получения дополнительной информации!",
    checkIpAlertForOrgAdmin: "Доступ к этому приложению разрешен только в определенных местах. Как орг. Администратор у вас есть доступ только к администратору.",
    customize: "Настройка",
    acceptCookies: "Принять печенье",
    cookiesSettings: "Настройки файлов cookie",
    seeOurPrivacyPolicy: "Смотрите наш <a href='{pdfUrl}' target='_blank'> Политику конфиденциальности </a>",
    noAccept: "Я не принимаю куки",
    saveAndAccept: "Сохранить и принять",
    cookieConsentDesc: 'Мы используем файлы cookie на этом сайте, некоторые из них важны, в то время как Othres помогает нам улучшить опыт и предоставлять вам более персонализированные услуги.',
    mandatoryAlertTitle: "{consentTitle} необходимы",
    mandatoryAlertDesc: "Если вы не принимаете {consentTitle}, вы не сможете получить доступ к Deephow.",
    DontAcceptAndExit: "Не принимайте и не выйдите",
    acceptConsent: "Принять {consentTitle}",
  },
  rules: {
    required: "Необходимые.",
    invalidEmail: "Неверный адрес электронной почты.",
    noSpace: "Не могу добавлять пробелы.",
    validPassword: "Пароль должна быть не менее 6 символов, включает в себя номер, нижнюю и верхнюю букву буквы.",
    maxPassword: "Максимум 16 символов.",
    mustMatch: "Пароли должны совпадать.",
    max25: "Макс 25 символов.",
    max90: "Макс 90 символов.",
    max100: "Максимум 100 символов.",
    max140: "Макс 140 символов.",
    numMaxCharacters: "Макс 0 символ. | Макс 1 символ. | Макс {count} символов.",
    min6: "Минимум 6 знаков.",
    noWhiteSpace: "Введите текст как заголовок.",
    noAllSpace: "Не может использовать пробелы как имя.",
    invalidEmployeeID: "Неверный идентификатор сотрудника.",
    invalidUrl: "неправильный адрес",
    mustMatchId: "Идентификатор сотрудника должен соответствовать.",
    canNotEnterSharedAccount: "Не может вставить общий идентификатор учетной записи.",
    validPasswordMin10: "Пароль должен быть не менее 10 символов, включающий номер, специальный символ, нижний и верхний корпус.",
    invalidName: "Неверное имя.",
  },
  alert: {
    success: "Успех",
    info: "Информация",
    alert: "Внимание",
    error: "Ошибка",
    dontShowThisMsgAgain: "Не показывайте это сообщение снова",
    unexpectedError: "Произошла неожиданная ошибка. Пожалуйста, попробуйте еще раз или свяжитесь с Deephow для получения помощи.",
    warning: "Предупреждение",
  },
  skills: {
    skillsMatrix: "Матрица навыков",
    createSkill: "Создать мастерство",
    skillsCreated: "Навыки созданы",
    skillsPublished: "Навыки опубликованы",
    skillsIn: "Навыки внутри",
    hintSearchSkills: "Поиск навыков ...",
    lastUpdated: "Последнее обновление",
    skillsPerPage: "Навыки на страницу",
    usersAssigned: "Пользователи назначены",
    status: "Статус",
    createdBy: "От",
    skillsSelected: "Навык выбран | Навык выбран | Навыки отобраны",
    updatedBy: "От",
    deleteSkill: "Удалить умение",
    popupMsgDeleteSkill: "Вы уверены, что хотите удалить этот навык?",
    deleteSkills: "Удалить навыки",
    popupMsgDeleteSkills: "Вы уверены, что хотите удалить эти навыки?",
    clearAll: "Очистить все",
    clearAllFilters: "Очистить все фильтры",
    clearFilter: "Очистить фильтр",
    delete: "Удалить",
    youAreViewing: "Вы просматриваете",
    numWorkflows: "0 рабочий процесс | 1 рабочий процесс | {count} рабочих процесса",
    searchIn: "Поиск в",
    loadingSkillMatrix: "Матрица навыков погрузки ...",
    createSkillSubtitle: "Выберите имя и где вы хотите загрузить его",
    skillName: "Название навыка",
    create: "Создавать",
    editSkill: "Редактировать умение",
    popupMsgEditSkill: "Если вы отправитесь в режим редактирования, ваш навык автоматически будет автоматически неопубликован, вы уверены, что хотите продолжить?",
    publishSkill: "Опубликовать мастерство",
    popupMsgPublishSkill: "Вы собираетесь опубликовать свои навыки, и пользователи смогут его увидеть, вы уверены, что хотите это сделать?",
    alertFailedSaveSkill: "Не удалось сохранить навыки",
    alertSkillSuccessfullyPublished: "Ваш навык был успешно опубликован.",
    alertSkillSuccessfullyUnpublished: "Ваш навык был успешно неопубликован.",
    alertSkillSuccessfullySaved: "Ваш навык был успешно сохранен",
    alertFailedSavedSkill: "Не удалось сохранить навыки",
    alertFailedPublishSkill: "Не удалось опубликовать навыки",
    alertSkillSuccessfullyDeleted: "Ваш навык был успешно удален",
    alertSkillFailedDeleted: "Не удалось удалить навык",
    content: "Содержание",
    addContent: "Добавить содержание",
    workflowDeleted: "Рабочий процесс удален",
    workflowsSelected: "рабочий процесс выбран | рабочий процесс выбран | рабочих процессов были выбраны",
    selectAll: "Выбрать все",
    remove: "Удалять",
    noContentMsg: "У вас нет контента в вашем навыке",
    assignUsers: "Назначьте пользователей",
    noUserMsg: "У вас нет пользователей, назначенных на вашу подготовку",
    numUsers: "<b>0</b> Пользователь | <b>1</b> Пользователь | <b>{count}</b> пользователей",
    assignedOn: "Назначен",
    certifiedBy: "Сертифицировано",
    progress: "Прогресс",
    aletUserSuccessfullyRemoved: "Пользователь был успешно удален.",
    removeUser: "Удалить пользователя",
    popupMsgRemoveUserFromSkill: "Вы уверены, что хотите удалить <b>{name}</b> из этого навыка?",
    assign: "Назначать",
    successfulAssigned: "Успешно назначен",
    AddedToSkill: "Добавлено в навык.",
    loadingSkills: "Навыки погрузки ...",
    searchingForSkills: "В поисках навыков ...",
    toDo: "Сделать",
    completed: "Завершенный",
    assignedBy: "Назначен",
    min: " мин.",
    nextPart: "Следующая часть",
    noSkillsAssignedMsg: "У вас еще нет назначенных навыков.",
    noSkillsAssignedMsg2: "Мы сообщим вам, когда у вас есть что-то новое!",
    noCompletedSkillsMsg: "У вас еще нет завершенных навыков.",
    noCompletedSkillsMsg2: "Давайте начнем обучать навыки!",
    noDataSkillsMatrix: "У вас пока нет данных в матрице навыков ...",
    certificate: "Сертификат",
    certificateMsg: "Пользователи получат сертификат завершения, если успешно заканчивает этот навык.",
    selectCertificateMsg: "Выберите под пользователями, которые смогут подтвердить сертификат:",
    validateSkill: "Проверка навыка",
    validateSkillMsg: "Вы уверены, что хотите проверить <b>{name}</b> в навыках? <br/> Вы не сможете отменить это.",
    yesValidate: "Да, проверка",
    noValidate: "Нет",
    certifier: "Сертификатор",
    signaturesOn: "на",
    teamProgress: "Прогресс команды",
    openTeam: "Открытая команда",
    removeCertificate: "Удалить сертификат",
    removeCertificateMsg: "Вы удалите все пользователи, которые могут подтвердить сертификат, вы уверены?",
    yesRemove: "Да, удалить",
    mustAddUserMsg: "* Сертификат не будет активирован, если пользователь не будет добавлен.",
    skillCover: "Крышка навыка",
    alertSuccessfullyUpdatedSkillCover: "Обложка навыков было успешно обновлено.",
    alertFailedUpdatedSkillCover: "Не удалось обновить обложку навыков.",
    closeSkill: "Близкий навык",
    allWorkflowsDeleted: "Все рабочие процессы удаляются в этом навыке.",
    waitingForValidation: "Ожидание проверки",
    validatedBy: "Проверенный:",
    skillWasUnpublished: "Навык был неопубликован",
    skillWasUnpublishedMsg: "Я дам вам знать, когда этот навык снова станет доступен. На данный момент вы все хорошо!",
    notAssignedToSkill: "Вы не назначены на этот навык.",
    notAssignedToSkillMsg: "Извините, эта страница доступна только пользователям, которые были назначены на этот навык.",
    remindPendingUsers: "Напомните в ожидании пользователей",
    alertSuccessRemindPendingUsers: "Успешно напомнил в ожидании пользователей.",
    alertFailedRemindPendingUsers: "Не удалось напомнить от ожидания пользователей",
    alertNoPendingUsers: "Нет ожидающих пользователей не найдено",
    numVideo: "0 видео | 1 видео | {count} видео",
    // Skill Deadline
    deadline: "Срок",
    deadlineMsg: "Установите глобальный период времени, чтобы пользователи закончили просмотр этого навыка. После крайнего срока ожидаемые пользователи будут уведомлены ...",
    deadlineForThisSkill: "Активируйте крайний срок для этого навыка.",
    certificateForThisSkill: "Активировать сертификат для этого навыка.",
    deadlineStartsMsg: "Период начинает считать, учитывая дату, когда пользователь был назначен.",
    selectAPeriod: "Выберите период",
    finished: "Законченный",
    editDeadline: "Редактировать крайний срок",
    editDeadlineMsg: "Редактируя крайний срок, предыдущий больше не будет действительным, и новый период будет применен для всех пользователей в этом навыке. <br> <br> ты уверен, что хочешь продолжить?",
    alertSuccessNewDeadline: "Новый крайний срок теперь применяется для всех пользователей.",
    alertSuccessRemoveDeadline: "Крайний срок навыки был успешно удален.",
    alertFailedDeadline: "Не удалось обновить крайний срок навыков.",
    dueToday: "Должен сегодня",
    turnOffDeadlineMsg: "Выключив крайний срок, предыдущие данные будут потеряны. В следующий раз, когда он будет включен, он сбросит все даты начала пользователя. <br> <br> ты уверен, что хочешь продолжить?"
  },
  time: {
    numDays: "0 день | 1 день | {count} дни",
    numWeeks: "0 неделя | 1 неделя | {count} недели",
    numMonths: "0 месяц | 1 месяц | {count} месяцы",
    numDaysLeft: "0 День остался | 1 день остался | {count} дни остались",
    numDaysDelayed: "0 дней задержано | 1 день задержано | {count} дни задержанные",
  },
  notification: {
    sharedWorkflow: "поделился рабочим процессом",
    assignedNewSkill: "поделился навыком",
    sentAnnouncement: "Отправил объявление",
    needValidation: "нуждается в вашей проверке",
    noNotificationsMsg: "У вас нет уведомлений",
    justNow: "Прямо сейчас",
    updatedSkill: "Обновлен навык",
    unpublishedSkill: "неопубликованный навык",
    sentAReminder: "отправил вам напоминание об этом навыке. Начните смотреть, чтобы он мог отслеживать ваш прогресс.",
    assignedNewSkillDue: "назначил вам новый навык. У вас есть <span>{time}</span>, чтобы закончить его.",
    delayedSkillMsg: "У вас есть <span>задержка навыка </span>. Начните смотреть сейчас, чтобы вернуться на правильный путь!",
    daysLeftMsg: "У вас осталось только <span>{time}</span>, чтобы закончить просмотр этого навыка. Спешите, чтобы вы не задерживали!",
    askToReviewWorkflow: "попросил вас просмотреть рабочий процесс.",
    reactedWorkflowMsg: "<span>{user}</span> отреагировал на ваш рабочий процесс.",
    reactedWorkflowMsgTwoUsers: "<span>{user1} и {user2}</span> отреагировали на ваш рабочий процесс.",
    reactedWorkflowMsgMoreUsers: "<span>{user1}, {user2} и еще {count} человек</span> отреагировали на ваш рабочий процесс.",
  },
  mediaPicker: {
    insertFullWorkflow: "Вставьте полный рабочий процесс",
    selectWorkflowStep: "Выберите рабочий процесс шаг",
    stepSelected: " Шаг выбран",
  },
  zoomIntegration: {
    settingsTitle: "Настройки Zoom",
    settingsSubtitle: "Интеграция",
    deleteCopy: "Удалить копию записанного видео из Zoom Cloud",
    goToDeephow: "Перейти к DeepHow",
    landingSuccess: "<b>DeepHow Knowhow Video App </b> Успешное установлено.",
    landingError: "Произошла ошибка при установке видео-приложения DeepHow Know-How </b>. Пожалуйста, свяжитесь с <a href = 'mailto: service@deephow.com'>service@deephow.com</a> Для поддержки.",
    landingWarning: "Интеграция Zoom не включена для вашей организации.",
  },
  reactions: {
    likeIt: "Нравится",
    greatContent: "Отличный контент",
    wellPresented: "Хорошо представленный",
    niceProduction: "Хорошее производство",
  },
  maven: {
    // TODO: maven chat added on 03/15/2023
    mavenChat: "Chat",
    searchInWorkspace: "Search in {workspace}",
    showReferences: "Show references",
    hideReferences: "Hide references",
    helloMessage: "Hello {userName},",
    assistMessage: "How can I assist you today?",
    askMeAnything: "Ask me anything...",
    tryOtherQuestionMessage: "You can try asking other question.",
    audioIsReady: "Please recording your audio clip in English...",
    audioLimitationTime: "The audio limitation time is 3 mins.",
    clearChatHistory: "Clear chat history",
    newLineHint: "new line: shift + Enter",
    // TODO: maven map added on 04/14/2023
    map: "Map",
    resetMap: "reset map",
    filterWorkspaces: "filter workspaces",
    showQuestions: "show questions",
    hideQuestions: "hide questions",
    showClusters: "show clusters",
    hideClusters: "hide clusters",
    question: "Question",
    loadingMap: "Loading map...",
    clearChatHistoryConfirmation: "Clearing the chat history will start a new chat. Are you sure?",
    clearConfirm: "Yes, clear",
  },
  $vuetify: {
    dataIterator: {
      rowsPerPageText: "Пункты на странице:",
      rowsPerPageAll: "Все",
      pageText: "{0} - {1} {2}",
      noResultsText: "Совпадающих записей не найдено",
      nextPage: "Следущая страница",
      prevPage: "Предыдущая страница",
    },
    dataTable: {
      rowsPerPageText: "Ряды на страницу:",
    },
    noDataText: "Данные недоступны",
    carousel: {
      prev: "Предыдущий визуальный",
      next: "Следующий визуальный",
    }
  }
};
