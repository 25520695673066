import { render, staticRenderFns } from "./PlayerProcessedMenu.vue?vue&type=template&id=478b57b6&scoped=true&"
import script from "./PlayerProcessedMenu.vue?vue&type=script&lang=js&"
export * from "./PlayerProcessedMenu.vue?vue&type=script&lang=js&"
import style0 from "./PlayerProcessedMenu.vue?vue&type=style&index=0&id=478b57b6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "478b57b6",
  null
  
)

export default component.exports