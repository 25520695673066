module.exports = {
  ad: {
    highlights: "Highlights",
    highlightsIntro: "Here you will always have a quick view of new feature releases, like this one. Also, Stephanie will remind you and help keep track of your ongoing Skills training.",
    recommendations: "Recommendations",
    recommendationsIntro: "Stephanie will recommend Workflows based on your activities around here. She’ll learn over every interaction you have with her!",
    favoritesIntro: "Ever wondered how awesome it would be to have all the Workflows you need the most, right there waiting for you?! Now you have a special place to keep them ready to go!",
    categoriesIntro: "So many Workflows, so little time... Wouldn't it be nice to just click and show categories that are relevant to you? Now you can! :)",
    loginMsgTitle: "New feature update!",
    loginMsg: "You can now easily search for a Workspace. Plus, check out content recommendations designed to help you best utilize your DeepHow account."
  },
  all: {
    success: "Success",
    player: "Player",
    editor: "Editor",
    admin: "Admin",
    analytics: "Analytics",
    skills: "Skills",
    skill: "Skill",
    unpublished: "Unpublished",
    published: "Published",
    signOut: "Sign out",
    step: "step",
    steps: "steps",
    numStep: "0 step | 1 step | {count} steps",
    transcription: "Transcription",
    ok: "OK",
    dontSave: "Don't save",
    saveChanges: "Save changes",
    no: "No",
    yes: "Yes",
    delete: "delete",
    diagram: "diagram",
    all: "All",
    discard: "discard",
    save: "save",
    next: "next",
    process: "Process",
    cancel: "Cancel",
    close: "Close",
    allUsers: "All Users",
    users: "Users",
    user: "user",
    numUsers: " users",
    numUser: " user",
    countUsers: "0 User | 1 User | {count} Users",
    workspaces: "Workspaces",
    workspace: "Workspace",
    workspaceGroup: "Workspace Group",
    workflows: "Workflows",
    workflow: "Workflow",
    organization: "Organization",
    business: "Business",
    create: "create",
    name: "Name",
    namePerson: "Name",
    email: "E-mail",
    employeeId: "Employee ID",
    role: "role",
    organizationAdmin: "Organization Admin",
    workspaceAdmin: "Workspace Admin",
    publisher: "Publisher",
    viewer: "Viewer",
    organizationAdmins: "Organization Admins",
    workspaceAdmins: "Workspace Admins",
    publishers: "Publishers",
    viewers: "Viewers",
    add: "Add",
    attachments: "attachments",
    private: "private",
    public: "public",
    content: "Content",
    noRecordFound: "No records found",
    loadingRecords: "Loading records...",
    teams: "Teams",
    loadedAllRecords: "All records loaded",
    language: "Language",
    interfaceLanguage: "Interface Language",
    personalInfo: "Personal Info",
    privacyPolicy: "Privacy Policy",
    team: "Team",
    unselect: "Unselect",
    select: "Select",
    applied: "Applied",
    noAccessToAdminTools: "This user account currently does not have access to admin tools.",
    changePhoto: "Change photo",
    removePhoto: "Remove photo",
    profilePhoto: "Profile Photo",
    takeOrUpload: "Take or upload a photo.",
    useCamera: "Use camera",
    uploadPhoto: "Upload photo",
    editPhoto: "Edit photo",
    takePhoto: "Take Photo",
    retake: "Retake",
    countWorkspaces: "Workspace | Workspace | Workspaces",
    whatsNew: "What’s New?",
    howToUse: "How to Use",
    defaultInterfaceLanguage: "Default Interface Language",
    newVersionSnackbarText: "A new version of DeepHow is available.",
    refresh: "refresh",
    unpublishedList: "Unpublished",
    publishedList: "Published",
    userAgreement: "User Agreement",
    numAttachments: "0 attachment | 1 attachment | {count} attachments",
    attachment: "attachment",
    knowledgeBase: "DeepHow Knowledge Base",
    changeName: "Change name",
    changePassword: "Change password",
    openInApp: "Open in Deephow Navigator",
    userNewToDeepHow: "Are you new to DeepHow?",
    downloadTheApp: "Download the app",
    continueToUseBrowser: "Continue to use the browser",
    contentManager: "Content Manager",
    gen2Forbidden: "As a Gen2 Light user, you are not able to login right now.",
    confirm: "Confirm",
    myWorkspaceGroups: "My workspace groups",
    otherWorkspaceGroups: "Other workspace groups",
    searchResult: "Search results",
    noResultsFound: "No results found.",
    browseWorkspace: "Browse Workspace...",
    version: "version",
  },
  password: {
    createYourAccount: "Create your account",
    checkNameChoosePassword: "Check your name and choose a password",
    createAccount: "Create account",
    createNewPassword: "Create new password",
    selectNewPassword: "Select a new password for your account",
    newPassword: "New password",
    confirmNewPassword: "Confirm new password",
    passwordMustMatch: "Password must match.",
    confirm: "Confirm",
    passwordCreatedMsg: "Your new password was created, <br>please wait while we redirect you…",
    goToDeepHowPlayer: "Go to DeepHow player",
    failedResetPassword: "Failed to reset the password. Your account was already created or there's a network issue.",
    alertSetPasswordFailed: "Failed to set the password. Your account was already created or there's a network issue.",
    linkExpired: "This link has expired",
    passwordExpired: "Your Password reset link has expired.",
    inviteExpired: "Your invitation has expired.",
    resetPasswordMsg: "Click the button below to request a password reset email.",
    // added 09/08/2022
    alertFailedResetPassword: "Failed to reset the password.",
    alertSuccessfullyResetPassword: "Successfully reset the password, please wait while we redirect you to log in again…<br><br>Or click the button below.",
    newPasswordNotTheSame: "The new password should not be the same as the old one.",
    alertCurrentPasswordIncorrect: "Current password incorrect.",
    alertTooManyAttempts: "Too many attempts to validate current password. Try again later.",
  },
  analytics: {
    overview: "Overview",
    engagement: "Engagement",
    activeUsers: "Active Users",
    workflowsCreated: "Workflows Created",
    workflowsPublished: "Workflows Published",
    workflowViews: "Workflow Views",
    timeCreated: "Time Created",
    timePublished: "Time Published",
    timeViewed: "Time Viewed",
    searchWorkspace: "Search for Workspaces...",
    mostUsers: "Most Users",
    mostWorkflowsCreated: "Most Workflows Created",
    numberOfUsers: "Number of users",
    organizationOverview: "Organization Overview",
    numWorkflowsIn: "Workflows in",
    searchWorkflow: "Search for Workflows...",
    createdBy: "Created by",
    usersIn: "Users in",
    searchUser: "Search for Users...",
    watchTime: "Watch Time",
    workflowsViewed: "Workflows Viewed",
    percentViewed: "% Viewed",
    dateViewed: "Date viewed",
    watchWorkflow: "Watch Workflow",
    backToUsers: "Back to Users",
    backToWorkflows: "Back to Workflows",
    likes: "Likes",
    avgTimeViewed: "Avg. Time Viewed",
    numberofViews: "Number of Views",
    workspacesPerPage: "Workspaces per page",
    usersPerPage: "Users per page",
    workflowsPerPage: "Workflows per page",
    exportTable: "Export table",
    TopViewers: "Top Viewers",
    TopPublishers: "Top Publishers",
    totalViews: "Total Views",
    msgNoDataYet: "No data available",
    msgNoSearchResults: "No matching records found",
    hms: "(H:M:S)",
    viewHistory: "View history",
    views: "Views",
    totalViewingTime: "Total viewing time",
    usersViewedThisWorkflow: "<b>0</b> User viewed this Workflow| <b>1</b> User viewed this Workflow | <b>{count}</b> Users viewed this Workflow",
    date: "Date",
    // added 2022/07/11
    dashboards: "Dashboards",
    reactions: "Reactions",
    userReaction: "User Reaction",
    viewAll: "View All",
    favorites: "Favorites",
  },
  admin: {
    status: "Status",
    active: "Active",
    inactive: "Inactive",
    invited: "Invited",
    invitationExpired: "Invitation expired",
    couldNotInvited: "Could not be invited",
    hintSearchUsers: "Search users...",
    hintSearchWorkspaces: "Search workspace...",
    hintSearchBusiness: "Search business...",
    alertSuccessfullyInviteUser: "Successfully invited the user.",
    alertFailedInviteUser: "Failed to invite user.",
    alertSuccessfullyDeletedUser: "Successfully deleted the user.",
    alertFailedDeleteUser: "Failed to delete user.",
    alertSuccessfullyCreatedWorkspace: "Successfully created workspace.",
    alertSuccessfullyEditedWorkspace: "Successfully edited workspace.",
    alertSuccessfullyCreatedBusiness: "Successfully created business.",
    alertSuccessfullyEditedBusiness: "Successfully edited business.",
    alertUserInvitationSuccessfullyResent: "User invitation successfully resent.",
    alertFailedResendUserInvitation: "Failed to resend user invitation.",
    alertUserInvitationSuccessfullyDelete: "User invitation successfully deleted.",
    alertFailedDeleteUserInvitation: "Failed to delete user invitation.",
    resendInvitation: "Resend Invitation",
    popupMsgResendInvitation: "Are you sure you want to resend invitation to ",
    popupBtnSend: "Yes, send",
    deleteInvitation: "Delete Invitation",
    popupMsgDeleteInvitation: "Are you sure you want to delete invitation to ",
    addUser: "Add user",
    msgNoMembersYet: "This Organization does not have members yet.",
    msgNoRecordsAllUsers: "No matching records found in all users.",
    createWorkspace: "Create workspace",
    msgNoWorkspacesYet: "This Organization does not have Workspaces yet.",
    msgNoRecordsWorkspaces: "No matching records found in Workspaces.",
    msgNoRecordsWorkspacesGroupAdmin: "This account does not have Workspaces yet.",
    createBusiness: "Create business",
    msgNoBusinessYet: "This Organization does not have Business yet.",
    msgNoRecordsBusiness: "No matching records found in Business.",
    usersIn: "Users in ",
    tableMsgNoUsersYet: "This Workspace does not have members yet.",
    tableMsgNoRecordsThisWorkspace: "No matching records found in this Workspace.",
    memberAt: "Member at",
    alerBusinessAlreadyInSystem: "Business already in the system",
    alerFailedAddBusiness: "Failed to add business.",
    alertFailedEditBusiness: "Failed to edit business.",
    alertWorkspaceAlreadyInSystem: "This workspace name is already in the system.",
    aletFailedCreateWorkspace: "Failed to create the workspace.",
    alertFailedEditWorkspace: "Failed to edit the workspace.",
    resendAllInvites: "Resend all invites",
    resendAllInvitesMsg: "You will send an invite to all pending and expired invitations. Are you sure?",
    yesSendInvites: "Yes, send invites",
    businessesPerPage: "Businesses per page",
    //add org user
    popupTitleAddOrgUser: "Add organization admin user",
    invite: "Invite",
    popupTitleNewUserInvited: "New user invited!",
    popupMsgNewUserInvitedOrg: " was invited.<br>After validating the account he/she will have access to this Organization.",
    popupTitleExistingUserInvited: "Existing user added!",
    popupMsgExistingUserInvitedOrg: "was added to this Organization.",
    popupBtnAddAnotherUser: "Add another user",
    alertFailedCheckPendingInvitations: "Failed to check if user has pending invitations.",
    alertFailedAddExistingUserAsOrganizationAdmin: "Failed to add existing user as organization admin",
    alertAddExistingUserAsOrganizationAdmin: "Add existing user as organization admin.",
    alertUserAlreadyOrganizationAdmin: "User already in the system as organization admin.",
    alertFailedCheckUserAlreadyInSystem: "Failed to check if user already in the system.",
    //Add user to workspace
    addUserToWorkspace: "Add user to workspace",
    companyEmail: "Company e-mail",
    employeeIdOnly: "Employee ID only",
    fullName: "Full name",
    workspaceName: "Workspace name",
    businessName: "Business name",
    popupMsgNewUserInvited: " was invited.<br>After validating the account he/she will have access to this Workspace.",
    popupMsgExistingUserInvited: "was added to this Workspace.",
    popupTitleNewUserCreated: "New user created!",
    popupTitleUserAlreadyinSystem: "User already in the system",
    alertUserIsAlreadyInWorkspace: "This user ({info}) is already in this workspace.",
    alertPendingInvitation: "This user has a pending invitation. Please wait for the user to accept the current invitation.",
    searchingforUsers: "Searching for Users...",
    noResultsFound: "No results found.",
    // Change User Role
    popupTitleChangeUserRole: "Change user role",
    popupMsgChangeUser: "Are you sure you want to change <b>{name}'s</b> role from <b>{oldRole}</b> to <b>{newRole}</b>?",
    popupBtnChange: "Yes, change",
    alertUpdatedUserSettings: "Successfully updated user settings.",
    // Remove User
    popupTitleRemoveUser: "Remove user from workspace",
    popupMsgRemoveUserFromWorkspace: "Are you sure you want to remove <b>{name}</b> from the Workspace?",
    popupBtnRemove: "Yes, remove",
    alertRemoveUser: "Successfully removed user from workspace.",
    // Delete User
    popupTitleDeleteUser: "Delete user from system",
    popupMsgDeleteUserFromAllWorkspaces: "If you do this, you will lose all the records regarding this user <b>{name}</b> and he will be deleted from all the Workspaces and roles he is assigned.",
    popupBtnDelete: "Yes, delete",
    tooltipEditUser: "Edit user",
    tooltipResendInvite: "Resend invite",
    tooltipCancelInvite: "Cancel invite",
    tooltipRemoveUser: "Remove user",
    // settings
    settings: "Settings",
    location: "location",
    hireDate: "hire date",
    department: "department",
    position: "position",
    site: "site",
    userId: "User ID",
    ssoMapping: "SSO Mapping",
    ssoMappingNoData: "There is no data to map yet.<br />You need to upload the CSV via FTP, please contact support.",
    attributes: "Attributes",
    oneAttributeToMap: "You can only select one attribute to map users into Workspaces",
    selectAttribute: "Select attribute",
    changeAttribute: "Change attribute",
    discardChanges: "Discard changes",
    applyChanges: "Apply changes",
    applyChangesMsg: "You are going to make changes to the following Workspaces, are you sure?",
    mappingValuesUpdated: "Mapping values updated",
    mappingValuesUpdatedSubtitle: "In order to successfully update the mapping, you need to apply changes. You can do this when you finish editing all Workspaces.",
    values: "Values",
    clickPlusToAddValues: "Click on the plus icon to add values",
    selectValues: "Select values",
    searchForValues: "Search for values…",
    assign: "Assign",
    assignValues: "Assign Values",
    addUsersBtn: "Yes, add users",
    YesChangeBtn: "Yes, change",
    numMore: "{count} more",
    removeValues: "Remove values",
    remove: "Remove",
    changeAttributeMessage: "Are you sure you want to change attribute?<br/>You will lose all the actual mapping you have for this attribute.",
    discardChangesMessage: "You are going to lose all changes you made since your last save, are you sure?",
    discardChangesBtn: "Yes, discard changes",
    applyChangesBtn: "Yes, apply changes",
    alertDiscardedSuccess: "Mapping successfully discarded.",
    alertAppliedSuccess: "Mapping successfully applied.",
    alertAppliedFailed: "Failed to apply mapping.",
    //permissions
    permissions: "Permissions",
    products: "DeepHow Products",
    roleAccessToProduct: "Define below, which user roles will be able to access each product",
    analytics: "Analytics",
    skillsManager: "Skills Management",
    skillsPlayer: "Skills Player",
    workflowCapture: "Workflow Capture",
    workflowEditor: "Workflow Editor",
    organizationAdmin: "Organization Admin",
    groupAdmin: "Workspace Admin",
    publisher: "Publishers",
    viewer: "Viewers",
    rolesPermission: "Roles Permission",
    rolesPermissionMessage: "Are you sure you want to change the role permission to this product?<br/>Changes will be made immediately.",
    inviteUser: "Invite user",
    workflowSharing: "Workflow Sharing",
    allowUserstoSharePublicLinks: "Allow users to share public links",
    changeWorkflowSharingExplanation: "By turning off sharing public links, public workflows will only be accessed by users that are signed in to the platform.",
    allowWorkflowSharingMessage: "Are you sure you want to turn on sharing public links?",
    preventWorkflowSharingMessage: "Are you sure you want to turn off sharing public links? Changes will be made immediately.",
    workflowDownload: "Workflow Download",
    allowUsersToDownloadWorkflows: "Allow users to download workflows",
    allowDownloadWorkflowsMessage: "Are you sure you want to allow users to download workflows?",
    preventDownloadWorkflowsMessage: "Are you sure you want to prevent users from downloading workflows?",
    autoLogout: "Auto Logout",
    autoLogoutAfterSelectedTime: "Users will be automatically logged out after the selected time.",
    numMinutes: "0 minute | 1 minute | {count} minutes",
    changeLogoutTimeMessage: "Are you sure you want to change the automatically logged out time to {num} minutes?",
    enableAutoLogoutMessage: "Are you sure you want to enable auto logout?",
    disableAutoLogoutMessage: "Are you sure you want to disable auto logout?",
    changesMadeImmediately: "Changes will be made immediately.",
    sessionIsAboutToExpire: "Your session is about to expire.",
    youWillBeLoggedOutNumSeconds: "You will be logged out in 0 second. | You will be logged out in 1 second. | You will be logged out in {count} seconds.",
    continueSession: "Continue Session",
    //system languages
    languages: "Languages",
    systemLanguage: "System Language",
    searchForWorkspaces: "Search for Workspaces…",
    workspacesPerPage: "Workspaces per page",
    workspace: "workspace",
    workspaceLanguage: "language",
    changeLanguage: "Change Language",
    popupMsgChangeLanguage: "Changing language will affect all users inside of this Workspace, are you sure you want to change?",
    alertLanguageSuccessfullyChanged: "Successfully changed language",
    alertFailedChangeLanguage: "Failed to change language",
    // Categories
    categories: "Categories",
    alertCategoriesNotSaved: "Categories not saved, try again later.",
    noCategoryResult: "No category search result",
    noCategoriesAddedYet: "No categories added yet",
    categoriesAssignment: "Categories Assignment",
    categoryPlaceholder: "Search categories and subcategories...",
    previouslyUsed: "Previously used",
    allCategories: "All Categories",
    workflowCategories: "Workflow Categories",
    searchForCategories: "Search for Categories…",
    categoryItems: "items",
    categoryItemsNum: "items",
    createCategory: "Create Category",
    editCategory: "Edit Category",
    categoryName: "name",
    noCategoriesCreatedYet: "No Categories created yet.",
    noItemsCreatedYet: "No Items created yet.",
    categoriesPerPage: "Categories per page",
    itemsPerPage: "Items per page",
    alertCategoryAlreadyInSystem: "Category already in the system.",
    alertFailedAddCategory: "Failed to add Category.",
    alertFailedEditCategory: "Failed to edit Category.",
    deleteCategory: "Delete Category",
    popupMsgDeleteCategory: "Are you sure you want to delete the Category ",
    itemsIn: "Items in",
    searchForItems: "Search for Items…",
    createItem: "Create Item",
    editItem: "Edit Item",
    deleteItem: "Delete Item",
    itemName: "name",
    alertItemAlreadyInSystem: "Item already in the system.",
    alertFailedAddItem: "Failed to add Item.",
    alertSuccessAddItem: "Successfully created Item.",
    alertFailedEditItem: "Failed to edit Item.",
    alertSuccessEditItem: "Successfully edited Item.",
    // Teams
    teamsPerPage: "Teams per page",
    max1000UsersPerTeam: "* Max 1000 users per team.",
    usersSelected: "0 User Selected | 1 User Selected | {count} Users Selected",
    selectAll: "Select all",
    removeAll: "Remove all",
    createTeam: "Create Team",
    editTeam: "Edit Team",
    teamName: "name",
    teamsIn: "Teams in",
    searchForTeams: "Search for Teams…",
    searchForUsers: "Search for Users…",
    noUsersAddedYet: "No Users added to your Team yet.",
    dateAdded: "Date Added",
    alertTeamAlreadyInSystem: "Team already in the system.",
    alertFailedEditTeam: "Failed to edit Team.",
    deleteTeam: "Delete Team",
    popupMsgDeleteTeam: "You will delete the <b>{name}</b> team. Are you sure you want to do that?",
    noTeamsCreatedYet: "No Teams created yet.",
    popupMsgDeleteItem: "Are you sure you want to delete the Item ",
    removeUser: "Remove User",
    popupMsgRemoveUser: "You will remove <b>{name}</b> from this team. Are you sure you want to do that?",
    alertErrorGettingWorkspaceList: "Error getting workspace list.",
    alertSuccessfullyCreatedTeam: "Successfully created the team",
    alertFailedCreatedTeam: "Failed to create the team.",
    alertSuccessfullyUpdatedTeam: "Successfully updated the team.",
    alertSuccessfullyRemovedUserFromTeam: "Successfully removed the user from the team.",
    alertFailedRemovedUserFromTeam: "Failed to remove the user from Team.",
    alertSuccessfullyAddedUserToTeam: "Successfully added the user to the team.",
    alertFailedAddedUserToTeam: "Failed to add the user to Team.",
    alertWarningAddedUserToTeamAlready: "The user has already been added to the team.",
    // TODO added 2022/02/03
    alertAllInvitationResentSuccess: "All invitations successfully resent.",
    alertAllInvitationResentFailed: "Failed to resend all invitations.",
    alertCheckUserInSystemFailed: "Failed to check if the user is already in the system.",
    alertOrganizationAdminAssignedFailed: "Organization admin cannot be assigned to a workspace",
    // TODO added 2022/02/07
    alertFailedDeleteItem: "Failed to delete Item.",
    alertSuccessfullyDeletedItem: "Successfully deleted Item.",
    alertCategoryDeleteFailed: "Failed to delete Category.",
    alertSuccessfullyDeletedCategory: "Successfully deleted Category.",
    // TODO added 2022/02/08
    upgradeToOrgAdmin: "Upgrade to Org. Admin",
    yesUpgrade: "Yes, upgrade",
    popupMsgUpgradeToOrgAdmin: "Are you sure you want to upgrade <b>{name}</b>? Org. Admins have full access to configure your Organization’s settings and much more…",
    alertFailedUpgradeToOrg: "Failed to upgrade to Org. Admin.",
    alertSuccessfullyUpgradeToOrg: "Successfully Upgraded to Org. Admin.",
    // TODO added 2022/02/17
    userActive: "User Active?",
    profile: "Profile",
    // TODO: added 2022/02/09 settings > Master Password
    masterPassword: "Master Password",
    enableMasterPassword: "Enable Master Password",
    disableMasterPassword: "Disable Master Password",
    resetMasterPassword: "Reset Master Password",
    employeeIDAccess: "Employee ID Access",
    employeeIDAccessMsg: "If you turn this feature on, users that access only with Employee ID will have to input this password to access DeepHow.",
    disableMasterPasswordMsg: "If you turn this feature off, users that access only with Employee ID won't need to input a password to access DeepHow.",
    resetMasterPasswordMsg: "Users that access only with Employee ID will need to use this new password to access DeepHow.",
    insertPassword: "Insert password",
    alertEnablePasswordFailed: "Failed to enable Master Password.",
    alertEnablePasswordSuccess: "Successfully enabled Master Password.",
    alertDisablePasswordFailed: "Failed to disable Master Password.",
    alertDisablePasswordSuccess: "Successfully disabled Master Password.",
    savePasswordMsg: "This feature won't be enabled until your password is successfully saved.",
    resetPasswordMsg: "Enter a new Master Password and Save.",
    currentPassword: "Current Password",
    yesEnable: "Yes, enable",
    yesDisable: "Yes, disable",
    downgradeOrgAdmin: "Downgrade Org. Admin",
    popupMsgDowngradeOrgAdmin: "Are you sure you want to downgrade <b>{name}</b>? They will be downgraded to the role you select in all Workspaces they belong to.",
    selectRole: "Select the role for this user",
    yesDowngrade: "Yes, downgrade",
    alertFailedDowngradeToOrg: "Failed to downgrade to {userRole}.",
    alertSuccessfullyDowngradeToOrg: "Successfully downgraded to {userRole}.",
    lastActivity: "Last Log In",
    coverColor: "Cover color",
    defaultLanguage: "Default language",
    blue: "Blue",
    red: "Red",
    green: "Green",
    yellow: "Yellow",
    purple: "Purple",
    belongsToWorkspaces: "Belongs to Workspaces",
    alertFailedSaveChanges: "Failed to save changes.",
    // TODO: added 04/02
    alertFailedRemoveUser: "Failed to remove user.",
    // TODO: added 0608
    privateOrnot: "private?",
    privateWorkspaceInfo: "This Workspace will only be seen by the members assigned to it…",
    noPermission: "You're not permitted to see this.",
    noPermissionContact: "The page you're trying to access has restricted access. <br>If you feel this is a mistake, contact your admin.",
    privateCannotChanged: "Private setting cannot be changed after the workspace is created.",
    // Shared Account
    sharedAccount: "Shared account",
    addManually: "Add manually",
    addASharedAccount: "Add a shared account",
    addASharedAccountMsg: "Shared accounts cannot be used to watch Workflows individually, they are only used for employee ID users to self-create accounts in DeepHow and also keep track of each user in shared devices...",
    accountName: "Account name",
    alertFailedAddShared: "Failed to add Shared account",
    alertSuccessfullyCreatedShared: "Successfully created Shared account",
    alertSuccessfullyAddExistingShared: "Successfully added Existing Shared account",
    accountId: "Account ID",
    addSharedAccount: "Add shared account",
    sharedAccountId: "Shared account ID",
    unselectedTeam: "Unselect team",
    // TODO: added 2022/06/22
    workflowReview: "Workflow Review",
    workflowReviewDescription: "If this option is on, Workspace Admins will be able to choose if their Workspaces require Workflow review or not",
    preventReviewWorkflowsMessage: "Are you sure you want to disable Workflow Review?",
    allowReviewWorkflowsMessage: "Are you sure you want to allow users to review workflows?",
    // TODO: added 2022/06/29
    addAsReviewer: "Add as reviewer",
    removeAsReviewer: "Remove as reviewer",
    addAsReviewerSuccessMessage: "Add {user} as reviewer successfully.",
    addAsReviewerFailedMessage: "Failed to add {user} as reviewer.",
    removeAsReviewerSuccessMessage: "Remove {user} as reviewer successfully.",
    removeAsReviewerFailedMessage: "Failed to remove {user} as reviewer.",
    // Shared Account Admin settings 07/27
    allowsSharedAccountMsg: "Allow organization and workspace admins to create shared accounts. Users will be able to self-sign up using their employee ID accounts in a shared device.",
    disableSharedAccountMsg: "Are you sure you want to disable shared account? <br>Changes will be made immediately.",
    enableSharedAccountMsg: "Are you sure you want to enable shared account? <br>Changes will be made immediately.",
    alertEnableSharedAccountFailed: "Failed to enable shared account.",
    alertEnableSharedAccountSuccess: "Successfully enabled shared account.",
    alertDisableSharedAccountFailed: "Failed to disable shared account.",
    alertDisableSharedAccountSuccess: "Successfully disabled shared account.",
    // QR Code & Watermark
    qrCodeAndWatermark: "QR Code & Watermark",
    insertYourBrand: "Insert your brand",
    uploadYourLogo: "Upload your logo",
    uploadingYourLogo: "Uploading your logo",
    logoSuccessfullyUploaded: "Logo successfully uploaded",
    errorInUpload: "Error in your upload",
    errorInUploadMsg: "File type not supported. Please select the following file type : .PNG",
    uploadLogoMsg: "Drag and drop a <b>.PNG</b> with a maximum <b>3 MB</b> file or <span>browse</span> from computer.",
    uploadLogoReplaceMsg: "Drag or <span>browse</span> to replace with a image from computer or <span id='DUploadImage_removeLogo'>remove</span> the current image.",
    simulation: "Simulation",
    simulationMsg: "See below how your logo will be presented in the videos watermark and in the QR Codes.",
    workflowTitle: "Workflow title",
    logoAlreadyUploaded: "Logo already uploaded",
    alertLogoSuccessfullyRemoved: "Logo successfully removed.",
    alertLogoFailedRemoved: "Failed to remove logo.",
    alertUserInOtherOrg: "This user already exists in another organization.",
    // added 10/13 delete workspace
    deleteWorkspace: "Delete Workspace",
    popupMsgDeleteWorkspace: "Are you sure you want to delete this workspace ({name})?",
    alertDeleteGroupSuccess: "Successfully deleted this workspace.",
    alertDeleteGroupFailedWorkflows: "There are Workflows assigned to this workspace, so you can’t delete it.",
    alertDeleteGroupFailedSkills: "There are Skills assigned to this workspace, so you can’t delete it.",
    alertDeleteGroupFailedTeams: "There are Teams assigned to this workspace, so you can’t delete it.",
    // added 11/3 delete team
    alertDeleteTeamSuccess: "Successfully deleted this team.",
    alertDeleteTeamFailedSkills: "This team is not allowed to remove, because of existing skill(s) assigned to.",
    alertDeleteTeamFailed: "Failed to delete this team.",
    // Change Profile Name
    changeProfileName: "Change Profile Name",
    allowsChangeProfileNameMsg: "Users are allowed to change their names on their profiles.",
    disableChangeProfileNameMsg: "Are you sure you want to disable change profile name? <br>Changes will be made immediately.",
    enableChangeProfileNameMsg: "Are you sure you want to enable change profile name? <br>Changes will be made immediately.",
    alertEnableChangeProfileNameSuccess: "Successfully enabled change profile name.",
    alertEnableChangeProfileNameFailed: "Failed to enable change profile name.",
    alertDisableChangeProfileNameSuccess: "Successfully disabled change profile name.",
    alertDisableChangeProfileNameFailed: "Failed to disable change profile name.",
    // Workflow Comments
    workflowComments: "Workflow Comments",
    allowsWorkflowCommentsMsg: "Users are allowed to leave comments to published workflows.",
    disableWorkflowCommentsMsg: "Are you sure you want to disable workflow comments? <br>Changes will be made immediately.",
    enableWorkflowCommentsMsg: "Are you sure you want to enable workflow comments? <br>Changes will be made immediately.",
    alertEnableWorkflowCommentsSuccess: "Successfully enabled workflow comments.",
    alertEnableWorkflowCommentsFailed: "Failed to enable workflow comments.",
    alertDisableWorkflowCommentsSuccess: "Successfully disabled workflow comments.",
    alertDisableWorkflowCommentsFailed: "Failed to disable workflow comments.",
    // Workspace Groups
    workspaceGroupsNum: "Workspace Group | Workspace Group | Workspace Groups",
    searchWorkspaceGroups: "Search for Workspace Groups…",
    noWorkspaceGroupsYet: "No Workspace Group created yet.",
    createWorkspaceGroup: "Create Workspace Group",
    editWorkspaceGroup: "Edit Workspace Group",
    workspaceGroupName: "Workspace Group name",
    editWorkspace: "Edit Workspace",
    userNum: "User | User | Users",
    teamNum: "Team | Team | Teams",
    defaultLanguage: "Default language",
    deleteWorkspaceGroup: "Delete Workspace Group",
    alertCreateWorkspaceGroupSuccess: "Successfully created Workspace Group.",
    alertWorkspaceGroupNameAlreadyExists: "This Workspace Group name is already in the system.",
    alertEditWorkspaceGroupSuccess: "Successfully edited Workspace Group.",
    alertEditWorkspaceGroupFailed: "Failed to edit the Workspace Group.",
    alertDeleteWorkspaceGroupSuccess: "Successfully deleted this Workspace Group.",
    alertDeleteWorkspaceGroupFailed: "Failed to delete this workspace group.",
    alertWorkspaceAssignedDeleteGroupFailed: "There are Workspace assigned to this Group, so you can't delete it.",
    deleteWorkspaceGroupMessage: "Are you sure you want to delete this Workspace Group (<b>{name}</b>)?",
    alertWorkspaceNameAlreadyExists: "The Workspace name already exists.",
    limitIDLoginToSharedAccount: "Limit employee ID login to shared devices only.",
    allowEmployeesCreateIDAccount: "Allow employees to create their own accounts in a shared device.",
    disableDHLoginMsg: "Are you sure you want to limit employee ID login to shared devices only?",
    enableDHLoginMsg: "Are you sure you don't want to limit employee ID login to shared devices only?",
    enableEmployeeCreationMsg: "Are you sure you want to allow employees to create their own accounts in a shared device?",
    disableEmployeeCreationMsg: "Are you sure you don't want to allow employees to create their own accounts in a shared device?",
    alertSharedDeviceSuccessfullyChanged: "Shared Device setting successfully changed.",
    alertSharedDeviceFailedChanged: "Failed to change Shared Device setting.",
    redefinePassword: "Redefine Password",
    redefinePasswordMsg: "Please select a new password for this account:",
    redefine: "Redefine",
    alertSuccessfullyResetPassword: "Successfully reset the password.",
    alertResetMasterPasswordSuccess: "Successfully reset Master Password.",
    alertResetMasterPasswordFailed: "Failed to reset Master Password.",
    sharedDevices: "Shared Devices",
    ipRestriction: "IP Restriction",
    allowIpRestrictionMessage: "If you turn this feature on, only whitelisted IP\'s will be able to access DeepHow.",
    enableIpRestrictionMessage: "Are you sure you want to enable IP Restriction?<br>Changes will be made immediately.",
    disableIpRestrictionMessage: "Are you sure you want to disable IP Restriction?<br>Changes will be made immediately.",
    whitelistedIps: "Whitelisted IP\'s",
    whitelistedIpDescription: "Description",
    whitelistedIpsOrRange: "IPs or Range",
    whitelistedIpSourceFilter: "Source Filter",
    searchForWhitelistedIp: "Search for Whitelisted IP…",
    addWhitelistedIpRule: "Add rule",
    editWhitelistedRule: "Edit rule",
    noWhitelistedIpCreatedYet: "No Whitelisted IP created yet.",
    addAnotherIp: "Add another IP",
    deleteWhitelistedIp: "Delete Whitelisted IP",
    popupMessageDeleteWhitelistedIp: "Are you sure you want to delete the Whitelisted IP",
    alertEnableIpRestrictionSuccess: "Successfully enabled IP Restriction.",
    alertEnableIpRestrictionFailed: "Failed to enabled IP Restriction.",
    alertDisableIpRestrictionSuccess: "Successfully disabled IP Restriction.",
    alertDisableIpRestrictionFailed: "Failed to disabled IP Restriction.",
    alertCreateWhitelistedIpSuccess: "Successfully created Whitelisted IP.",
    alertCreateWhitelistedIpFailed: "Failed to created Whitelisted IP.",
    alertUpdateWhitelistedIpSuccess: "Successfully updated Whitelisted IP.",
    alertUpdateWhitelistedIpFailed: "Failed to updated Whitelisted IP.",
    alertWhitelistedIpTypeError: "The Source type is not match for the IP rule.",
    alertWhitelistedCIDRError: "CIDR invalid or unexpected error.",
    alertDeleteWhitelistedIpSuccess: "Successfully deleted Whitelisted IP.",
    alertDeleteWhitelistedIpFailed: "Failed to deleted Whitelisted IP.",
  },
  builder: {
    publish: "Publish",
    reviewTranscription: "Review transcription",
    viewer: "Viewer",
    links: "Links",
    trim: "Trim",
    translation: "Translation",
    share: "Share",
    addDiagramMsg: "Drag or click to add diagrams",
    onlyImageAccepted: "Only image files accepted",
    unsupportedImageFormats: "Unsupported image formats: svg, tiff, xbm.",
    addDiagram: "Add diagram",
    start: "Start",
    end: "End",
    resetToOriginal: "Reset to original",
    trimStartMsg: "Choose where you want want your video to start and finish",
    trimProcessingMsg: "Trim is not available while workflow processing is in progress. <br>Please come back later",
    cannotTrimLessThan3s: "You can’t trim videos shorter than 3 seconds",
    trimVideo: "Trim Video",
    trimStep: "Trim Step",
    editWorkflow: "Edit workflow",
    popupBtnEdit: "Yes, edit",
    popupBtnPublish: "Yes, publish",
    popupBtnUnpublish: "Yes, unpublish",
    popupMsgTrimConfirmation: "Are you sure you want to trim?",
    popupMsgTrimWithSaveConfirmation: "You have unsaved changes on your workflow. We will save the changes for you.",
    popupBtnTrim: "Yes, trim",
    popupMsgTranscoding: "Transcoding is in process.",
    popupMsgTrimBackLater: "You can resume editing the workflow after the transcoding is complete.",
    popupMsgTrimSuccess: "Your Step was successfully trimmed!",
    done: "Done",
    publishWorkflow: "Publish workflow",
    popupMsgEditWorkflow: "If you go into edit mode, your workflow will automatically be unpublished , are you sure you want to continue?",
    popupMsgUnpublishWorkflow: "You are going to unpublish your workflow, are you sure you want to do that?",
    alertSaveFailed: "Failed to save workflow.",
    alertTrimResetSuccess: "The step is successfully reset.",
    alertTrimResetFailed: "Failed to reset step.",
    alertTrimCanNotReset: "This step cannot be reset.",
    resetStep: "Reset Step",
    popupMsgResetStep: "Are you sure you want to reset? Diagrams added to this step will be lost.",
    popupBtnReset: "Yes, reset",
    hintSearchBuilder: "Search for workflows and keywords...",
    stepsSelected: "selected",
    clear: "Clear",
    insert: "Insert",
    reset: "Reset",
    resetWorkflow: "Reset Workflow",
    resetWorkflowAlert: "You will lose all the work done in this Workflow and start from the beginning again, are you sure you want to continue?",
    resetWorkflowKeepCopy: "Keep a copy of this Workflow",
    // Transcript / Translation
    closeTranscript: "Close Transcription",
    popupMsgCloseTranscript: "You have unsaved changes on your transcription. If you continue you will <b>lose all changes</b>. Are you sure?",
    stepNoTranscript: "Step with no transcript",
    originalTranscript: "Original transcript",
    addLanguageTooltip: "Click here to add new languages to your video",
    closeTranslation: "Close Translation",
    popupMsgCloseTranslation: "You have unsaved changes on your translation. If you continue you will <b>lose all changes</b>. Are you sure?",
    switchTranslation: "Switch Translation",
    popupBtnNoSwitch: "No, just switch",
    popupBtnYesSwitch: "Yes, save and switch",
    createAudio: "Create audio",
    addNewLanguageTooltip: "Click here to add a new language to your video",
    popupMsgCreateAudio: "Are you sure you want to create the audio translations for your workflow? We strongly encourage you to do it only once, since Stephanie will process all your transcription.",
    popupBtnYesCreate: "Yes, create",
    alertAudioBeingCreated: "Your translation audio is being created.",
    alertTranscriptionSaved: "Transcription successfully saved.",
    alertTranscriptionSaveFailed: "Failed to save transcription.",
    alertTranslationsSaved: "Translations successfully saved.",
    alertTranslationsSaveFailed: "Failed to save translations.",
    alertAudioCreated: "audio successfully created.",
    alertAudioFailed: "Failed to create your translation audio.",
    alertNoAudioGenerated: "No audio was generated because there's no update on the translation.",
    alertErrorCreateTranscription: "error creating transcription.",
    //Share workflow
    shareWorkflow: "Share workflow",
    fullWorkflow: "Full workflow",
    specificSteps: "Specific steps",
    chooseShareType: "Choose share type",
    qrCode: "QR code",
    link: "Link",
    embedLink: "Embed Link",
    privateWorkflow: "Private workflow",
    publicWorkflow: "Public workflow",
    close: "Close",
    saveQRCode: "Save QR code",
    download: "Download",
    copyLink: "Copy Link",
    copied: "Copied",
    downloading: "Downloading",
    videoPrivacy: "Video privacy",
    popupMsgVideoPrivacy: "When you turn your workflow public, anyone can have access, even without a DeepHow account. Are you sure?",
    includeSubtitles: "Include subtitles",
    noSubtitles: "No subtitles",
    selectLanguage: "Select the language",
    workflowReady: "Workflow ready!",
    prepareToDownload: "Prepare to download",
    processingDownloadVideoMsg: "Do not close this page!<br />In a few minutes your file will be ready for download.",
    processingWorkflow: "Processing Workflow…",
    openAnotherTabMsg: "While you wait, you can navigate on DeepHow in another tab",
    openNewTab: "Open new tab",
    alertErrorPreparingDownload: "An error occured while preparing your download. Please try again or contact DeepHow for assistance.",
    hide: "Hide",
    addNewLink: "Add new link",
    clickToAddLinks: "Click to add links to this step",
    addALink: "Add a link",
    editLink: "Edit link",
    addLinkAddress: "Enter the address down below to add a link to this step.",
    addLinkLable: "Do you want to add a label?",
    clickOpenOfficialDocument: "i.e.: Click to open the official document",
    //settings /categories
    settings: "Settings",
    categories: "Categories",
    noCategories: "You haven't created any categories yet.",
    selectCategoriesMessage: "Select at least one category, so users can filter through content easier",
    closeSettings: "Close settings",
    popupMsgCloseSettings: "You have unsaved changes. If you continue you <b>will lose all changes</b>. Are you sure?",
    workflowCategories: "Workflow Categories",
    popupBtnAssignCategoriesAndPublish: "Assign Categories and Publish",
    popupBtnSkipAndPublish: "Skip this step and Publish anyway",
    popupMsgworkflowCategories: "Before you publish, why don't you select some categories for your workflow, so users can find it easier?",
    alertCategoriesSaved: "Categories successfully saved.",
    alertCategoriesSaveFailed: "Failed to save categories.",
    searchForCategoryName: "Search for {name}...",
    loadingCategories: "Loading Categories...",
    alertFailedDownloadVideo: "Failed to download video",
    // Workflow Cover
    workflowCover: "Workflow Cover",
    selectFromTheVideo: "Select from the video",
    browseForAFile: "Browse for a file",
    dropFileMessage: "Drop your file or click here to browse it.",
    selectedImage: "Selected image",
    noImageSelected: "no image selected",
    alertSuccessfullyUpdatedCover: "Workflow cover was successfully updated.",
    alertFailedUpdatedCover: "Failed to update workflow cover.",
    selectAnotherImage: "select another image",
    alertErrorStepLostTranscription: "You currently have Steps that have not been translated. Please translate them before you can download your Workflow.",
    annotation: "Annotation",
    allNotes: "All notes",
    noNotesAddedYet: "No notes added yet.",
    popupMsgEnterTrim: "Your step has annotations. If you would like to trim the video, then these annotations will be gone.",
    popupMsgEnterErase: "Your step has annotations. If you would like to erase the video, then these annotations will be gone.",
    popupMsgEnterAnnotation: "Please perform all Erase and Trim steps prior to adding any annotations, otherwise the annotation may be removed.",
    workingOn: "Working on",
    eraseStep: "Erase Step",
    popupMsgEraseSuccess: "Your Step was successfully Erased!",
    popupBtnYesErase: "Yes, erase",
    popupMsgEraseConfirmation: "Are you sure you want to erase?",
    eraseProcessingMsg: "Erase is not available while workflow processing is in progress. <br>Please come back later",
    translateOnlyTitlesBtn: "Translate Titles and Step Names",
    untitled: "Untitled",
    hintNoEmptyTitle: "Title cannot be empty",
    popupMsgSwitchTranslation: "You have unsaved changes in your <b>{lang}</b> translation. Would you like to save them before you switch?",
    alertNoEmptyContent: "Titles or Sentences cannot be empty. Please enter the content.",
    PDF: "PDF",
    PDFSizeLimit: "PDF file size is limited to 50 MB.",
    addPDF: "Add PDF",
    onlyPDFAccepted: "Only PDF files accepted",
    preview: "Preview",
    // Send to reviewer
    sendToReviewer: "Send to reviewer",
    alertTitleSelectReviewers: "Select the user(s) to review your Workflow",
    alertSendToReviewSuccess: "Your workflow was successfully sent to review.",
    alertSendToReviewFailed: "Failed to send to review",
    subtitlesSize: "Subtitles Size",
    small: "Small",
    medium: "Medium",
    big: "Big",
    yourVideoReadyToDownload: "Your video ready to download.",
    yourVideoWasSuccessfullyDownloaded: "Your workflow was successfully downloaded.",
    notAvailable: "not available",
    downloadFileFormat: "format",
    selectDownloadWorkflowQuality: "Select the video quality",
    downloadSizeLimitedMsg: "Download SCORM at {resolution} supports up to {duration} min workflows.",
    workflowTotalDurationMsg: "Workflow total duration: {minute} min {second} sec.",
    // Export Compliance Wire
    export: "Export",
    code: "Code",
    organization: "Organization",
    approvalDate: "Approval Date",
    processing: "Processing",
    workflowShouldBePublic: "Workflow should be public.",
    alertExportComplianceWireSuccess: "Export control document successfully. Code: {code}",
    alertExportComplianceWireExists: "Control document already exists. Code: {code}",
    alertExportComplianceWireFailed: "Something went wrong while exporting control document.",
    confirmedPublished: "Are you sure you want to Publish this Workflow?<br> If you do this, everybody will be able to watch it.",
    versionDescription: "Version {num} description (optional):",
    versionIsDuplicated: "version is duplicated",
  },
  editor: {
    closeWorkflow: "Close workflow",
    popupMsgCloseWorkflow: "You have unsaved changes on your workflow. If you continue you <b>will lose all changes</b>. Are you sure?",
    popupBtnClose: "Yes, close",
    processWorkflow: "Process workflow",
    popupMsgProcessWorkflow: "Great! Sounds like you finished your review and you want Stephanie to process it.<br><br>You will <b>not be able to</b> go back to this review screen. Are you ready?",
    popupBtnProcess: "Yes, process",
    alertIsSaving: "Saving is in progress.",
    alertNoEmptySentence: "Sentences cannot be empty. To correct the issue, enter the sentence and select Save again.",
    alertIsSaved: "Your changes were saved.",
    alertWasUnpublished: "Your workflow was successfully unpublished.",
    alertWasPublished: "Your workflow was successfully published.",
    alertNoMoreSteps: "You cannot add anymore steps in here.",
    alertNoEmptyTitle: "Title cannot be empty. Please enter the title, then save again.",
    scrollToVoice: "Scroll to voice",
    noAudioMsg: "Sorry, I couldn't find any audio in your video...\nIf you want you can go to:",
    youWillDeleteStep: "You'll delete Step",
    areYouSure: "are you sure?",
    areYouSureDelete: "Are you sure you<br> want to delete?",
    replace: "Replace",
    keyword: "Keyword",
    find: "Find",
    replaceWith: "Replace with",
    replaceAll: "Replace all",
    replaceAllMsg: "You’ll replace all these words.",
    of: "of",
    msgToSteps: "Analyzing your workflow... <br>please wait...",
    msgToEditor1: "Now let's review the workflow before publish",
    msgToEditor2: "I’ll guide you in each step no worries",
    msgToBuilder1: "Almost there, give me a sec",
    msgToBuilder2: "I’m creating the steps for your workflow",
    newStep: "New Step",
    hintNoEmptySentence: "Sentences cannot be empty",
    hintNoEmptyTitle: "(Title cannot be empty)",
    alertNoTranscription: "no transcription for the workflow.",
    alertEmptyingTranscription: "WARNING! You are emptying the transcription. You cannot do it",
    alertStepCorrupted: "Step {count} is corrupted. Please remove it before processing",
    // Editor 2.0
    trim: "Trim",
    erase: "Erase",
    voiceOver: "Voice Over",
    videoEditing: "Video Editing",
    segmentation: "Segmentation",
    popupMsgRestToOriginal: "Are you sure you want to reset to the original video? All added changes will be lost.",
    trimHintMessage: "Use the arrows to select the area you want to trim",
    eraseHintMessage: "Use the arrows to select the area you want to erase",
    voiceOverHintMessage: "Select the area you want to voice over...",
    voiceOverStartHint: "Press here to start your recording…",
    voiceOverStart: "Record",
    voiceOverSave: "Save Record",
    voiceOverCancel: "Discard",
    voiceOverCancelPopupMsg: "Are you sure you want to delete your recording？",
    voiceOverPause: "Pause",
    voiceOverResume: "Resume",
    unableToGetAudioInput: "No audio input device detected",
    restart: "restart",
    editVideo: "Edit Video",
    popupRequestTranscription: "Can not find transcription for the workflow. Let's create a new one.",
    // TODO 2023-11-30 added by andy
    popupMsgEditVideoWarning: "All changes made on the transcription will be lost if you edit your video, are you sure you want to edit?", // reserved until new wording is confirmed
    popupMsgEditVideoWarning2WithoutTTS:'All changes made on the transcription phase will be lost if you continue to edit your workflow. Edits, such as cutting, should be completed before proceeding with a voice-over. Select Confirm to continue or Cancel to return.',
    popupMsgEditVideoWarning2WithTTS: "All changes made on the transcription phase will be lost if you continue to edit your workflow. Edits, such as cutting, should be completed before proceeding with a voice-over or text-to-speech (TTS) recording. Select Confirm to continue or Cancel to return.",
    popupMsgReTranscriptionWarning: "Note: if you have done any video editing actions (such as trim/erase/voice-over), you will not be able to use the Re-transcription function.",
    popupMsgReTranscriptionWarningMultiEditing: "Note: if you have done any video editing actions (such as cut/voice-over), you will not be able to use the Re-transcription function.",
    popupMsgReTranscriptionWarningTextToSpeech: "Note: if you have done any video editing actions (such as cut/voice-over/text-to-speech), you will not be able to use the Re-transcription function.",
    popupMsgReTranscriptionWarningTextToSpeech2: "Note: Any editing actions performed (cut, voice-over, text-to-speech) you will no longer be able to change the transcription language.",
    rotate: "Rotate",
    rotateLeft: "Rotate left",
    rotateRight: "Rotate right",
    goBack: "Go Back",
    goBackToEditorHome: "go back to the Editor home",
    zoom: "zoom",
    // transcription new features
    transcriptionCopySuccess: "Transcription copied to your clipboard",
    keyTerms: "Key Terms",
    AILabs: "AI Labs",
    copyTranscription: "Copy Transcription",
    changeLanguage: "Change Language",
    copy: "copy",
    changeLanguageTitle: "Please select the new language for your transcription",
    changeLanguageSubTitle: "Stephanie will need to re-process all your transcription.",
    reProcessingYourVideo: "Stephanie is re-processing your video...",
    goBackToEditor: "You can wait here or go back to the Editor to keep on working!",
    // keyterms: added on 08/09/2022
    addTerm: "Add Term",
    removeTerm: "Remove Term",
    termsFound: "<span>0</span> term found | <span>1</span> term found | <span>{count}</span> terms found",
    loadingTerms: "Loading Terms",
    termsInfo: "Key Terms can be very helpful when searching for specific contents in workflows.",
    // Editor 3.0
    cut: "Cut",
    tabsWarningMessages: "Warning! If you are leaving this page without saving, all the changes you made will be lost.",
    versionHistory: "Version History",
    seeMore:"see more...",
    seeLess:"see less...",
    workflowCreated:"Workflow Created",
    createdBy: "Created by {name}",
    publishedBy: "Published by {name}",
    lastUpdateBy: "Last updated by {name}",
    emptyTitle: "No versions yet!",
    emptyContent: "This Workflow has not been published yet, as soon as you publish for the first time you will start seeing the versions here.",
    // added by andy 22/08/2023
    textToSpeech: "Text to Speech",
    play: "Play",
    retry: "Retry",
    generate: "Generate",
    generating: "Generating",
    addBlock: "Add block",
    addNewBlock: "Add new block",
    audioSpeed: "{value}x Speed",
    audioInputPlaceholder: "Insert text...",
    alertNeetToGenerateAllBeforeSave: "You need to generate all TTS blocks before saving your Workflow.",
    alertNeetToSaveBeforeLeave: "You have unsaved changes on your TTS, do you want to save before exiting?",
    alertExceedVideoDuration: "Audio blocks's total time exceed original video duration. Please adjust it.",
    alertTextToSpeechCreatingTitle: "Stephanie is creating the voice to your text",
    alertProcessingBackToDesc: "You can wait here or go back to the Editor Home to keep on working!",
    alertTextToSpeechAdjustingTitle: "Stephanie is adjusting your TTS",
    alertHoldOn: "Please hold on a little bit!",
    generateAllBlocks: "Generate all blocks",
    generateAll: "Generate all",
    originalAudio: "Original Audio",
    originalVideo: "Original Video",
    enArial: "English - Aria",
    enEric: "English - Eric",
    enJane: "English - Jane",
    enJason: "English - Jason",
    zhXiaomeng: "Chinese - Xiaomeng",
    zhYunyang: "Chinese - Yunyang",
    frSylvie: "French - Sylvie",
    frAntoine: "French - Antoine",
    esJorge: "Spanish - Jorge",
    esLarissa: "Spanish - Larissa",
    manualTextInput: "Manual text input",
    typeYourTextManually: "Type your text manually",
    uploadFile: "Upload file",
    useTxtPdfDoc: "Use TXT, PDF, DOC",
    selectAnOptionBelow: "Select an option below:",
    startCreatingTTSFromScratch:"Start creating TTS from scratch",
    startATTSWillConvertAllTranscription: "Starting a TTS will convert all transcription of your Workflow to TTS voice.",
  },
  player: {
    loadingVideo: "Loading video...",
    loadingLanguage: "Loading language...",
    previousStep: "Previous Step",
    nextStep: "Next Step",
    audio: "Audio",
    subtitles: "Subtitles",
    autoPlay: "Auto Play",
    off: "Off",
    on: "On",
    quality: "Quality",
    speed: "Speed",
    normal: "Normal",
    rotatePhone: "<span class='primary--text'>Rotate </span>your phone for a<br>better experience",
    alertTranscodingFailed: "Oops, looks like the video transcoding has failed. Please contact DeepHow for further assistance.",
    alertTranscodingStillProgress: "Oops, looks like the video transcoding is still in progress. Please come back later.",
    settings: "Settings",
    openInBrowser: "Open in browser",
    goToHome: "Go to Home",
    thisWorkflowWasUnpublished: "This Workflow was unpublished",
    thisWorkflowWasDeleted: "This Workflow was deleted",
    talkToSupervisor: "If you think this Workflow should be published, talk to your supervisor",
    selectWorkspace: "Select Workspace",
    // player2.0
    home: "Home",
    myWorkflows: "My Workflows",
    recommendedForYou: "Recommended for You",
    favorites: "Favorites",
    welcomeToDeepHowNavigator: "Welcome to DeepHow Navigator",
    numNewSkillsTrainings: "You have<span> 0 new skill</span><br> assigned to you. | You have<span> 1 new skill</span><br> assigned to you. | You have<span> {count} new skills</span><br> assigned to you.",
    clickForTips: "Click here for some smart tips.",
    learnHow: "Learn How",
    goToSkills: "Go to Skills",
    at: "at ",
    youAreIn: "You're in",
    loadingRecommendations: "Loading Recommendations...",
    loadingFavoriteWorkflows: "Loading Favorite Workflows...",
    noFavoriteWorkflows: "You have no items in your favorites yet.",
    canFindSavedItemsHere: "You can find all your saved items here.",
    removeWorkflowFromFavorites: "Are you sure you want to remove this workflow from your Favorites?",
    yesGoAhead: "Yes, go ahead",
    thisWorkflowIsNotAvailable: "This workflow is not available",
    dismiss: "Dismiss",
    navigator: "Navigator",
    howToUseNavigator: "Learn how to use the new <br><b>DeepHow Navigator</b>",
    checkTheNewFeatures: "Check the new features.",
    unknownUser: "Unkown User",
    okCloseBtn: "Ok, close.",
    emptyRecommendWorkflowTitle: "Hey! Where is the content?",
    emptyRecommendWorkflowDescription: "Don't worry! As soon as Workflows are created, I'll recommend some for you. Come back soon!",
    thisWorkflowDoesNotExist: "This workflow does not exist",
    // navigator
    resume: "Resume",
    myWorkspaces: "My Workspaces",
    otherWorkspaces: "Other workspaces",
    loading: "Loading...",
    loadingfavoriteWorkspaces: "Loading favorite Workspaces...",
    removeWorkspaceFromFavorites: "Are you sure you want to remove this Workspace from your Favorites?",
    YouAreViewing: "You're viewing",
    allWorkspaces: "All Workspaces",
    removeFromFavorites: "Remove from Favorites",
    favoriteThisVideo: "Favorite this video",
    shareThisVideo: "Share this video",
    viewAll: "View All",
    openFile: "Open file",
    suggestedKeyterms: "Suggested Key Terms",
    // workflow player added 10/10/2022
    posted: "Posted",
    views: "0 View | 1 View | {count} Views",
    saveFavorite: "Save",
    react: "React",
    numColleaguesReact: "0 colleague reacted | 1 colleague reacted | {count} colleagues reacted",
    youAndNumColleaguesReact: "<span>You</span> and 0 colleague reacted | <span>You</span> and 1 colleague reacted | <span>You</span> and {count} colleagues reacted",
    youReacted: "<span>You</span> reacted",
    userReaction: "User Reaction",
    fullscreen: "Full screen",
    exitFullscreen: "Exit full screen",
    // 1/23/2023 Comments
    comments: "Comments",
    deleteComment: "Delete Comment",
    deleteCommentMsg: "Are you sure you want to delete this Comment? <br>This can't be undone.",
    edit: "Edit",
    updating: "updating...",
    changesSaved: "Changes saved.",
    noComments: "No comments yet…",
    makeFirstComment: "Make the first comment below",
    comment: "comment",
    writeAComment: "Write a comment...",
    edited: "edited",
    emojiRecent: "Frequently Used",
    emojiSmileys: "Smileys & Emoticon",
    emojiPeople: "People & Body",
    emojiNature: "Animals & Nature",
    emojiFoods: "Food & Drink",
    emojiActivity: "Activity",
    emojiPlaces: "Travel & Places",
    emojiObjects: "Objects",
    emojiSymbols: "Symbols",
    emojiFlags: "Flags",
    emojiCustom: "Custom",
    back: "back", //back to the last page
    feedback: "Feedback",
    leaveAFeedback: "Leave a feedback",
    leaveAFeedbackMsg: "Tell us about any problems, leave a suggestion or even a constructive criticism.",
    typeYourMessageHere: "Type your message here",
    submit: "Submit",
    thankYou: "Thank you.",
    feedbackSuccessMsg: "Your feedback was successfully sent.",
    alertFeedbackFailedMsg: "Failed to send your feedback. Please try again.",
    diagrams: "diagrams",
    pdfFiles: "PDF files",
    theaterMode: "Theater mode",
    exitTheaterMode: "Exit theater mode",
    // TODO: 09/08/2023
    enableWorkflowRepeat: "Enable workflow repeat",
    enableStepRepeat: "Enable step repeat",
    disableRepeat: "Disable repeat",
  },
  profile: {
    // added 12/09/2021
    addPositionLocation: "Add Position and Location",
    noWorkflowsCreated: "There is no workflows created yet.",
    comeBackLater: "Come back later.",
    createdWorkflows: "Created workflows",
    profileOwnerNoContentMsg: "<span>Hey, {name}! Here is your new profile page.</span> <br>In the future, you'll be able to share knowledge with everyone. Hold on!",
    profileColleguesNoContentMsg: "<span>This is {name}'s profile page.</span> <br> He's not yet able to publish content, but in the near future he will!",
    editProfile: "Edit Profile",
    personalInformation: "Personal information",
    personalInformationMsg: "People visiting your profile will see the following info.",
    photo: "photo",
    position: "Position",
    locationRegion: "Location/ Region",
    whatYouDo: "Let people know what you do at this organization.",
    whereYouBased: "Let people know where you are based.",
    accountManagement: "Account management",
    accountManagementMsg: "This information is private and won’t show up in your public profile.",
    identifyMyself: "I identify myself as",
    yourPassword: "Your password",
    nonDeclared: "Non-declared (default)",
    male: "Male",
    female: "Female",
    nonBinary: "Non-binary",
    viewProfile: "View Profile",
    alertFailedToUpdateProfile: "Failed to update your profile."
  },
  explorer: {
    search: "Search",
    in: "in&nbsp;",
    howTo: "How to…",
    didYouMean: "Did you mean",
    searchInTitle: "In the title",
    searchInContent: "In the content",
    searchInVideo: "Stephanie's suggestions",
    title: "Title",
    searchStartMsg1: "What are you looking for?",
    searchStartMsg2: "Use the search box above",
    sortBy: "Sort by",
    lastUpdated: "Last Updated",
    alphabetically: "Alphabetically",
    workflowsIn: "Workflows in",
    tagNew: "New",
    tagProcessing: "Processing",
    msgProcessing: "Stephanie is processing your workflow…<br/>please wait :)",
    tagFailed: "Failed",
    msgFailed: "Oops, something went wrong while processing the speech or video. We'll retry again soon...",
    msgVideoLimitExceeded: "The video exceeded the length supported by DeepHow. It will be automatically reuploaded in two or more workflows.",
    msgVideoExceededMaximum: "The video exceeds the maximum workflow length supported by DeepHow. It will be automatically split in two or more workflows",
    tagTranscoding: "Transcoding",
    msgTranscoding: "Stephanie is transcoding your workflow…<br/>please wait :)",
    deleteWorkflow: "Delete workflow",
    popupBtnDelete: "Yes, delete",
    popupMsgDeleteWorkflow: "Are you sure you want to delete this workflow? You will lose all the steps and data related to this workflow.",
    noUnpublishedWorkflows: "You have no unpublished workflows yet...",
    noPublishedWorkflows: "You have no published workflows yet...",
    startCreatingWorkflow: "Let's start creating workflow!",
    startPublishingWorkflow: "Let's start publishing workflow!",
    noSearchResult: "No results found…",
    trySearchAgain: "Please try searching again",
    searchingForWorkflows: "Searching for Workflows...",
    secondsAgo: "0 second ago | 1 second ago | {count} seconds ago",
    minutesAgo: "0 minute ago | 1 minute ago | {count} minutes ago",
    hoursAgo: "0 hour ago | 1 hour ago | {count} hours ago",
    daysAgo: "0 day ago | 1 day ago | {count} days ago",
    monthsAgo: "0 month ago | 1 month ago | {count} months ago",
    yearsAgo: "0 year ago | 1 year ago | {count} years ago",
    sAgo: "{count} s",
    minAgo: "{count} min",
    hAgo: "{count} h",
    dAgo: "{count} d",
    mAgo: "{count} m",
    yAgo: "{count} y",
    renameWorkflow: "Rename workflow",
    moveWorkflowTo: "Move workflow to",
    duplicateWorkflow: "Duplicate workflow",
    unpublishWorkflow: "Unpublish workflow",
    shareKnowhow: "Share know-how",
    or: "Or",
    clickHere: "click here",
    andCopyMsg: "and copy URL link to send by e-mail",
    successfulSharing: "Successful sharing.",
    send: "Send",
    typeHereAMessage: "Type here a message",
    wellDone: "Well done",
    numMore: "{count} more",
    moveWorkflow: "Move Workflow",
    currentWorkspace: "Current workspace",
    whereToMsg: "Where do you want to move<br> this workflow to?",
    move: "move",
    alertSuccessfullyMoved: "Successfully moved the workflow.",
    alertFailedMoved: "Failed to move the workflow.",
    noNotificationsMsg: "You currently have no notifications",
    betaSearch: "Please be aware that Stephanie's suggestion is still in Beta...",
    relevantSteps: "Relevant Steps:",
    relevance: "relevance",
    resultsFor: "{num} results for <b>&ldquo;{input}&rdquo;</b>",
    resultFor: "{num} result for <b>&ldquo;{input}&rdquo;</b>",
    byPublisher: "By Publisher",
    alertFailedShareWorkflow: "Failed to share workflow.",
  },
  new: {
    newWorkflow: "New workflow",
    uploadVideo: "Upload video",
    recordScreen: "Record screen",
    replaceVideoSubtitle: "Drag or click to replace your video.",
    recordScreenSubtitle: "Stephanie is ready to start recording your screen.",
    workflowName: "Workflow name",
    workflowLanguage: "Workflow language",
    addToWorkspace: "Add to Workspace",
    upload: "Upload",
    startRecording: "Start Recording",
    cancel: "Cancel",
    wrongUploadFile: "File type not supported. Please select the following file type :",
    limitFileDuration: "File duration supports up to {duration} min. Please use DeepHow Windows Capture App",
    limitFileDurationGen2: "File duration supports up to {duration} min.",
    uploadingVideo: "Uploading video",
    uploadingVideoSubtitle: "Please wait while Stephanie uploads your video",
    renderingVideo: "Rendering/Saving video",
    renderingVideoSubtitle: "Please wait while Stephanie renders your video",
    almostDone: "We are almost done!",
    uploadingStep1: "Step 1 of 2 - Analyzing the workflow...",
    uploadingStep2: "Uploading the video...",
    successSubtitle: "Your workflow was successfully uploaded",
    goToEditor: "Go to the editor",
    goToContentManger: "Go to the content manager",
    uploadingAlert: "Changing or closing the browser tab will cause upload errors.",
    recordingScreen: "Recording Screen",
    support15Min: "Keep in mind screen capture supports up to <b>15 min</b>.",
    hereWeGo: "Here we go!",
    remember15Min: "Remember that you have 15 min.",
    windowsAppAvailable: "DeepHow Windows Capture App<br>is now available.",
    windowsAppAvailableNews: "DeepHow Windows Capture App is already available for Windows. Now you can upload larger files and also record screen.",
    downloadNow: "Download now!",
    deepHowWindowsApp: "DeepHow Windows Capture App",
    downloadAndInstalltheWindowsApp: "Download and install the DeepHow Windows Capture App.",
    notAllowedToInstall: "If you're not allowed to install the version above,",
    downloadThisVersion: "download this version",
    and: " and ",
    watchThisVideo: "<span>watch this video</span> on how to use it.",
    // Added on 2022/01/19
    abiAuthorizationLabel: "I hereby acknowledge that all individuals in this video have signed the <span>Photograph/Video Recording Authorization</span> and Release specific to my country.",
    abiPrivacyLabel: "I hereby agree that I have read and understood the ABInbev <span>Photograph/Video Recording Privacy Statement.</span>",
    // Added on 2022/06/15
    importFromZoom: "Import from Zoom",
    importFromZoomSteps: "Step {step} of {totalStep}",
    selectVideosFrom: "Select videos from",
    videoSelected: "<span>0</span> video selected | <span>1</span> video selected | <span>{count}</span> videos selected",
    workflowInfo: "Workflow info",
    newImport: "New Import",
    orBackToEditor: "or back to the <span>Editor</span>",
    importFromZoomSuccessMsg: "Your videos will start uploading soon, it may take some time before they are available for editing.",
    zoomFailedUploadMsg: "Failed to upload Zoom video",
    zoomPeriodLastMonth: "last month",
    zoomPeriodPastMonth: "past {n} months",
    loadingZoomRecordings: "Loading videos",
    zoomNoRecordingsInfo: "No recordings found for the selected period.",
    yourVideoExceedTwoHours: "Your video exceeds 2 hours and cannot be uploaded.",
    yourVideoExceed3GSize: "Your video exceeds 3 GB and cannot be uploaded.",
    yourVideoExceedTwoHoursAnd3GSize: "Your video exceeds 2 hours, 3GB and cannot be uploaded.",
    videoUploadWarningMessage: "Drag or click to import your video ({supportedFileTypes}) Max. size is 3 GB and up to 2 hours.",
    videoUploadWarningMessageGen2: "Drag or click to import your video ({supportedFileTypes}) Max. up to 40 mins.",
  },
  auth: {
    aboutDeephow: "About DeepHow",
    authTitle: "First AI solution for skilled trades training.",
    authIntro: "DeepHow bridges the skills gap in manufacturing, service and repair through an AI-powered learning platform based on interactive how-to videos.",
    allRightReserved: "All rights reserved",
    logIn: "Log in",
    logOut: "Logout",
    logInSubtitle: "Please insert your details to log in.",
    logInSubtitleSso: "Please click below to log in.",
    emailOrId: "E-mail or Employee ID",
    hello: "Hello, ",
    passwordSubtitle: "Please insert your password.",
    password: "Password",
    forgotPassword: "Forgot password",
    forgotPasswordSubtitle: "Please insert your e-mail, so we can send you a link to create a new password.",
    email: "E-mail",
    resetPassword: "Reset password",
    alertResetSuccess: "Please check your email inbox.",
    alertResetFailed: "Failed to recover password.",
    alertInvalidId: "Invalid employee ID.",
    successMsg: "Please check your e-mail and click on the link to define a new password for your account.",
    logInWithSSO: "Log in with SSO",
    loggingInWithSSO: "Logging in with SSO...",
    logInWithGoogle: "Log in with Google",
    logInWithMicrosoft: "Log in with Microsoft",
    loadingSso: "Loading SSO...",
    about: "about",
    // 12/17/2021 iframe
    loginToYourAccount: "<b>Log in</b> to your account",
    pleaseInputYourPassword: "Please input your password.",
    backToLogin: "Back to log in",
    // added 03/02/2022
    wrongPassword: "Wrong Password.",
    // Shared Account
    sharedDevice: "Shared Device",
    loginExistingAccount: "Log in with existing account",
    createNewAccount: "Create a new account",
    noUsersMsg: "No users have logged in this shared device yet...",
    insertIDMsg: "Please insert your employee ID",
    createIdUserMsg: "We are <b>almost there</b>!<br>Please fill in your details below.",
    confirmEmployeeID: "Confirm Employee ID",
    welcomeToDeepHow: "Welcome to DeepHow, <b>{name}</b>",
    confirmWorkspaceTeamMsg: "Please confirm your Workspace and select your team!",
    LetsGetStarted: "Let's get started!",
    // added 05/26/2022
    useSsoLogin: "Email/Password login is not enabled for your organization. Please log in with SSO.",
    // added 02/17/2023
    cookieAlert: "I accept the use of cookies as detailed in our <a href='{pdfUrl}' target='_blank'>Privacy Policy</a>.",
    useCookie: "Do you accept our cookies?",
    useCookieContext: "We use cookies to improve the experience and provide more personalized services to you. To find out more about the cookies we use, see our <a style='color: #DFE0E0;' href='{url}' target='_blank'>Privacy Policy</a>.",
    useCookieContextMobile: "We use cookies to improve the experience and provide more personalized services to you. To find out more, see our <a style='color: #DFE0E0;' href='{url}' target='_blank'>Privacy Policy</a>.",
    privacyPolicy: "Privacy Policy",
    acceptCookies: "Accept cookies",
    alertDisableDHLogin: "Employee ID login is not allowed for this organization.",
    alertPasswordFormatError: "Password format validation failed.",
    alertPasswordReused: "Password is used for the last 5 time(s).",
    alertPasswordIncludeCertainValue: "Password is including the email/name of the user.",
    alertPasswordMustUpdate: "Passwords must be changed after 90 days.",
    alertPasswordWillExpire: "password will expire in {n} day(s)",
    alertLoginFailed: "Something went wrong while logging in.",
    alertFailedToGetSharedDeviceSettings: "Something went wrong while getting Shared Account.",
    alertUserNotFound: "User is not found.",
    alertEmployeeIdInvalid: "Employee ID is invalid.",
    alertAccountIdAlreadyUsed: "Account ID is already used.",
    alertAccountIdInvalid: "Account ID is invalid.",
    alertMasterPasswordIncorrect: "Password is incorrect.",
    alertChangeUserNameFailed: 'Failed to change user name.',
    checkIpPopupTitle: "Checking your IP and role...",
    checkIpPopupDesc: "Please hold on a little bit!",
    checkIpDeephowDesc: "Know-How Video Platform",
    checkIpNotAllowedTitle: "IP Address not allowed",
    checkIpIs: "(Your IP is {clientIP})",
    checkIpNotAllowedDesc: "Access to this app is allowed from specific locations only. Please contact your admin for more info!",
    checkIpAlertForOrgAdmin: "Access to this app is allowed from specific locations only. As an Org. Admin you have access only to the Admin.",
    unavailableTitle: "Page Unavailable",
    unavailableDesc: "It seems that your current settings are blocking access to this page. To ensure you can use all features, please check your network, system settings or check with your network administrator. Once you've adjusted the settings, click 'Refresh' to reload the page and continue.",
    customize: "Customize",
    acceptCookies: "Accept all",
    cookiesSettings: "Cookies Settings",
    seeOurPrivacyPolicy: "See our  <a href='{pdfUrl}' target='_blank'>privacy policy</a>",
    noAccept: "I don't accept cookies",
    saveAndAccept: "Save & Accept",
    cookieConsentDesc: 'We use cookies in this site, some are essential while othres help us to improve the experience and provide more personalized services to you.',
    mandatoryAlertTitle: "{consentTitle} are needed",
    mandatoryAlertDesc: "If you don't accept {consentTitle}, you won't be able to access DeepHow.",
    DontAcceptAndExit: "Don't accept and exit",
    acceptConsent: "Accept {consentTitle}",
  },
  rules: {
    required: "Required.",
    invalidEmail: "Invalid e-mail.",
    noSpace: "Can not add spaces.",
    validPassword: "Password needs to be at least 6 characters, include number, lower and upper case letter.",
    maxPassword: "Max 16 characters.",
    mustMatch: "Password must match.",
    max25: "Max 25 characters.",
    max90: "Max 90 characters.",
    max100: "Max 100 characters.",
    max140: "Max 140 characters.",
    numMaxCharacters: "Max 0 character. | Max 1 character. | Max {count} characters.",
    min6: "Min 6 characters.",
    noWhiteSpace: "Enter text as title.",
    noAllSpace: "Can not use spaces as name.",
    invalidEmployeeID: "Invalid employee ID.",
    invalidUrl: "Invalid URL",
    mustMatchId: "Employee Id must match.",
    canNotEnterSharedAccount: "Can not insert Shared Account ID.",
    validPasswordMin10: "Password needs to be at least 10 characters, include number, special character, lower and upper case letter.",
    invalidName: "Invalid name.",
    validPasswordCharacters: "Password needs to include number, special character, lower and upper case letter.",
    min10: "Min 10 characters.",
  },
  alert: {
    success: "Success",
    info: "Information",
    alert: "Alert",
    error: "Error",
    dontShowThisMsgAgain: "Don't show this message again",
    unexpectedError: "An unexpected error occured. Please try again or contact DeepHow for assistance.",
    warning: "Warning",
  },
  skills: {
    skillsMatrix: "Skills Matrix",
    createSkill: "Create skill",
    skillsCreated: "Skills created",
    skillsPublished: "Skills published",
    skillsIn: "Skills in",
    hintSearchSkills: "Search for Skills…",
    lastUpdated: "Last Update",
    skillsPerPage: "Skills per page",
    usersAssigned: "Users Assigned",
    status: "Status",
    createdBy: "By",
    skillsSelected: "skill selected | skill selected | skills selected",
    updatedBy: "By",
    deleteSkill: "Delete Skill",
    popupMsgDeleteSkill: "Are you sure you want to delete this skill?",
    deleteSkills: "Delete Skills",
    popupMsgDeleteSkills: "Are you sure you want to delete these skills?",
    clearAll: "Clear all",
    clearAllFilters: "Clear all filters",
    clearFilter: "Clear filter",
    delete: "Delete",
    youAreViewing: "You are viewing",
    numWorkflows: "0 Workflow | 1 Workflow | {count} Workflows",
    searchIn: "Search in",
    loadingSkillMatrix: "Loading Skill Matrix...",
    createSkillSubtitle: "Select a name and where you want to upload it",
    skillName: "Skill name",
    create: "Create",
    editSkill: "Edit skill",
    popupMsgEditSkill: "If you go into edit mode, your skill will automatically be unpublished , are you sure you want to continue?",
    publishSkill: "Publish skill",
    popupMsgPublishSkill: "You are going to publish your skill and users will be able to see it, are you sure you want to do that?",
    alertFailedSaveSkill: "Failed to save skill",
    alertSkillSuccessfullyPublished: "Your skill was successfully published.",
    alertSkillSuccessfullyUnpublished: "Your skill was successfully unpublished.",
    alertSkillSuccessfullySaved: "Your skill was successfully saved",
    alertFailedSavedSkill: "Failed to save skill",
    alertFailedPublishSkill: "Failed to publish skill",
    alertSkillSuccessfullyDeleted: "Your skill was successfully deleted",
    alertSkillFailedDeleted: "Failed to delete skill",
    content: "Content",
    addContent: "Add content",
    workflowDeleted: "Workflow deleted",
    workflowsSelected: "workflow selected | workflow selected | workflows selected",
    selectAll: "Select all",
    remove: "Remove",
    noContentMsg: "You have no content in your skill yet",
    assignUsers: "Assign users",
    noUserMsg: "You have no users assigned to your training yet",
    numUsers: "<b>0</b> User | <b>1</b> User | <b>{count}</b> Users",
    assignedOn: "Assigned on",
    certifiedBy: "Certified by",
    progress: "Progress",
    aletUserSuccessfullyRemoved: "The user was successfully removed.",
    removeUser: "Remove User",
    popupMsgRemoveUserFromSkill: "Are you sure you want to remove <b>{name}</b> from this skill?",
    assign: "Assign",
    successfulAssigned: "Successfully assigned",
    AddedToSkill: "Added to skill.",
    loadingSkills: "Loading Skills...",
    searchingForSkills: "Searching for skills...",
    toDo: "To do",
    completed: "Completed",
    assignedBy: "Assigned by",
    min: " min",
    nextPart: "Next Part",
    noSkillsAssignedMsg: "You have no Skills assigned yet.",
    noSkillsAssignedMsg2: "We will let you know when you have something new!",
    noCompletedSkillsMsg: "You have no completed Skills yet.",
    noCompletedSkillsMsg2: "Let's start learning Skills!",
    noDataSkillsMatrix: "You have no data in Skills Matrix yet...",
    certificate: "Certificate",
    certificateMsg: "Users will get a completion certificate if sucessfully finishes this skill.",
    selectCertificateMsg: "Select below the users who will be able to validate the certificate:",
    validateSkill: "Validate Skill",
    validateSkillMsg: "Are you sure you want to validate <b>{name}</b> in the skill {skill}? <br />You won't be able to undo this.",
    yesValidate: "Yes, validate",
    noValidate: "No",
    certifier: "Certifier",
    signaturesOn: "on",
    teamProgress: "Team Progress",
    openTeam: "Open team",
    removeCertificate: "Remove Certificate",
    removeCertificateMsg: "You will remove all the users who are able to validate the certificate, are you sure?",
    yesRemove: "Yes, remove",
    mustAddUserMsg: "* Certificate won't be activated if no user is added.",
    skillCover: "Skill Cover",
    alertSuccessfullyUpdatedSkillCover: "Skill cover was successfully updated.",
    alertFailedUpdatedSkillCover: "Failed to update Skill cover.",
    closeSkill: "Close skill",
    allWorkflowsDeleted: "All the Workflows are deleted in this Skill.",
    waitingForValidation: "Waiting for validation",
    validatedBy: "Validated by:",
    skillWasUnpublished: "Skill was unpublished",
    skillWasUnpublishedMsg: "I will let you know when this Skill becomes available again. For now, you are all good!",
    notAssignedToSkill: "You're not assigned to this Skill.",
    notAssignedToSkillMsg: "Sorry, this page is only avaiable to users who have been assigned to this Skill.",
    remindPendingUsers: "Remind pending users",
    alertSuccessRemindPendingUsers: "Successfully reminded pending users.",
    alertFailedRemindPendingUsers: "Failed to Remind pending users.",
    alertNoPendingUsers: "No pending users found.",
    numVideo: "0 video | 1 video | {count} videos",
    // Skill Deadline
    deadline: "Deadline",
    deadlineMsg: "Set a global period of time for users to finish watching this skill. After the deadline, pending users will be notified...",
    deadlineForThisSkill: "Activate deadline for this skill.",
    certificateForThisSkill: "Activate certificate for this skill.",
    deadlineStartsMsg: "The period starts to count considering the date user was assigned.",
    selectAPeriod: "Select a period",
    finished: "Finished",
    editDeadline: "Edit Deadline",
    editDeadlineMsg: "By editing the deadline, the previous one will no longer be valid, and the new period will be applied for all users in this Skill. <br><br>Are you sure you want to continue?",
    alertSuccessNewDeadline: "New deadline is now applied for all users.",
    alertSuccessRemoveDeadline: "Skill Deadline was successfully removed.",
    alertFailedDeadline: "Failed to update Skill Deadline.",
    dueToday: "Due Today",
    turnOffDeadlineMsg: "By turning off the Deadline, the previous data will be lost. The next time it is turned on, it will reset all user's start dates. <br><br>Are you sure you want to continue?"
  },
  time: {
    numDays: "0 day | 1 day | {count} days",
    numWeeks: "0 week | 1 week | {count} weeks",
    numMonths: "0 month | 1 month | {count} months",
    numDaysLeft: "0 day left | 1 day left | {count} days left",
    numDaysDelayed: "0 day delayed | 1 day delayed | {count} days delayed",
  },
  notification: {
    sharedWorkflow: "shared a workflow",
    assignedNewSkill: "assigned you a new skill",
    sentAnnouncement: "sent an announcement",
    needValidation: "needs your validation",
    noNotificationsMsg: "You currently have no notifications",
    justNow: "Just now",
    updatedSkill: "updated the skill",
    unpublishedSkill: "unpublished the skill",
    sentAReminder: "sent you a reminder about this skill. Start watching so he can track your progress.",
    assignedNewSkillDue: "assigned you a new skill. You have <span>{time}</span> to finish watching it.",
    delayedSkillMsg: "You have a <span>delayed skill</span>. Start watching now to get back on track!",
    daysLeftMsg: "You have only <span>{time} left</span> to finish watching this skill. Hurry up so you wont be delayed!",
    askToReviewWorkflow: "asked you to review a Workflow.",
    reactedWorkflowMsg: "<span>{user}</span> reacted to your workflow.",
    reactedWorkflowMsgTwoUsers: "<span>{user1} and {user2}</span> reacted to your workflow.",
    reactedWorkflowMsgMoreUsers: "<span>{user1}, {user2} and {count} other</span> reacted to your workflow.",
  },
  mediaPicker: {
    insertFullWorkflow: "Insert Full Workflow",
    selectWorkflowStep: "Select Workflow Step",
    stepSelected: " Step selected"
  },
  zoomIntegration: {
    settingsTitle: "Zoom Settings",
    settingsSubtitle: "Integration",
    deleteCopy: "Delete the copy of recorded video from Zoom Cloud",
    goToDeephow: "Go to DeepHow",
    landingSuccess: "<b>DeepHow Know-How Video App</b> Successful Installed",
    landingError: "An error occurred while installing the <b>DeepHow Know-How Video App</b>. Please contact <a href = 'mailto: service@deephow.com'>service@deephow.com</a> for support.",
    landingWarning: "The Zoom integration is not enabled for your organization.",
  },
  reactions: {
    likeIt: "Like it",
    greatContent: "Great Content",
    wellPresented: "Well Presented",
    niceProduction: "Nice Production",
  },
  maven: {
    // TODO: maven chat added on 03/15/2023
    mavenChat: "Chat",
    searchInWorkspace: "Search in {workspace}",
    showReferences: "Show references",
    hideReferences: "Hide references",
    helloMessage: "Hello {userName},",
    assistMessage: "How can I assist you today?",
    askMeAnything: "Ask me anything...",
    tryOtherQuestionMessage: "You can try asking other question.",
    audioIsReady: "Please recording your audio clip in English...",
    audioLimitationTime: "The audio limitation time is 3 mins.",
    clearChatHistory: "Clear chat history",
    newLineHint: "new line: shift + Enter",
    loadingReferences: "Loading references",
    // TODO: maven map added on 04/14/2023
    map: "Map",
    resetMap: "reset map",
    filterWorkspaces: "filter workspaces",
    showQuestions: "show questions",
    hideQuestions: "hide questions",
    showClusters: "show clusters",
    hideClusters: "hide clusters",
    question: "Question",
    loadingMap: "Loading map...",
    clearChatHistoryConfirmation: "Clearing the chat history will start a new chat. Are you sure?",
    clearConfirm: "Yes, clear",
    summary: "Summary",
    countWorkflows: "Workflow(0) | Workflow(1) | Workflows({count})",
    answer: "Answer",
    userRate: "User rate",
    workflowReferences: "Workflow references",
    countQuestions: "Question(0) | Question(1) | Questions({count})",
    relatedWorkflow: "Related workflow",
    clusterInfo: "Clusters Info",
    numClusters: "0 Cluster | 1 Cluster | {count} Clusters",
    maven: "Maven",
    chatHistory: "Chat History",
    newChat: "New chat",
    newRegularChat: "New regular chat",
    scriptGenerator: "Script Generator",
    sopGenerator: "SOP Generator",
    mavenScriptPlaceholder: "Insert the link for you Workflow",
    mavenWarning: "AI can make mistakes. Consider checking important information.",
    emptyScriptText: "Import your existing SOP documents to generate video scripts to support your content creation process.",
    emptySOPText: "Import an existing DeepHow workflow to automatically generate a SOP document.",
    dragFile: "Drag & drop your TXT files",
    orBrowser: "or <span style='color: #57A6FF;'>browse files</span> on your computer",
    DropItHere: "Drop it here !",
    mChat: "Maven Chat",
    regularDesc: "Ask anything from you workflows or general knowledge, you can filter the output by selecting different workspaces using the selector above.",
    regularPlaceholder: "Message regular chat...",
    UploadProcessed: "Upload Processed",
    Generate: "Generate",
    sopQuery: "Generate a SOP based on the document provided",
    scriptQuery: "Generate a script based on the document uploaded",
    askChange: "Ask for changes (Ex: break into more steps, make it longer, etc...)",
    clearConversation: "Clear Conversation",
  },
  $vuetify: {
    dataIterator: {
      rowsPerPageText: "Items per page:",
      rowsPerPageAll: "All",
      pageText: "{0}-{1} of {2}",
      noResultsText: "No matching records found",
      nextPage: "Next page",
      prevPage: "Previous page"
    },
    dataTable: {
      rowsPerPageText: "Rows per page:"
    },
    noDataText: "No data available",
    carousel: {
      prev: "Previous visual",
      next: "Next visual"
    }
  }
};
