export const BROWSER_TAB_ID_KEY = "browserTabId";
export const BROWSER_TAB_KEY = "usingBrowerTabIdList";
export const LOGOUT_NOTIFY_KEY = "logoutNotify";

export const getIdleLocalStorage = (key = "") => {
  return localStorage.getItem(key);
};
export const removeIdleLocalStorage = (key = "") => {
  localStorage.removeItem(key);
};
export const setBrowserTabIdLocalStorage = (id) => {
  localStorage.setItem(BROWSER_TAB_ID_KEY, id);
};
export const updateBrowerTabLocalStorage = (newList = []) => {
  localStorage.setItem(BROWSER_TAB_KEY, JSON.stringify(newList));
};
export const setLogoutNotifyLocalStorage = (time) => {
  const params = { time, updateTime: Date.now() };
  localStorage.setItem(LOGOUT_NOTIFY_KEY, JSON.stringify(params));
};
export const getLogoutNotifyLocalStorage = () => {
  let result;
  try {
    const data = JSON.parse(localStorage.getItem(LOGOUT_NOTIFY_KEY));
    result = data;
  } catch (e) {
    console.error("JSON parse error of logout notify from local storage");
  } finally {
    return result;
  }
};

const LOGOUT_IMMEDIATELY = "logoutImmediately";
export const setLogoutImmediatelyLocalStorage = () => {
  localStorage.setItem(LOGOUT_IMMEDIATELY, true);
};
export const getLogoutImmediatelyLocalStorage = () => {
  return localStorage.getItem(LOGOUT_IMMEDIATELY);
};
export const removeLogoutImmediatelyLocalStorage = () => {
  localStorage.removeItem(LOGOUT_IMMEDIATELY, true);
};
