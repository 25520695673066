<template>
  <section class="admin-workspace-popup">
    <v-card width="480" color="#FFFFFF" class="card-popup">
      <button class="card-button--close" @click="$emit('closePopup')" :autotest="getAutotest('close')">
        <icon-base color="#4A4A4A" width="22" height="22">
          <d-icon-close2 />
        </icon-base>
      </button>

      <div class="card-title">
        <d-icon-workspace width="170" />
        <div v-if="showForm === 'create-workspace'">
          <div class="card-title--text">{{ $t("admin.createWorkspace") }}</div>
        </div>
        <div v-if="showForm === 'edit-workspace'">
          <div class="card-title--text" autotest="edit-workspace-popup-name-display">
            {{ $t("admin.editWorkspace") }}
          </div>
        </div>
      </div>

      <v-form v-model="formValid" autocomplete="off" class="card-body">
        <d-layout>
          <d-flex class="xs8 mr-2">
            <d-text-field
              autofocus
              v-model="workspaceName"
              :disabled="disabledChangeValue"
              :class="{ 'disabled-input': disabledChangeValue }"
              :label="$t('admin.workspaceName') + '*'"
              :rules="[rules.required, rules.noAllSpace, rules.workspaceNameAllowed]"
              autocomplete="chrome-off"
              :autotest="getAutotest('name-input')"
            ></d-text-field>
          </d-flex>

          <d-flex class="xs4 ml-2">
            <div class="switch-item">
              <div class="text-capitalize mr-1">{{ $t("admin.privateOrnot") }}</div>
              <d-tooltip textWrap>
                <template slot="content">
                  <span>{{ $t("admin.privateWorkspaceInfo") }}</span>
                </template>
                <div class="info-button">
                  <v-icon color="#000000" class="info-icon ma-0 pa-0" small>info_outline</v-icon>
                </div>
              </d-tooltip>
              <v-switch
                v-model="isPrivate"
                color="#4689f4"
                class="switch-btn ml-2"
                :autotest="getAutotest('private-toggle')"
                readonly
                @click.stop="switchPrivate"
              ></v-switch>
            </div>
          </d-flex>
        </d-layout>

        <d-select
          :disabled="disableWorkspaceGroupOptions"
          :class="{ 'disabled-input': disableWorkspaceGroupOptions }"
          v-model="belongedWorkspaceGroup"
          :items="getter_admin_workspace_groups"
          displayProperty="name"
          valueProperty="id"
          :label="$t('all.workspaceGroup')"
          :autotest="getAutotest('workspace-group-dropdown')"
          listWidth="430"
        ></d-select>

        <d-layout>
          <d-flex>
            <d-select
              v-model="selectedColor"
              :items="colorList"
              :label="$t('admin.coverColor')"
              :autotest="getAutotest('color-dropdown')"
            >
              <template slot="selection">
                <div class="d-select__selection">
                  <div class="d-select__colorBlock" :style="{ background: getWorkspaceColor(selectedColor) }"></div>
                  <span>{{ $t("admin." + selectedColor) }}</span>
                </div>
              </template>
              <template slot="item" slot-scope="props">
                <div class="d-select__item">
                  <div class="d-select__colorBlock mt-0" :style="{ background: getWorkspaceColor(props.item) }"></div>
                  <span>{{ $t("admin." + props.item) }}</span>
                </div>
              </template>
            </d-select>
          </d-flex>
        </d-layout>

        <d-select
          v-model="belongedBusiness"
          :items="businesses"
          displayProperty="name"
          valueProperty="id"
          :label="$t('all.business')"
          :autotest="getAutotest('business-dropdown')"
          listWidth="430"
        ></d-select>
      </v-form>

      <v-card-actions class="card-actions">
        <d-layout>
          <d-flex class="xs6 mr-2">
            <d-button
              outline
              depressed
              round
              block
              class="body-2 text-capitalize"
              color="#4689f4"
              :autotest="getAutotest('cancel')"
              light
              @click="$emit('closePopup')"
              >{{ $t("all.cancel") }}</d-button
            >
          </d-flex>

          <d-flex class="xs6 ml-2">
            <d-button
              v-if="showForm === 'create-workspace'"
              depressed
              round
              block
              class="body-2 text-capitalize white--text"
              color="#4689f4"
              :disabled="!formValid || isProcessingAction"
              light
              @click="createWorkspace"
              autotest="create-workspace-popup-create-btn"
              >{{ $t("all.create") }}</d-button
            >

            <d-button
              v-if="showForm === 'edit-workspace'"
              depressed
              round
              block
              class="body-2 text-capitalize white--text"
              color="#4689f4"
              :disabled="!madeChanges || !formValid || isProcessingAction"
              light
              @click="updateWorkspace"
              autotest="edit-workspace-popup-save-btn"
              >{{ $t("all.save") }}</d-button
            >
          </d-flex>
        </d-layout>
      </v-card-actions>
    </v-card>
  </section>
</template>

<script>
import MixinDB from "@/components/MixinDB.vue";
import MixinUser from "@/components/MixinUser.vue";
import MixinAdmin from "@/components/MixinAdmin.vue";
import IconBase from "@/components/IconBase.vue";
import DIconWorkspace from "@/components/icons/colored/DIconWorkspace.vue";
import DIconClose2 from "@/components/icons/DIconClose2.vue";
import DTextField from "@/components/ui_components/DTextField.vue";
import DSelect from "@/components/ui_components/DSelect.vue";
import DTooltip from "@/components/ui_components/DTooltip.vue";
import authChoice from "@/../auth";
import { getWorkspaceColorCss } from "@/js/workspace-color/workspace-color.js";
import { checkAdminCommonCharacters } from "@/js/character-validator/character-validator.js";
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  name: "AdminAddWorkspacePopup",
  components: {
    IconBase,
    DIconWorkspace,
    DIconClose2,
    DTextField,
    DSelect,
    DTooltip,
  },
  mixins: [MixinDB, MixinUser, MixinAdmin],
  props: {
    showForm: String,
    workspaces: Array,
    businesses: Array,
    editedWorkspace: Object,
    isProcessingAction: Boolean,
    hasWorkspaceGroupOptions: Boolean,
    defaultWorkspaceGroupId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      workspaceName: "",
      workspaceId: "",
      isPrivate: false,
      belongedWorkspaceGroup: {},
      selectedColor: "blue",
      colorList: ["blue", "red", "green", "yellow", "purple"],
      belongedBusiness: {},
      locale: "en",
      formValid: false,
      rules: {
        required: (value) => !!value || this.$t("rules.required"),
        noAllSpace: (value) => {
          const patt = /^[\s]*$/;
          return !patt.test(value) || "Can not use spaces as name.";
        },
        workspaceNameAllowed: () => !this.workspaceNameNotAllowed || this.$t("rules.invalidName"),
      },
    };
  },
  computed: {
    ...mapState("workspaceColor", ["workspaceColorsConfig"]),
    ...mapGetters("workspaceGroups", ["getter_admin_workspace_groups", "getter_admin_workspaceg_group_map"]),
    workspaceNameNotAllowed() {
      return checkAdminCommonCharacters(this.workspaceName);
    },
    disabledChangeValue() {
      return this.editedWorkspace.id === this.$defaultGroup;
    },
    madeChanges() {
      const { name, color, business, workspaceGroupId } = this.editedWorkspace;
      const belongedWorkspaceGroup = this.getter_admin_workspaceg_group_map[workspaceGroupId] || {};
      return !(
        this.workspaceName === name &&
        this.belongedWorkspaceGroup.name === belongedWorkspaceGroup.name &&
        this.selectedColor === color &&
        this.belongedBusiness.id === business
      );
    },
    disableWorkspaceGroupOptions() {
      return (this.showForm === "create-workspace" && !this.hasWorkspaceGroupOptions) || this.disabledChangeValue;
    },
  },
  created() {
    this.setFormFields();
  },
  methods: {
    ...mapMutations("global", ["MUTATE_ALERT_INFO"]),
    getAutotest(tag) {
      const formType = this.showForm === "create-workspace" ? "create" : "edit";
      const autotestMapping = {
        close: `${formType}-workspace-popup-close-btn`,
        "name-input": `${formType}-workspace-popup-name-input`,
        "private-toggle": `${formType}-workspace-popup-private-toggle`,
        "workspace-group-dropdown": `${formType}-workspace-popup-workspace-group-dropdown`,
        "color-dropdown": `${formType}-workspace-popup-color-dropdown`,
        "business-dropdown": `${formType}-workspace-popup-business-dropdown`,
        cancel: `${formType}-workspace-popup-cancel-btn`,
      };
      return autotestMapping[tag] || "";
    },
    setFormFields() {
      const formTypeMap = {
        "create-workspace": this.setFormForCreator,
        "edit-workspace": this.setFormForEditor,
      };
      formTypeMap[this.showForm]();
    },
    switchPrivate() {
      if (this.showForm === "create-workspace") {
        this.isPrivate = !this.isPrivate;
      } else {
        const message = this.$t("admin.privateCannotChanged");
        this.MUTATE_ALERT_INFO({ message, type: "error" });
      }
    },
    setFormForCreator() {
      this.belongedWorkspaceGroup = this.getter_admin_workspaceg_group_map[this.defaultWorkspaceGroupId] || {};
      this.locale = this.belongedWorkspaceGroup.locale || "en";
    },
    setFormForEditor() {
      const { id, name, private: isPrivate, color, business, workspaceGroupId } = this.editedWorkspace;

      this.workspaceId = id;
      this.workspaceName = name;
      this.isPrivate = isPrivate;
      this.belongedWorkspaceGroup = this.getter_admin_workspaceg_group_map[workspaceGroupId];
      this.selectedColor = color || "blue";
      if (business) {
        this.belongedBusiness = this.businesses.find((item) => item.id === business);
      }
    },
    getWorkspaceColor(selectedColor) {
      return getWorkspaceColorCss(selectedColor, this.workspaceColorsConfig);
    },
    checkWorkspaceExisted() {
      const isExisted = this.workspaces.some(
        (item) => item.name.toLowerCase() === this.workspaceName.toLowerCase() && item.id !== this.editedWorkspace.id
      );
      if (isExisted) {
        const message = this.$t("admin.alertWorkspaceAlreadyInSystem");
        this.MUTATE_ALERT_INFO({ message, type: "error" });
      }
      return isExisted;
    },
    async createWorkspace() {
      if (this.checkWorkspaceExisted()) return;

      const data = {
        name: this.workspaceName,
        organization: this.$organization,
        private: this.isPrivate,

        // TODO: Confirm `locale` for workspace.
        locale: this.locale,
        workspaceGroupId: this.belongedWorkspaceGroup.id,
        color: this.selectedColor,
        idToken: await this.getIdToken(),
      };
      this.belongedBusiness.id ? (data.business = this.belongedBusiness.id) : null;

      this.$emit("addWorkspace", { data });
    },
    async updateWorkspace() {
      if (this.checkWorkspaceExisted()) return;

      // TODO: Confirm `private` for workspace.
      // For Version 1: Public/Private flag cannot be changed when editing existing workspaces.
      // const isPrivate = this.workspaceId === this.$defaultGroup ? false : this.editedWorkspace.private;
      const data = {
        organization: this.$organization,
        name: this.workspaceName,
        workspaceGroupId: this.belongedWorkspaceGroup.id,
        groupId: this.workspaceId,
        color: this.selectedColor,
        // private: isPrivate,
        idToken: await authChoice().currentUser.getIdToken(),
      };
      this.belongedBusiness.id ? (data.business = this.belongedBusiness.id) : null;

      this.$emit("updateWorkspace", { data });
    },
  },
};
</script>

<style scoped lang="scss">
.disabled-input ::v-deep {
  color: #98a1b3 !important;

  .v-menu__activator {
    cursor: auto !important;
  }

  .input-box {
    background-color: #eff2f7 !important;
  }

  input {
    cursor: auto !important;
  }

  .arrow-icon {
    display: none !important;
  }
}
.admin-workspace-popup {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 2;
}
.card-popup {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0 auto;
}
.card-button--close {
  position: absolute;
  right: 26px;
  top: 28px;
  z-index: 1;
}
.card-title {
  position: relative;
  width: 100%;
  text-align: center;
  padding-top: 84px;
}
.card-title--text {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #52545d;
  padding: 12px 28px 0 28px;
}
.card-body {
  margin: 40px 24px 8px 24px;
}
.card-actions {
  padding: 24px;
}
.d-select__colorBlock {
  height: 24px;
  width: 42px;
  border-radius: 4px;
  margin-top: -3px;
}
.d-select__selection {
  display: flex;
}
.d-select__selection span {
  font-size: 16px;
  line-height: 18px;
  color: #3d3f45;
  margin-left: 8px;
}
.d-select__item {
  display: flex;
}
.d-select__item span {
  font-size: 14px;
  line-height: 24px;
  color: #3d3f45;
  margin-left: 8px;
}
.switch-item {
  position: relative;
  display: flex;
  padding: 34px 0 0 8px;
  color: #404040;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}
.switch-item ::v-deep .tooltip-div {
  width: 230px !important;
}
.info-button {
  position: relative;
  height: 24px;
  width: 16px;
  cursor: pointer;
}
.switch-btn {
  margin: 0;
  padding: 0;
}
.info-icon {
  position: relative;
  top: -1px;
}
</style>
