import axios from "axios";
import environment from "../../env";
import { apiWrapperGenerator } from "./lib";

function getBaseUrl() {
  // NOTICE: this API is not support china version, so the value is null
  return environment.backendType === "firebase"
    ? environment.service[environment.env].whisperServer
    : environment.service[environment.env]["china"].whisperServer;
}

const whisperServer = axios.create({
  baseURL: getBaseUrl(),
});

whisperServer.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => Promise.resolve(error)
);
const apiWrapper = apiWrapperGenerator(whisperServer);

export const postFetchTextByVoice = ({ oid, idToken, file }) => {
  const formData = new FormData();
  formData.append("oid", oid);
  formData.append("idToken", idToken);
  formData.append("file", file);
  return apiWrapper("post", "/speech-to-text", formData);
};
