<template>
  <section class="BuilderShareDownload">
    <div class="BuilderShareDownload__container">
      <div class="BuilderShareDownload__container--settings" :class="{ 'move-left': !isShowSettingLists }">
        <p class="BuilderShareDownload__container--settings-title">
          {{ $t("player.settings") }}
        </p>
        <div
          v-for="{ text, icon, value, type, lists } in downloadSettings"
          :key="type"
          :class="judgeSettingClassName(type)"
          @click="handleClickSetting({ lists, type, text, value })"
        >
          <component :is="icon" />
          <span>{{ text }}</span>
          <span class="value">{{ value }}</span>
          <v-icon color="white">navigate_next</v-icon>
        </div>
      </div>

      <div class="BuilderShareDownload__container--lists" :class="{ 'move-right': !isShowSettingLists }">
        <div class="BuilderShareDownload__container--lists-title">
          <button @click="selectedType = ''">
            <v-icon color="white">navigate_before</v-icon>
            <span>{{ listTile }}</span>
          </button>
        </div>
        <div class="lists">
          <div
            v-for="{ text, value } in selectedLists"
            :key="value"
            :class="{ isSelected: downloadVideoConfig[selectedType] === value }"
            @click="handleClickList(value)"
          >
            {{ text }}
            <v-icon color="#4689F3" v-if="downloadVideoConfig[selectedType] === value">done</v-icon>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { DOWNLOAD_VIDEO_TYPE } from "@/constants/downloadVideoStatus";
import DIconSubtitle from "@/components/icons/builder/DIconSubtitle.vue";
import DIconFontSize from "@/components/icons/builder/DIconFontSize.vue";
import DIconArrowBack from "@/components/icons/DIconArrowBack.vue";
import { sortLanguageCodeByFullName } from "@/js/workflow/language.js";
export default {
  name: "DShareDownload",
  components: { DIconSubtitle, DIconFontSize, DIconArrowBack },
  props: {
    languageList: {
      type: Array,
      default: () => [],
    },
    selectedLanguage: {
      type: String,
      default: () => "off",
    },
    downloadSubtitlesFontSize: {
      type: String,
      // TODO: PD-320 temporarily remove big subtitle,
      default: () => (this.$backendType === "ali" ? "medium" : "small"),
    },
  },
  data() {
    const defaultValue = {
      [DOWNLOAD_VIDEO_TYPE.SUBTITLE]: this.selectedLanguage,
      [DOWNLOAD_VIDEO_TYPE.FONT_SIZE]: this.downloadSubtitlesFontSize,
    };
    const downloadVideoConfig = Object.values(DOWNLOAD_VIDEO_TYPE).reduce(
      (a, c) => ({ ...a, [c]: defaultValue[c] }),
      {}
    );
    return {
      downloadVideoConfig,
      selectedType: "",
      selectedLists: [],
      listTile: "",
    };
  },
  methods: {
    handleClickSetting(config) {
      const { type, text, lists } = config;
      this.selectedLists = lists;
      this.selectedType = type;
      this.listTile = text;
    },
    handleClickList(value) {
      this.downloadVideoConfig[this.selectedType] = value;
    },
    judgeSettingClassName(type) {
      return [
        "item",
        type === DOWNLOAD_VIDEO_TYPE.FONT_SIZE && ["", "off"].includes(this.selectedLanguage) && "disabled",
      ];
    },
  },
  deactivated() {
    this.selectedType = "";
  },
  computed: {
    isShowSettingLists() {
      return this.selectedType === "";
    },
    downloadSettings() {
      const fontSizeList = [
        { text: this.$t("builder.small"), value: "small" },
        { text: this.$t("builder.medium"), value: "medium" },
        /** TODO: PD-320 temporarily remove big subtitle,
         * when splitter long sentence is done, add it again */
        // { text: this.$t("builder.big"), value: "big" },
      ];
      const fontSizeValue = fontSizeList.find(({ value }) => this.downloadSubtitlesFontSize === value);
      return [
        {
          type: DOWNLOAD_VIDEO_TYPE.SUBTITLE,
          text: this.$t("player.subtitles"),
          icon: "DIconSubtitle",
          value: this.languageFullName[this.selectedLanguage] || this.$t("player.off"),
          lists: this.supportedLanguages,
        },
        {
          type: DOWNLOAD_VIDEO_TYPE.FONT_SIZE,
          text: this.$t("builder.subtitlesSize"),
          icon: "DIconFontSize",
          value: fontSizeValue.text,
          lists: fontSizeList,
        },
      ];
    },
    languageFullName() {
      let locale = this.$i18n.locale;
      if (locale === "en") locale = "en-us";
      return this.$dictionary[locale].languageCode;
    },
    supportedLanguages() {
      const sortedLanguageCodeList = sortLanguageCodeByFullName(this.languageList, this.languageFullName);
      return [
        {
          text: this.$t("player.off"),
          value: "",
        },
      ].concat(
        sortedLanguageCodeList.map((langCode) => ({
          text: this.languageFullName[langCode],
          value: langCode,
        }))
      );
    },
  },
  watch: {
    downloadVideoConfig: {
      handler(downloadVideoConfig) {
        this.$emit("fetchDownloadVideoConfig", downloadVideoConfig);
      },
      deep: true,
    },
    selectedLanguage: {
      immediate: true,
      handler(selectedLanguage) {
        this.downloadVideoConfig[DOWNLOAD_VIDEO_TYPE.SUBTITLE] = selectedLanguage;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.BuilderShareDownload {
  --animation-duration: 0.2s;
  background-color: #121314;
  color: #fff;
  padding: 1rem 2.3rem;
  margin-top: 34px;
  border-radius: 4px;
  height: 100%;

  &__title {
    font-size: 14px;
    line-height: 24px;
    text-align: start;
    color: #9397a6;
    text-transform: uppercase;
  }

  &__container {
    position: relative;

    &--settings {
      transform-origin: left;
      transition: var(--animation-duration);
      display: flex;
      flex-direction: column;
      gap: 1rem;

      &-title {
        font-size: 14px;
        line-height: 24px;
        text-align: start;
        color: #9397a6;
        text-transform: uppercase;
      }

      .item {
        display: flex;
        align-items: center;
        gap: 2rem;
        cursor: pointer;

        span {
          font-size: 1.1rem;
        }
        .value {
          margin-left: auto;
          color: #9397a6;
        }
      }

      .disabled {
        pointer-events: none;
        opacity: 0.3;
      }

      &.move-left {
        transform: scale(0);
      }
    }

    &--lists {
      position: absolute;
      left: 0;
      top: 0;

      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      transform: scale(0);
      transform-origin: right;
      transition: var(--animation-duration);
      &.move-right {
        transform: scale(1);
      }
      &-title {
        button {
          display: flex;
          align-items: center;
          user-select: none;
        }
      }
      .lists {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow: auto;
        height: 380px;
        width: 100%;
        gap: 1rem;
        padding: 1rem 0;

        @media (max-height: 950px) {
          height: 320px;
        }

        div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          cursor: pointer;
          transition: var(--animation-duration);
          width: 100%;
          text-align: start;
          padding: 0.5rem 0 0.5rem 26px;

          &:hover,
          &.isSelected {
            background-color: #28292e;
          }
        }
      }
    }
  }

  p {
    // override vuetify css
    margin: 0;
  }
}
</style>
