import { getChatList, clearChat } from "@/server/chat-server";
import { CHAT_TYPE } from "@/constants/maven";
export const namespaced = true;
export const state = {
  chatType: CHAT_TYPE.REGULAR,
  chatList: [],
  selectedChatId: -1,
  disableChageSelectedChatId: false,
};

export const getters = {
  is_regular_chat: (state) => state.chatType === CHAT_TYPE.REGULAR,
  is_script_chat: (state) => state.chatType === CHAT_TYPE.SCRIPT,
  is_sop_chat: (state) => state.chatType === CHAT_TYPE.SOP,
};

export const actions = {
  async fetchChatList({ commit }) {
    const res = await getChatList();
    if (!res.ok) return;
    const { items } = res.data;
    commit("SET_CHAT_LIST", items);
  },
  resetMaven({ commit }) {
    commit("SET_CHAT_ID", -1);
    commit("SET_CHAT_LIST", []);
    commit("SET_CHAT_TYPE", CHAT_TYPE.REGULAR);
  },
  async clearConversations({ commit, dispatch }) {
    commit("SET_DISABLE_CHAGE_SELECTED_CHAT_ID", true);
    const res = await clearChat();
    if (!res.ok) return;
    await dispatch("fetchChatList");
    commit("SET_DISABLE_CHAGE_SELECTED_CHAT_ID", false);
  },
  setDisableChageSelectedChatId({ commit }, disableChageSelectedChatId) {
    commit("SET_DISABLE_CHAGE_SELECTED_CHAT_ID", disableChageSelectedChatId);
  },
};

export const mutations = {
  SET_CHAT_TYPE(state, chatType) {
    state.chatType = chatType;
  },
  SET_CHAT_LIST(state, chatList) {
    state.chatList = chatList;
  },
  SET_CHAT_ID(state, chatId) {
    state.selectedChatId = chatId;
  },
  SET_DISABLE_CHAGE_SELECTED_CHAT_ID(state, disableChageSelectedChatId) {
    state.disableChageSelectedChatId = disableChageSelectedChatId;
  },
};
