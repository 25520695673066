import DButton from "@/components/DVuetify/button/DButton";
import DLayout from "@/components/DVuetify/DLayout";
import DFlex from "@/components/DVuetify/DFlex";

const DeephowUI = {
  install(Vue) {
    Vue.component("d-button", DButton);
    Vue.component("d-layout", DLayout);
    Vue.component("d-flex", DFlex);
  },
};

export default DeephowUI;
