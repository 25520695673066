<template>
  <v-dialog
    max-width="640"
    persistent
    :value="isOpenConfirmedPublishPopup"
    content-class="builderPublishConfirmedPopup"
    @input="handleClose"
  >
    <v-card class="card">
      <div class="close-btn" autotest="popup-close-btn" @click="handleClose"></div>
      <v-card-text class="card__text">
        <d-icon-published class="icon" />
        <h3 class="title">{{ $t("builder.publishWorkflow") }}</h3>
        <div class="subtitle" v-html="$t('builder.confirmedPublished')" />
        <div class="description" v-if="!isInitializedWorkflow">
          <div class="description__title">{{ $t("builder.versionDescription", { num: versionNumber }) }}</div>
          <textarea
            class="description__input"
            @input="$emit('update:versionDescription', $event.target.value)"
            :value="versionDescription"
          ></textarea>
        </div>
      </v-card-text>

      <v-card-actions class="card__actions">
        <d-button
          analytics="confirm-close-buildermain"
          autotest="popup-cancel-btn"
          outline
          round
          color="#4689f4"
          class="text-capitalize card__actions--btn"
          light
          @click="handleClose"
          >{{ $t("all.cancel") }}
        </d-button>
        <d-button
          analytics="confirm-publish"
          autotest="popup-yes-btn"
          round
          color="#4689f4"
          dark
          class="text-capitalize card__actions--btn"
          @click="$emit('confirmPublish', Number(versionNumber) || 1)"
          >{{ $t("builder.publish") }}
        </d-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchVersionHistoryByWorkflowId } from "@/server/version-history";
import { isEmpty } from "lodash-es";

export default {
  name: "DBuilderPublishConfirmedPopup",
  props: {
    isOpenConfirmedPublishPopup: Boolean,
    versionDescription: String,
    workflowId: String,
  },
  components: {
    DIconPublished: () => import("@/components/icons/builder/DIconPublished.vue"),
  },
  methods: {
    handleClose() {
      this.$emit("update:isOpenConfirmedPublishPopup", false);
      this.$emit("update:versionDescription", "");
    },
    async refreshVersionHistoryData(workflowId) {
      const { ok, data, errorMessage } = await fetchVersionHistoryByWorkflowId(workflowId);
      if (!ok) {
        console.error(errorMessage);
        return;
      }
      this.versionHistoryData = data.items.sort((a, b) => b.version - a.version);
    },
  },
  data() {
    return {
      versionHistoryData: [],
    };
  },
  computed: {
    latestVersionNumber() {
      if (this.versionHistoryData.length === 0) {
        return {};
      }
      return this.versionHistoryData[0];
    },
    isInitializedWorkflow() {
      return this.latestVersionNumber.actionType === "initialize" || this.versionHistoryData.length === 0;
    },
    versionNumber() {
      if (isEmpty(this.latestVersionNumber)) {
        return "";
      }
      return (Number(this.latestVersionNumber.version) + 1).toFixed(1);
    },
  },
  watch: {
    isOpenConfirmedPublishPopup: {
      immediate: true,
      handler(isOpenConfirmedPublishPopup) {
        if (!isOpenConfirmedPublishPopup) {
          return;
        }
        this.refreshVersionHistoryData(this.workflowId);
      },
    },
    workflowId: {
      immediate: true,
      handler(workflowId) {
        if (!workflowId) {
          return;
        }
        this.refreshVersionHistoryData(workflowId);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.builderPublishConfirmedPopup {
  .card {
    position: relative;
    background-color: #1e1f22;

    .close-btn {
      --roate-angle: 45deg;
      width: 16px;
      height: 16px;
      position: absolute;
      cursor: pointer;
      right: 24px;
      top: 24px;
      z-index: 10;
      &:before,
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #9397a6;
        top: 50%;
        left: 50%;
        transform-origin: center;
        transform: translate(-50%, -50%) rotate(var(--roate-angle));
      }

      &:after {
        --roate-angle: -45deg;
      }
    }

    &__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 48px 32px 0 32px;
      .icon {
        margin-bottom: 24px;
      }
      .title {
        color: #fff;
        font-size: 20px;
        margin-bottom: 4px;
      }

      .subtitle {
        color: #8d909f;
        font-size: 16px;
        margin-bottom: 48px;
      }

      .description {
        width: 100%;
        color: #fff;
        &__title {
          text-align: left;
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 8px;
        }

        &__input {
          background-color: #121314;
          width: 100%;
          height: 100px;
          outline: none;
          padding: 0.5rem;
          resize: none;
          border-radius: 4px;
        }
      }
    }

    &__actions {
      padding: 32px;
      display: flex;
      align-items: center;
      gap: 32px;

      &--btn {
        flex: 1 0 auto;
      }
    }
  }
}
</style>
