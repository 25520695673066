<template>
  <div class="workflow-categories" @mouseup="closeMenu()">
    <div class="scroll-all-div">
      <div class="pa-0 text-xs-left categories-card content-center">
        <v-card-title class="title font-weight-regular">
          <span class="workflow-categories-title">{{ $t("builder.workflowCategories") }}</span>
          <v-spacer />
          <v-icon small color="grey" @click="$emit('close-popup')">close</v-icon>
        </v-card-title>
        <v-divider dark />
        <div class="categories-div">
          <p class="lable-text">{{ $t("builder.popupMsgworkflowCategories") }}</p>
          <div v-if="!isLoadedCategories" class="cat-group my-5">
            <div class="loading-msg">
              <p>{{ $t("builder.loadingCategories") }}</p>
              <d-icon-processing-bar-flex height="6px" width="240px" class="loading-bar" />
            </div>
          </div>
          <div v-else class="cat-group">
            <builder-categories-form
              ref="builderCategoriesForm"
              :categories="categories"
              :categorySelectedMap="categorySelectedMap"
              :selectedItems="selectedItems"
              @toggle-category-item="toggleCategoryItem($event)"
            ></builder-categories-form>
          </div>
        </div>
        <div class="bottom-div">
          <d-button
            dark
            round
            depressed
            block
            class="body-2 white--text btn-width"
            color="#4689f3"
            @click="saveAndPublish()"
            :disabled="!madeChanges"
            >{{ $t("builder.popupBtnAssignCategoriesAndPublish") }}</d-button
          >
          <button class="body-2 btn-width skip-and-publish" @click="closeAndPublish()">
            {{ $t("builder.popupBtnSkipAndPublish") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import MixinDB from "@/components/MixinDB.vue";
import BuilderCategoriesForm from "@/components/DBuilder/BuilderCategoriesForm.vue";
import { mapActions, mapState } from "vuex";
import IconBase from "../IconBase.vue";
import DIconDone from "../icons/DIconDone.vue";
import DIconAlert from "../icons/DIconAlert.vue";
import DIconInfo from "../icons/DIconInfo.vue";
import DIconCircleAdd from "../icons/DIconCircleAdd.vue";
import DIconCircleChecked2 from "../icons/DIconCircleChecked2.vue";
import DIconProcessingBarFlex from "../icons/animated/DIconProcessingBarFlex.vue";

export default {
  name: "BuilderCategoriesPopup",
  props: {
    workflow: Object,
  },
  mixins: [MixinDB],
  components: {
    IconBase,
    DIconDone,
    DIconAlert,
    DIconInfo,
    DIconCircleAdd,
    DIconCircleChecked2,
    BuilderCategoriesForm,
    DIconProcessingBarFlex,
  },
  watch: {
    selectedItems: function () {
      if (this.selectedItems.length > 0) {
        this.madeChanges = true;
      } else {
        this.madeChanges = false;
      }
    },
  },
  data: function () {
    return {
      categorySelectedMap: {},
      selectedItems: [],
      madeChanges: false,
      isLoading: true,
    };
  },
  created() {
    this.fetchAllCategories();
  },
  methods: {
    ...mapActions("category", ["fetchAllCategories"]),
    toggleCategoryItem({ item: targetItem }) {
      if (!this.categorySelectedMap[targetItem.category]) {
        Vue.set(this.categorySelectedMap, targetItem.category, []);
      }
      const idx = this.categorySelectedMap[targetItem.category].findIndex(({ id }) => id === targetItem.id);
      if (idx === -1) {
        this.categorySelectedMap[targetItem.category].push(targetItem);
        this.selectedItems.push(targetItem.id);
      } else {
        this.categorySelectedMap[targetItem.category].splice(idx, 1);
        this.selectedItems.splice(this.selectedItems.indexOf(targetItem.id), 1);
      }
    },
    closeMenu() {
      this.$refs.builderCategoriesForm.closeMenu();
    },
    closeAndPublish() {
      this.$emit("show-publish-popup");
    },
    async saveAndPublish() {
      try {
        await this.saveChanges();
        this.$emit("show-publish-popup");
      } catch (error) {
        console.log("saveAndPublish error", error);
      }
    },
    async saveChanges() {
      try {
        const selectedSubCategories = this.selectedItems;
        const selectedSubCategoriesMap = this.setMap(selectedSubCategories);
        const selectedCategories = Object.keys(this.categorySelectedMap);
        const selectedCategoriesMap = this.setMap(selectedCategories);
        await this.updateDocument("workflows", this.workflow.id, {
          categories: selectedCategories,
          catMap: selectedCategoriesMap,
          subCategories: selectedSubCategories,
          subCatMap: selectedSubCategoriesMap,
        });
        this.madeChanges = false;
      } catch (error) {
        console.log("saveChanges error", error);
      }
    },
    setMap(itemsArray) {
      let itemMap = {};
      itemsArray.forEach((item) => {
        itemMap[item] = true;
      });
      return itemMap;
    },
  },
  computed: {
    ...mapState("category", ["categories", "isLoadedCategories"]),
  },
};
</script>

<style scoped>
.workflow-categories {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: auto;
}
.workflow-categories:hover::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
}
.scroll-all-div {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 724px;
  min-width: 580px;
}

.content-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}
.categories-card {
  height: 712px;
  width: 570px;
  background-color: #1e1f22;
}
.categories-div {
  position: relative;
  height: 520px;
  padding: 0 26px 30px 32px;
  overflow: auto;
}
.loading-bar {
  position: relative;
  margin: 0px auto 24px auto;
}
.loading-msg {
  color: #8d909f;
  font-family: Roboto;
  font-size: 16px;
  text-align: center;
}
.cat-group {
  position: relative;
  padding-right: 4px;
}
.cat-group::-webkit-scrollbar {
  width: 4px;
}
.cat-group:hover::-webkit-scrollbar-thumb {
  background-color: #2c2d32;
}
.bottom-div {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 132px;
  padding: 0 32px;
  text-align: center;
}
.btn-width {
  width: 100%;
  text-transform: none !important;
}
/* ///// */
/* .click-to-close {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-color: violet;
} */
.lable-text {
  color: #ffffff;
  font-family: Roboto;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 32px;
  padding-top: 30px;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #1e1f22;
  z-index: 1;
}
.workflow-categories-title {
  color: #8d909f;
  font-family: Roboto;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 32px;
}
/* ////// */
div ::v-deep .v-card__title {
  padding: 9px 16px;
}

div ::v-deep .v-card__text {
  padding: 32px;
}

div ::v-deep .v-list {
  background: #555555 !important;
  color: white !important;
}
div ::v-deep .v-list__tile:hover {
  background-color: #424242 !important;
}

div ::v-deep .v-select__slot {
  height: 27px;
  margin-top: 4px;
}

div ::v-deep .v-select__slot input {
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  height: 27px;
}

div ::v-deep .v-select.v-select--chips .v-select__selections {
  padding-bottom: 10px;
}

div ::v-deep .v-select__slot .v-label {
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  /* height: 27px; */
  top: 0px;
}

div ::v-deep .v-select__slot .v-label--active {
  font-size: 11px;
  line-height: 13px;
  top: -4px !important;
}

div ::v-deep .v-list {
  background: #555555 !important;
  color: white !important;
}

div ::v-deep .v-list__tile:hover {
  background-color: #424242 !important;
}

div ::v-deep .v-select .v-chip {
  height: 28px;
  border-radius: 14px;
  background-color: #52545d;
}

div ::v-deep .v-chip__content {
  height: 16px;
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
}

.skip-and-publish {
  position: relative;
  text-align: center;
  cursor: pointer;
  color: #8d909f;
  font-family: Roboto;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 26px;
}
</style>
