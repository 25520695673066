<template>
  <div class="d-video-secure-multistep" :style="{ 'max-width': videoMaxWidth }">
    <div class="video-container">
      <div class="container-inner">
        <!-- player -->
        <video
          ref="videoPlayer"
          class="multi-step-video-player"
          :src="videoSource"
          :crossorigin="crossorigin"
          :preload="preload"
          @ended="autoPlayNextVideo()"
          @timeupdate="timeUpdateEvent()"
          @waiting="showingSpinner = true"
          @canplay="showingSpinner = false"
        ></video>
      </div>
      <!-- loading spinner -->
      <div class="over-video-div spinner-container">
        <div class="vertical-center">
          <v-progress-circular v-if="showingSpinner" :size="48" color="white" indeterminate></v-progress-circular>
        </div>
      </div>

      <!-- for adding select screenshot button on BuilderPosterPopup -->
      <div style="index: 1">
        <slot name="over-video"></slot>
      </div>

      <!-- simple controls -->
      <div
        v-if="controls == 'simple'"
        class="simple-controls-div"
        :class="{ 'show-controls': !isPlaying }"
        @click="playOrPauseVideo()"
      >
        <div class="vertical-center">
          <button class="simple-play-btn">
            <icon-base color="white" height="22%" width="22%">
              <d-icon-pause v-if="isPlaying" />
              <d-icon-play v-else />
            </icon-base>
          </button>
        </div>
      </div>
    </div>

    <!-- default controls -->
    <div v-if="controls == 'default'" class="ControlDiv">
      <button class="ControlDiv__play-btn" @click="playOrPauseVideo()">
        <icon-base color="white" height="28" width="28">
          <d-icon-pause v-if="isPlaying" />
          <d-icon-play v-else />
        </icon-base>
      </button>
      <div class="ControlDiv__slider">
        <v-slider
          v-model="videoCurrentTime"
          color="#4689f4"
          track-color="#303030"
          thumb-color="#8A8E93"
          height="4"
          always-dirty
          :max="getVideoDuration()"
          @mousedown="mousedownTimePosition()"
          @change="setVideoTimePosition"
        ></v-slider>
      </div>
      <div class="ControlDiv__time">
        {{ toCentiSecondTimeFormat(videoCurrentTime) }}
        <span class="grey--text">/</span>
        {{ toCentiSecondTimeFormat(workflow.videoDuration) }}
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconPlay from "@/components/icons/DIconPlay.vue";
import DIconPause from "@/components/icons/DIconPause.vue";
import MixinVideo from "@/components/MixinVideo.vue";
import { toCentiSecondTimeFormat } from "@/js/video-player/video-player-time.js";

export default {
  name: "DVideoSecureMultistep",
  props: {
    crossorigin: {
      type: String,
      default: "",
    },
    preload: {
      type: String,
      default: "auto",
    },
    steps: {
      type: Array,
      default: () => [],
    },
    workflow: {
      type: Object,
      default: () => {},
    },
    isBuilderTranscription: {
      type: Boolean,
      default: false,
    },
    controls: {
      type: String,
      default: "default",
    },
    videoMaxHeight: {
      type: Number,
      default: -1,
    },
  },
  components: {
    IconBase,
    DIconPlay,
    DIconPause,
  },
  mixins: [MixinVideo],
  data() {
    return {
      gotEndTime: false,
      currentStep: 0,
      gotSignedSources: false,
      signedSteps: [],
      showingSpinner: false,
      allPlayedStepDuration: [],
      isPlaying: false,
      wasPlaying: false,
      videoCurrentTime: 0,
      isChangingTime: false,
      videoMaxWidth: "100%",
    };
  },
  created() {
    this.getVideoMaxWidth();
    this.updateVideoSources();
  },
  mounted() {},
  watch: {
    steps: function () {
      // update player time when chenged the steps in Builder
      this.gotEndTime = false;
      this.getAllStepEndTime();
    },
  },
  computed: {
    videoSource() {
      if (!this.signedSteps || !this.gotSignedSources) {
        return "";
      }
      if (this.signedSteps[this.currentStep].videos) {
        const rez = Object.keys(this.steps[this.currentStep].videos)[0];
        return this.signedSteps[this.currentStep].videos[rez];
      } else {
        return this.signedSteps[this.currentStep].video;
      }
    },
  },
  beforeDestroy: function () {
    this.removeVideo();
  },
  methods: {
    getVideoMaxWidth() {
      // Prevent the video from being higher than the parent div (e.g Share workflow popup)
      if (this.videoMaxHeight > 0) {
        const maxWidth = parseInt((this.videoMaxHeight / 9) * 16);
        this.videoMaxWidth = maxWidth + "px!important";
      } else {
        this.videoMaxWidth = "100%";
      }
    },

    /////// only for BuilderTranscription ///////
    // update current sentence in BuilderTranscription
    getCurrentSentence(newCurrentTime) {
      if (this.isBuilderTranscription) {
        this.$emit("get-current-sentence", {
          currentStep: this.currentStep,
          newCurrentTime: newCurrentTime,
        });
      }
    },
    // set Player time by click the Sentences in BuilderTranscription
    setVideoTimeFromParent(newTime) {
      this.mousedownTimePosition();
      this.videoCurrentTime = newTime;
      this.setVideoTimePosition();
    },

    /////// DVideoSecureMultistep ///////
    mousedownTimePosition() {
      this.wasPlaying = this.isPlaying;
      this.pauseVideo();
      this.isChangingTime = true;
    },
    setVideoTimePosition() {
      this.getAllStepEndTime();
      if (this.$refs.videoPlayer) {
        let changedStep = false;
        for (let idx = 0; idx < this.steps.length; idx++) {
          if (
            this.videoCurrentTime < this.allStepEndTime[idx] ||
            (idx === this.steps.length - 1 && this.videoCurrentTime > this.allStepEndTime[idx])
          ) {
            if (this.currentStep != idx) {
              changedStep = true;
              this.currentStep = idx;
            }
            const currentTime = this.videoCurrentTime - this.allPlayedStepDuration[this.currentStep];
            if (changedStep) {
              this.$refs.videoPlayer.addEventListener(
                "loadedmetadata",
                function () {
                  this.currentTime = currentTime;
                },
                false
              );
              this.$refs.videoPlayer.addEventListener("playing", (event) => {
                this.isChangingTime = false;
              });
            } else {
              this.$refs.videoPlayer.currentTime = currentTime;
              this.isChangingTime = false;
            }
            if (this.wasPlaying) {
              this.$nextTick(() => this.playVideo());
            }
            this.getCurrentSentence(currentTime); // for BuilderTranscription (update current sentence)
            break;
          }
        }
      }
    },
    getVideoDuration() {
      return this.workflow.videoDuration;
    },
    autoPlayNextVideo() {
      if (this.isPlaying && this.currentStep < this.steps.length - 1) {
        this.currentStep++;
        const currentTime = 0;
        this.$refs.videoPlayer.addEventListener(
          "loadedmetadata",
          function () {
            this.currentTime = currentTime;
          },
          false
        );
        this.$nextTick(() => this.playVideo());
        this.getCurrentSentence(currentTime); // for BuilderTranscription (update current sentence)
      } else {
        this.pauseVideo();
        this.currentStep = 0;
        this.$refs.videoPlayer.addEventListener(
          "loadedmetadata",
          function () {
            this.currentTime = 0;
          },
          false
        );
      }
    },
    async updateVideoSources() {
      const result = await this.handleCloudFrontStepUrlSignByRez(this.steps);
      this.signedSteps = result.steps;
      if (result.ok) {
        this.gotSignedSources = true;
      }
    },
    playOrPauseVideo() {
      if (this.isPlaying) {
        this.pauseVideo();
      } else {
        this.playVideo();
      }
    },
    pauseVideo() {
      if (this.$refs.videoPlayer) {
        this.isPlaying = false;
        this.$refs.videoPlayer.pause();
      }
    },
    playVideo() {
      if (this.$refs.videoPlayer) {
        this.isPlaying = true;
        var playPromise = this.$refs.videoPlayer.play();
        if (playPromise !== undefined) {
          playPromise.catch((_) => {});
        }
      }
    },
    removeVideo() {
      this.pauseVideo();
      this.$refs.videoPlayer.removeAttribute("src"); // empty source
      this.$refs.videoPlayer.load();
    },
    timeUpdateEvent() {
      this.getAllStepEndTime();
      if (!this.isChangingTime && this.$refs.videoPlayer) {
        this.videoCurrentTime = this.$refs.videoPlayer.currentTime + this.allPlayedStepDuration[this.currentStep];
        // for BuilderTranscription (update current sentence)
        const currentTime = this.$refs.videoPlayer.currentTime;
        this.getCurrentSentence(currentTime);
      }
    },
    getAllStepEndTime() {
      if (!this.gotEndTime) {
        this.allPlayedStepDuration = [];
        this.allStepEndTime = [];
        let playedStepDuration = 0;
        let videoEndTime = 0;
        for (let index = 0; index < this.steps.length; index++) {
          videoEndTime = videoEndTime + this.steps[index].duration;
          this.allStepEndTime.push(videoEndTime);
          if (index == 0) {
            this.allPlayedStepDuration.push(playedStepDuration);
          } else {
            playedStepDuration = playedStepDuration + this.steps[index - 1].duration;
            this.allPlayedStepDuration.push(playedStepDuration);
          }
        }
        this.gotEndTime = true;
      }
    },
    toCentiSecondTimeFormat,
  },
};
</script>

<style scoped lang="scss">
.d-video-secure-multistep {
  position: relative;
  margin: 0 auto;
}
.video-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 */
  height: 0px;
  border-radius: 4px;
  overflow: hidden;
}
.multi-step-video-player {
  width: 100%;
  height: auto;
  max-height: 100%;
}
.container-inner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000000;
  min-height: 300px;
  max-height: 50vh;
  margin: 0 auto;
}
.over-video-div {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.spinner-container {
  pointer-events: none;
}
.vertical-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* default controls */
.ControlDiv {
  position: relative;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  &__slider {
    width: 100%;
  }
  &__time {
    font-size: 12px;
    color: white;
    text-wrap: nowrap;
  }
}

/* simple-controls */
.simple-controls-div {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}
.simple-controls-div:hover {
  opacity: 1;
}
.show-controls {
  opacity: 1;
}
.simple-play-btn {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
