<template>
  <div ref="contentAll" class="PlayerProcessedVideoMobile">
    <div
      class="PlayerProcessedVideoMobile--fullscreen"
      :class="{ 'PlayerProcessedVideoMobile--dividedScreen': getter_mobile_divided_screen }"
    >
      <!-- video -->
      <div
        class="PlayerProcessedVideoMobile__video"
        :class="{
          'PlayerProcessedVideoMobile__subtitles--iphone': isIphone,
          'PlayerProcessedVideoMobile__subtitles--lower': !showIcons && playing && !showSettingPopup,
          'PlayerProcessedVideoMobile__subtitles--windowMode': !isMobileFullscreen,
        }"
        @mousedown="touchPlayer"
        v-d-resize-observer="handleVideoResizeEvent"
      >
        <video
          v-for="(step, idx) in steps"
          :key="idx"
          :poster="step.poster"
          v-show="idx == currentStep"
          ref="videoPlayer"
          crossorigin="anonymous"
          preload="auto"
          :autoplay="isIosDevice"
          @timeupdate="timeUpdateEvent(idx)"
          @ended="handleVideoEnded()"
          @loadeddata="autoPlayVideoWhenEnter(idx, $event)"
          webkit-playsinline
          playsinline
          @waiting="isLoadingVideo = true"
          @canplay="(isLoadingVideo = false), (firstLoadingVideo = false)"
          @playing="isLoadingVideo = false"
        >
          <source :src="videoSources[idx]" />
          <track
            v-for="(subtitle, idx) in step.subtitles"
            kind="subtitles"
            :key="idx"
            :srclang="subtitle.language"
            :src="subtitle.source"
            :disabled="subtitle.disabled"
          />
        </video>
        <slot name="annotation"></slot>
      </div>

      <!-- setting popup  -->
      <d-player-setting
        v-show="showSettingPopup"
        ref="DPlayerSetting"
        class="PlayerProcessedVideoMobile__DPlayerSetting"
        :isMobile="true"
        :showSettingPopup="showSettingPopup"
        :enableText2Speech="false"
        :currentAudioChoice="audioChoice"
        :autoNextStep="autoNextStep"
        :isUserSetSubtitleToNull="subtitlesChoice === null"
        :steps="steps"
        :currentStep="currentStep"
        :subtitlesChoice="subtitlesChoice"
        :hasHls="hasHls"
        :videoResolution="videoResolution"
        :hlsRezOptions="[]"
        :hlsRefs="[]"
        :hlsCurrentLevel="-1"
        :speedOption="speedOption"
        :currentPlaySpeed="playSpeed"
        @close="showSettingPopup = false"
        @change-auto-play="changeAutoPlay($event)"
        @change-subtitles="subtitlesChoice = $event.subtitlesChoice"
        @set-quality-video-resolution="videoResolution = $event.videoResolution"
        @change-speed="
          playSpeed = $event.playSpeed;
          setPlaybackRate();
        "
      />
      <div v-show="showSettingPopup" class="PlayerProcessedVideoMobile__DPlayerSetting--mobileBackground"></div>

      <!-- controls (fullscreen mode) -->
      <controls-mobile-fullscreen
        v-show="isMobileFullscreen && !getter_mobile_divided_screen"
        :workflowId="workflow.id"
        :hideControls="hideControls"
        :isPublic="isPublic"
        :isSearchPlayer="isSearchPlayer"
        :workflowDisplayTitle="getWorkflowDisplayTitle(subtitlesChoice)"
        :stepNum="getStepNum(currentStep)"
        :stepTitle="getStepDisplayTitle(currentStep, subtitlesChoice)"
        :playing="playing"
        :formatedCurrentTime="getTimeFormat(currentVideoTime)"
        :formatedDuration="getTimeFormat(steps[currentStep].duration)"
        :isMuted="isMuted"
        @close-player="closeVideoPlayer()"
        @click-menu-btn="isPublic ? [clickToShowStepsMenu($event), beforeLeaveVideo()] : clickToShowStepsMenu($event)"
        @play-pause-video="onclickPlayOrPause"
        @play-back="playBack()"
        @play-forward="playForward()"
        @play-previous-step="playPreviousStep()"
        @play-next-step="playNextStep()"
        @click-mute="muteVideoButton()"
        @click-setting="showSettingPopup = !showSettingPopup"
        @click-serach="clickToShowSearch()"
        @show-or-hide-icons="showOrHideIcons"
      >
        <d-progress-bar
          v-model="currentVideoTime"
          :max="steps[currentStep].duration"
          :isPhone="true"
          :hideControls="hideControls"
          @setTimePosition="setTimePositionNew()"
          @temporaryPauseVideo="temporaryPauseVideo()"
          @keepPlayingVideo="keepPlayingVideo()"
        ></d-progress-bar>
      </controls-mobile-fullscreen>

      <!-- Reactions (only show on fullscreen mode) -->
      <div v-if="!isPublic" v-show="showReactionsBtn" class="PlayerProcessedVideoMobile__reactionsButton">
        <reactions-in-player :workflowId="workflow.id" isMobileDevice isMobileFullscreen />
      </div>

      <!-- controls (window mode and divided screen mode) -->
      <controls-mobile-small
        v-show="!isMobileFullscreen || getter_mobile_divided_screen"
        :hideControls="hideControls"
        :isPublic="isPublic"
        :isSearchPlayer="isSearchPlayer"
        :playing="playing"
        @close-player="closeVideoPlayer()"
        @click-menu-btn="clickToShowStepsMenu($event)"
        @play-pause-video="onclickPlayOrPause"
        @play-previous-step="playPreviousStep()"
        @play-next-step="playNextStep()"
        @click-setting="showSettingPopup = !showSettingPopup"
        @click-serach="clickToShowSearch()"
        @show-or-hide-icons="showOrHideIcons"
      >
        <d-progress-bar
          v-model="currentVideoTime"
          :max="steps[currentStep].duration"
          :isPhone="true"
          :hideControls="hideControls"
          @setTimePosition="setTimePositionNew()"
          @temporaryPauseVideo="temporaryPauseVideo()"
          @keepPlayingVideo="keepPlayingVideo()"
        ></d-progress-bar>
      </controls-mobile-small>

      <!-- Step name tag -->
      <d-step-name-tag
        :isShowingTaskName="isShowingTaskName"
        :stepNum="getStepNum(currentStep)"
        :currentStepName="getStepDisplayTitle(currentStep, subtitlesChoice)"
        :isMobileDevice="isMobileDevice"
        class="PlayerProcessedVideoMobile__stepNameTag"
        :class="{ 'PlayerProcessedVideoMobile__stepNameTag--upper': showIcons || !playing || subtitlesChoice !== null }"
      ></d-step-name-tag>

      <!-- Loading -->
      <player-loading v-if="loadingLanguage" z-index="0">
        {{ $t("player.loadingLanguage") }}
      </player-loading>
      <player-loading v-if="firstLoadingVideo || isLoadingVideo" z-index="0" />
    </div>

    <!-- step menu -->
    <player-processed-menu-phone
      :workflow="workflow"
      :steps="steps"
      :currentStep="currentStep"
      :menuHidden="menuHidden"
      :subtitlesChoice="subtitlesChoice"
      :attachmentsData="attachmentsData"
      :isMobileFullscreen="isMobileFullscreen"
      @setCurrentStepEvent="setCurrentStep($event)"
      @close-menu="clickToCloseStepMenu()"
    ></player-processed-menu-phone>

    <!-- search result -->
    <player-search
      ref="playerSearch"
      :workflow="workflow"
      :steps="steps"
      :showPlayerSearch="showPlayerSearch"
      :subtitlesChoice="subtitlesChoice"
      :isPublic="isPublic"
      :isMobileFullscreen="isMobileFullscreen"
      :isMobileDevice="isMobileDevice"
      @closePlayerSearch="clickToCloseSearch()"
      @jumpToSearchResult="jumpToSearchResult($event)"
    ></player-search>

    <!-- attachments -->
    <player-attachments-button
      v-show="showAttachmentsBtn"
      isMobileDevice
      :upper="!showIcons && playing"
      :currentStep="currentStep"
    />
    <player-attachments-mobile :currentStep="currentStep" />

    <player-voice-assistant
      v-if="!isPublic"
      ref="voiceAssistant"
      @playVideoEvent="playing = true"
      @pauseVideoEvent="playing = false"
      @playNextStepEvent="playNextStep"
      @repeatEvent="videoRepeat++"
      @openMenuEvent="menuHidden = false"
      @muteEvent="isMuted = true"
      @unmuteEvent="isMuted = false"
      @showMeHowToEvent="$emit('showMeHowToEvent', $event)"
      @showMeEvent="$emit('showMeEvent', $event)"
      @goBackEvent="playPreviousStep"
      @speakInEvent="speakIn($event)"
    ></player-voice-assistant>

    <player-processed-language
      ref="PlayerProcessedLanguage"
      :videoCurrentTime="currentVideoTime"
      :currentStep="currentStep"
      :steps="steps"
      :isPlaying="playing"
      :language="language"
      @muteVideoEvent="$refs.videoPlayer[currentStep].muted = true"
      @unmuteVideoEvent="$refs.videoPlayer[currentStep].muted = false"
      @finishLoading="finishLoading()"
    ></player-processed-language>
  </div>
</template>

<script>
import PlayerLoading from "@/components/DWorkflowPlayer/player/message/PlayerLoading.vue";
import PlayerProcessedMenuPhone from "@/components/PlayerProcessedMenuPhone.vue";
import PlayerVoiceAssistant from "@/components/PlayerVoiceAssistant.vue";
import PlayerSearch from "@/components/DWorkflowPlayer/PlayerSearch.vue";
import ControlsMobileFullscreen from "@/components/DWorkflowPlayer/controls/ControlsMobileFullscreen.vue";
import ControlsMobileSmall from "@/components/DWorkflowPlayer/controls/ControlsMobileSmall.vue";
import PlayerProcessedLanguage from "@/components/PlayerProcessedLanguage.vue";
import DStepNameTag from "@/components/ui_components/DStepNameTag.vue";
import DProgressBar from "@/components/ui_components/DProgressBar.vue";
import DPlayerSetting from "@/components/DWorkflowPlayer/setting/DPlayerSetting.vue";
import ReactionsInPlayer from "@/components/DWorkflowPlayer/controls/ReactionsInPlayer.vue";
import PlayerAttachmentsButton from "@/components/DWorkflowPlayer/attachments/PlayerAttachmentsButton.vue";
import PlayerAttachmentsMobile from "@/components/DWorkflowPlayer/attachments/PlayerAttachmentsMobile.vue";
import MixinDB from "@/components/MixinDB.vue";
import MixinUser from "@/components/MixinUser.vue";
import MixinTracker from "@/components/MixinTracker.vue";
import Vue from "vue";
import Hls from "hls.js";
import eventbus from "@/main";
import MixinVideo from "@/components/MixinVideo.vue";
import MixinIdle from "@/components/MixinIdle.vue";
import {
  watchOnStepChangedWithVideoTracker,
  saveInitialStepWithVideoTrackerOnce,
} from "@/js/video-tracker/videoTrackerUtility.js";
import { getDisplayTitle } from "@/js/video-player/title-display.js";
import { debounce } from "lodash-es";
import { mapActions, mapGetters, mapState } from "vuex";
import { REPEAT_TYPES } from "@/constants/workflow-repeat-status.js";
import DResizeObserver from "@/directives/d-resize-observer";
import { isIosDevice } from "@/js/mobile.js";

export default {
  name: "PlayerProcessedVideoMobile",
  directives: { DResizeObserver },
  watch: {
    audioChoice: function () {
      this.speakIn(this.audioChoice);
    },
    subtitlesChoice: function () {
      this.setSubtitles();
    },
    playing: function () {
      const event = this.playing ? "on_play" : "on_pause";
      this.trackPlayer({
        ...this.getTrackPlayerArgsWhenOnPalyOrPause({
          event,
        }),
      });

      this.playOrPauseVideo();
      if (this.playing) {
        this.actionToShowIcons();
      }
    },
    steps: function () {
      this.language = this.steps[this.currentStep].languageCode;
    },
    isMuted: function () {
      const foreign = this.$refs.PlayerProcessedLanguage.audioPlayer;
      if (this.language == this.steps[this.currentStep].languageCode || this.language == "default") {
        this.setMuteStatus();
      } else {
        if (this.isMuted) {
          foreign.muted = true;
        } else {
          foreign.muted = false;
        }
      }
    },
    currentVideoTime: function (currentVideoTime) {
      this.$emit("fetchVideoDataAndCurrentStepIndex", { currentVideoTime });
      if (this.playing && Math.floor(this.currentVideoTime) == 2 && !this.isShowingTaskName) {
        this.showTaskName();
      }
    },

    currentStep: function (currentStepIndex, prevStepIndex) {
      this.$emit("fetchVideoDataAndCurrentStepIndex", { currentStepIndex });
      watchOnStepChangedWithVideoTracker({
        mixinTrackerRef: this,
        prevStepIndex,
        currentStepIndex,
        steps: this.steps,
      });

      this.cancelShowTaskName();
      //move back to the beginning of the step when changing steps
      this.setSubtitles();
      this.playOrPauseVideo();
      this.setCurrentStepIdx({ index: currentStepIndex }); // update current step index in steps sidebar
      this.setVideoElement(this.currentVideoPlayer);
      if (this.getter_is_repeat_step) {
        this.changeRepeatType({ type: REPEAT_TYPES.WORKFLOW });
      }
    },
    videoResolution: function () {
      this.trackPlayer({
        workflow: this.workflow,
        event: "on_change_rez",
        step: this.steps[this.currentStep],
        organization: this.getter_organization,
        user: this.$user,
        settings: { rez: this.videoResolution },
      });

      this.updateVideoSources();
    },
    menuHidden(menuHidden) {
      this.togglePlayerAnnotation(!menuHidden);
    },
    showPlayerSearch(showPlayerSearch) {
      this.togglePlayerAnnotation(showPlayerSearch);
    },
    isWorkflowURLsUpdated: {
      handler(isWorkflowURLsUpdated) {
        if (isWorkflowURLsUpdated) {
          this.updateVideoSources();
        }
      },
    },
    getter_current_step_idx: {
      handler(getter_current_step_idx) {
        if (getter_current_step_idx >= 0 && getter_current_step_idx !== this.currentStep) {
          this.setCurrentStep(getter_current_step_idx);
        }
      },
    },
    getter_is_repeat_workflow: {
      handler(getter_is_repeat_workflow) {
        const ele = this.$refs.DPlayerSetting;
        if (ele && getter_is_repeat_workflow) ele.changeAutoPlay(getter_is_repeat_workflow);
      },
    },
    getter_is_repeat_step: {
      handler(getter_is_repeat_step) {
        const ele = this.$refs.DPlayerSetting;
        if (ele && getter_is_repeat_step) ele.changeAutoPlay(getter_is_repeat_step);
      },
    },
  },
  props: {
    workflow: {
      type: Object,
    },
    steps: {
      type: Array,
    },
    attachmentsData: {
      type: Array,
      default: () => [],
    },
    searchQuery: {
      type: String,
    },
    stepId: {
      type: String,
    },
    startTimestamp: {
      type: Number,
    },
    isPublic: {
      type: Boolean,
    },
    isSkillPlayer: {
      type: Boolean,
    },
    showSkillNext: {
      type: Boolean,
    },
    isSearchPlayer: {
      type: Boolean,
    },
    isNewPlayer: {
      type: Boolean,
    },
    isMobileDevice: {
      // this component temporary only use for mobile version
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.mounted = true;
    //setVideoResolution not working for Safari
    if (!this.isIphone) {
      this.setVideoResolution();
    }
    this.checkMobileFullscreen();
    this.updateVideoSources();
    this.language = this.steps[this.currentStep].languageCode;
    if (this.stepId) {
      this.setCurrentStepById(this.stepId);
    }

    this.$nextTick(() => {
      window.addEventListener("orientationchange", this.iPhoneLandscapePauseVideo);
    });

    eventbus.$on("stopPlaybackClosePlayer", () => {
      if (this.currentVideoPlayer) {
        this.closeVideoPlayer();
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("orientationchange", this.iPhoneLandscapePauseVideo);
    clearInterval(this.loadingInterval);
  },
  updated() {
    if (!this.audioChoice) {
      this.audioChoice = this.steps[this.currentStep].languageCode;
    }
    if (!this.videoSources[this.currentStep]) {
      //for iOS devices
      this.updateVideoSources();
    }
    if (!this.hlsIsSet) {
      // this.setHls();
      this.hlsIsSet = true;
    }
  },
  data() {
    return {
      isIosDevice: isIosDevice(),
      loadingInterval: null,
      autoNextStep: true,
      playing: false,
      isMuted: false,
      videoRepeat: 0,
      menuHidden: true,
      currentStep: 0,
      previousStep: 0,
      currentTime: [], //keeps track of the video's currentTime
      showIcons: true,
      language: "",
      subtitlesChoice: null,
      audioChoice: null,
      videoCurrentTime: "",
      isShowingTaskName: false,
      fadeOutTimeout: null,
      taskNameTimeout: null,
      hideIconsTimeout: null,
      videoResolution: "720", //default video resolution to 720
      progress: [],
      currentVideoTime: 0, //keeps track of the currentTime of the current video
      onTimeUpdateWasSet: false,
      videoSources: [],
      videoSwapped: false,
      loadingLanguage: false,
      showSettingPopup: false,
      wasPlaying: false,
      showNextTooltip: false,
      showPreviousTooltip: false,
      showPlayerSearch: false,
      hasHls: false,
      hlsIsSet: false,
      firstLoadingVideo: true,
      isLoadingVideo: false,
      isIphone: false,
      playSpeed: 1,
      speedOption: [0.5, 0.75, 1, 1.25, 1.5, 2],
      currentStepWasSet: false,
    };
  },
  mixins: [MixinDB, MixinTracker, MixinUser, MixinVideo, MixinIdle],
  components: {
    PlayerLoading,
    PlayerProcessedMenuPhone,
    PlayerVoiceAssistant,
    PlayerSearch,
    ControlsMobileFullscreen,
    ControlsMobileSmall,
    // PlayerAttachments,
    PlayerProcessedLanguage,
    DStepNameTag,
    DProgressBar,
    DPlayerSetting,
    ReactionsInPlayer,
    PlayerAttachmentsButton,
    PlayerAttachmentsMobile,
  },
  created() {
    //only check auth if not public
    if (!this.isPublic) {
      this.auth().onAuthStateChanged((user) => {
        if (!user) {
          this.$router.push("/");
          location.reload();
        } else {
          if (!this.$organization) {
            //if group is not yet available (when the user refresh the page)
            this.getUserData();
          }
        }
      });
    }

    if (navigator.userAgent.match(/iPhone/i)) {
      this.isIphone = true;
      this.videoResolution = "360";
      const self = this;
      clearInterval(this.loadingInterval);
      this.loadingInterval = setInterval(function () {
        if (self.$refs.videoPlayer[self.currentStep] && self.videoSources[self.currentStep]) {
          self.$refs.videoPlayer[self.currentStep].load();
          clearInterval(self.loadingInterval);
        }
      }, 500);
      if (this.isPublic) {
        this.firstLoadingVideo = false;
      }
    }

    this.$syncPlayerTracking.bindingGetVideoPlayerPauseArgs(() => {
      return this.getTrackPlayerArgsWhenOnPalyOrPause({
        event: "on_pause",
      });
    });
    this.$syncPlayerTracking.bindingGetVideoPlayerPlayArgs(() => {
      return this.getTrackPlayerArgsWhenOnPalyOrPause({
        event: "on_play",
      });
    });
  },
  destroyed() {},
  methods: {
    ...mapActions("workflowPlayer", [
      "enterMobileFullscreen",
      "exitMobileFullscreen",
      "setCurrentStepIdx",
      "setVideoElement",
      "changeRepeatType",
    ]),
    onclickPlayOrPause() {
      this.playing = !this.playing;
      this.playOrPauseVideo();
    },
    checkMobileFullscreen() {
      const div = this.$refs.contentAll;
      if (div.clientHeight === window.innerHeight && div.clientWidth === window.innerWidth) {
        this.enterMobileFullscreen();
      }
    },
    handleVideoResizeEvent: debounce(function ({ clientWidth, clientHeight }) {
      this.$emit("fetchVideoDataAndCurrentStepIndex", { clientWidth, clientHeight });
    }, 500),
    togglePlayerAnnotation(isShow) {
      if (this.wasPlaying && isShow) {
        setTimeout(() => {
          this.$emit("update:isPlayerAttachmentShowUp", isShow);
        }, 300);
      } else {
        this.$emit("update:isPlayerAttachmentShowUp", isShow);
      }
    },
    getTrackPlayerArgsWhenOnPalyOrPause({ event }) {
      return {
        workflow: this.workflow,
        event,
        step: this.steps[this.currentStep],
        organization: this.getter_organization,
        user: this.$user,
      };
    },
    closeVideoPlayer() {
      this.playing = false;
      this.playOrPauseVideo();
      if (this.$refs.videoPlayer[this.currentStep]) {
        this.$refs.videoPlayer[this.currentStep].pause();
        this.$refs.videoPlayer[this.currentStep].removeAttribute("src");
        this.$refs.videoPlayer[this.currentStep].load();
      }
      this.trackPlayer({
        workflow: this.workflow,
        event: "on_close_video_player",
        step: this.steps[this.currentStep],
        organization: this.getter_organization,
        user: this.$user,
      });
      this.$nextTick(() => {
        this.$emit("closePlayerEvent");
      });
    },
    setCurrentStepById(stepId) {
      if (!stepId) {
        this.setCurrentStepIdx({ index: 0 });
        return;
      }
      for (let i = 0; i < this.steps.length; i++) {
        if (this.steps[i].id == stepId) {
          this.currentStep = i;
          this.setCurrentStepIdx({ index: i });
          break;
        }
      }
    },
    clickToShowSearch() {
      this.showPlayerSearch = true;
      this.wasPlaying = this.playing;
      this.playing = false;
    },
    clickToCloseSearch() {
      this.showPlayerSearch = false;
      if (this.wasPlaying) {
        this.playing = true;
        this.playOrPauseVideo();
      }
    },
    jumpToSearchResult(result) {
      if (this.playing) {
        this.wasPlaying = true;
        this.playing = false;
        this.playOrPauseVideo();
      }
      this.currentStep = result.stepNumber;
      this.$refs.videoPlayer[this.currentStep].currentTime = result.startTime;
      setTimeout(() => {
        this.clickToCloseSearch();
      }, 100);
    },
    iPhoneLandscapePauseVideo() {
      const width = document.documentElement.clientWidth;
      const height = document.documentElement.clientHeight;
      if (height > width) {
        if (this.wasPlaying) {
          this.playing = true;
          this.playOrPauseVideo();
        }
      } else {
        this.wasPlaying = this.playing;
        this.playing = false;
        this.playOrPauseVideo();
      }
    },
    touchPlayer() {
      if (!this.showIcons && !this.showSettingPopup) {
        this.actionToShowIcons();
      }
    },
    clickToShowStepsMenu(event) {
      event.preventDefault();
      this.menuHidden = false;
      this.wasPlaying = this.playing;
      this.playing = false;
      this.playOrPauseVideo();
    },
    mousedownTimePosition(event) {
      event.preventDefault();
      this.wasPlaying = this.playing;
      this.playing = false;
      this.playOrPauseVideo();
    },
    setTimePositionNew() {
      this.trackPlayer({
        workflow: this.workflow,
        event: "on_progress_bar",
        step: this.steps[this.currentStep],
        organization: this.getter_organization,
        user: this.$user,
        currentTime: this.$refs.videoPlayer[this.currentStep].currentTime,
        nextVideoTime: this.currentVideoTime,
      });
      this.$refs.videoPlayer[this.currentStep].currentTime = this.currentVideoTime;
    },
    temporaryPauseVideo() {
      if (this.playing) {
        this.wasPlaying = true;
        this.playing = false;
        this.playOrPauseVideo();
      }
    },
    keepPlayingVideo() {
      if (this.wasPlaying) {
        this.playing = true;
        this.playOrPauseVideo();
        this.wasPlaying = false;
      }
    },
    setTimePosition(event) {
      this.$refs.videoPlayer[this.currentStep].currentTime = this.currentVideoTime;
      if (this.wasPlaying) {
        this.$nextTick(() => {
          this.playing = true;
          this.playOrPauseVideo();
        });
      }
    },
    touchmoveTime($event) {
      event.preventDefault();
    },
    finishLoading() {
      this.loadingLanguage = false;
      if (this.wasPlaying) {
        this.playing = true;
        this.playOrPauseVideo();
        this.$refs.PlayerProcessedLanguage.audioPlayer.play();
      }
    },
    autoPlayVideoWhenEnter(idx) {
      if (!this.currentStepWasSet) {
        this.setCurrentStepById(this.stepId);
        this.currentStepWasSet = true;
      }

      //turn on subtitle by default
      this.subtitlesChoice = this.workflow.languageCode;

      // auto play and jump to continue watching Timestamp when first loading video
      if (this.firstLoadingVideo && idx === this.currentStep) {
        const validTimestamp = this.startTimestamp && this.startTimestamp < this.steps[this.currentStep].duration;
        if (validTimestamp) {
          this.$refs.videoPlayer[this.currentStep].currentTime = this.startTimestamp;
        }
        this.firstLoadingVideo = false;
        const canPlay = !this.isIphone && !this.showPlayerSearch;
        if (canPlay) {
          this.playing = true;
          this.playOrPauseVideo();
        }
      }

      saveInitialStepWithVideoTrackerOnce({
        mixinTrackerRef: this,
        currentStepIndex: this.currentStep,
        steps: this.steps,
      });
    },
    setHls(videoURL) {
      if (this.workflow.id != "cPMETx6Fr2nMVzzXmOWZ") return;
      this.hasHls = true;
      alert("set hls");
      const video = this.$refs.videoPlayer[this.currentStep];
      const self = this;
      const config = {
        debug: true,
        xhrSetup: function (xhr, url) {
          xhr.withCredentials = true; // do send cookie
          // xhr.setRequestHeader("Access-Control-Allow-Headers","Content-Type, Accept, X-Requested-With");
          // xhr.setRequestHeader("Access-Control-Allow-Origin","http://dev.deephow.ai");
          // xhr.setRequestHeader("Access-Control-Allow-Credentials","true");
        },
      };
      if (Hls.isSupported(config)) {
        var hls = new Hls();
        hls.loadSource(videoURL);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          alert("hls playing");
          if (this.playing) {
            video.play().catch((err) => {
              this.playing = false;
            });
          } else {
            video.pause();
          }
        });
      }
      // hls.js is not supported on platforms that do not have Media Source Extensions (MSE) enabled.
      // When the browser has built-in HLS support (check using `canPlayType`), we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video element through the `src` property.
      // This is using the built-in support of the plain video element, without using hls.js.
      // Note: it would be more normal to wait on the 'canplay' event below however on Safari (where you are most likely to find built-in HLS support) the video.src URL must be on the user-driven
      // white-list before a 'canplay' event will be emitted; the last video event that can be reliably listened-for when the URL is not on the white-list is 'loadedmetadata'.
      else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = "https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8";
        video.addEventListener("loadedmetadata", function () {
          video.play();
        });
      }
    },

    hideIconsOnIdle() {
      clearInterval(this.hideIconsTimeout);
      this.hideIconsTimeout = setInterval(() => {
        this.showIcons = false;
      }, 3000);
    },
    actionToHideIcons() {
      clearInterval(this.hideIconsTimeout);
      this.showIcons = false;
    },
    actionToShowIcons() {
      this.showIcons = true;
      this.hideIconsOnIdle();
    },
    showOrHideIcons() {
      if (this.showIcons) {
        this.actionToHideIcons();
      } else {
        this.actionToShowIcons();
      }
    },
    getStepTitle(idx) {
      const stepIdx = idx;
      if (stepIdx < 0 || stepIdx == this.steps.length) {
        return "";
      } else {
        return this.steps[stepIdx].title;
      }
    },
    clickToCloseStepMenu() {
      this.menuHidden = true;
      if (this.wasPlaying) {
        this.playing = true;
        this.playOrPauseVideo();
      }
    },
    playBack() {
      this.trackPlayer({
        workflow: this.workflow,
        event: "on_backward",
        step: this.steps[this.currentStep],
        organization: this.getter_organization,
        user: this.$user,
        settings: {},
        currentTime: this.currentVideoTime,
      });
      this.wasPlaying = this.playing;
      this.playing = false;
      this.playOrPauseVideo();
      if (this.currentVideoTime + 5 >= 0) {
        this.$refs.videoPlayer[this.currentStep].currentTime = this.currentVideoTime - 5;
      } else {
        this.$refs.videoPlayer[this.currentStep].currentTime = 0;
      }
      this.playing = this.wasPlaying;
      this.playOrPauseVideo();
    },
    playForward() {
      this.trackPlayer({
        workflow: this.workflow,
        event: "on_forward",
        step: this.steps[this.currentStep],
        organization: this.getter_organization,
        user: this.$user,
        settings: {},
        currentTime: this.currentVideoTime,
      });

      this.wasPlaying = this.playing;
      this.playing = false;
      this.playOrPauseVideo();
      if (this.currentVideoTime + 5 < this.steps[this.currentStep].duration) {
        this.$refs.videoPlayer[this.currentStep].currentTime = this.currentVideoTime + 5;
        this.playing = this.wasPlaying;
        this.playOrPauseVideo();
      } else {
        this.$refs.videoPlayer[this.currentStep].currentTime = this.steps[this.currentStep].duration;
        this.playing = false;
        this.playOrPauseVideo();
      }
    },
    timeUpdateEvent(idx) {
      this.currentTime[idx] = this.$refs.videoPlayer[idx].currentTime;
      this.currentVideoTime = this.currentTime[idx];
      this.trackPlayer({
        workflow: this.workflow,
        event: "on_playing",
        step: this.steps[this.currentStep],
        organization: this.getter_organization,
        user: this.$user,
      });
      this.updateIdleTimeout();
    },
    autoPlayNextVideo() {
      // for skill player
      if (this.isSkillPlayer && this.currentStep == this.steps.length - 1) {
        this.playing = false;
        this.playOrPauseVideo();
        this.$emit("workflow-ended");

        this.videoEnded();
        return;
      }
      //go to next step when video ends if user choise auto play
      let isSendOnEndingEvent = false;
      if (this.autoNextStep) {
        if (this.currentStep != this.steps.length - 1) {
          isSendOnEndingEvent = true;
          //emit event for the previous video
          this.trackPlayer({
            workflow: this.workflow,
            event: "on_ending",
            step: this.steps[this.currentStep],
            organization: this.getter_organization,
            user: this.$user,
            nextStep: this.steps[this.currentStep + 1],
          });
          this.previousStep = this.currentStep;
          this.currentStep++;
          //emit event for the new video
          this.trackPlayer({
            workflow: this.workflow,
            event: "on_next",
            step: this.steps[this.currentStep],
            organization: this.getter_organization,
            user: this.$user,
            nextStep: this.steps[this.currentStep + 1],
          });
          this.playNewStep();
        } else {
          this.playing = false;
          this.playOrPauseVideo();
        }
      } else {
        this.playing = false;
        this.playOrPauseVideo();
      }

      const isNotYetSendOnEndingEvent = !isSendOnEndingEvent;
      if (isNotYetSendOnEndingEvent) {
        this.videoEnded();
      }
    },
    videoEnded() {
      this.trackPlayer({
        workflow: this.workflow,
        event: "on_ending",
        step: this.steps[this.currentStep],
        organization: this.getter_organization,
        user: this.$user,
      });
    },
    getTimeFormat(time) {
      time = parseFloat(time).toFixed(0);
      const minutes = Math.floor(time / 60);
      const seconds = time - minutes * 60;
      const hours = Math.floor(time / 3600);
      time = time - hours * 3600;
      function str_pad_left(string, pad, length) {
        return (new Array(length + 1).join(pad) + string).slice(-length);
      }
      const finalTime = str_pad_left(minutes, "0", 2) + ":" + str_pad_left(seconds, "0", 2);
      return finalTime;
    },
    getStepNum(index) {
      index++;
      if (index < 10) {
        return "0" + index.toString();
      } else {
        return index.toString();
      }
    },

    getUserData: function () {
      Vue.prototype.$user = this.auth().currentUser;
      this.getOrganization();
    },
    getOrganization() {
      const self = this;
      this.getDocument("users", this.$user.uid)
        .then((user) => {
          Vue.prototype.$organization = user.organization;
        })
        .catch((err) => {
          alert("failed to get organization.");
        });
    },
    getSubtitles(idx) {
      return this.steps[idx].subtitles;
    },
    setVideoResolution() {
      const speed = navigator.connection.downlink;
      if (speed >= 10) {
        this.videoResolution = "1080";
      } else if (speed >= 1) {
        this.videoResolution = "480";
      }
    },
    updateVideoSources() {
      const sourceSteps = this.steps;
      // TODO Dev cn nginx POC
      this.$store.dispatch("docGoogleUrlBridgeReplacement", sourceSteps);

      // get video source based on resolution and availability
      // Hls currently is not enabled for mobile player
      for (let i = 0; i < sourceSteps.length; i++) {
        if (sourceSteps[i].videos) {
          if (sourceSteps[i].videos[this.videoResolution]) {
            this.videoSources[i] = sourceSteps[i].videos[this.videoResolution];
          } else {
            //resolution not available
            const lowestAvailable = Object.keys(sourceSteps[i].videos).sort(function (a, b) {
              return a - b;
            })[0];
            if (parseInt(this.videoResolution) > parseInt(lowestAvailable)) {
              this.videoResolution = lowestAvailable;
            }
            this.videoSources[i] = sourceSteps[i].videos[lowestAvailable];
          }
        } else {
          this.videoSources[i] = sourceSteps[i].video;
        }
        this.$refs.videoPlayer[i].src = this.videoSources[i];
        if (this.currentTime[i] > 0) {
          this.$refs.videoPlayer[i].currentTime = this.currentTime[i];
        }
      }
      this.playOrPauseVideo();
      this.setVideoElement(this.currentVideoPlayer);
    },
    setMuteStatus() {
      for (let i = 0; i < this.steps.length; i++) {
        this.$refs.videoPlayer[i].muted = this.isMuted;
      }
    },
    showTaskName() {
      this.isShowingTaskName = true;
      //must clear previous timeout
      clearTimeout(this.taskNameTimeout);
      this.taskNameTimeout = setTimeout(() => {
        this.isShowingTaskName = false;
      }, 5000);
    },
    cancelShowTaskName() {
      this.isShowingTaskName = false;
      //must clear previous timeout
      clearTimeout(this.taskNameTimeout);
    },
    setSubtitles() {
      //set subtitle to off if the language it's not available
      let languageIdx = -1;
      for (let i = 0; i < this.steps[this.currentStep].subtitles.length; i++) {
        if (this.steps[this.currentStep].subtitles[i].language == this.subtitlesChoice) {
          languageIdx = i;
        }
      }
      if (languageIdx == -1) {
        this.subtitlesChoice = null;
      }
      if (this.subtitlesChoice != null) {
        this.trackPlayer({
          workflow: this.workflow,
          event: "on_subtitles",
          step: this.steps[this.currentStep],
          organization: this.getter_organization,
          user: this.$user,
          settings: { languageCode: this.subtitlesChoice },
        });

        for (let i = 0; i < this.steps[this.currentStep].subtitles.length; i++) {
          if (i != languageIdx) {
            this.$refs.videoPlayer[this.currentStep].textTracks[i].mode = "disabled";
          } else {
            this.$refs.videoPlayer[this.currentStep].textTracks[languageIdx].mode = "showing";
          }
        }
      } else {
        for (let i = 0; i < this.steps[this.currentStep].subtitles.length; i++) {
          this.$refs.videoPlayer[this.currentStep].textTracks[i].mode = "disabled";
        }
      }
    },
    speakIn(language) {
      //this will trigger PlayerProcessedLanguage to work
      if (language == this.steps[this.currentStep].languageCode) {
        this.language = this.steps[this.currentStep].languageCode;
        this.$refs.videoPlayer[this.currentStep].muted = false;
        this.$refs.PlayerProcessedLanguage.audioPlayer.muted = true;
      } else {
        this.language = language;
        this.wasPlaying = this.playing;
        this.playing = false;
        this.playOrPauseVideo();
        this.loadingLanguage = true;
        this.$refs.videoPlayer[this.currentStep].muted = true;
        this.$refs.videoPlayer[this.currentStep].pause();
        this.$refs.PlayerProcessedLanguage.setAudioPlayer(language);
      }
    },
    setCurrentStep(index) {
      ///called when user clicks steps-menu
      if (index == this.currentStep) {
        this.clickToCloseStepMenu();
      } else {
        this.menuHidden = true; //hide menu after clicking step
        this.previousStep = this.currentStep;
        this.currentStep = index;
        if (this.wasPlaying) {
          this.playing = true;
          this.playOrPauseVideo();
        }
        this.playNewStep();
        this.trackPlayer({
          workflow: this.workflow,
          event: "on_change_step_from_menu",
          step: this.steps[this.currentStep],
          organization: this.getter_organization,
          user: this.$user,
        });
      }
    },
    playNewStep() {
      //this is called when currentStep changes
      this.updateAudioAndSubtitles();
      this.$refs.videoPlayer[this.previousStep].pause();
      this.$refs.videoPlayer[this.currentStep].currentTime = 0;
      this.playOrPauseVideo();
    },
    updateAudioAndSubtitles() {
      //set audio to defult if the audio choice is not available in the new step
      if (
        this.audioChoice != this.steps[this.currentStep].languageCode &&
        this.audioOptions.indexOf(this.audioChoice) === -1
      ) {
        this.audioChoice = this.steps[this.currentStep].languageCode;
      }
    },
    setPlaybackRate() {
      const videoPlayer = this.currentVideoPlayer;
      videoPlayer.playbackRate = this.playSpeed;
    },
    playOrPauseVideo() {
      //this is called when "playing" changes
      const videoPlayer = this.currentVideoPlayer;
      videoPlayer.playbackRate = this.playSpeed;
      if (this.playing) {
        videoPlayer.play().catch((err) => {
          this.playing = false;
        });
        const time = this.currentTime[this.currentStep];
      } else {
        videoPlayer.pause();
      }
      if (this.isMuted) {
        videoPlayer.muted = true;
      } else {
        videoPlayer.muted = false;
      }
    },
    playPreviousStep() {
      if (!this.isPublic) {
        this.trackPlayer({
          workflow: this.workflow,
          event: "on_previous",
          step: this.steps[this.currentStep],
          organization: this.getter_organization,
          user: this.$user,
          nextStep: this.steps[this.currentStep - 1],
        });
      } else {
        this.trackPlayer({
          workflow: { ...this.workflow, id: "" },
          event: "on_previous_public",
          step: this.steps[this.currentStep],
          nextStep: this.steps[this.currentStep - 1],
        });
      }
      this.previousStep = this.currentStep;
      if (this.currentStep > 0) {
        this.currentStep = this.currentStep - 1;
      }
      this.playNewStep();
    },
    playNextStep() {
      if (!this.isPublic) {
        this.trackPlayer({
          workflow: this.workflow,
          event: "on_next",
          step: this.steps[this.currentStep],
          organization: this.getter_organization,
          user: this.$user,
          nextStep: this.steps[this.currentStep + 1],
        });
      } else {
        this.trackPlayer({
          workflow: { ...this.workflow, id: "" },
          event: "on_next_public",
          step: this.steps[this.currentStep],
          nextStep: this.steps[this.currentStep + 1],
        });
      }
      this.previousStep = this.currentStep;
      const totalSteps = this.steps.length - 1;
      if (this.currentStep < totalSteps) {
        this.currentStep = this.currentStep + 1;
        this.playNewStep();
      }
    },
    muteVideoButton() {
      this.isMuted = !this.isMuted;
    },
    //// display translated titles
    getWorkflowDisplayTitle(subtitlesChoice) {
      const displayLang = subtitlesChoice || this.$i18n.locale;
      return getDisplayTitle(this.workflow, displayLang);
    },
    getStepDisplayTitle(currentStep, subtitlesChoice) {
      const displayLang = subtitlesChoice || this.$i18n.locale;
      const step = this.steps[currentStep] || {};
      return getDisplayTitle(step, displayLang);
    },
    changeAutoPlay({ autoPlay }) {
      this.autoNextStep = autoPlay;
      const isRepeatOn = this.getter_is_repeat_workflow || this.getter_is_repeat_step;
      if (!autoPlay && isRepeatOn) this.changeRepeatType({ type: REPEAT_TYPES.DISABLE });
    },
    repeatCurrentStep() {
      this.$refs.videoPlayer[this.currentStep].currentTime = 0;
      this.playOrPauseVideo();
    },
    repeatWorkflow() {
      if (this.currentStep === this.steps.length - 1) {
        this.previousStep = this.currentStep;
        this.currentStep = 0;
        this.playNewStep();
      } else this.autoPlayNextVideo();
    },
    handleVideoEnded() {
      if (this.getter_is_disabled_repeat) this.autoPlayNextVideo();
      else if (this.getter_is_repeat_workflow) this.repeatWorkflow();
      else if (this.getter_is_repeat_step) this.repeatCurrentStep();
    },
  },
  computed: {
    ...mapState("workflowPlayer", ["isMobileFullscreen", "isWorkflowURLsUpdated"]),
    ...mapGetters("workflowPlayer", [
      "getter_current_step_idx",
      "getter_mobile_divided_screen",
      "getter_is_disabled_repeat",
      "getter_is_repeat_workflow",
      "getter_is_repeat_step",
    ]),
    ...mapGetters(["getter_organization"]),
    hideControls() {
      return (!this.showIcons && this.playing && !this.showSettingPopup) || this.showSkillNext;
    },
    showReactionsBtn() {
      const showingComponents = this.showPlayerSearch || this.showSettingPopup || !this.menuHidden;
      return this.isMobileFullscreen && !showingComponents && !this.hideControls && !this.getter_mobile_divided_screen;
    },
    showAttachmentsBtn() {
      const showBtn =
        this.hideControls &&
        this.attachmentsData[this.currentStep] &&
        this.attachmentsData[this.currentStep].length > 0;
      // window mode
      if (!this.isMobileFullscreen) {
        return showBtn;
      }
      // fullscreen mode
      return !this.getter_mobile_divided_screen && showBtn;
    },
    languageFullName() {
      let locale = this.$i18n.locale;
      if (locale == "en") locale = "en-us";
      return this.$dictionary[locale].languageCode;
    },
    audioOptions() {
      // if (this.steps[this.currentStep].foreignLanguageAudio) {
      //   return Object.keys(this.steps[this.currentStep].foreignLanguageAudio);
      // } else {
      return [];
      // }
    },
    currentVideoPlayer() {
      return this.$refs.videoPlayer[this.currentStep];
    },
  },
};
</script>

<style lang="scss" scoped>
$z-index: (
  settings: 10,
  subtitles: 10,
  settings-portrait-background: 9,
  step-name-tag: 1,
  reactions-button: 11,
);
.PlayerProcessedVideoMobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #0c0c0e;
  &--fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &--dividedScreen {
    position: absolute;
    width: 70%;
    height: 100%;
  }
  &__video {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    video {
      object-fit: contain;
      height: 100%;
      max-width: 100%;
    }
    video::cue {
      color: white;
      background-color: rgba(12, 12, 14, 0.6) !important;
      line-height: 16px !important;
      opacity: 1;
      font-size: 14px !important;
    }
    video::-webkit-media-text-track-container {
      z-index: map-get($z-index, subtitles);
      position: relative;
      overflow: visible !important;
      transform: translateY(-70px) !important;
    }
  }
  &__subtitles {
    &--iphone {
      video::-webkit-media-text-track-container {
        transform: translateY(-30px) !important;
      }
    }
    &--lower {
      video::-webkit-media-text-track-container {
        transform: translateY(-8px) !important;
      }
    }
    &--windowMode {
      video::-webkit-media-text-track-container {
        transform: translateY(-40px) !important;
      }
    }
  }
  &__DPlayerSetting {
    z-index: map-get($z-index, settings);
    position: absolute;
    bottom: 0;
    right: 0;
    -webkit-touch-callout: none;
    user-select: none;
    touch-action: auto;
    @media only screen and (max-width: 768px) and (orientation: portrait) {
      position: fixed;
      width: 100%;
    }
    &--mobileBackground {
      pointer-events: none;
      @media only screen and (max-width: 768px) and (orientation: portrait) {
        z-index: map-get($z-index, settings-portrait-background);
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.8);
      }
    }
  }
  &__reactionsButton {
    z-index: map-get($z-index, reactions-button);
    position: absolute;
    bottom: 44px;
    right: 225px;
  }
  &__stepNameTag {
    z-index: map-get($z-index, step-name-tag);
    position: absolute;
    bottom: 24px;
    left: 16px;
    pointer-events: none;
    &--upper {
      bottom: 80px !important;
    }
  }
}
</style>
