export const WORKFLOW_ACTION = {
  CREATE: "CREATE",
  DELETE: "DELETE",
  DUPLICATED: "DUPLICATED",
  MOVE: "MOVE",
  TO_UNPUBLISHED: "TO_UNPUBLISHED",
};

export const EDITOR_STATUS = {
  COMPLETED_SEGMENTATION: "completed segmentation",
  COMPLETED_TRANSCRIPTION: "completed transcription",
  COMPLETED_BUILDER_MAIN: "completed buildermain",
  DEFAULT: "",
  PROCESSING_CUT: "processing cut",
  PROCESSING_ERASE: "processing erase",
  PROCESSING_PATCH_TRANSCRIPTION: "processing patch transcription",
  PROCESSING_ROTATE: "processing rotate",
  PROCESSING_SEGMENTATION: "processing segmentation",
  PROCESSING_BUILDER_MAIN: "processing buildermain",
  PROCESSING_TRANSCRIPTION: "processing transcription",
  PROCESSING_RE_TRANSCRIPTION: "processing re-transcription",
  PROCESSING_TRIM: "processing trim",
  PROCESSING_VOICEOVER: "processing voice over",
  PROCESSING_TEXT_TO_SPEECH: "processing voice over sections",
};

export const EDITOR_TYPE = {
  CUT: "CUT",
  ROTATE: "ROTATE",
  VOICEOVER: "VOICEOVER",
  TEXT_TO_SPEECH: "TEXT_TO_SPEECH",
};

export const PROCESSING_POPUP_TYPE = {
  CLOSE: "",
  REQUEST_CUT: "REQUEST_CUT",
  REQUEST_ERASE: "REQUEST_ERASE",
  REQUEST_RESET: "REQUEST_RESET",
  REQUEST_ROTATE: "REQUEST_ROTATE",
  REQUEST_TRIM: "REQUEST_TRIM",
  REQUEST_VOICEOVER: "REQUEST_VOICEOVER",
  REQUEST_TEXT_TO_SPEECH: "REQUEST_TEXT_TO_SPEECH",
};

export const REVIEW_POPUP_TYPE = {
  CLOSE: "",
  WAITING_INITIAL: "WAITING_INITIAL",
  REQUEST_PATCH_TRANSCRIPTION: "REQUEST_PATCH_TRANSCRIPTION",
};

export const RECORD_STATUS = {
  WAITING: "WAITING",
  COUNTDOWN: "COUNTDOWN",
  PAUSE: "PAUSE",
  RECORDING: "RECORDING",
  FINISH: "FINISH",
};

export const TERMS_STATUS = {
  IN_PROGRESS: "inProgress",
  REPROCESS: "reprocess",
  EXTRACTED: "extracted",
  EDITED: "edited",
};

export const MULTI_EDITOR_STATUS = {
  DEFAULT_FPS: 30,
};

export const AUDIO_CONTENT_STATUS = {
  TODO: "todo", // [UI] before submit api
  AUDIO_LOADING: "audioLoading", // [UI] before submit api
  PLAYING: "playing", // [UI]
  PENDING: "pending",
  PROCESSING: "processing",
  COMPLETED: "completed",
  FAILURE: "failure",
};

export const TEXT_TO_SPEECH_OPTIONS = {
  MANUAL_INPUT: "Manual text input",
  UPLOAD_FILE: "Upload file",
};

export const VOICES_LANGUAGE_SELECTIONS = {
  EN_JASON: "en-US-JasonNeural",
  EN_ERIC: "en-US-EricNeural",
  EN_ARIAL: "en-US-AriaNeural",
  EN_JANE: "en-US-JaneNeural",
  ZH_YUNYANG: "zh-CN-YunyangNeural",
  ZH_XIAOMENG: "zh-CN-XiaomengNeural",
  FR_ANTOINE: "fr-CA-AntoineNeural",
  FR_SYLVIE: "fr-CA-SylvieNeural",
  ES_JORGE: "es-MX-JorgeNeural",
  ES_LARISSA: "es-MX-LarissaNeural",
};

const GLOBAL_STATIC_PATH = "https://storage.googleapis.com/deephow-public/text-to-speech";
const CHINA_STATIC_PATH = "https://share-deephow.oss-cn-shanghai.aliyuncs.com/text-to-speech";

export const VOICES_SOUND_SELECTIONS = {
  global: {
    [VOICES_LANGUAGE_SELECTIONS.EN_ARIAL]: `${GLOBAL_STATIC_PATH}/tts-en-US-AriaNeural.wav`,
    [VOICES_LANGUAGE_SELECTIONS.EN_ERIC]: `${GLOBAL_STATIC_PATH}/tts-en-US-EricNeural.wav`,
    [VOICES_LANGUAGE_SELECTIONS.EN_JANE]: `${GLOBAL_STATIC_PATH}/tts-en-US-JaneNeural.wav`,
    [VOICES_LANGUAGE_SELECTIONS.EN_JASON]: `${GLOBAL_STATIC_PATH}/tts-en-US-JasonNeural.wav`,
    [VOICES_LANGUAGE_SELECTIONS.ZH_XIAOMENG]: `${GLOBAL_STATIC_PATH}/tts-zh-CN-XiaomengNeural.wav`,
    [VOICES_LANGUAGE_SELECTIONS.ZH_YUNYANG]: `${GLOBAL_STATIC_PATH}/tts-zh-CN-YunyangNeural.wav`,
    [VOICES_LANGUAGE_SELECTIONS.FR_SYLVIE]: `${GLOBAL_STATIC_PATH}/tts-fr-CA-SylvieNeural.wav`,
    [VOICES_LANGUAGE_SELECTIONS.FR_ANTOINE]: `${GLOBAL_STATIC_PATH}/tts-fr-CA-AntoineNeural.wav`,
    [VOICES_LANGUAGE_SELECTIONS.ES_JORGE]: `${GLOBAL_STATIC_PATH}/tts-es-MX-JorgeNeural.wav`,
    [VOICES_LANGUAGE_SELECTIONS.ES_LARISSA]: `${GLOBAL_STATIC_PATH}/tts-es-MX-LarissaNeural.wav`,
  },
  china: {
    [VOICES_LANGUAGE_SELECTIONS.EN_ARIAL]: `${CHINA_STATIC_PATH}/tts-en-US-AriaNeural.wav`,
    [VOICES_LANGUAGE_SELECTIONS.EN_ERIC]: `${CHINA_STATIC_PATH}/tts-en-US-EricNeural.wav`,
    [VOICES_LANGUAGE_SELECTIONS.EN_JANE]: `${CHINA_STATIC_PATH}/tts-en-US-JaneNeural.wav`,
    [VOICES_LANGUAGE_SELECTIONS.EN_JASON]: `${CHINA_STATIC_PATH}/tts-en-US-JasonNeural.wav`,
    [VOICES_LANGUAGE_SELECTIONS.ZH_XIAOMENG]: `${CHINA_STATIC_PATH}/tts-zh-CN-XiaomengNeural.wav`,
    [VOICES_LANGUAGE_SELECTIONS.ZH_YUNYANG]: `${CHINA_STATIC_PATH}/tts-zh-CN-YunyangNeural.wav`,
    [VOICES_LANGUAGE_SELECTIONS.FR_SYLVIE]: `${CHINA_STATIC_PATH}/tts-fr-CA-SylvieNeural.wav`,
    [VOICES_LANGUAGE_SELECTIONS.FR_ANTOINE]: `${CHINA_STATIC_PATH}/tts-fr-CA-AntoineNeural.wav`,
    [VOICES_LANGUAGE_SELECTIONS.ES_JORGE]: `${CHINA_STATIC_PATH}/tts-es-MX-JorgeNeural.wav`,
    [VOICES_LANGUAGE_SELECTIONS.ES_LARISSA]: `${CHINA_STATIC_PATH}/tts-es-MX-LarissaNeural.wav`,
  },
};
