module.exports = {
  ad: {
    highlights: "ไฮไลท์",
    highlightsIntro: "คุณจะสามารถเห็นการเปิดตัวคุณลักษณะใหม่ได้อย่างรวดเร็วเสมอในที่นี่ ยกตัวอย่างเช่นนี้ นอกจากนี้ สเตฟานีจะเตือนคุณและช่วยติดตามการฝึกทักษะอย่างต่อเนื่องของคุณ",
    recommendations: "คำแนะนำ",
    recommendationsIntro: "สเตฟานีจะแนะนำเวิร์กโฟลว์ตามกิจกรรมของคุณที่นี่ เธอจะเรียนรู้ทุกครั้งที่คุณมีปฏิสัมพันธ์กับเธอ!",
    favoritesIntro: "การมีเวิร์กโฟลว์ทั้งหมดที่คุณต้องการมากที่สุดเป็นเรื่องที่ยอดเยี่ยมมาก ตอนนี้คุณมีที่พิเศษที่จะไป",
    categoriesIntro: "เวิร์กโฟลว์มากมาย แต่มีเวลาน้อย... จะดีกว่าไหมถ้าเพียงคลิกและแสดงหมวดหมู่ที่เกี่ยวข้องกับคุณ ตอนนี้คุณทำได้! :)",
    loginMsgTitle: "อัปเดตฟีเจอร์ใหม่!",
    loginMsg: "คุณสามารถค้นหา Workspace ได้อย่างง่ายดาย นอกจากนี้ โปรดดูคำแนะนำเนื้อหาที่ออกแบบมาเพื่อช่วยให้คุณใช้บัญชี DeepHow ได้ดีที่สุด"
  },
  all: {
    success: "สำเร็จ",
    player: "เครื่องเล่น",
    editor: "บรรณาธิการ",
    admin: "ผู้ดูแลระบบ",
    analytics: "การวิเคราะห์",
    skills: "ทักษะ",
    skill: "ทักษะ",
    unpublished: "ที่ไม่ได้เผยแพร่",
    published: "เผยแพร่",
    signOut: "ออกจากระบบ",
    step: "ขั้นตอน",
    steps: "ขั้นตอน",
    numStep: "{count} ขั้นตอน",
    transcription: "การถอดรหัส",
    ok: "ตกลง",
    dontSave: "อย่าบันทึก",
    saveChanges: "บันทึกการเปลี่ยนแปลง",
    no: "ไม่",
    yes: "ใช่",
    delete: "ลบ",
    diagram: "แผนภาพ",
    all: "ทั้งหมด",
    discard: "ทิ้ง",
    save: "บันทึก",
    next: "ถัดไป",
    process: "กระบวนการ",
    cancel: "ยกเลิก",
    close: "ปิด I",
    allUsers: "ผู้ใช้งานทั้งหมด",
    users: "ผู้ใช้งาน",
    user: "ผู้ใช้งาน",
    numUsers: "ผู้ใช้งาน",
    numUser: "ผู้ใช้งาน",
    countUsers: "ผู้ใช้งาน {count} คน",
    workspaces: "พื้นที่ทำงาน",
    workspace: "พื้นที่ทำงาน",
    workspaceGroup: "กลุ่มพื้นที่ทำงาน",
    workflows: "เวิร์กโฟลว์",
    workflow: "เวิร์กโฟลว์",
    organization: "องค์กร",
    business: "ธุรกิจ",
    create: "สร้าง",
    name: "ชื่อ",
    namePerson: "ชื่อ",
    email: "อีเมล",
    employeeId: "รหัสพนักงาน",
    role: "หน้าที่",
    organizationAdmin: "ผู้ดูแลระบบองค์กร",
    workspaceAdmin: "ผู้ดูแลระบบพื้นที่การทำงาน",
    publisher: "ผู้เผยแพร่",
    viewer: "ผู้ชม",
    organizationAdmins: "ผู้ดูแลระบบองค์กร",
    workspaceAdmins: "ผู้ดูแลระบบพื้นที่การทำงาน",
    publishers: "ผู้เผยแพร่",
    viewers: "ผู้ชม",
    add: "เพิ่ม",
    attachments: "สิ่งที่แนบมา",
    private: "ส่วนตัว",
    public: "สาธารณะ",
    content: "เนื้อหา",
    noRecordFound: "ไม่พบบันทึก",
    loadingRecords: "โหลดบันทึก ...",
    teams: "ทีม",
    loadedAllRecords: "โหลดบันทึกทั้งหมดแล้ว",
    language: "ภาษา",
    interfaceLanguage: "ภาษาอินเตอร์เฟส",
    personalInfo: "ข้อมูลส่วนบุคคล",
    privacyPolicy: "นโยบายความเป็นส่วนตัว",
    team: "ทีม",
    unselect: "ยกเลิกการเลือก",
    select: "เลือก",
    applied: "ประยุกต์ใช้แล้ว",
    noAccessToAdminTools: "ปัจจุบันบัญชีผู้ใช้งานนี้ไม่มีสิทธิ์เข้าถึงเครื่องมือผู้ดูแลระบบ",
    changePhoto: "เปลี่ยนรูปภาพ",
    removePhoto: "ลบรูปภาพ",
    profilePhoto: "รูปภาพประจำตัว",
    takeOrUpload: "ถ่าย หรือ อัพโหลดรูปภาพ",
    useCamera: "ใช้กล้อง",
    uploadPhoto: "อัพโหลดรูปภาพ",
    editPhoto: "แก้ไขรูปภาพ",
    takePhoto: "ถ่ายภาพ",
    retake: "ถ่ายใหม่",
    countWorkspaces: "พื้นที่ทำงาน",
    whatsNew: "มีอะไรใหม่",
    howToUse: "วิธีใช้",
    defaultInterfaceLanguage: "ภาษาอินเตอร์เฟสเริ่มต้น",
    newVersionSnackbarText: "มี DeepHow เวอร์ชันใหม่",
    refresh: "รีเฟรช",
    unpublishedList: "ที่ไม่ได้เผยแพร่",
    publishedList: "เผยแพร่",
    userAgreement: "ข้อตกลงการใช้",
    numAttachments: "0 สิ่งที่แนบมา | 1 สิ่งที่แนบมา | {count} สิ่งที่แนบมา",
    attachment: "สิ่งที่แนบมา",
    knowledgeBase: "ฐานความรู้ DeepHow",
    changeName: "เปลี่ยนชื่อ",
    changePassword: "เปลี่ยนรหัสผ่าน",
    openInApp: "เปิดใน Deephow Navigator",
    userNewToDeepHow: "คุณยังใหม่กับ Deephow หรือไม่?",
    downloadTheApp: "ดาวน์โหลดแอพ",
    continueToUseBrowser: "ใช้เบราว์เซอร์ต่อไป",
    contentManager: "ผู้จัดการเนื้อหา",
    gen2Forbidden: "ในฐานะผู้ใช้ Gen2 Light คุณไม่สามารถเข้าสู่ระบบได้ในขณะนี้",
    confirm: "ยืนยัน",
    myWorkspaceGroups: "กลุ่มพื้นที่ทำงานของฉัน",
    otherWorkspaceGroups: "กลุ่มพื้นที่ทำงานอื่นๆ",
    searchResult: "ผลการค้นหา",
    noResultsFound: "ไม่พบผลลัพธ์.",
    browseWorkspace: "เรียกดูพื้นที่ทำงาน...",
    version: "รุ่น",
  },
  password: {
    createYourAccount: "สร้างบัญชีของคุณ",
    checkNameChoosePassword: "ตรวจสอบชื่อของคุณและเลือกรหัสผ่าน",
    createAccount: "สร้างบัญชี",
    createNewPassword: "สร้างรหัสผ่านใหม่",
    selectNewPassword: "เลือกรหัสผ่านใหม่สำหรับบัญชีของคุณ",
    newPassword: "รหัสผ่านใหม่",
    confirmNewPassword: "ยืนยันรหัสผ่านใหม่",
    passwordMustMatch: "รหัสผ่านต้องถูกต้อง",
    confirm: "ยืนยัน",
    passwordCreatedMsg: "รหัสผ่านใหม่ของคุณกำลังถูกสร้างขึ้น <br>โปรดรอสักครู่ในขณะที่เรากำลังเปลี่ยน",
    goToDeepHowPlayer: "ไปที่เครื่องเล่น DeepHow ",
    failedResetPassword: "ไม่สามารถรีเซ็ตรหัสผ่านได้ บัญชีของคุณถูกสร้างขึ้นแล้วหรือเครือข่ายอินเตอ์เน็ตมีปัญหา",
    alertSetPasswordFailed: "ไม่สามารถตั้งรหัสผ่านได้ บัญชีของคุณถูกสร้างขึ้นแล้วหรือมีปัญหาเกี่ยวกับเครือข่าย",
    linkExpired: "ลิงค์นี้หมดอายุแล้ว",
    passwordExpired: "ลิงค์รีเซ็ตรหัสผ่านของคุณหมดอายุแล้ว",
    inviteExpired: "คำเชิญของคุณหมดอายุแล้ว",
    resetPasswordMsg: "คลิกปุ่มด้านล่างเพื่อขออีเมลรีเซ็ตรหัสผ่าน",
    // added 09/08/2022
    alertFailedResetPassword: "ไม่สามารถรีเซ็ตรหัสผ่านได้",
    alertSuccessfullyResetPassword: "รีเซ็ตรหัสผ่านสำเร็จโปรดรอในขณะที่เราเปลี่ยนเส้นทางให้คุณเข้าสู่ระบบอีกครั้ง… <br><br> หรือคลิกปุ่มด้านล่าง",
    newPasswordNotTheSame: "รหัสผ่านใหม่ไม่ควรเหมือนกับรหัสเก่า",
    alertCurrentPasswordIncorrect: "รหัสผ่านปัจจุบันไม่ถูกต้อง",
    alertTooManyAttempts: "พยายามตรวจสอบรหัสผ่านปัจจุบันมากเกินไป ลองอีกครั้งในภายหลัง",
  },
  analytics: {
    overview: "ภาพรวม",
    engagement: "ข้อตกลง",
    activeUsers: "ผู้ใช้งานที่ใช้งานอยู่",
    workflowsCreated: "สร้างเวิร์กโฟลว์",
    workflowsPublished: "เวิร์กโฟลว์ที่เผยแพร่แล้ว",
    workflowViews: "จำนวนการดูเวิร์กโฟลว์",
    timeCreated: "เวลาที่สร้างขึ้น",
    timePublished: "เวลาที่เผยแพร่",
    timeViewed: "เวลาที่เยี่ยมชม",
    searchWorkspace: "ค้นหาเวิร์กสเปซ ...",
    mostUsers: "ผู้ใช้งานส่วนใหญ่",
    mostWorkflowsCreated: "เวิร์กโฟลว์ส่วนใหญ่ที่ถูกสร้างขึ้น",
    numberOfUsers: "จำนวนผู้ใช้งาน",
    organizationOverview: "ภาพรวมขององค์กร",
    numWorkflowsIn: "เวิร์กโฟลว์ใน",
    searchWorkflow: "ค้นหาเวิร์กโฟลว์",
    createdBy: "จัดสร้างโดย",
    usersIn: "ผู้ใช้งานใน",
    searchUser: "ค้นหาผู้ใช้งาน",
    watchTime: "เวลาในการรับชม",
    workflowsViewed: "เวิร์กโฟลว์ที่ดูแล้ว",
    percentViewed: "ดู % แล้ว",
    dateViewed: "วันที่ดู",
    watchWorkflow: "ดูเวิร์กโฟลว์",
    backToUsers: "กลับไปที่ผู้ใช้งาน",
    backToWorkflows: "กลับไปที่เวิร์กโฟลว์",
    likes: "ชอบ",
    avgTimeViewed: "เวลาในการรับชมเฉลี่ย",
    numberofViews: "จำนวนการรับชม",
    workspacesPerPage: "เวิร์กสเปซต่อหน้า",
    usersPerPage: "ผู้ใช้งานต่อหน้า",
    workflowsPerPage: "เวิร์กโฟลว์ต่อหน้า",
    exportTable: "ตารางส่งออก",
    TopViewers: "ผู้เยี่ยมชมมากที่สุด",
    TopPublishers: "ผู้เผยแพร่มากที่สุด",
    totalViews: "ยอดคนดูทั้งหมด",
    msgNoDataYet: "ไม่มีข้อมูลที่สามารถใช้ได้",
    msgNoSearchResults: "ไม่มีบันทึกที่ตรงกัน",
    hms: "(ชั่วโมง:นาที:วินาที)",
    viewHistory: "ดูประวัติ",
    views: "จำนวนผู้รับชม",
    totalViewingTime: "เวลาในการรับชมทั้งหมด",
    usersViewedThisWorkflow: "มีผู้ใช้งาน<b>{count}</b>คนดูเวิร์กโฟลว์นนี้",
    date: "วันที่",
    // added 2022/07/11
    dashboards: "แดชบอร์ด",
    reactions: "ปฏิกิริยาตอบโต้",
    userReaction: "ความคิดเห็นของลูกค้า",
    viewAll: "ดูทั้งหมด",
    favorites: "รายการโปรด",
  },
  admin: {
    status: "สถานะ",
    active: "ใช้งาน",
    inactive: "ไม่ได้ใช้งาน",
    invited: "ได้รับเชิญ",
    invitationExpired: "คำเชิญหมดอายุ",
    couldNotInvited: "ไม่สามารถเชิญได้",
    hintSearchUsers: "ค้นหาผู้ใช้งาน ...",
    hintSearchWorkspaces: "ค้นหาเวิร์กสเปซ ...",
    hintSearchBusiness: "ค้นหาธุรกิจ ...",
    alertSuccessfullyInviteUser: "คำเชิญบัญชีผู้ใช้งานถูกเชิญสำเร็จ",
    alertFailedInviteUser: "ไม่สามารถเชิญบัญชีผู้ใช้งาน",
    alertSuccessfullyDeletedUser: "ลบบัญชีผู้ใช้งานสำเร็จแล้ว",
    alertFailedDeleteUser: "ไม่สามารถลบบัญชีผู้ใช้งาน",
    alertSuccessfullyCreatedWorkspace: "สร้างเวิร์กสเปซสำเร็จแล้ว",
    alertSuccessfullyEditedWorkspace: "แก้ไขเวิร์กสเปซสำเร็จแล้ว",
    alertSuccessfullyCreatedBusiness: "สร้างธุรกิจสำเร็จ",
    alertSuccessfullyEditedBusiness: "แก้ไขธุรกิจสำเร็จแล้ว",
    alertUserInvitationSuccessfullyResent: "ส่งคำเชิญบัญชีผู้ใช้งานสำเร็จ",
    alertFailedResendUserInvitation: "ไม่สามารถส่งคำเชิญบัญชีผู้ใช้งานอีกครั้ง",
    alertUserInvitationSuccessfullyDelete: "ลบคำเชิญบัญชีผู้ใช้งานสำเร็จ",
    alertFailedDeleteUserInvitation: "ไม่สามารถลบคำเชิญบัญชีผู้ใช้งาน",
    resendInvitation: "ส่งคำเชิญอีกครั้ง",
    popupMsgResendInvitation: "แน่ใจหรือว่าต้องการส่งคำเชิญไปที่...อีกครั้ง",
    popupBtnSend: "ตกลง,ส่ง",
    deleteInvitation: "ลบคำเชิญ",
    popupMsgDeleteInvitation: "แน่ใจหรือไม่ว่าต้องการลบคำเชิญที่ส่งไปที่...",
    addUser: "เพิ่มบัญชีผู้ใช้งาน",
    msgNoMembersYet: "องค์กรนี้ยังไม่มีสมาชิก",
    msgNoRecordsAllUsers: "ไม่พบบันทึกที่ตรงกันในผู้ใช้งานทั้งหมด",
    createWorkspace: "สร้างเวิร์กสเปซ",
    msgNoWorkspacesYet: "องค์กรนี้ยังไม่มีเวิร์กสเปซ",
    msgNoRecordsWorkspaces: "ไม่พบบันทึกที่ตรงกันในเวิร์กสเปซ",
    msgNoRecordsWorkspacesGroupAdmin: "บัญชีนี้ยังไม่มีเวิร์กสเปซ",
    createBusiness: "สร้างธุรกิจ",
    msgNoBusinessYet: "องค์กรนี้ยังไม่มีธุรกิจ",
    msgNoRecordsBusiness: "ไม่พบบันทึกที่ตรงกันในธุรกิจ",
    usersIn: "ผู้ใช้งานใน",
    tableMsgNoUsersYet: "เวิร์กสเปซนี้ยังไม่มีสมาชิก",
    tableMsgNoRecordsThisWorkspace: "ไม่พบบันทึกที่ตรงกันในเวิร์กสเปซ",
    memberAt: "สมาชิกที่",
    alerBusinessAlreadyInSystem: "ธุรกิจอยู่ในระบบแล้ว",
    alerFailedAddBusiness: "ไม่สามารถเพิ่มธุรกิจได้",
    alertFailedEditBusiness: "ไม่สามารถแก้ไขธุรกิจได้",
    alertWorkspaceAlreadyInSystem: "ชื่อเวิร์กสเปซนี้อยู่ในระบบแล้ว",
    aletFailedCreateWorkspace: "ไม่สามารถสร้างเวิร์กสเปซได้",
    alertFailedEditWorkspace: "ไม่สามารถแก้ไขเวิร์กสเปซได้",
    resendAllInvites: "ส่งคำเชิญทั้งหมดอีกครั้ง",
    resendAllInvitesMsg: "แน่ใจว่าจะส่งคำเชิญไปยังคำเชิญที่รอดำเนินการและหมดอายุทั้งหมดหรือไม่",
    yesSendInvites: "ใช่ ส่งคำเชิญ",
    businessesPerPage: "ธุรกิจต่อหน้า",
    //add org user
    popupTitleAddOrgUser: "เพิ่มบัญชีผู้ใช้งานผู้ดูแลระบบองค์กร",
    invite: "เชิญ",
    popupTitleNewUserInvited: "บัญชีผู้ใช้งานใหม่ถูกเชิญ",
    popupMsgNewUserInvitedOrg: "หลังจากการตรวจสอบบัญชีผู้ใช้งานจะสามารถเข้าถึงองค์กรนี้ได้",
    popupTitleExistingUserInvited: "เพิ่มผู้ใช้งานที่มีอยู่แล้ว!",
    popupMsgExistingUserInvitedOrg: "ถูกเพิ่มไปยังองค์กรนี้",
    popupBtnAddAnotherUser: "เพิ่มบัญชีผู้ใช้งานรายอื่น",
    alertFailedCheckPendingInvitations: "ไม่สามารถตรวจสอบได้ว่าผู้ใช้งานมีคำเชิญที่รอดำเนินการหรือไม่",
    alertFailedAddExistingUserAsOrganizationAdmin: "ไม่สามารถเพิ่มผู้ใช้งานที่มีอยู่เป็นผู้ดูแลระบบองค์กร",
    alertAddExistingUserAsOrganizationAdmin: "เพิ่มผู้ใช้งานที่มีอยู่เป็นผู้ดูแลระบบองค์กร",
    alertUserAlreadyOrganizationAdmin: "มีบัญชีผู้ใช้งานแล้วในระบบในฐานะผู้ดูแลระบบองค์กร",
    alertFailedCheckUserAlreadyInSystem: "ไม่สามารถตรวจสอบได้ว่าผู้ใช้งานอยู่ในระบบแล้วหรือไม่",
    //Add user to workspace
    addUserToWorkspace: "เพิ่มผู้ใช้งานไปยังเวิร์กสเปซ",
    companyEmail: "อีเมลบริษัท",
    employeeIdOnly: "รหัสพนักงานเท่านั้น",
    fullName: "ชื่อเต็ม",
    workspaceName: "ชื่อเวิร์กสเปซ",
    businessName: "ชื่อธุรกิจ",
    popupMsgNewUserInvited: "หลังจากการตรวจสอบบัญชี ผู้ใช้งานจะสามารถเข้าถึงเวิร์กสเปซนี้ได้",
    popupMsgExistingUserInvited: "ถูกเพิ่มไปยังเวิร์กสเปซนี้",
    popupTitleNewUserCreated: "บัญชีผู้ใช้งานใหม่ถูกสร้างขึ้น",
    popupTitleUserAlreadyinSystem: "มีผู้ใช้งานในระบบแล้ว",
    alertUserIsAlreadyInWorkspace: "ผู้ใช้งานรายนี้ ({info}) อยู่ในเวิร์กสเปซนี้แล้ว",
    alertPendingInvitation: "ผู้ใช้งานมีคำเชิญที่รอดำเนินการ โปรดรอให้ผู้ใช้งานยอมรับคำเชิญปัจจุบัน",
    searchingforUsers: "ค้นหาผู้ใช้งาน ...",
    noResultsFound: "ไม่พบผลลัพธ์.",
    // Change User Role
    popupTitleChangeUserRole: "เปลี่ยนบทบาทของผู้ใช้งาน",
    popupMsgChangeUser: "คุณแน่ใจหรือไม่ว่าต้องการเปลี่ยนบทบาทของ <b>{name}</b> จาก <b>{oldRole}</b> เป็น <b>{newRole}</b>",
    popupBtnChange: "ใช่ เปลี่ยน",
    alertUpdatedUserSettings: "อัพเดทการตั้งค่าผู้ใช้งานสำเร็จ",
    // Remove User
    popupTitleRemoveUser: "ลบผู้ใช้งานออกจากเวิร์กสเปซ",
    popupMsgRemoveUserFromWorkspace: "คุณแน่ใจหรือไม่ว่าต้องการลบ <b>{name}</ b> ออกจากเวิร์กสเปซ",
    popupBtnRemove: "ใช่ ลบ",
    alertRemoveUser: "นำผู้ใช้งานออกจากเวิร์กสเปซสำเร็จแล้ว",
    // Delete User
    popupTitleDeleteUser: "ลบผู้ใช้งานออกจากระบบ",
    popupMsgDeleteUserFromAllWorkspaces: "หากคุณทำเช่นนี้ คุณจะสูญเสียบันทึกทั้งหมดเกี่ยวกับผู้ใช้งานรายนี้ <b>{name}</b> และเขาจะถูกลบออกจากเวิร์กสเปซทั้งหมดและบทบาทที่เขาได้รับมอบหมาย",
    popupBtnDelete: "ใช่ ลบ",
    tooltipEditUser: "แก้ไขผู้ใช้งาน",
    tooltipResendInvite: "ส่งคำเชิญอีกครั้ง",
    tooltipCancelInvite: "ยกเลิกคำเชิญ",
    tooltipRemoveUser: "ลบผู้ใช้งาน",
    // settings
    settings: "การตั้งค่า",
    location: "ตำแหน่งที่ตั้ง",
    hireDate: "วันที่จ้าง",
    department: "แผนก",
    position: "ตำแหน่ง",
    site: "สถานที่ตั้ง",
    userId: "ID ผู้ใช้งาน",
    ssoMapping: "การแมป SSO",
    ssoMappingNoData: "ยังไม่มีข้อมูลที่จะแมป <br/>คุณต้องอัพโหลด CSV ผ่าน FTP กรุณาติดต่อฝ่ายช่วยเหลือ",
    attributes: "คุณลักษณะ",
    oneAttributeToMap: "คุณสามารถเลือกได้ 1 คุณลักษณะเพื่อแมปผู้ใช้งานในเวิร์กสเปซ",
    selectAttribute: "เลือกคุณลักษณะ",
    changeAttribute: "เปลียนคุณลักษณะ",
    discardChanges: "ยกเลิกการเปลี่ยนแปลง",
    applyChanges: "ใช้การเปลี่ยนแปลง",
    applyChangesMsg: "คุณแน่ใจว่าจะเปลี่ยนแปลงเวิร์กสเปซต่อไปนี้ หรือไม่",
    mappingValuesUpdated: "กาอัพเดทค่าการแมป",
    mappingValuesUpdatedSubtitle: "หากต้องการอัพเดทการแมปให้สำเร็จ คุณต้องใช้การเปลี่ยนแปลง คุณสามารถทำได้เมื่อแก้ไขเวิร์กสเปซทั้งหมดเสร็จแล้ว",
    values: "ค่า",
    clickPlusToAddValues: "คลิกที่ปุ่ม + เพื่อเพิ่มค่า",
    selectValues: "เลือกค่า",
    searchForValues: "ค้นหาค่า ...",
    assign: "กำหนด",
    assignValues: "กำหนดค่า",
    addUsersBtn: "ใช่ เพิ่มผู้ใช้งาน",
    YesChangeBtn: "ใช่ เปลี่ยน",
    numMore: "อีก {count} ราย",
    removeValues: "ลบค่า",
    remove: "ลบ",
    changeAttributeMessage: "คุณแน่ใจหรือไม่ว่าต้องการเปลี่ยนคุณลักษณะ <br/> คุณจะสูญเสียการแมปจริงทั้งหมดที่คุณมีสำหรับคุณลักษณะนี้",
    discardChangesMessage: "คุณแน่ใจหรือไม่ ที่การเปลี่ยนแปลงทั้งหมดที่คุณทำตั้งแต่บันทึกครั้งล่าสุดจะสูญหาย",
    discardChangesBtn: "ใช่ ยกเลิกการเปลี่ยนแปลง",
    applyChangesBtn: "ใช่ ใช้การเปลี่ยนแปลง",
    alertDiscardedSuccess: "ยกเลิกการแมปสำเร็จ",
    alertAppliedSuccess: "ใช้การแมปสำเร็จ",
    alertAppliedFailed: "ไม่สามารถใช้การแมปได้",
    //permissions
    permissions: "สิทธิ์",
    products: "ผลิตภัณฑ์ DeepHow",
    roleAccessToProduct: "กำหนดด้านล่างว่าบทบาทของผู้ใช้งานใดจะสามารถเข้าถึงแต่ละผลิตภัณฑ์ได้",
    analytics: "การวิเคราะห์",
    skillsManager: "การจัดการทักษะ",
    skillsPlayer: "เครื่องเล่นทักษะ",
    workflowCapture: "การจับภาพเวิร์กโฟลว์",
    workflowEditor: "บรรณาธิการเวิร์กโฟลว์",
    organizationAdmin: "ผู้ดูแลระบบองค์กร",
    groupAdmin: "ผู้ดูแลระบบเวิร์กสเปซ",
    publisher: "ผู้เผยแพร่",
    viewer: "ผู้ชม",
    rolesPermission: "สิทธิ์ในบทบาท",
    rolesPermissionMessage: "คุณแน่ใจหรือไม่ว่าต้องการเปลี่ยนสิทธิ์ในบทบาทของผลิตภัณฑ์นี้ <br/> การเปลี่ยนแปลงจะสำเร็จทันที",
    inviteUser: "เชิญผู้ใช้งาน",
    workflowSharing: "การแชร์เวิร์กโฟลว์",
    allowUserstoSharePublicLinks: "อนุญาตให้ผู้ใช้งานแบ่งปันลิงค์สาธารณะ",
    changeWorkflowSharingExplanation: "ด้วยการปิดการแชร์ลิงค์ เวิร์กโฟลว์สาธารณะจะสามารถเข้าถึงได้เฉพาะผู้ใช้งานที่ลงชื่อเข้าใช้กับแพลตฟอร์มนี้เท่านั้น",
    allowWorkflowSharingMessage: "คุณแน่ใจหรือไม่ว่าต้องการที่จะเปิดลิงก์เป็นสาธารณะ",
    preventWorkflowSharingMessage: "คุณแน่ใจหรือไม่ว่าต้องการปิดการแชร์ลิงค์สาธารณะ การเปลี่ยนแปลงจะสำเร็จทันที",
    workflowDownload: "ดาวน์โหลดเวิร์กโฟลว์",
    allowUsersToDownloadWorkflows: "อนุญาตให้ผู้ใช้งานดาวน์โหลดเวิร์กโฟลว์",
    allowDownloadWorkflowsMessage: "คุณแน่ใจหรือว่าต้องการอนุญาตให้ผู้ใช้งานดาวน์โหลดเวิร์กโฟลว์",
    preventDownloadWorkflowsMessage: "คุณแน่ใจหรือว่าต้องการป้องกันผู้ใช้งานจากการดาวน์โหลดเวิร์กโฟลว์",
    autoLogout: "ออกจากระบบอัตโนมัติ",
    autoLogoutAfterSelectedTime: "ผู้ใช้งานจะออกจากระบบโดยอัตโนมัติหลังจากเวลาที่เลือก",
    numMinutes: "{count} นาที",
    changeLogoutTimeMessage: "คุณแน่ใจหรือไม่ว่าคุณต้องการเปลี่ยนเวลาที่ออกจากระบบอัตโนมัติเป็น {num} นาที",
    enableAutoLogoutMessage: "คุณแน่ใจหรือว่าต้องการเปิดใช้งานการออกจากระบบอัตโนมัติ",
    disableAutoLogoutMessage: "คุณแน่ใจหรือไม่ว่าต้องการปิดใช้งานการออกจากระบบอัตโนมัติ",
    changesMadeImmediately: "การเปลี่ยนแปลงจะสำเร็จทันที",
    sessionIsAboutToExpire: "เซสชั่นของคุณกำลังจะหมดอายุ",
    youWillBeLoggedOutNumSeconds: "คุณจะออกจากระบบใน {count} วินาที",
    continueSession: "เซสชั่นต่อ",
    //system languages
    languages: "ภาษา",
    systemLanguage: "ภาษาของระบบ",
    searchForWorkspaces: "ค้นหาเวิร์กสเปซ ...",
    workspacesPerPage: "เวิร์กสเปซต่อหน้า",
    workspace: "เวิร์กสเปซ",
    workspaceLanguage: "ภาษา",
    changeLanguage: "เปลี่ยนภาษา",
    popupMsgChangeLanguage: "การเปลี่ยนภาษาจะส่งผลกระทบต่อผู้ใช้งานทุกคนในเวิร์กสเปซนี้ คุณแน่ใจจะเปลี่ยนหรือไม่",
    alertLanguageSuccessfullyChanged: "เปลี่ยนภาษาสำเร็จแล้ว",
    alertFailedChangeLanguage: "ไม่สามารถเปลี่ยนภาษาได้",
    // Categories
    categories: "หมวดหมู่",
    alertCategoriesNotSaved: "หมวดหมู่ที่ไม่ได้บันทึกลองอีกครั้งในภายหลัง",
    noCategoryResult: "ไม่มีผลการค้นหาหมวดหมู่",
    noCategoriesAddedYet: "ยังไม่มีการเพิ่มหมวดหมู่",
    categoriesAssignment: "การกำหนดหมวดหมู่",
    categoryPlaceholder: "ค้นหาหมวดหมู่และหมวดหมู่ย่อย ...",
    previouslyUsed: "ใช้ก่อนหน้านี้",
    allCategories: "ทุกหมวดหมู่",
    workflowCategories: "หมวดหมู่เวิร์กโฟลว์",
    searchForCategories: "ค้นหาหมวดหมู่ ...",
    categoryItems: "รายการ",
    categoryItemsNum: "รายการ",
    createCategory: "สร้างหมวดหมู่",
    editCategory: "แก้ไขหมวดหมู่",
    categoryName: "ชื่อ",
    noCategoriesCreatedYet: "ยังไม่มีหมวดหมู่ที่สร้างขึ้น",
    noItemsCreatedYet: "ยังไม่มีรายการที่สร้างขึ้น",
    categoriesPerPage: "หมวดหมู่ต่อหน้า",
    itemsPerPage: "รายการต่อหน้า",
    alertCategoryAlreadyInSystem: "หมวดหมู่อยู่ในระบบแล้ว",
    alertFailedAddCategory: "ไม่สามารถเพิ่มหมวดหมู่",
    alertFailedEditCategory: "ไม่สามารถแก้ไขหมวดหมู่",
    deleteCategory: "ลบหมวดหมู่",
    popupMsgDeleteCategory: "คุณแน่ใจหรือว่าต้องการลบหมวดหมู่",
    itemsIn: "รายการ",
    searchForItems: "ค้นหารายการ ...",
    createItem: "สร้างรายการ",
    editItem: "แก้ไขรายการ",
    deleteItem: "ลบรายการ",
    itemName: "ชื่อ",
    alertItemAlreadyInSystem: "รายการอยู่ในระบบแล้ว",
    alertFailedAddItem: "ไม่สามารถเพิ่มรายการได้",
    alertSuccessAddItem: "เพิ่มรายการสำเร็จ",
    alertFailedEditItem: "ไม่สามารถแก้ไขรายการ",
    alertSuccessEditItem: "แก้ไขรายการสำเร็จ",
    //Teams
    teamsPerPage: "ทีมต่อหน้า",
    max1000UsersPerTeam: "* ผู้ใช้งานมากสุด 1,000 คนต่อทีม",
    usersSelected: "เลือกผู้ใช้งานแล้ว {count} คน",
    selectAll: "เลือกทั้งหมด",
    removeAll: "ลบทั้งหมด",
    createTeam: "สร้างทีม",
    editTeam: "แก้ไขทีม",
    teamName: "ชื่อ",
    teamsIn: "ทีมใน",
    searchForTeams: "ค้นหาทีม ...",
    searchForUsers: "ค้นหาผู้ใช้งาน ...",
    noUsersAddedYet: "ไม่มีผู้ใช้งานเพิ่มในทีมของคุณ",
    dateAdded: "วันที่เพิ่ม",
    alertTeamAlreadyInSystem: "ทีมอยู่ในระบบแล้ว",
    alertFailedEditTeam: "แก้ไขทีมล้มเหลว",
    deleteTeam: "ลบทีม",
    popupMsgDeleteTeam: "แน่ใจว่าจะลบทีม <b>{name}</b> หรือไม่",
    noTeamsCreatedYet: "ไม่มีทีมที่สร้างขึ้น",
    popupMsgDeleteItem: "คุณแน่ใจหรือว่าต้องการลบรายการ",
    removeUser: "ลบผู้ใช้งาน",
    popupMsgRemoveUser: "แน่ใจว่าจะลบ <b>{name}</b> จากทีมนี้หรือไม่",
    alertErrorGettingWorkspaceList: "เกิดข้อผิดพลาดในการรับรายการเวิร์กสเปซ",
    alertSuccessfullyCreatedTeam: "สร้างทีมสำเร็จแล้ว",
    alertFailedCreatedTeam: "ไม่สามารถสร้างทีมได้",
    alertSuccessfullyUpdatedTeam: "อัพเดททีมสำเร็จแล้ว",
    alertSuccessfullyRemovedUserFromTeam: "ลบผู้ใช้งานออกจากทีมสำเร็จแล้ว",
    alertFailedRemovedUserFromTeam: "ไม่สามารถลบผู้ใช้งานออกจากทีมได้",
    alertSuccessfullyAddedUserToTeam: "เพิ่มผู้ใช้งานในทีมสำเร็จแล้ว",
    alertFailedAddedUserToTeam: "ไม่สามารถเพิ่มผู้ใช้งานในทีม",
    alertWarningAddedUserToTeamAlready: "ผู้ใช้ได้ถูกเพิ่มเข้าไปในทีมแล้ว",
    // TODO added 2022/02/03
    alertAllInvitationResentSuccess: "คำเชิญทั้งหมดไม่พอใจสำเร็จ",
    alertAllInvitationResentFailed: "ไม่สามารถส่งคำเชิญทั้งหมดได้ทั้งหมด",
    alertCheckUserInSystemFailed: "ไม่สามารถตรวจสอบว่าผู้ใช้อยู่ในระบบแล้ว",
    alertOrganizationAdminAssignedFailed: "ผู้ดูแลระบบองค์กรไม่สามารถกำหนดให้กับพื้นที่ทำงานได้",
    // TODO added 2022/02/07
    alertFailedDeleteItem: "ไม่สามารถลบรายการ",
    alertSuccessfullyDeletedItem: "ลบรายการสำเร็จแล้ว",
    alertCategoryDeleteFailed: "ไม่สามารถลบหมวดหมู่",
    alertSuccessfullyDeletedCategory: "ลบหมวดหมู่สำเร็จแล้ว",
    // TODO added 2022/02/08
    upgradeToOrgAdmin: "อัปเกรดเป็นองค์กร ผู้ดูแลระบบ",
    yesUpgrade: "ใช่อัพเกรด",
    popupMsgUpgradeToOrgAdmin: "คุณแน่ใจหรือไม่ว่าต้องการอัพเกรด <b>{name}</b>? ผู้ดูแลระบบองค์กรมีสิทธิ์เข้าถึงการตั้งค่าการตั้งค่าองค์กรของคุณและอีกมากมาย ...",
    alertFailedUpgradeToOrg: "ไม่สามารถอัปเกรดเป็นองค์กรได้ ผู้ดูแลระบบ",
    alertSuccessfullyUpgradeToOrg: "อัปเกรดเป็นองค์กรสำเร็จแล้ว ผู้ดูแลระบบ",
    // TODO added 2022/02/17
    userActive: "ผู้ใช้ที่เปิดใช้งาน",
    profile: "ประวัติผู้ใช้",
    // TODO: added 2022/02/09 settings > Master Password
    masterPassword: "รหัสผ่านหลัก",
    enableMasterPassword: "เปิดใช้งานรหัสผ่านหลัก",
    disableMasterPassword: "ปิดใช้งานรหัสผ่านหลัก",
    resetMasterPassword: "รีเซ็ตรหัสผ่านหลัก",
    employeeIDAccess: "การเข้าถึง ID พนักงาน",
    employeeIDAccessMsg: "หากคุณเปิดคุณสมบัตินี้ผู้ใช้ที่เข้าถึงได้กับรหัสพนักงานเท่านั้นที่จะต้องป้อนรหัสผ่านนี้เพื่อเข้าถึง DeepHow",
    disableMasterPasswordMsg: "หากคุณปิดคุณสมบัตินี้ผู้ใช้ที่เข้าถึงได้กับรหัสพนักงานเท่านั้นไม่จำเป็นต้องป้อนรหัสผ่านเพื่อเข้าถึง DeepHow",
    resetMasterPasswordMsg: "ผู้ใช้ที่เข้าถึงได้กับรหัสพนักงานเท่านั้นที่จะต้องใช้รหัสผ่านใหม่นี้เพื่อเข้าถึง DeepHow",
    insertPassword: "ใส่รหัสผ่าน",
    alertEnablePasswordFailed: "ไม่สามารถเปิดใช้งานรหัสผ่านหลัก",
    alertEnablePasswordSuccess: "เปิดใช้งานรหัสผ่านหลักที่เปิดใช้งานเรียบร้อยแล้ว",
    alertDisablePasswordFailed: "ไม่สามารถปิดใช้งานรหัสผ่านหลัก",
    alertDisablePasswordSuccess: "ปิดใช้งานรหัสผ่านหลักที่ปิดใช้งานเรียบร้อยแล้ว",
    savePasswordMsg: "คุณสมบัตินี้จะไม่ถูกเปิดใช้งานจนกว่ารหัสผ่านของคุณจะถูกบันทึกเรียบร้อยแล้ว",
    resetPasswordMsg: "ป้อนรหัสผ่านหลักใหม่และบันทึก",
    currentPassword: "รหัสผ่านปัจจุบัน",
    yesEnable: "ใช่เปิดใช้งาน",
    yesDisable: "ใช่ปิดการใช้งาน",
    downgradeOrgAdmin: "ดาวน์เกรด org ผู้ดูแลระบบ",
    popupMsgDowngradeOrgAdmin: "คุณแน่ใจหรือไม่ว่าต้องการลดระดับ <b>{name}</b>? เขาจะลดระดับลงในบทบาทที่คุณเลือกในพื้นที่ทำงานทั้งหมดที่เขาเป็นของ",
    selectRole: "เลือกบทบาทสำหรับผู้ใช้รายนี้",
    yesDowngrade: "ใช่ลดระดับ",
    alertFailedDowngradeToOrg: "ล้มเหลวในการปรับลดลงเป็น{userRole}",
    alertSuccessfullyDowngradeToOrg: "ปรับลดระดับสำเร็จไปยัง{userRole}",
    lastActivity: "เข้าสู่ระบบครั้งสุดท้าย",
    coverColor: "ปกสี",
    defaultLanguage: "ภาษาเริ่มต้น",
    blue: "สีฟ้า",
    red: "สีแดง",
    green: "สีเขียว",
    yellow: "เหลือง",
    purple: "สีม่วง",
    belongsToWorkspaces: "เป็นของพื้นที่ทำงาน",
    alertFailedSaveChanges: "ล้มเหลวในการบันทึกการเปลี่ยนแปลง",
    // TODO: added 04/02
    alertFailedRemoveUser: "ไม่สามารถลบผู้ใช้",
    // TODO: added 0608
    privateOrnot: "ส่วนตัว?",
    privateWorkspaceInfo: "พื้นที่ทำงานนี้จะเห็นได้โดยสมาชิกที่ได้รับมอบหมายเท่านั้น ...",
    noPermission: "คุณไม่ได้รับอนุญาตให้ดูสิ่งนี้",
    noPermissionContact: "หน้าคุณพยายามเข้าถึงมีการ จำกัด การเข้าถึง <br> หากคุณรู้สึกว่านี่เป็นความผิดพลาดให้ติดต่อผู้ดูแลระบบของคุณ",
    privateCannotChanged: "การตั้งค่าส่วนตัวไม่สามารถเปลี่ยนแปลงได้หลังจากสร้างพื้นที่ทำงาน",
    // Shared Account
    sharedAccount: "บัญชีที่ใช้ร่วมกัน",
    addManually: "เพิ่มด้วยตนเอง",
    addASharedAccount:"เพิ่มบัญชีที่ใช้ร่วมกัน",
    addASharedAccountMsg: "บัญชีที่ใช้ร่วมกันไม่สามารถใช้เพื่อดูเวิร์กโฟลว์เป็นรายบุคคลพวกเขาจะใช้สำหรับผู้ใช้ ID พนักงานในการสร้างบัญชีด้วยตนเองใน Deephow และติดตามผู้ใช้แต่ละคนในอุปกรณ์ที่ใช้ร่วมกัน ...",
    accountName: "ชื่อบัญชี",
    alertFailedAddShared: "ไม่สามารถเพิ่มบัญชีที่ใช้ร่วมกันได้",
    alertSuccessfullyCreatedShared: "สร้างบัญชีที่ใช้ร่วมกันสำเร็จ",
    alertSuccessfullyAddExistingShared: "เพิ่มบัญชีที่ใช้ร่วมกันที่มีอยู่สำเร็จ",
    accountId: "หมายเลขบัญชี",
    addSharedAccount:"เพิ่มบัญชีที่ใช้ร่วมกัน",
    sharedAccountId:"รหัสบัญชีที่ใช้ร่วมกัน",
    unselectedTeam: "ทีมที่ไม่ได้เลือก",
    // TODO: added 2022/06/22
    workflowReview: "รีวิวเวิร์กโฟลว์",
    workflowReviewDescription: "หากตัวเลือกนี้เปิดอยู่ผู้ดูแลพื้นที่ทำงานจะสามารถเลือกได้หากพื้นที่ทำงานของพวกเขาต้องการการตรวจสอบเวิร์กโฟลว์หรือไม่",
    preventReviewWorkflowsMessage: "คุณแน่ใจหรือไม่ว่าต้องการป้องกันผู้ใช้จากการตรวจสอบเวิร์กโฟลว์?",
    allowReviewWorkflowsMessage: "แน่ใจหรือว่าต้องการอนุญาตให้ผู้ใช้ตรวจสอบเวิร์กโฟลว์?",
    // TODO: added 2022/06/29
    addAsReviewer: "เพิ่มเป็นผู้ตรวจสอบ",
    removeAsReviewer: "ลบเป็นผู้ตรวจสอบ",
    addAsReviewerSuccessMessage: "เพิ่ม {user} เป็นผู้ตรวจสอบได้สำเร็จ",
    addAsReviewerFailedMessage: "ไม่สามารถเพิ่ม {user} ในฐานะผู้ตรวจสอบ",
    removeAsReviewerSuccessMessage: "ลบ {user} เป็นผู้ตรวจสอบได้สำเร็จ",
    removeAsReviewerFailedMessage: "ไม่สามารถลบ {user} เป็นผู้ตรวจสอบได้",
    // Shared Account Admin settings 07/27
    allowsSharedAccountMsg: "อนุญาตให้ผู้ดูแลระบบและพื้นที่ทำงานสร้างบัญชีที่ใช้ร่วมกัน ผู้ใช้จะสามารถลงนามด้วยตนเองโดยใช้บัญชี ID พนักงานของพวกเขาในอุปกรณ์ที่ใช้ร่วมกัน",
    disableSharedAccountMsg: "แน่ใจหรือว่าต้องการปิดการใช้งานบัญชีที่ใช้ร่วมกัน? <br> การเปลี่ยนแปลงจะเกิดขึ้นทันที",
    enableSharedAccountMsg: "แน่ใจหรือว่าต้องการเปิดใช้งานบัญชีที่ใช้ร่วมกัน? <br> การเปลี่ยนแปลงจะเกิดขึ้นทันที",
    alertEnableSharedAccountFailed: "ล้มเหลวในการเปิดใช้งานบัญชีที่ใช้ร่วมกัน",
    alertEnableSharedAccountSuccess: "เปิดใช้งานบัญชีที่ใช้ร่วมกันสำเร็จ",
    alertDisableSharedAccountFailed: "ไม่สามารถปิดการใช้งานบัญชีที่ใช้ร่วมกันได้",
    alertDisableSharedAccountSuccess: "ปิดใช้งานบัญชีที่ใช้ร่วมกันสำเร็จ",
    // QR Code & Watermark
    qrCodeAndWatermark: "รหัส QR และลายน้ำ",
    insertYourBrand: "แทรกแบรนด์ของคุณ",
    uploadYourLogo: "อัปโหลดโลโก้ของคุณ",
    uploadingYourLogo: "การอัปโหลดโลโก้ของคุณ",
    logoSuccessfullyUploaded: "อัพโหลดโลโก้สำเร็จ",
    errorInUpload: "ข้อผิดพลาดในการอัปโหลดของคุณ",
    errorInUploadMsg: "ประเภทไฟล์ไม่รองรับ โปรดเลือกประเภทไฟล์ต่อไปนี้: .png",
    uploadLogoMsg: "ลากและวาง <b>.png</b> ด้วยไฟล์สูงสุด <b>3 MB</b> หรือ <span>เรียกดู</span> จากคอมพิวเตอร์",
    uploadLogoReplaceMsg: "ลากหรือ <span>เรียกดู</span> เพื่อแทนที่ด้วยรูปภาพจากคอมพิวเตอร์หรือ <span id='duploadimage_removelogo'>ลบ</span> ภาพปัจจุบัน",
    simulation: "การจำลอง",
    simulationMsg: "ดูด้านล่างว่าโลโก้ของคุณจะถูกนำเสนอในวิดีโอลายน้ำและในรหัส QR อย่างไร",
    workflowTitle: "ชื่อเวิร์กโฟลว์",
    logoAlreadyUploaded: "อัปโหลดโลโก้แล้ว",
    alertLogoSuccessfullyRemoved: "ลบโลโก้สำเร็จ",
    alertLogoFailedRemoved: "ไม่สามารถลบโลโก้ได้",
    alertUserInOtherOrg: "ผู้ใช้นี้มีอยู่แล้วในองค์กรอื่น",
    // added 10/13 delete workspace
    deleteWorkspace: "ลบพื้นที่ทำงาน",
    popupMsgDeleteWorkspace: "แน่ใจหรือว่าคุณต้องการลบพื้นที่ทำงานนี้ ({name})?",
    alertDeleteGroupSuccess: "ลบพื้นที่ทำงานนี้สำเร็จ",
    alertDeleteGroupFailedWorkflows: "มีเวิร์กโฟลว์ที่กำหนดให้กับพื้นที่ทำงานนี้ดังนั้นคุณจึงไม่สามารถลบได้",
    alertDeleteGroupFailedSkills: "มีทักษะที่กำหนดให้กับพื้นที่ทำงานนี้ดังนั้นคุณจึงไม่สามารถลบได้",
    alertDeleteGroupFailedTeams: "มีทีมที่ได้รับมอบหมายให้กับพื้นที่ทำงานนี้ดังนั้นคุณจึงไม่สามารถลบได้",
    // added 11/3 delete team
    alertDeleteTeamSuccess: "ลบทีมนี้สำเร็จ",
    alertDeleteTeamFailedSkills: "ทีมนี้ไม่ได้รับอนุญาตให้ลบเนื่องจากทักษะที่มีอยู่ที่ได้รับมอบหมาย",
    alertDeleteTeamFailed: "ล้มเหลวในการลบทีมนี้",
    // Change Profile Name
    changeProfileName: "เปลี่ยนชื่อโปรไฟล์",
    allowsChangeProfileNameMsg: "ผู้ใช้จะได้รับอนุญาตให้เปลี่ยนชื่อในโปรไฟล์ของพวกเขา",
    disableChangeProfileNameMsg: "แน่ใจหรือว่าต้องการปิดใช้งานการเปลี่ยนชื่อโปรไฟล์? <br> การเปลี่ยนแปลงจะเกิดขึ้นทันที",
    enableChangeProfileNameMsg: "แน่ใจหรือว่าต้องการเปิดใช้งานชื่อโปรไฟล์เปลี่ยน? <br> การเปลี่ยนแปลงจะเกิดขึ้นทันที",
    alertEnableChangeProfileNameSuccess: "เปิดใช้งานการเปลี่ยนชื่อโปรไฟล์ได้สำเร็จ",
    alertEnableChangeProfileNameFailed: "ล้มเหลวในการเปิดใช้งานการเปลี่ยนชื่อโปรไฟล์",
    alertDisableChangeProfileNameSuccess: "ปิดใช้งานการเปลี่ยนชื่อโปรไฟล์ให้สำเร็จ",
    alertDisableChangeProfileNameFailed: "ล้มเหลวในการปิดใช้งานการเปลี่ยนแปลงชื่อโปรไฟล์",
    // Workflow Comments
    workflowComments: "ความคิดเห็นเวิร์กโฟลว์",
    allowsWorkflowCommentsMsg: "ผู้ใช้จะได้รับอนุญาตให้แสดงความคิดเห็นกับเวิร์กโฟลว์ที่เผยแพร่",
    disableWorkflowCommentsMsg: "แน่ใจหรือว่าต้องการปิดใช้งานความคิดเห็นเวิร์กโฟลว์? <br> การเปลี่ยนแปลงจะเกิดขึ้นทันที",
    enableWorkflowCommentsMsg: "แน่ใจหรือว่าต้องการเปิดใช้งานความคิดเห็นเวิร์กโฟลว์? <br> การเปลี่ยนแปลงจะเกิดขึ้นทันที",
    alertEnableWorkflowCommentsSuccess: "เปิดใช้งานความคิดเห็นเวิร์กโฟลว์สำเร็จ",
    alertEnableWorkflowCommentsFailed: "ล้มเหลวในการเปิดใช้งานความคิดเห็นเวิร์กโฟลว์",
    alertDisableWorkflowCommentsSuccess: "ปิดการใช้งานความคิดเห็นเวิร์กโฟลว์สำเร็จ",
    alertDisableWorkflowCommentsFailed: "ล้มเหลวในการปิดใช้งานความคิดเห็นเวิร์กโฟลว์",
    // Workspace Groups
    workspaceGroupsNum: "Workspace Group | Group | กลุ่มพื้นที่ทำงาน",
    searchWorkspaceGroups: "ค้นหากลุ่มพื้นที่ทำงาน ...",
    noWorkspaceGroupsYet: "ยังไม่มีกลุ่มพื้นที่ทำงานที่สร้างขึ้น",
    createWorkspaceGroup: "สร้างกลุ่มพื้นที่ทำงาน",
    editWorkspaceGroup: "แก้ไข Workspace Group",
    workspaceGroupName: "ชื่อกลุ่มพื้นที่ทำงาน",
    editWorkspace: "แก้ไขพื้นที่ทำงาน",
    userNum: "ผู้ใช้งาน | ผู้ใช้งาน | ผู้ใช้งาน",
    teamNum: "ทีม | ทีม | ทีม",
    defaultLanguage: "ภาษาเริ่มต้น",
    deleteWorkspaceGroup: "ลบกลุ่มพื้นที่ทำงาน",
    alertCreateWorkspaceGroupSuccess: "สร้างกลุ่มพื้นที่ทำงานสำเร็จ",
    alertWorkspaceGroupNameAlreadyExists: "ชื่อกลุ่มพื้นที่ทำงานนี้อยู่ในระบบแล้ว",
    alertEditWorkspaceGroupSuccess: "แก้ไขพื้นที่เวิร์กสเปซได้สำเร็จ",
    alertEditWorkspaceGroupFailed: "ไม่สามารถแก้ไขกลุ่มพื้นที่ทำงานได้",
    alertDeleteWorkspaceGroupSuccess: "ลบกลุ่มพื้นที่ทำงานนี้สำเร็จ",
    alertDeleteWorkspaceGroupFailed: "ล้มเหลวในการลบกลุ่มพื้นที่ทำงานนี้",
    alertWorkspaceAssignedDeleteGroupFailed: "มีพื้นที่ทำงานที่กำหนดให้กับกลุ่มนี้ดังนั้นคุณจึงไม่สามารถลบได้",
    deleteWorkspaceGroupMessage: "คุณแน่ใจหรือไม่ว่าต้องการลบกลุ่มพื้นที่ทำงานนี้ (<b>{name}</b>)?",
    alertWorkspaceNameAlreadyExists: "ชื่อพื้นที่ทำงานมีอยู่แล้ว",
    limitIDLoginToSharedAccount: "จำกัด ID พนักงานเข้าสู่ระบบไปยังอุปกรณ์ที่ใช้ร่วมกันเท่านั้น",
    allowEmployeesCreateIDAccount: "อนุญาตให้พนักงานสร้างบัญชีของตัวเองในอุปกรณ์ที่ใช้ร่วมกัน",
    disableDHLoginMsg: "คุณแน่ใจหรือไม่ว่าต้องการ จำกัด ID พนักงานเข้าสู่ระบบไปยังอุปกรณ์ที่ใช้ร่วมกันเท่านั้น?",
    enableDHLoginMsg: "แน่ใจหรือว่าคุณไม่ต้องการ จำกัด ID พนักงานเข้าสู่ระบบไปยังอุปกรณ์ที่ใช้ร่วมกันเท่านั้น?",
    enableEmployeeCreationMsg: "คุณแน่ใจว่าต้องการอนุญาตให้พนักงานสร้างบัญชีของตัวเองในอุปกรณ์ที่ใช้ร่วมกันหรือไม่?",
    disableEmployeeCreationMsg: "คุณแน่ใจหรือไม่ว่าคุณไม่ต้องการอนุญาตให้พนักงานสร้างบัญชีของตัวเองในอุปกรณ์ที่ใช้ร่วมกัน?",
    alertSharedDeviceSuccessfullyChanged: "การตั้งค่าอุปกรณ์ที่ใช้ร่วมกันเปลี่ยนสำเร็จ",
    alertSharedDeviceFailedChanged: "ล้มเหลวในการเปลี่ยนการตั้งค่าอุปกรณ์ที่ใช้ร่วมกัน",
    redefinePassword: "นิยามรหัสผ่านใหม่",
    redefinePasswordMsg: "โปรดเลือกรหัสผ่านใหม่สำหรับบัญชีนี้:",
    redefine: "นิยามใหม่",
    alertSuccessfullyResetPassword: "รีเซ็ตรหัสผ่านสำเร็จ",
    ipRestriction: "การ จำกัด IP",
    allowIpRestrictionMessage: "หากคุณเปิดฟีเจอร์นี้มีเพียง IP ที่ได้รับการอนุญาตเพียงอย่างเดียวเท่านั้นที่จะสามารถเข้าถึง DeepHow ได้",
    enableIpRestrictionMessage: "แน่ใจหรือว่าคุณต้องการเปิดใช้งานการ จำกัด IP? <br> การเปลี่ยนแปลงจะเกิดขึ้นทันที",
    disableIpRestrictionMessage: "คุณแน่ใจหรือไม่ว่าต้องการปิดใช้งานการ จำกัด IP? <br> การเปลี่ยนแปลงจะเกิดขึ้นทันที",
    whitelistedIps: "IP ของ whitelisted",
    whitelistedIpDescription: "คำอธิบาย",
    whitelistedIpsOrRange: "IPS หรือช่วง",
    whitelistedIpSourceFilter: "ตัวกรองต้นทาง",
    searchForWhitelistedIp: "ค้นหา IP ที่ได้รับการอนุญาต ...",
    addWhitelistedIpRule: "เพิ่มกฎ",
    editWhitelistedRule: "แก้ไขกฎ",
    noWhitelistedIpCreatedYet: "ยังไม่มี IP ที่ได้รับการอนุญาต",
    addAnotherIp: "เพิ่ม IP อื่น",
    deleteWhitelistedIp: "ลบ IP ที่อนุญาต",
    popupMessageDeleteWhitelistedIp: "แน่ใจหรือว่าต้องการลบ IP ที่ได้รับการอนุญาต",
    alertEnableIpRestrictionSuccess: "เปิดใช้งานการ จำกัด IP ที่ประสบความสำเร็จ",
    alertEnableIpRestrictionFailed: "ไม่สามารถเปิดใช้งานการ จำกัด IP ได้",
    alertDisableIpRestrictionSuccess: "ปิดใช้งานการ จำกัด IP ที่ประสบความสำเร็จ",
    alertDisableIpRestrictionFailed: "ไม่สามารถปิดการ จำกัด IP ได้",
    alertCreateWhitelistedIpSuccess: "สร้าง IP ที่ได้รับการอนุญาตให้สำเร็จ",
    alertCreateWhitelistedIpFailed: "ไม่สามารถสร้าง IP ที่ได้รับการอนุญาต",
    alertUpdateWhitelistedIpSuccess: "อัปเดต IP Whitelisted สำเร็จแล้ว",
    alertUpdateWhitelistedIpFailed: "ไม่สามารถอัปเดต IP Whitelisted",
    alertWhitelistedIpTypeError: "ประเภทต้นทางไม่ตรงกับกฎ IP",
    alertWhitelistedCIDRError: "CIDR ไม่ถูกต้องหรือข้อผิดพลาดที่ไม่คาดคิด",
    alertDeleteWhitelistedIpSuccess: "ลบ IP ที่ได้รับการอนุญาตให้ใช้งานสำเร็จ",
    alertDeleteWhitelistedIpFailed: "ไม่สามารถลบ IP ที่อนุญาตให้ใช้งานได้",
  },
  builder: {
    publish: "เผยแพร่",
    reviewTranscription: "การถอดรหัส",
    viewer: "ผู้ชม",
    links: "ลิงค์",
    trim: "ตัดแต่ง",
    translation: "การแปล",
    share: "แบ่งปัน",
    addDiagramMsg: "ลากหรือคลิกเพื่อเพิ่มแผนภาพ",
    onlyImageAccepted: "รับเฉพาะไฟล์ภาพเท่านั้น",
    unsupportedImageFormats: "รูปแบบภาพที่ไม่รองรับ: SVG, TIFF, XBM",
    addDiagram: "เพิ่มแผนภาพ",
    start: "เริ่ม",
    end: "จบ",
    resetToOriginal: "รีเซ็ตเป็นสภาพเดิม",
    trimStartMsg: "เลือกตำแหน่งที่คุณต้องการให้วิดีโอของคุณเริ่มต้นและเสร็จสิ้น",
    trimProcessingMsg: "การตัดแต่งไม่พร้อมใช้งานในขณะที่การประมวลผลเวิร์กโฟลว์กำลังดำเนินการอยู่ <br>โปรดกลับมาใหม่ในภายหลัง",
    cannotTrimLessThan3s: "คุณไม่สามารถตัดวิดีโอสั้นกว่า 3 วินาที",
    trimVideo: "ตัดแต่งวิดีโอ",
    trimStep: "ขั้นตอนการตัดแต่ง",
    editWorkflow: "แก้ไขเวิร์กโฟลว์",
    popupBtnEdit: "ใช่ แก้ไข",
    popupBtnPublish: "ใช่ เผยแพร่",
    popupBtnUnpublish: "ใช่ ยกเลิกการเผยแพร่",
    popupMsgTrimConfirmation: "คุณแน่ใจหรือว่าต้องการตัดแต่ง",
    popupMsgTrimWithSaveConfirmation: "คุณมีการเปลี่ยนแปลงที่ยังไม่ได้บันทึกในเวิร์กโฟลว์ของคุณ เราจะบันทึกการเปลี่ยนแปลงให้คุณ",
    popupBtnTrim: "ใช่ ตัดแต่ง",
    popupMsgTranscoding: "กำลังแปลงรหัส",
    popupMsgTrimBackLater: "คุณสามารถดำเนินการแก้ไขเวิร์กโฟลว์ต่อได้หลังจากการแปลงรหัสเสร็จสิ้น",
    popupMsgTrimSuccess: "ขั้นตอนของคุณถูกตัดแต่งเรียบร้อยแล้ว",
    done: "เสร็จแล้ว",
    publishWorkflow: "เผยแพร่เวิร์กโฟลว์",
    popupMsgEditWorkflow: "หากคุณเข้าสู่โหมดแก้ไข เวิร์กโฟลว์ของคุณจะไม่ได้รับการเผยแพร่โดยอัตโนมัติ คุณแน่ใจหรือว่าต้องการดำเนินการต่อ",
    popupMsgUnpublishWorkflow: "คุณจะยกเลิกการเผยแพร่เวิร์กโฟลว์ของคุณ คุณแน่ใจหรือว่าต้องการทำเช่นนั้น",
    alertSaveFailed: "บันทึกเวิร์กโฟลว์ล้มเหลว",
    alertTrimResetSuccess: "รีเซ็ตขั้นตอนสำเร็จ",
    alertTrimResetFailed: "ไม่สามารถรีเซ็ตขั้นตอนได้",
    alertTrimCanNotReset: "ขั้นตอนนี้ไม่สามารถรีเซ็ตได้",
    resetStep: "รีเซ็ตขั้นตอน",
    popupMsgResetStep: "คุณแน่ใจหรือว่าต้องการรีเซ็ต แผนภาพเพิ่มในขั้นตอนนี้จะหายไป",
    popupBtnReset: "ใช่ รีเซ็ต",
    hintSearchBuilder: "ค้นหาเวิร์กโฟลว์และคำหลัก",
    stepsSelected: "ที่ถูกเลือก",
    clear: "ชัดเจน",
    insert: "ใส่",
    reset: "รีเซ็ต",
    resetWorkflow: "รีเซ็ตเวิร์กโฟลว์",
    resetWorkflowAlert: "คุณจะสูญเสียงานทั้งหมดที่ทำในเวิร์กโฟลว์นี้และเริ่มต้นจากจุดเริ่มต้นอีกครั้งคุณแน่ใจหรือไม่ว่าต้องการดำเนินการต่อ",
    resetWorkflowKeepCopy: "เก็บสำเนาเวิร์กโฟลว์นี้",
    // Transcript / Translation
    closeTranscript: "ปิดการถอดรหัส",
    popupMsgCloseTranscript: "คุณมีการเปลี่ยนแปลงที่ยังไม่ได้บันทึกในการถอดรหัสของคุณ หากคุณดำเนินการต่อ คุณจะ <b>สูญเสียการเปลี่ยนแปลงทั้งหมด</b> คุณแน่ใจหรือไม่",
    stepNoTranscript: "ขั้นตอนที่ไม่มีการถอดรหัส",
    originalTranscript: "การถอดรหัสดั้งเดิม",
    addLanguageTooltip: "คลิกที่นี่เพื่อเพิ่มภาษาใหม่ให้กับวิดีโอของคุณ",
    closeTranslation: "ปิดการแปล",
    popupMsgCloseTranslation: "คุณมีการเปลี่ยนแปลงการแปลของคุณ หากคุณดำเนินการต่อคุณจะ <b>สูญเสียการเปลี่ยนแปลงทั้งหมด</b> คุณแน่ใจไหม?",
    switchTranslation: "สลับการแปล",
    popupBtnNoSwitch: "ไม่ เพียงแค่สลับ",
    popupBtnYesSwitch: "ใช่ บันทึกและสลับ",
    createAudio: "สร้างเสียง",
    addNewLanguageTooltip: "คลิกที่นี่เพื่อเพิ่มภาษาใหม่ในวิดีโอของคุณ",
    popupMsgCreateAudio: "คุณแน่ใจหรือว่าต้องการสร้างการแปลเสียงให้กับเวิร์กโฟลว์ของคุณ เราขอแนะนำให้คุณทำเพียงครั้งเดียว เนื่องจากสเตฟานีจะประมวลผลการถอดรหัสทั้งหมดของคุณ ",
    popupBtnYesCreate: "ใช่ สร้าง",
    alertAudioBeingCreated: "เสียงการแปลของคุณกำลังถูกสร้างขึ้น",
    alertTranscriptionSaved: "บันทึกการถอดรหัสสำเร็จ",
    alertTranscriptionSaveFailed: "บันทึกการถอดรหัสล้มเหลว",
    alertTranslationsSaved: "บันทึกการแปลสำเร็จ",
    alertTranslationsSaveFailed: "ไม่สามารถบันทึกการแปล",
    alertAudioCreated: "สร้างเสียงสำเร็จ",
    alertAudioFailed: "ไม่สามารถสร้างเสียงการแปลของคุณ",
    alertNoAudioGenerated: "ไม่มีการสร้างเสียง เพราะไม่มีการอัพเดทในการแปล",
    alertErrorCreateTranscription: "เกิดข้อผิดพลาดในการถอดรหัส",
    //Share workflow
    shareWorkflow: "แชร์เวิร์กโฟลว์",
    fullWorkflow: "เวิร์กโฟลว์เต็มรูปแบบ",
    specificSteps: "ขั้นตอนที่เฉพาะเจาะจง",
    chooseShareType: "เลือกประเภทการแชร์",
    qrCode: "คิวอาร์โค้ด",
    link: "ลิงค์",
    embedLink: "ฝังลิ้งค์ลงไป",
    privateWorkflow: "เวิร์กโฟลว์ส่วนตัว",
    publicWorkflow: "เวิร์กโฟลว์สาธารณะ",
    close: "ปิด I",
    saveQRCode: "บันทึกคิวอาร์โค้ด",
    download: "ดาวน์โหลด",
    copyLink: "คัดลอกลิงค์",
    copied: "คัดลอกแล้ว",
    downloading: "กำลังดาวน์โหลด",
    videoPrivacy: "ความเป็นส่วนตัวของวิดีโอ",
    popupMsgVideoPrivacy: "เมื่อคุณเปลี่ยนเวิร์กโฟลว์ให้เป็นสาธารณะ ทุกคนสามารถเข้าถึงได้ แม้ไม่มีบัญชี DeepHow คุณแน่ใจหรือไม่",
    includeSubtitles: "รวมคำบรรยาย",
    noSubtitles: "ไม่มีคำบรรยาย",
    selectLanguage: "เลือกภาษา",
    workflowReady: "เวิร์กโฟลว์พร้อม",
    prepareToDownload: "เตรียมที่จะดาวน์โหลด",
    processingDownloadVideoMsg: "อย่าปิดหน้านี้ <br /> อีกไม่กี่นาทีไฟล์ของคุณจะพร้อมสำหรับการดาวน์โหลด",
    processingWorkflow: "ประมวลผลเวิร์กโฟลว์",
    openAnotherTabMsg: "ระหว่างการรอคอย คุณสามารถไปที่ DeepHow ได้ในแท็บอื่น",
    openNewTab: "เปิดแท็บใหม่",
    alertErrorPreparingDownload: "เกิดข้อผิดพลาดขณะเตรียมการดาวน์โหลดของคุณ โปรดลองอีกครั้งหรือติดต่อ DeepHow เพื่อขอความช่วยเหลือ",
    hide: "ซ่อน",
    addNewLink: "เพิ่มลิงค์ใหม่",
    clickToAddLinks: "คลิกเพื่อเพิ่มลิงค์ในขั้นตอนนี้",
    addALink: "เพิ่มลิงค์",
    editLink: "แก้ไขลิงค์",
    addLinkAddress: "ป้อนที่อยู่ด้านล่างเพื่อเพิ่มลิงค์ในขั้นตอนนี้",
    addLinkLable: "คุณต้องการเพิ่มฉลากหรือไม่",
    clickOpenOfficialDocument: "เช่น: คลิกเพื่อเปิดเอกสารทางการ",
    //settings /categories
    settings: "การตั้งค่า",
    categories: "หมวดหมู่",
    noCategories: "คุณยังไม่ได้สร้างหมวดหมู่ใดๆ",
    selectCategoriesMessage: "เลือกอย่างน้อยหนึ่งหมวดหมู่เพื่อให้ผู้ใช้งานสามารถกรองเนื้อหาได้ง่ายขึ้น",
    closeSettings: "ปิดการตั้งค่า",
    popupMsgCloseSettings: "คุณมีการเปลี่ยนแปลงที่ยังไม่ได้บันทึก หากคุณดำเนินการต่อ คุณ <b>จะสูญเสียการเปลี่ยนแปลงทั้งหมด</b> คุณแน่ใจหรือไม่",
    workflowCategories: "หมวดหมู่เวิร์กโฟลว์",
    popupBtnAssignCategoriesAndPublish: "กำหนดหมวดหมู่และเผยแพร่",
    popupBtnSkipAndPublish: "ข้ามขั้นตอนนี้และเผยแพร่ต่อไป",
    popupMsgworkflowCategories: "ก่อนที่คุณจะเผยแพร่ ให้เลือกหมวดหมู่สำหรับเวิร์กโฟลว์ของคุณเพื่อให้ผู้ใช้งานค้นหาได้ง่ายขึ้น",
    alertCategoriesSaved: "บันทึกหมวดหมู่สำเร็จ",
    alertCategoriesSaveFailed: "ไม่สามารถบันทึกหมวดหมู่ได้",
    searchForCategoryName: "ค้นหา {name} ...",
    loadingCategories: "โหลดหมวดหมู่ ...",
    alertFailedDownloadVideo: "ไม่สามารถดาวน์โหลดวิดีโอ",
    // Workflow Cover
    workflowCover: "ปกเวิร์กโฟลว์",
    selectFromTheVideo: "เลือกจากวิดีโอ",
    browseForAFile: "เรียกดูไฟล์",
    dropFileMessage: "วางไฟล์ของคุณหรือคลิกที่นี่เพื่อเรียกดู",
    selectedImage: "เลือกรูปภาพ",
    noImageSelected: "ไม่มีการเลือกภาพ",
    alertSuccessfullyUpdatedCover: "อัพเดทปกเวิร์กโฟลว์เรียบร้อยแล้ว",
    alertFailedUpdatedCover: "ไม่สามารถอัพเดทปกเวิร์กโฟลว์",
    selectAnotherImage: "เลือกภาพอื่น",
    alertErrorStepLostTranscription: "ขณะนี้คุณมีขั้นตอนที่ยังไม่ได้รับการแปล โปรดแปลก่อนจึงจะสามารถดาวน์โหลดเวิร์กโฟลว์ของคุณได้",
    annotation: "คำอธิบายประกอบ",
    allNotes: "หมายเหตุทั้งหมด",
    noNotesAddedYet: "ยังไม่มีการเพิ่มบันทึก",
    popupMsgEnterTrim: "ขั้นตอนของคุณมีคำอธิบายประกอบ หากคุณต้องการตัดวิดีโอคำอธิบายประกอบเหล่านี้จะหายไป",
    popupMsgEnterErase: "ขั้นตอนของคุณมีคำอธิบายประกอบ หากคุณต้องการลบวิดีโอคำอธิบายประกอบเหล่านี้จะหายไป",
    popupMsgEnterAnnotation: "โปรดตรวจสอบให้แน่ใจว่าได้ตัดหรือลบวิดีโอก่อนเพิ่มคำอธิบายประกอบใด ๆ",
    workingOn: "การทำงาน",
    eraseStep: "ลบขั้นตอน",
    popupMsgEraseSuccess: "ขั้นตอนของคุณถูกลบสำเร็จ!",
    popupBtnYesErase: "ใช่ลบ",
    popupMsgEraseConfirmation: "แน่ใจหรือว่าต้องการลบ?",
    eraseProcessingMsg: "การลบไม่สามารถใช้ได้ในขณะที่การประมวลผลเวิร์กโฟลว์กำลังดำเนินการอยู่ <br> โปรดกลับมาในภายหลัง",
    translateOnlyTitlesBtn: "แปลชื่อและชื่อขั้นตอน",
    untitled: "ไม่มีชื่อ",
    hintNoEmptyTitle: "ชื่อไม่สามารถว่างเปล่า",
    popupMsgSwitchTranslation: "คุณมีการเปลี่ยนแปลงที่ไม่ได้บันทึกในการแปล<b>{lang}</b>คุณต้องการบันทึกไว้ก่อนที่จะสลับหรือไม่?",
    alertNoEmptyContent: "ชื่อหรือประโยคไม่สามารถว่างเปล่า กรุณากรอกเนื้อหา",
    PDF: "ไฟล์ PDF",
    PDFSizeLimit: "ขนาดไฟล์ PDF จำกัด อยู่ที่ 50 MB",
    addPDF: "เพิ่ม PDF",
    onlyPDFAccepted: "เท่านั้นที่ได้รับไฟล์ PDF",
    preview: "แสดงตัวอย่าง",
    // Send to reviewer
    sendToReviewer: "ส่งไปยังผู้ตรวจสอบ",
    alertTitleSelectReviewers: "เลือกผู้ใช้เพื่อตรวจสอบเวิร์กโฟลว์ของคุณ",
    alertSendToReviewSuccess: "เวิร์กโฟลว์ของคุณถูกส่งไปตรวจสอบสำเร็จ",
    alertSendToReviewFailed: "ไม่สามารถส่งไปตรวจสอบได้",
    subtitlesSize: "ขนาดคำบรรยาย",
    small: "เล็ก",
    medium:"ปานกลาง",
    big: "ใหญ่",
    yourVideoReadyToDownload: "วิดีโอของคุณพร้อมที่จะดาวน์โหลด",
    yourVideoWasSuccessfullyDownloaded: "เวิร์กโฟลว์ของคุณถูกดาวน์โหลดเรียบร้อยแล้ว",
    notAvailable: "ไม่สามารถใช้ได้",
    downloadFileFormat: "รูปแบบ",
    selectDownloadWorkflowQuality: "เลือกคุณภาพวิดีโอ",
    downloadSizeLimitedMsg: "ดาวน์โหลด SCORM ที่ {resolution} รองรับเวิร์กโฟลว์สูงสุด {duration} นาที",
    workflowTotalDurationMsg: "เวิร์กโฟลว์ระยะเวลารวม: {minute} นาที {second} วินาที Workflow",
    // Export Compliance Wire
    export: "ส่งออก",
    code: "รหัส",
    organization: "องค์กร",
    approvalDate: "วันที่อนุมัติ",
    processing: "กำลังประมวลผล",
    workflowShouldBePublic: "เวิร์กโฟลว์ควรเป็นสาธารณะ",
    alertExportComplianceWireSuccess: "เอกสารควบคุมการส่งออกสำเร็จ รหัส: {code}",
    alertExportComplianceWireExists: "เอกสารควบคุมมีอยู่แล้ว รหัส: {code}",
    alertExportComplianceWireFailed: "มีบางอย่างผิดปกติในขณะที่ส่งออกเอกสารการควบคุม",
    confirmedPublished: "คุณแน่ใจหรือไม่ว่าคุณต้องการเผยแพร่เวิร์กโฟลว์นี้ <br> ถ้าคุณทำสิ่งนี้ทุกคนจะสามารถดูได้",
    versionDescription: "เวอร์ชัน {num} คำอธิบาย (ไม่บังคับ):",
    versionIsDuplicated: "เวอร์ชันซ้ำกัน",
  },
  editor: {
    closeWorkflow: "ปิดเวิร์กโฟลว์",
    popupMsgCloseWorkflow: "คุณมีการเปลี่ยนแปลงที่ยังไม่ได้บันทึกในเวิร์กโฟลว์ของคุณ หากคุณดำเนินการต่อ คุณจะ <b>สูญเสียการเปลี่ยนแปลงทั้งหมด</b> คุณแน่ใจหรือไม่",
    popupBtnClose: "ใช่ ปิด",
    processWorkflow: "ประมวลผลเวิร์กโฟลว์",
    popupMsgProcessWorkflow: "ยอดเยี่ยม! ดูเหมือนคุณเสร็จสิ้นการตรวจสอบและต้องการให้สเตฟานีประมวลผลแล้ว <br><br>คุณจะ <b>ไม่สามารถ</b> กลับไปที่หน้าจอรีวิวนี้ คุณพร้อมหรือไม่",
    popupBtnProcess: "ใช่ ประมวลผล",
    alertIsSaving: "กำลังบันทึกอยู่",
    alertNoEmptySentence: "ประโยคไม่สามารถเว้นว่างได้ โปรดป้อนหรือลบประโยค แล้วบันทึกอีกครั้ง",
    alertIsSaved: "การเปลี่ยนแปลงของคุณถูกบันทึกไว้",
    alertWasUnpublished: "เวิร์กโฟลว์ของคุณไม่ได้รับเผยแพร่",
    alertWasPublished: "เวิร์กโฟลว์ของคุณได้รับการตีพิมพ์เรียบร้อยแล้ว",
    alertNoMoreSteps: "คุณไม่สามารถเพิ่มขั้นตอนอีกต่อไปที่นี่",
    alertNoEmptyTitle: "หัวข้อต้องไม่ว่างเปล่า โปรดป้อนหัวข้อแล้วบันทึกอีกครั้ง",
    scrollToVoice: "เลื่อนไปที่เสียง",
    noAudioMsg: "เราไม่พบเสียงใดๆ ที่จะถอดความในเวิร์กโฟลว์ของคุณ ...",
    youWillDeleteStep: "คุณจะลบขั้นตอน",
    areYouSure: "แน่ใจหรือไม่",
    areYouSureDelete: "คุณแน่ใจหรือว่าต้องการลบ",
    replace: "เปลี่ยน",
    keyword: "คำสำคัญ",
    find: "หา",
    replaceWith: "เปลี่ยนด้วย",
    replaceAll: "เปลี่ยนทั้งหมด",
    replaceAllMsg: "คุณจะเปลี่ยนคำเหล่านี้ทั้งหมด",
    of: "ของ",
    msgToSteps: "วิเคราะห์เวิร์กโฟลว์ของคุณ ... <br>โปรดรอสักครู่ ",
    msgToEditor1: "ตอนนี้ให้ตรวจสอบเวิร์กโฟลว์ก่อนเผยแพร่",
    msgToEditor2: "ฉันจะแนะนำคุณในแต่ละขั้นตอน ไม่ต้องกังวล",
    msgToBuilder1: "ใกล้จะถึงแล้ว ขอเวลาหน่อย",
    msgToBuilder2: "ฉันกำลังสร้างขั้นตอนสำหรับเวิร์กโฟลว์ของคุณ",
    newStep: "ขั้นตอนใหม่",
    hintNoEmptySentence: "ประโยคต้องไม่ว่างเปล่า",
    hintNoEmptyTitle: "(หัวข้อต้องไม่ว่างเปล่า)",
    alertNoTranscription: "ไม่มีการถอดรหัสสำหรับเวิร์กโฟลว์",
    alertEmptyingTranscription: "คำเตือน! คุณกำลังล้างการถอดรหัส คุณไม่สามารถทำได้",
    alertStepCorrupted: "ขั้นตอนที่ {count} เสียหาย โปรดลบออกก่อนการประมวลผล",
    // Editor 2.0
    trim: "ตัดต่อ",
    erase: "ลบ",
    voiceOver: "การพากย์เสียง",
    videoEditing: "แก้ไขวีดีโอ",
    segmentation: "การแบ่งส่วน",
    popupMsgRestToOriginal: "คุณแน่ใจหรือไม่ว่าต้องการรีเซ็ตเป็นวิดีโอต้นฉบับ การเปลี่ยนแปลงที่เพิ่มทั้งหมดจะสูญหายไป",
    trimHintMessage: "ใช้ลูกศรเพื่อเลือกพื้นที่ที่คุณต้องการตัดแต่ง",
    eraseHintMessage: "ใช้ลูกศรเพื่อเลือกพื้นที่ที่คุณต้องการลบ",
    voiceOverHintMessage: "เลือกพื้นที่ที่คุณต้องการส่งเสียง ...",
    voiceOverStartHint: "กดที่นี่เพื่อเริ่มบันทึก",
    voiceOverStart: "บันทึก",
    voiceOverSave: "บันทึกบันทึก",
    voiceOverCancel: "ละทิ้ง",
    voiceOverCancelPopupMsg: "คุณแน่ใจหรือว่าต้องการลบการบันทึกของคุณ",
    voiceOverPause: "หยุด",
    voiceOverResume: "ประวัติย่อ",
    unableToGetAudioInput: "ไม่พบอุปกรณ์อินพุตเสียง",
    restart: "เริ่มต้นใหม่",
    editVideo: "แก้ไขวีดีโอ",
    popupRequestTranscription: "ไม่สามารถค้นหาการถอดความสำหรับเวิร์กโฟลว์ มาสร้างใหม่กัน",
    // TODO 2023-11-30 added by andy
    popupMsgEditVideoWarning: "การเปลี่ยนแปลงทั้งหมดที่ทำในการถอดความจะหายไปหากคุณแก้ไขวิดีโอของคุณคุณแน่ใจหรือว่าต้องการแก้ไข?",// reserved until new wording is confirmed
    popupMsgEditVideoWarning2WithoutTTS: "การเปลี่ยนแปลงทั้งหมดที่ทำในขั้นตอนการถอดความจะสูญหายหากคุณดำเนินการแก้ไขขั้นตอนการทำงานของคุณต่อไป การแก้ไข เช่น การตัดต้องเสร็จสิ้นก่อนที่จะดำเนินการพากย์ต่อไป เลือกยืนยันเพื่อดำเนินการต่อหรือยกเลิกเพื่อกลับไป",
    popupMsgEditVideoWarning2WithTTS: "การเปลี่ยนแปลงทั้งหมดที่ทำในขั้นตอนการถอดความจะสูญหายหากคุณดำเนินการแก้ไขขั้นตอนการทำงานของคุณต่อไป การแก้ไข เช่น การตัดต้องเสร็จสิ้นก่อนที่จะดำเนินการพากย์ต่อไป หรือบันทึกเสียงจากข้อความ (TTS) เลือกยืนยันเพื่อดำเนินการต่อหรือยกเลิกเพื่อกลับไป",
    popupMsgReTranscriptionWarning: "หมายเหตุ: หากคุณได้ทำการแก้ไขวิดีโอใด ๆ (เช่น ตัดต่อ/ลบ/การพากย์เสียง) คุณจะไม่สามารถใช้ฟังก์ชั่นการถอดความอีกครั้ง",
    popupMsgReTranscriptionWarningMultiEditing: "หมายเหตุ: หากคุณดำเนินการแก้ไขวิดีโอใด ๆ (เช่น ตัด/พากย์เสียง) คุณจะไม่สามารถใช้ฟังก์ชันการถอดความใหม่ได้",
    popupMsgReTranscriptionWarningTextToSpeech: "หมายเหตุ: หากคุณดำเนินการแก้ไขวิดีโอใด ๆ (เช่น ตัด/พากย์เสียง/ส่งข้อความถึงการพูด) คุณจะไม่สามารถใช้ฟังก์ชันการถอดความใหม่ได้",
    popupMsgReTranscriptionWarningTextToSpeech2: "Note: Any editing actions performed (cut, voice-over, text-to-speech) you will no longer be able to change the transcription language.",
    rotate: "หมุน",
    rotateLeft: "หมุนซ้าย",
    rotateRight: "หมุนขวา",
    goBack: "กลับ",
    goBackToEditorHome: "กลับไปที่บ้านบรรณาธิการ",
    zoom: "ซูม",
    // transcription new features
    transcriptionCopySuccess: "การถอดความคัดลอกไปยังคลิปบอร์ดของคุณ",
    keyTerms: "เงื่อนไขสำคัญ",
    AILabs: "AI Labs",
    copyTranscription: "คัดลอกการถอดความ",
    changeLanguage: "เปลี่ยนภาษา",
    copy: "สำเนา",
    changeLanguageTitle: "โปรดเลือกภาษาใหม่สำหรับการถอดความของคุณ",
    changeLanguageSubTitle: "สเตฟานีจะต้องประมวลผลการถอดความทั้งหมดของคุณอีกครั้ง",
    reProcessingYourVideo: "สเตฟานีกำลังประมวลผลวิดีโอของคุณอีกครั้ง ...",
    goBackToEditor: "คุณสามารถรอที่นี่หรือกลับไปที่บรรณาธิการเพื่อทำงานต่อไป!",
    // keyterms: added on 08/09/2022
    addTerm: "เพิ่มคำศัพท์",
    removeTerm: "ลบเทอม",
    termsFound: "<span>0</span> คำพบ | พบคำ <span>1</span> | พบคำศัพท์ <span>{count}</span>",
    loadingTerms: "การโหลดเงื่อนไข",
    termsInfo: "คำศัพท์อาจมีประโยชน์มากเมื่อค้นหาเนื้อหาเฉพาะในเวิร์กโฟลว์",
    // Editor 3.0
    cut: "ตัด",
    tabsWarningMessages: "คำเตือน! หากคุณออกจากหน้านี้โดยไม่บันทึกการเปลี่ยนแปลงทั้งหมดที่คุณทำจะหายไป",
    versionHistory: "ประวัติเวอร์ชัน",
    seeMore:"ดูเพิ่มเติม...",
    seeLess:"ดูน้อยลง ...",
    workflowCreated:"เวิร์กโฟลว์สร้างขึ้น",
    createdBy: "สร้างโดย {name}",
    publishedBy: "เผยแพร่โดย {name}",
    lastUpdateBy: "อัปเดตล่าสุดโดย {name}",
    emptyTitle: "ยังไม่มีเวอร์ชัน!",
    emptyContent: "เวิร์กโฟลว์นี้ยังไม่ได้รับการเผยแพร่ทันทีที่คุณเผยแพร่เป็นครั้งแรกที่คุณจะเริ่มเห็นเวอร์ชันที่นี่",
    // added by andy 22/08/2023
    textToSpeech: "ส่งข้อความถึงการพูด",
    play: "เล่น",
    retry: "ลองอีกครั้ง",
    generate: "สร้าง",
    generating: "การสร้าง",
    addBlock: "เพิ่มบล็อก",
    addNewBlock: "เพิ่มบล็อกใหม่",
    audioSpeed: "{value} x ความเร็ว",
    audioInputPlaceholder: "แทรกข้อความ ...",
    alertNeetToGenerateAllBeforeSave: "คุณต้องสร้างบล็อก TTS ทั้งหมดก่อนที่จะบันทึกเวิร์กโฟลว์ของคุณ",
    alertNeetToSaveBeforeLeave: "คุณมีการเปลี่ยนแปลงที่ไม่ได้บันทึกใน TTS ของคุณคุณต้องการบันทึกก่อนออกหรือไม่?",
    alertExceedVideoDuration: "เวลารวมของบล็อกเสียงเกินระยะเวลาวิดีโอดั้งเดิม โปรดปรับ",
    alertTextToSpeechCreatingTitle: "สเตฟานีกำลังสร้างเสียงลงในข้อความของคุณ",
    alertProcessingBackToDesc: "คุณสามารถรอที่นี่หรือกลับไปที่บ้านบรรณาธิการเพื่อทำงานต่อไป!",
    alertTextToSpeechAdjustingTitle: "สเตฟานีกำลังปรับ TTS ของคุณ",
    alertHoldOn: "กรุณารอสักหน่อย!",
    generateAllBlocks: "สร้างบล็อกทั้งหมด",
    generateAll: "สร้างทั้งหมด",
    originalAudio: "Original Audio",
    originalVideo: "วิดีโอต้นฉบับ",
    enArial: "English - Aria",
    enEric: "English - Eric",
    enJane: "English - Jane",
    enJason: "English - Jason",
    zhXiaomeng: "Chinese - Xiaomeng",
    zhYunyang: "Chinese - Yunyang",
    frSylvie: "French - Sylvie",
    frAntoine: "French - Antoine",
    esJorge: "Spanish - Jorge",
    esLarissa: "Spanish - Larissa",
    manualTextInput: "อินพุตข้อความด้วยตนเอง",
    typeYourTextManually: "พิมพ์ข้อความของคุณด้วยตนเอง",
    uploadFile: "อัปโหลดไฟล์",
    useTxtPdfDoc: "ใช้ txt, pdf, doc",
    selectAnOptionBelow: "เลือกตัวเลือกด้านล่าง:",
    startCreatingTTSFromScratch:"เริ่มสร้าง TTS ตั้งแต่เริ่มต้น",
    startATTSWillConvertAllTranscription: "การเริ่มต้น TTS จะแปลงการถอดรหัสเวิร์กโฟลว์ทั้งหมดเป็นเสียง TTS",
  },
  player: {
    loadingVideo: "กำลังโหลดวิดีโอ ...",
    loadingLanguage: "กำลังโหลดภาษา ...",
    previousStep: "ขั้นตอนก่อนหน้า",
    nextStep: "ขั้นตอนต่อไป",
    audio: "เสียง",
    subtitles: "คำบรรยาย",
    autoPlay: "เล่นอัตโนมัติ",
    off: "ปิด",
    on: "เปิด",
    quality: "คุณภาพ",
    speed: "ความเร็ว",
    normal: "ปกติ",
    rotatePhone: "<span class='primary--text'>หมุน</span> โทรศัพท์ของคุณเพื่อ<br>ประสบการณ์ที่ดียิ่งขึ้น",
    alertTranscodingFailed: "อุ๊บ ดูเหมือนว่าการแปลงรหัสวิดีโอล้มเหลว โปรดติดต่อ DeepHow เพื่อขอความช่วยเหลือเพิ่มเติม",
    alertTranscodingStillProgress: "อุ๊บ ดูเหมือนว่าการแปลงรหัสวิดีโอยังอยู่ในระหว่างดำเนินการ โปรดกลับมาในภายหลัง",
    settings: "การตั้งค่า",
    openInBrowser: "เปิดในเบราว์เซอร์",
    goToHome: "ไปที่หน้าหลัก",
    thisWorkflowWasUnpublished: "เวิร์กโฟลว์นี้ไม่ได้รับการเผยแพร่",
    thisWorkflowWasDeleted: "เวิร์กโฟลว์นี้ถูกลบแล้ว",
    talkToSupervisor: "หากคุณคิดว่าควรเผยแพร่เวิร์กโฟลว์นี้ ให้คุยกับหัวหน้างานของคุณ",
    selectWorkspace: "เลือกเวิร์กสเปซ",
    // player 2.0:
    home: "หน้าหลัก",
    myWorkflows: "เวิร์กโฟลว์ของฉัน",
    recommendedForYou: "แนะนำสำหรับคุณ",
    favorites: "รายการโปรด",
    welcomeToDeepHowNavigator: "ยินดีต้อนรับสู่ DeepHow Navigator",
    numNewSkillsTrainings: "<span>การฝึกอบรมทักษะ {count} ครั้ง</span> ได้มอบหมายให้คุณ",
    clickForTips: "คลิกที่นี่เพื่อดูเคล็ดลับ",
    learnHow: "เรียนรู้วิธีการ",
    goToSkills: "ไปที่ทักษะ",
    at: "ที่",
    youAreIn: "คุณอยู่ใน",
    loadingRecommendations: "กำลังโหลดคำแนะนำ ...",
    loadingFavoriteWorkflows: "กำลังโหลดเวิร์กโฟลว์ที่ชื่นชอบ ",
    noFavoriteWorkflows: "คุณยังไม่มีรายการในรายการโปรดของคุณ",
    canFindSavedItemsHere: "คุณสามารถค้นหารายการที่บันทึกไว้ทั้งหมดได้ที่นี่",
    removeWorkflowFromFavorites: "คุณแน่ใจหรือไม่ว่าต้องการลบเวิร์กโฟลว์นี้ออกจากรายการโปรด",
    yesGoAhead: "ใช่ ไปข้างหน้า",
    thisWorkflowIsNotAvailable: "เวิร์กโฟลว์นี้ไม่สามารถใช้ได้",
    dismiss: "ยกเลิก",
    navigator: "ระบบนำทาง",
    howToUseNavigator: "เรียนรู้วิธีใช้งาน <b>DeepHow Navigator ใหม่</b>",
    checkTheNewFeatures: "ตรวจสอบคุณสมบัติใหม่",
    unknownUser: "ผู้ใช้ที่ไม่รู้จัก",
    okCloseBtn: "โอเค ปิด",
    emptyRecommendWorkflowTitle: "เฮ้! เนื้อหาอยู่ที่ไหน",
    emptyRecommendWorkflowDescription: "ไม่ต้องกังวล! ทันทีที่มีการสร้างเวิร์กโฟลว์ ฉันจะแนะนำบางอย่างให้คุณ กลับมาเร็ว ๆ นี้!",
    thisWorkflowDoesNotExist: "ไม่มีเวิร์กโฟลว์นี้",
    // navigator
    resume: "ประวัติย่อ",
    myWorkspaces: "พื้นที่ทำงานของฉัน",
    otherWorkspaces: "พื้นที่ทำงานอื่น ๆ",
    loading: "กำลังโหลด ...",
    loadingfavoriteWorkspaces: "กำลังโหลดพื้นที่ทำงานที่ชื่นชอบ ...",
    removeWorkspaceFromFavorites: "คุณแน่ใจหรือว่าต้องการลบเวิร์กสเปซนี้ออกจากรายการโปรดของคุณ?",
    YouAreViewing: "คุณกำลังดู",
    allWorkspaces: "พื้นที่ทำงานทั้งหมด",
    removeFromFavorites: "ลบออกจากรายการโปรด",
    favoriteThisVideo: "ชื่นชอบวิดีโอนี้",
    shareThisVideo: "แบ่งปันวิดีโอนี้",
    viewAll: "ดูทั้งหมด",
    openFile: "เปิดไฟล์",
    suggestedKeyterms: "คำสำคัญที่แนะนำ",
    // workflow player added 10/10/2022
    posted: "โพสต์เมื่อ",
    views: "{count} มุมมอง",
    saveFavorite: "เก็บรวบรวม",
    react: "ข้อเสนอแนะ",
    numColleaguesReact: "เพื่อนร่วมงาน {count} คนได้ตอบกลับ",
    youAndNumColleaguesReact: "<span>คุณ</span>และเพื่อนร่วมงาน {count} คนแสดงปฏิกิริยา",
    youReacted: "<span>คุณ</span>ตอบสนองแล้ว",
    userReaction: "ความคิดเห็นของลูกค้า",
    fullscreen: "เต็มจอ",
    exitFullscreen: "ออกจากหน้าจอเต็มรูปแบบ",
    // 1/23/2023 Comments
    comments: "ความเห็น",
    deleteComment: "ลบความคิดเห็น",
    deleteCommentMsg: "คุณแน่ใจหรือว่าต้องการลบความคิดเห็นนี้? <br> สิ่งนี้ไม่สามารถยกเลิกได้",
    edit: "แก้ไข",
    updating: "อัปเดต ...",
    changesSaved: "บันทึกการเปลี่ยนแปลง",
    noComments: "ยังไม่มีความคิดเห้น…",
    makeFirstComment: "แสดงความคิดเห็นแรกด้านล่าง",
    comment: "การแสดงความคิดเห็น",
    writeAComment: "เขียนความคิดเห็น ...",
    edited: "ถูกแก้ไขแล้ว",
    emojiRecent: "ใช้บ่อย",
    emojiSmileys: "รอยยิ้มและอิโมติคอน",
    emojiPeople: "คนและร่างกาย",
    emojiNature: "สัตว์และธรรมชาติ",
    emojiFoods: "อาหารเครื่องดื่ม",
    emojiActivity: "กิจกรรม",
    emojiPlaces: "การเดินทางและสถานที่",
    emojiObjects: "วัตถุ",
    emojiSymbols: "สัญลักษณ์",
    emojiFlags: "ธง",
    emojiCustom: "กำหนดเอง",
    back: "กลับ",
    feedback: "ข้อเสนอแนะ",
    leaveAFeedback: "แสดงความคิดเห็น",
    leaveAFeedbackMsg: "บอกเราเกี่ยวกับปัญหาใด ๆ ให้คำแนะนำหรือวิจารณ์สร้างสรรค์",
    typeYourMessageHere: "พิมพ์ข้อความของคุณที่นี่",
    submit: "ส่ง",
    thankYou: "ขอบคุณค่ะ",
    feedbackSuccessMsg: "ข้อเสนอแนะของคุณถูกส่งเรียบร้อยแล้ว",
    alertFeedbackFailedMsg: "การส่งข้อเสนอแนะล้มเหลว กรุณาลองอีกครั้ง",
    diagrams: "ไดอะแกรม",
    pdfFiles: "ไฟล์ PDF",
    theaterMode: "โหมดโรงภาพยนตร์",
    exitTheaterMode: "ออกจากโหมดโรงภาพยนตร์",
    // TODO: 09/08/2023
    enableWorkflowRepeat: "เปิดใช้งานเวิร์กโฟลว์ซ้ำ",
    enableStepRepeat: "เปิดใช้งานขั้นตอนซ้ำ",
    disableRepeat: "ปิดการใช้งานซ้ำ",
  },
  profile: {
    // added 12/09/2021
    addPositionLocation: "เพิ่มตำแหน่งและตำแหน่ง",
    noWorkflowsCreated: "ยังไม่มีเวิร์กโฟลว์ที่สร้างขึ้น",
    comeBackLater: "กลับมาในภายหลัง.",
    createdWorkflows: "สร้างเวิร์กโฟลว์",
    profileOwnerNoContentMsg: "<span>เฮ้ {name}! นี่คือหน้าโปรไฟล์ใหม่ของคุณ</span> <br> ในอนาคตคุณจะสามารถแบ่งปันความรู้กับทุกคนได้ เดี๋ยว!",
    profileColleguesNoContentMsg: "<span>นี่คือหน้าโปรไฟล์ {name}</span> <br> เขายังไม่สามารถเผยแพร่เนื้อหาได้ แต่ในอนาคตอันใกล้นี้เขาจะ!",
    editProfile: "แก้ไขโปรไฟล์",
    personalInformation: "ข้อมูลส่วนบุคคล",
    personalInformationMsg: "ผู้คนที่เข้าชมโปรไฟล์ของคุณจะเห็นข้อมูลต่อไปนี้",
    photo: "รูปถ่าย",
    position: "ตำแหน่ง",
    locationRegion: "สถานที่/ ภูมิภาค",
    whatYouDo: "ให้คนอื่นรู้ว่าคุณทำอะไรในองค์กรนี้",
    whereYouBased: "ให้คนอื่นรู้ว่าคุณอยู่ที่ไหน",
    accountManagement: "การจัดการบัญชี",
    accountManagementMsg: "ข้อมูลนี้เป็นส่วนตัวและจะไม่ปรากฏในโปรไฟล์สาธารณะของคุณ",
    identifyMyself: "ฉันระบุว่าตัวเองเป็น",
    yourPassword: "รหัสผ่านของคุณ",
    nonDeclared: "ไม่ได้ประกาศ (ค่าเริ่มต้น)",
    male: "ชาย",
    female: "หญิง",
    nonBinary: "ไม่มีไบนารี",
    viewProfile: "ดูประวัติ",
    alertFailedToUpdateProfile: "ไม่สามารถอัปเดตโปรไฟล์ของคุณได้",
  },
  explorer: {
    search: "ค้นหา",
    in: "ใน&nbsp;",
    howTo: "วิธี...",
    didYouMean: "คุณหมายถึง",
    searchInTitle: "ในหัวข้อ",
    searchInContent: "ในเนื้อหา",
    searchInVideo: "คำแนะนำของสเตฟานี",
    title: "หัวข้อ",
    searchStartMsg1: "คุณกำลังมองหาอะไร",
    searchStartMsg2: "ใช้ช่องค้นหาด้านบน",
    sortBy: "เรียงโดย",
    lastUpdated: "อัพเดทล่าสุด",
    alphabetically: "ตามตัวอักษร",
    workflowsIn: "เวิร์กโฟลว์ใน",
    tagNew: "ใหม่",
    tagProcessing: "กำลังประมวลผล",
    msgProcessing: "สเตฟานีกำลังประมวลผลเวิร์กโฟลว์ของคุณ ... <br/> โปรดรอสักครู่",
    tagFailed: "ล้มเหลว",
    msgFailed: "อุ๊บ มีบางอย่างผิดปกติขณะประมวลผลคำบรรยายหรือวิดีโอ เราจะลองอีกครั้งในเร็วๆ นี้",
    msgVideoLimitExceeded: "วิดีโอมีความยาวเกินความยาวที่ DeepHow รองรับ จะมีการอัปโหลดซ้ำโดยอัตโนมัติในเวิร์กโฟลว์ตั้งแต่สองเวิร์กโฟลว์ขึ้นไป",
    msgVideoExceededMaximum: "วิดีโอเกินความยาวเวิร์กโฟลว์สูงสุดที่ได้รับการสนับสนุนโดย Deephow มันจะถูกแบ่งโดยอัตโนมัติในสองเวิร์กโฟลว์ขึ้นไป",
    tagTranscoding: "การแปลงรหัส",
    msgTranscoding: "สเตฟานีกำลังแปลงรหัสเวิร์กโฟลว์ของคุณ ... <br/> โปรดรอสักครู่ ",
    deleteWorkflow: "ลบเวิร์กโฟลว์",
    popupBtnDelete: "ใช่ ลบ",
    popupMsgDeleteWorkflow: "คุณแน่ใจหรือว่าต้องการลบเวิร์กโฟลว์นี้ คุณจะสูญเสียขั้นตอนและข้อมูลทั้งหมดที่เกี่ยวข้องกับเวิร์กโฟลว์นี้",
    noUnpublishedWorkflows: "คุณยังไม่มีเวิร์กโฟลว์ที่ยังไม่ได้เผยแพร่...",
    noPublishedWorkflows: "คุณยังไม่มีเวิร์กโฟลว์ที่เผยแพร่...",
    startCreatingWorkflow: "เริ่มสร้างเวิร์กโฟลว์กันเถอะ",
    startPublishingWorkflow: "เริ่มเผยแพร่เวิร์กโฟลว์กันเถอะ",
    noSearchResult: "ไม่พบผลลัพธ์ …",
    trySearchAgain: "โปรดลองค้นหาอีกครั้ง",
    searchingForWorkflows: "ค้นหาเวิร์กโฟลว์ ...",
    secondsAgo: "{count} วินาทีที่แล้ว",
    minutesAgo: "{count} นาทีที่แล้ว",
    hoursAgo: "{count} ชั่วโมงที่แล้ว",
    daysAgo: "{count} วันที่ผ่านมา",
    monthsAgo: "{count} เดือนที่ผ่านมา",
    yearsAgo: "{count} ปีที่ผ่านมา",
    sAgo: "{count} วินาที",
    minAgo: "{count} นาที",
    hAgo: "{count} ชั่วโมง",
    dAgo: "{count} วัน",
    mAgo: "{count} เดือน",
    yAgo: "{count} ปี",
    renameWorkflow: "เปลี่ยนชื่อเวิร์กโฟลว์",
    moveWorkflowTo: "ย้ายเวิร์กโฟลว์ไปที่",
    duplicateWorkflow: "เวิร์กโฟลว์ที่ซ้ำกัน",
    unpublishWorkflow: "เวิร์กโฟลว์ที่ยังไม่เผยแพร่",
    shareKnowhow: "แบ่งปันความรู้ความชำนาญ",
    or: "หรือ",
    clickHere: "คลิกที่นี่",
    andCopyMsg: "และคัดลอกลิงค์ URL เพื่อส่งทางอีเมล",
    successfulSharing: "การแบ่งปันสำเร็จ",
    send: "ส่ง",
    typeHereAMessage: "พิมพ์ข้อความที่นี่",
    wellDone: "ทำได้ดี",
    numMore: "อีก {count} ",
    moveWorkflow: "ย้ายเวิร์กโฟลว์",
    currentWorkspace: "เวิร์กสเปซปัจจุบัน",
    whereToMsg: "คุณต้องการย้ายเวิร์กโฟลว์ปที่ใด",
    move: "ย้าย",
    alertSuccessfullyMoved: "ย้ายเวิร์กโฟลว์สำเร็จ",
    alertFailedMoved: "ไม่สามารถย้ายเวิร์กโฟลว์ได้",
    noNotificationsMsg: "ขณะนี้คุณไม่มีการแจ้งเตือน",
    betaSearch: "โปรดทราบว่าคำแนะนำของ Stephanie ยังอยู่ในเบต้า ...",
    relevantSteps: "ขั้นตอนที่เกี่ยวข้อง:",
    relevance: "ความเกี่ยวข้อง",
    resultsFor: "{num} ผลลัพธ์สำหรับ <b>&ldquo;{input}&rdquo;</b>",
    resultFor: "{num} ผลลัพธ์สำหรับ <b>&ldquo;{input}&rdquo;</b>",
    byPublisher: "โดยสำนักพิมพ์",
    alertFailedShareWorkflow: "ล้มเหลวในการแบ่งปันเวิร์กโฟลว์",
  },
  new: {
    newWorkflow: "เวิร์กโฟลว์ใหม่",
    uploadVideo: "อัพโหลดวิดีโอ",
    recordScreen: "บันทึกหน้าจอ",
    replaceVideoSubtitle: "ลากหรือคลิกเพื่อเปลี่ยนวิดีโอของคุณ",
    recordScreenSubtitle: "สเตฟานีพร้อมที่จะเริ่มบันทึกหน้าจอของคุณแล้ว",
    workflowName: "ชื่อเวิร์กโฟลว์",
    workflowLanguage: "ภาษาเวิร์กโฟลว์",
    addToWorkspace: "เพิ่มในเวิร์กสเปซ",
    upload: "อัพโหลด",
    startRecording: "เริ่มการบันทึก",
    cancel: "ยกเลิก",
    wrongUploadFile: "ไม่รองรับประเภทไฟล์ โปรดเลือกประเภทไฟล์ต่อไปนี้:",
    limitFileDuration: "ระยะเวลาของไฟล์สูงสุด {duration} นาที โปรดใช้ Windows APP",
    limitFileDurationGen2: "ระยะเวลาของไฟล์สูงสุด {duration} นาที",
    uploadingVideo: "กำลังอัพโหลดวิดีโอ",
    uploadingVideoSubtitle: "โปรดรอสักครู่ในขณะที่สเตฟานีอัพโหลดวิดีโอของคุณ",
    renderingVideo: "การถอดความ / บันทึกวิดีโอ",
    renderingVideoSubtitle: "โปรดรอสักครู่ในขณะที่สเตฟานีถอดความวิดีโอของคุณ",
    almostDone: "เราเกือบเสร็จแล้ว",
    uploadingStep1: "ขั้นตอนที่ 1 จาก 2 - กำลังวิเคราะห์เวิร์กโฟลว์...",
    uploadingStep2: "กำลังอัพโหลดวิดีโอ ...",
    successSubtitle: "การอัพโหลดเวิร์กโฟลว์ของคุณสำเร็จแล้ว",
    goToEditor: "ไปที่บรรณาธิการ",
    goToContentManger: "ไปที่ผู้จัดการเนื้อหา",
    uploadingAlert: "การเปลี่ยนหรือปิดแท็บเบราว์เซอร์จะทำให้เกิดข้อผิดพลาดในการอัพโหลด",
    recordingScreen: "กำลังบันทึกหน้าจอ",
    support15Min: "จับภาพหน้าจอได้สูงสุด <b>15 นาที</b>",
    hereWeGo: "ไปเลย!",
    remember15Min: "คุณมีเวลา 15 นาที",
    windowsAppAvailable: "Windows APP<br>พร้อมใช้งานแล้ว",
    windowsAppAvailableNews: "แอป DeepHow Uploader มีอยู่แล้วสำหรับ Windows ตอนนี้คุณสามารถอัปโหลดไฟล์ขนาดใหญ่และบันทึกหน้าจอได้",
    downloadNow: "ดาวน์โหลดเดี๋ยวนี้!",
    deepHowWindowsApp: "Windows APP",
    downloadAndInstalltheWindowsApp: "ดาวน์โหลดและติดตั้ง Windows APP",
    notAllowedToInstall: "หากคุณไม่ได้รับอนุญาตให้ติดตั้งเวอร์ชันขึ้นไป",
    downloadThisVersion: "ดาวน์โหลดเวอร์ชันนี้",
    and: " และ",
    watchThisVideo: "<span>ดูวิดีโอนี้</span> เกี่ยวกับวิธีการใช้งาน",
    // Added on 2022/01/19
    abiAuthorizationLabel: "ฉันรับทราบในที่นี้ว่าบุคคลทั้งหมดในวิดีโอนี้ได้ลงนามใน<span>การอนุญาตการบันทึกวิดีโอ/การบันทึกวิดีโอ</span>และการเผยแพร่เฉพาะสำหรับประเทศของฉัน",
    abiPrivacyLabel: "ฉันยอมรับในที่นี้ว่าฉันได้อ่านและเข้าใจ <span>คำชี้แจงสิทธิ์ส่วนบุคคลในการถ่ายภาพ/วิดีโอของ ABInbev</span>",
    // Added on 2022/06/15
    importFromZoom: "นำเข้าจากซูม",
    importFromZoomSteps: "ขั้นตอน {step} ของ {totalStep}",
    selectVideosFrom: "เลือกวิดีโอจาก",
    videoSelected: "<span> 0 </span> วิดีโอที่เลือก | <span> 1 </span> วิดีโอที่เลือก | <span> {count} </span> วิดีโอที่เลือก",
    workflowInfo: "ข้อมูลเวิร์กโฟลว์",
    newImport: "การนำเข้าใหม่",
    orBackToEditor: "หรือกลับไปที่บรรณาธิการ",
    importFromZoomSuccessMsg: "วิดีโอของคุณจะเริ่มอัปโหลดเร็ว ๆ นี้อาจใช้เวลาสักครู่ก่อนที่จะมีการแก้ไข",
    zoomFailedUploadMsg: "อัพโหลดวิดีโอซูมล้มเหลว",
    zoomPeriodLastMonth: "เดือนที่แล้ว",
    zoomPeriodPastMonth: "อดีต {n} เดือน",
    loadingZoomVideos: "กำลังโหลดวิดีโอ",
    zoomNoRecordingsInfo: "ไม่พบการบันทึกสำหรับช่วงเวลาที่เลือก",
    yourVideoExceedTwoHours: "วิดีโอของคุณเกิน 2 ชั่วโมงและไม่สามารถอัปโหลดได้",
    yourVideoExceed3GSize: "วิดีโอของคุณเกิน 3 GB และไม่สามารถอัปโหลดได้",
    yourVideoExceedTwoHoursAnd3GSize: "วิดีโอของคุณเกิน 2 ชั่วโมง 3GB และไม่สามารถอัปโหลดได้",
    videoUploadWarningMessage: "ลากหรือคลิกเพื่อนำเข้าวิดีโอของคุณ ({supportedFileTypes}) สูงสุด ขนาดคือ 3 GB และสูงสุด 2 ชั่วโมง",
    videoUploadWarningMessageGen2: "ลากหรือคลิกเพื่อนำเข้าวิดีโอของคุณ ({supportedFileTypes}) สูงสุด ไม่เกิน 40 นาที",
  },
  auth: {
    aboutDeephow: "เกี่ยวกับ DeepHow",
    authTitle: "โซลูชัน AI แรกสำหรับการฝึกอบรมการค้าที่มีทักษะ",
    authIntro: "DeepHow ใช้วิดีโอการฝึกอบรมเชิงโต้ตอบด้วยเทคโนโลยี AI เพื่อเชื่อมช่องว่างทักษะในด้านการบริการและการซ่อมในอุตสาหกรรมการผลิต",
    allRightReserved: "สงวนลิขสิทธิ์",
    logIn: "เข้าสู่ระบบ",
    logOut: "ออกจากระบบ",
    logInSubtitle: "โปรดใส่รายละเอียดของคุณเพื่อเข้าสู่ระบบ",
    logInSubtitleSso: "กรุณาคลิกด้านล่างเพื่อเข้าสู่ระบบ",
    emailOrId: "อีเมลหรือรหัสพนักงาน",
    hello: "สวัสดี,",
    passwordSubtitle: "กรุณาใส่รหัสผ่านของคุณ",
    password: "รหัสผ่าน",
    forgotPassword: "ลืมรหัสผ่าน",
    forgotPasswordSubtitle: "กรุณาใส่อีเมลของคุณ เพื่อให้เราสามารถส่งลิงค์สำหรับสร้างรหัสผ่านใหม่ให้คุณ",
    email: "อีเมล",
    resetPassword: "รีเซ็ตรหัสผ่าน",
    alertResetSuccess: "โปรดตรวจสอบกล่องจดหมายอีเมลของคุณ",
    alertResetFailed: "ไม่สามารถกู้คืนรหัสผ่าน",
    alertInvalidId: "รหัสพนักงานไม่ถูกต้อง",
    successMsg: "โปรดตรวจสอบอีเมลของคุณและคลิกที่ลิงค์เพื่อกำหนดรหัสผ่านใหม่สำหรับบัญชีของคุณ",
    logInWithSSO: "เข้าสู่ระบบด้วย SSO",
    loggingInWithSSO: "กำลังเข้าสู่ระบบด้วย SSO",
    logInWithGoogle: "เข้าสู่ระบบด้วย Google",
    logInWithMicrosoft: "เข้าสู่ระบบด้วย Microsoft",
    loadingSso: "กำลังโหลด SSO ...",
    about: "เกี่ยวกับ",
    // 12/17/2021 iframe
    loginToYourAccount: "<b>เข้าสู่ระบบ</b> เข้าสู่บัญชีของคุณ",
    pleaseInputYourPassword: "กรุณาใส่รหัสผ่านของคุณ",
    backToLogin: "กลับไปเข้าสู่ระบบ",
    // added 03/02/2022
    wrongPassword: "รหัสผ่านผิด.",
    // Shared Account
    sharedDevice: "อุปกรณ์ที่ใช้ร่วมกัน",
    loginExistingAccount: "เข้าสู่ระบบด้วยบัญชีที่มีอยู่",
    createNewAccount: "สร้างบัญชีใหม่",
    noUsersMsg: "ยังไม่มีผู้ใช้เข้าสู่ระบบอุปกรณ์ที่ใช้ร่วมกันนี้ ...",
    insertIDMsg: "โปรดแทรกรหัสพนักงานของคุณ",
    createIdUserMsg: "เราคือ <b>เกือบจะมี</b>! <br>กรุณากรอกรายละเอียดของคุณด้านล่าง",
    confirmEmployeeID: "ยืนยันรหัสพนักงาน",
    welcomeToDeepHow: "ยินดีต้อนรับสู่ DeepHow, <b>{name}</b>",
    confirmWorkspaceTeamMsg: "โปรดยืนยันพื้นที่ทำงานของคุณและเลือกทีมของคุณ!",
    LetsGetStarted: "มาเริ่มกันเลย!",
    // added 05/26/2022
    useSsoLogin: "การเข้าสู่ระบบอีเมล/รหัสผ่านไม่ได้เปิดใช้งานสำหรับองค์กรของคุณ กรุณาเข้าสู่ระบบด้วย SSO",
    // added 02/17/2023
    cookieAlert: "ฉันยอมรับการใช้คุกกี้ตามรายละเอียดใน <a href='{pdfUrl}' target='_blank'> นโยบายความเป็นส่วนตัว </a>",
    useCookie: "คุณยอมรับคุกกี้ของเราหรือไม่?",
    useCookieContext: "เราใช้คุกกี้เพื่อปรับปรุงประสบการณ์และให้บริการที่เป็นส่วนตัวมากขึ้นให้กับคุณ หากต้องการข้อมูลเพิ่มเติมเกี่ยวกับคุกกี้ที่เราใช้ดู <a style='color: #DFE0E0;' href='{url}' target='_blank'> นโยบายความเป็นส่วนตัว </a>",
    useCookieContextMobile: "เราใช้คุกกี้เพื่อปรับปรุงประสบการณ์และให้บริการที่เป็นส่วนตัวมากขึ้นให้กับคุณ หากต้องการข้อมูลเพิ่มเติมโปรดดู <a style='color: #dfe0e0;' href='{url}' target='_blank'> นโยบายความเป็นส่วนตัว </a>",
    privacyPolicy: "นโยบายความเป็นส่วนตัว",
    acceptCookies: "ยอมรับคุกกี้",
    alertDisableDHLogin: "ไม่อนุญาตให้เข้าสู่ระบบ ID พนักงานสำหรับองค์กรนี้",
    alertPasswordFormatError: "Password format validation failed.",
    alertPasswordReused: "Password is used for the last 5 time(s).",
    alertPasswordIncludeCertainValue: "Password is including the email/name of the user.",
    alertPasswordMustUpdate: "Passwords must be changed after 90 days.",
    alertPasswordWillExpire: "password will expire in {n} day(s)",
    alertLoginFailed: "Something went wrong while logging in.",
    alertFailedToGetSharedDeviceSettings: "Something went wrong while getting Shared Account.",
    alertUserNotFound: "User is not found.",
    alertEmployeeIdInvalid: "Employee ID is invalid.",
    alertAccountIdAlreadyUsed: "Account ID is already used.",
    alertAccountIdInvalid: "Account ID is invalid.",
    alertMasterPasswordIncorrect: "Password is incorrect.",
    alertChangeUserNameFailed: 'Failed to change user name.',
    checkIpPopupTitle: "ตรวจสอบ IP และบทบาทของคุณ ...",
    checkIpPopupDesc: "กรุณารอสักหน่อย!",
    checkIpDeephowDesc: "แพลตฟอร์มวิดีโอความรู้",
    checkIpNotAllowedTitle: "ไม่อนุญาตที่อยู่ IP",
    checkIpNotAllowedDesc: "การเข้าถึงแอพนี้ได้รับอนุญาตจากสถานที่เฉพาะเท่านั้น กรุณาติดต่อผู้ดูแลระบบของคุณสำหรับข้อมูลเพิ่มเติม!",
    checkIpAlertForOrgAdmin: "การเข้าถึงแอพนี้ได้รับอนุญาตจากสถานที่เฉพาะเท่านั้น เป็นองค์กร ผู้ดูแลระบบคุณสามารถเข้าถึงผู้ดูแลระบบเท่านั้น",
    customize: "ปรับแต่ง",
    acceptCookies: "ยอมรับคุกกี้",
    cookiesSettings: "การตั้งค่าคุกกี้",
    seeOurPrivacyPolicy: "ดู <a href='{pdfUrl}' target='_blank'> นโยบายความเป็นส่วนตัว </a>",
    noAccept: "ฉันไม่ยอมรับคุกกี้",
    saveAndAccept: "บันทึกและยอมรับ",
    cookieConsentDesc: "เราใช้คุกกี้ในเว็บไซต์นี้บางอย่างมีความสำคัญในขณะที่ othres ช่วยให้เราปรับปรุงประสบการณ์และให้บริการที่เป็นส่วนตัวมากขึ้นให้กับคุณ",
    mandatoryAlertTitle: "{consentTitle} are needed",
    mandatoryAlertDesc: "หากคุณไม่ยอมรับ {consentTitle} คุณจะไม่สามารถเข้าถึง Deephow ได้",
    DontAcceptAndExit: "อย่ายอมรับและออก",
    acceptConsent: "ยอมรับ {consentTitle}",
  },
  rules: {
    required: "ที่จำเป็น.",
    invalidEmail: "อีเมลไม่ถูกต้อง",
    noSpace: "ไม่สามารถเพิ่มช่องว่าง",
    validPassword: "รหัสผ่านจำเป็นต้องมีอักขระอย่างน้อย 6 ตัว โดยประกอบด้วยตัวเลข ตัวพิมพ์เล็กและตัวพิมพ์ใหญ่",
    maxPassword: "สูงสุด 16 ตัวอักษร",
    mustMatch: "รหัสผ่านจะต้องตรงกัน",
    max25: "สูงสุด 25 ตัวอักษร",
    max90: "สูงสุด 90 ตัวอักษร",
    max100: "สูงสุด 100 ตัวอักษร",
    max140: "สูงสุด 140 ตัวอักษร",
    numMaxCharacters: "สูงสุด {count} ตัวอักษร",
    min6: "น้อยสุด 6 ตัวอักษร",
    noWhiteSpace: "ป้อนข้อความเป็นหัวข้อ",
    noAllSpace: "ไม่สามารถใช้ช่องว่างเป็นชื่อได้",
    invalidEmployeeID: "รหัสพนักงานไม่ถูกต้อง",
    invalidUrl: "URL ไม่ถูกต้อง",
    mustMatchId: "ID พนักงานต้องตรงกัน",
    canNotEnterSharedAccount: "ไม่สามารถแทรกรหัสบัญชีที่ใช้ร่วมกันได้",
    validPasswordMin10: "รหัสผ่านจะต้องมีอย่างน้อย 10 อักขระรวมถึงจำนวนอักขระพิเศษตัวอักษรตัวพิมพ์เล็กและตัวพิมพ์เล็ก",
    invalidName: "ชื่อไม่ถูกต้อง.",
  },
  alert: {
    success: "สำเร็จ",
    info: "ข้อมูล",
    alert: "การเตือน",
    error: "ข้อผิดพลาด",
    dontShowThisMsgAgain: "อย่าแสดงข้อความนี้อีก",
    unexpectedError: "เกิดข้อผิดพลาดที่ไม่คาดคิด โปรดลองอีกครั้งหรือติดต่อ DeepHow เพื่อขอความช่วยเหลือ",
    warning: "คำเตือน",
  },
  skills: {
    skillsMatrix: "เมทริกซ์ทักษะ",
    createSkill: "สร้างทักษะ",
    skillsCreated: "ทักษะที่สร้างขึ้น",
    skillsPublished: "ทักษะที่เผยแพร่",
    skillsIn: "ทักษะใน",
    hintSearchSkills: "ค้นหาทักษะ ...",
    lastUpdated: "อัพเดทล่าสุด",
    skillsPerPage: "ทักษะต่อหน้า",
    usersAssigned: "ผู้ใช้งานที่ได้รับมอบหมาย",
    status: "สถานะ",
    createdBy: "โดย",
    skillsSelected: "ทักษะที่เลือก",
    updatedBy: "โดย",
    deleteSkill: "ลบทักษะ",
    popupMsgDeleteSkill: "คุณแน่ใจหรือว่าต้องการลบทักษะนี้",
    deleteSkills: "ลบทักษะ",
    popupMsgDeleteSkills: "คุณแน่ใจหรือว่าต้องการลบทักษะเหล่านี้",
    clearAll: "ลบทั้งหมด",
    clearAllFilters: "ล้างตัวกรองทั้งหมด",
    clearFilter: "ล้างตัวกรอง",
    delete: "ลบ",
    youAreViewing: "คุณกำลังดู",
    numWorkflows: "{count} เวิร์กโฟลว์",
    searchIn: "ค้นหาใน",
    loadingSkillMatrix: "โหลดเมทริกซ์ทักษะ ...",
    createSkillSubtitle: "เลือกชื่อและตำแหน่งที่คุณต้องการอัพโหลด",
    skillName: "ชื่อทักษะ",
    create: "สร้าง",
    editSkill: "แก้ไขทักษะ",
    popupMsgEditSkill: "หากคุณเข้าสู่โหมดแก้ไข ทักษะของคุณจะไม่ได้รับการเผยแพร่โดยอัตโนมัติ คุณแน่ใจหรือไม่ว่าต้องการดำเนินการต่อ",
    publishSkill: "เผยแพร่ทักษะ",
    popupMsgPublishSkill: "คุณกำลังจะเผยแพร่ทักษะของคุณและผู้ใช้งานจะสามารถดูได้ คุณแน่ใจหรือว่าต้องการทำเช่นนั้น",
    alertFailedSaveSkill: "บันทึกทักษะล้มเหลว",
    alertSkillSuccessfullyPublished: "ทักษะของคุณได้รับการตีพิมพ์เรียบร้อยแล้ว",
    alertSkillSuccessfullyUnpublished: "ทักษะของคุณไม่ได้รับการเผยแพร่",
    alertSkillSuccessfullySaved: "ทักษะของคุณได้รับการบันทึกเรียบร้อยแล้ว",
    alertFailedSavedSkill: "บันทึกทักษะล้มเหลว",
    alertFailedPublishSkill: "ไม่สามารถเผยแพร่ทักษะ",
    alertSkillSuccessfullyDeleted: "ลบทักษะของคุณสำเร็จแล้ว",
    alertSkillFailedDeleted: "ไม่สามารถลบทักษะ",
    content: "เนื้อหา",
    addContent: "เพิ่มเนื้อหา",
    workflowDeleted: "ลบเวิร์กโฟลว์",
    workflowsSelected: "เวิร์กโฟลว์ที่เลือก",
    selectAll: "เลือกทั้งหมด",
    remove: "ลบ",
    noContentMsg: "ยังไม่มีเนื้อหาในทักษะของคุณ",
    assignUsers: "มอบหมายผู้ใช้งาน",
    noUserMsg: "คุณยังไม่มีผู้ใช้งานที่ได้รับมอบหมายให้เข้าร่วมการฝึกอบรมของคุณ",
    numUsers: "ผู้ใช้งาน <b>{count}</b> คน",
    assignedOn: "มอบหมายใน",
    certifiedBy: "ได้รับการรับรองโดย",
    progress: "ความคืบหน้า",
    aletUserSuccessfullyRemoved: "ผู้ใช้งานถูกลบเรียบร้อยแล้ว",
    removeUser: "ลบผู้ใช้งาน",
    popupMsgRemoveUserFromSkill: "คุณแน่ใจหรือไม่ว่าต้องการลบ <b>{name}</b> จากทักษะนี้",
    assign: "มอบหมาย",
    successfulAssigned: "ได้รับมอบหมายเรียบร้อยแล้ว",
    AddedToSkill: "เพิ่มลงในทักษะ",
    loadingSkills: "กำลังโหลดทักษะ",
    searchingForSkills: "ค้นหาทักษะ ...",
    toDo: "เพื่อทำ",
    completed: "เสร็จ",
    assignedBy: "ได้รับมอบหมายโดย",
    min: "นาที",
    nextPart: "ส่วนต่อไป",
    noSkillsAssignedMsg: "คุณไม่มีทักษะที่ได้รับมอบหมาย",
    noSkillsAssignedMsg2: "เราจะแจ้งให้คุณทราบเมื่อคุณมีอะไรใหม่",
    noCompletedSkillsMsg: "คุณยังไม่มีทักษะที่เสร็จสมบูรณ์",
    noCompletedSkillsMsg2: "เริ่มเรียนทักษะกันเถอะ",
    noDataSkillsMatrix: "คุณยังไม่มีข้อมูลในเมทริกซ์ทักษะ",
    certificate: "ใบรับรอง",
    certificateMsg: "ผู้ใช้งานจะได้รับใบรับรองการจบหลังจากเสร็จสิ้นทักษะ",
    selectCertificateMsg: "ผู้ใช้งานสามารถตรวจสอบใบรับรองได้โดนการเลือกด้านล่าง",
    validateSkill: "ตรวจสอบทักษะ",
    validateSkillMsg: "คุณแน่ใจหรือว่าต้องการตรวจสอบความถูกต้อง <b>{name}</b> ในทักษะ {skill}? <br />คุณจะไม่สามารถยกเลิกได้",
    yesValidate: "ใช่ ตรวจสอบความถูกต้อง",
    noValidate: "เลขที่",
    certifier: "ผู้รับรอง",
    signaturesOn: "บน",
    teamProgress: "ความคืบหน้าของทีม",
    openTeam: "เปิดทีม",
    removeCertificate: "ลบใบรับรอง",
    removeCertificateMsg: "คุณจะลบผู้ใช้งานทั้งหมดที่สามารถตรวจสอบใบรับรองได้ คุณแน่ใจหรือไม่",
    yesRemove: "ใช่ ลบ",
    mustAddUserMsg: "*ใบรับรองจะไม่ถูกเปิดใช้งานหากไม่มีการเพิ่มผู้ใช้งาน",
    skillCover: "ปกทักษะ",
    alertSuccessfullyUpdatedSkillCover: "อัพเดทปกทักษะเรียบร้อยแล้ว",
    alertFailedUpdatedSkillCover: "ไม่สามารถอัพเดทปกทักษะ",
    closeSkill: "ปิดทักษะ",
    allWorkflowsDeleted: "เวิร์กโฟลว์ทั้งหมดจะถูกลบออกในทักษะนี้",
    waitingForValidation: "รอการตรวจสอบ",
    validatedBy: "ตรวจสอบโดย:",
    skillWasUnpublished: "ทักษะไม่ได้รับการเผยแพร่",
    skillWasUnpublishedMsg: "ฉันจะแจ้งให้คุณทราบเมื่อมีทักษะนี้อีกครั้ง สำหรับตอนนี้คุณทุกคนดี!",
    notAssignedToSkill: "คุณไม่ได้รับมอบหมายให้ทักษะนี้",
    notAssignedToSkillMsg: "ขออภัยหน้านี้ใช้ได้เฉพาะกับผู้ใช้ที่ได้รับมอบหมายให้กับทักษะนี้",
    remindPendingUsers: "เตือนผู้ใช้ที่รอดำเนินการ",
    alertSuccessRemindPendingUsers: "เตือนผู้ใช้ที่รอดำเนินการเรียบร้อยแล้ว",
    alertFailedRemindPendingUsers: "ไม่สามารถเตือนผู้ใช้ที่รอดำเนินการ",
    alertNoPendingUsers: "ไม่พบผู้ใช้ที่ค้างอยู่",
    numVideo: "0 วิดีโอ | 1 วิดีโอ | {count} วิดีโอ",
    // Skill Deadline
    deadline: "วันกำหนดส่ง",
    deadlineMsg: "กำหนดช่วงเวลาระดับโลกให้ผู้ใช้ดูทักษะนี้ให้เสร็จ หลังจากกำหนดเวลาผู้ใช้ที่รอดำเนินการจะได้รับแจ้ง ...",
    deadlineForThisSkill: "เปิดใช้งานกำหนดเวลาสำหรับทักษะนี้",
    certificateForThisSkill: "เปิดใช้งานใบรับรองสำหรับทักษะนี้",
    deadlineStartsMsg: "ระยะเวลาเริ่มนับเมื่อพิจารณาจากผู้ใช้วันที่ได้รับมอบหมาย",
    selectAPeriod: "เลือกระยะเวลา",
    finished: "ที่เสร็จเรียบร้อย",
    editDeadline: "แก้ไขกำหนดเวลา",
    editDeadlineMsg: "โดยการแก้ไขกำหนดเวลาก่อนหน้านี้จะไม่ถูกต้องอีกต่อไปและระยะเวลาใหม่จะถูกนำไปใช้กับผู้ใช้ทั้งหมดในทักษะนี้ <br> <br> แน่ใจว่าต้องการดำเนินการต่อหรือไม่?",
    alertSuccessNewDeadline: "กำหนดกำหนดเวลาใหม่สำหรับผู้ใช้ทุกคน",
    alertSuccessRemoveDeadline: "กำหนดเส้นตายทักษะถูกลบออกได้สำเร็จ",
    alertFailedDeadline: "ล้มเหลวในการอัปเดตกำหนดเส้นตายทักษะ",
    dueToday: "ครบกำหนดวันนี้",
    turnOffDeadlineMsg: "โดยการปิดกำหนดเวลาข้อมูลก่อนหน้านี้จะหายไป ครั้งต่อไปที่เปิดใช้งานจะรีเซ็ตวันเริ่มต้นของผู้ใช้ทั้งหมด <br> <br> แน่ใจว่าต้องการดำเนินการต่อหรือไม่?"
  },
  time: {
    numDays: "0 วัน | 1 วัน | {นับ} ​​วัน",
    numWeeks: "0 สัปดาห์ | 1 สัปดาห์ | {นับ} ​​สัปดาห์",
    numMonths: "0 เดือน | 1 เดือน | {นับ} ​​เดือน",
    numDaysLeft: "เหลือ 0 วัน | เหลือ 1 วัน | {นับ} ​​วันที่เหลือ",
    numDaysDelayed: "0 วันล่าช้า | 1 วันล่าช้า | {นับ} ​​วันล่าช้า",
  },
  notification: {
    sharedWorkflow: "แชร์เวิร์กโฟลว์",
    assignedNewSkill: "แชร์ทักษะ",
    sentAnnouncement: "ส่งประกาศ",
    needValidation: "ต้องการการตรวจสอบของคุณ",
    noNotificationsMsg: "ขณะนี้คุณไม่มีการแจ้งเตือน",
    justNow: "ตอนนี้",
    updatedSkill: "อัปเดตทักษะ",
    unpublishedSkill: "ไม่ได้เผยแพร่ทักษะ",
    sentAReminder: "ส่งคำเตือนให้คุณเกี่ยวกับทักษะนี้ เริ่มดูเพื่อให้เขาสามารถติดตามความคืบหน้าของคุณ",
    assignedNewSkillDue: "มอบหมายทักษะใหม่ให้คุณ คุณมี <span>{time}</span> เพื่อดูมันให้เสร็จ",
    delayedSkillMsg: "คุณมี<span>ทักษะที่ล่าช้า</span>เริ่มดูตอนนี้เพื่อกลับไปติดตาม!",
    daysLeftMsg: "คุณมี<span>เหลือเพียง {time} </span>เพื่อดูทักษะนี้ให้เสร็จ รีบไปแล้วคุณจะไม่ล่าช้า!",
    askToReviewWorkflow: "ขอให้คุณตรวจสอบเวิร์กโฟลว์",
    reactedWorkflowMsg: "<span>{user}</span> ตอบสนองต่อเวิร์กโฟลว์ของคุณ",
    reactedWorkflowMsgTwoUsers: "<span>{user1} และ {user2}</span> ตอบสนองต่อขั้นตอนการทำงานของคุณ",
    reactedWorkflowMsgMoreUsers: "<span>{user1}, {user2} และอีก {count} คน</span>แสดงปฏิกิริยากับเวิร์กโฟลว์ของคุณ",
  },
  mediaPicker: {
    insertFullWorkflow: "ใส่เวิร์กโฟลว์เต็มรูปแบบ",
    selectWorkflowStep: "เลือกขั้นตอนเวิร์กโฟลว์",
    stepSelected: " ขั้นตอนที่เลือก"
  },
  zoomIntegration: {
    settingsTitle: "Zoom การตั้งค่า",
    settingsSubtitle: "บูรณาการ",
    deleteCopy: "ลบสำเนาของวิดีโอที่บันทึกจาก Zoom Cloud",
    goToDeephow: "ไปที่ DeepHow",
    landingSuccess: "<b>แอพวิดีโอความรู้ของ DeepHow</b> ติดตั้งได้สำเร็จ",
    landingError: "เกิดข้อผิดพลาดในขณะที่ติดตั้ง <b> DeepHow ความรู้แอพวิดีโอ </b> กรุณาติดต่อ <a href = 'mailto: service@deephow.com'>service@deephow.com</a> สำหรับการสนับสนุน",
    landingWarning: "การรวมซูมไม่ได้เปิดใช้งานสำหรับองค์กรของคุณ",
  },
  reactions: {
    likeIt: "ชอบมัน",
    greatContent: "เนื้อหาที่ยอดเยี่ยม",
    wellPresented: "นำเสนอได้ดี",
    niceProduction: "การผลิตที่ดี",
  },
  maven: {
    // TODO: maven chat added on 03/15/2023
    mavenChat: "Chat",
    searchInWorkspace: "Search in {workspace}",
    showReferences: "Show references",
    hideReferences: "Hide references",
    helloMessage: "Hello {userName},",
    assistMessage: "How can I assist you today?",
    askMeAnything: "Ask me anything...",
    tryOtherQuestionMessage: "You can try asking other question.",
    audioIsReady: "Please recording your audio clip in English...",
    audioLimitationTime: "The audio limitation time is 3 mins.",
    clearChatHistory: "Clear chat history",
    newLineHint: "new line: shift + Enter",
    // TODO: maven map added on 04/14/2023
    map: "Map",
    resetMap: "reset map",
    filterWorkspaces: "filter workspaces",
    showQuestions: "show questions",
    hideQuestions: "hide questions",
    showClusters: "show clusters",
    hideClusters: "hide clusters",
    question: "Question",
    loadingMap: "Loading map...",
    clearChatHistoryConfirmation: "Clearing the chat history will start a new chat. Are you sure?",
    clearConfirm: "Yes, clear",
  },
  $vuetify: {
    dataIterator: {
      rowsPerPageText: "รายการต่อหน้า:",
      rowsPerPageAll: "ทั้งหมด",
      pageText: "{0} - {1} ของ {2}",
      noResultsText: "ไม่พบบันทึกที่ตรงกัน",
      nextPage: "หน้าต่อไป",
      prevPage: "หน้าก่อนหน้า"
    },
    dataTable: {
      rowsPerPageText: "แถวต่อหน้า:"
    },
    noDataText: "แถวต่อหน้า:",
    carousel: {
      prev: "ภาพก่อนหน้า",
      next: "ภาพต่อไป"
    }
  }
};
