import axios from "axios";
import authChoice from "../../auth";
import environment from "../../env";
import { apiWrapperGenerator } from "./lib";
import { CLIENT_ENV } from "../constants/envVersion";

function getBaseUrl() {
  return environment.backendType === "firebase"
    ? environment.service[environment.env].deephowApiServer
    : environment.service[environment.env]["china"].deephowApiServer;
}

const apiServer = axios.create({
  baseURL: getBaseUrl(),
});

apiServer.interceptors.request.use(
  async (config) => {
    config.headers["x-user-token"] = await authChoice().currentUser.getIdToken();
    config.headers["client-environment"] = CLIENT_ENV;
    return config;
  },
  (error) => Promise.resolve(error)
);

const apiWrapper = apiWrapperGenerator(apiServer);

export const authWorkspaceGroupPermission = ({ workspaceGroupId, params = { workspacesType: "view" } }) =>
  apiWrapper("get", `/v1/workspaces-groups/${workspaceGroupId}/permissions`, { params });

export const authWorkflowPermission = (workflowId) => apiWrapper("get", `/v1/workflows/${workflowId}/permissions`);

export const authGroupPermission = (groupId) => apiWrapper("get", `/v1/groups/${groupId}/permissions`);

export const fetchGroups = (params) => apiWrapper("get", "/v1/groups", { params });

export const fetchWorkspaceById = (groupId) => apiWrapper("get", `/v1/groups/${groupId}`);

export const fetchWorkflowByWorkspaceId = (groupId) => apiWrapper("get", `/v1/groups/${groupId}/workflows`);

export const fetchWorkflowByWorkflowId = (workflowId) => apiWrapper("get", `/v1/workflows/${workflowId}`);

export const fetchWorkflowReviewers = (workflowId) => apiWrapper("get", `/v1/workflows/${workflowId}/reviewers`);

export const fetchWorkflowSteps = (workflowId) => apiWrapper("get", `/v1/workflows/${workflowId}/steps`);

export const fetchAllWorkflows = (params = { page: 0, size: 100 }) =>
  apiWrapper("get", `/v1/workflows/analytics`, { params });

export const deleteWorkflow = async (workflowId) => apiWrapper("delete", `/v1/workflows/${workflowId}`);

export const duplicateWorkflow = ({ workflowId, groupIds }) =>
  apiWrapper("post", `/v1/workflows/${workflowId}/duplication`, { groupIds });

export const updateWorkflowPoster = ({ workflowId, imageUrl }) =>
  apiWrapper("put", `/v1/workflows/${workflowId}/poster`, { imageUrl });

export const updateWorkflowTitle = ({ workflowId, title, languageCode = "" }) =>
  apiWrapper("put", `/v1/workflows/${workflowId}/title`, { title, languageCode });

export const updateWorkflowGroup = ({ workflowId, groupId }) =>
  apiWrapper("put", `/v1/workflows/${workflowId}/group`, { groupId });

export const unpublishWorkflow = (workflowId) => apiWrapper("put", `/v1/workflows/${workflowId}/unpublish`);

export const fetchWorkflowPreviewStatus = (workflowId) =>
  apiWrapper("get", `/v1/workflows/${workflowId}/preview/status`);

export const postGenerateSubtitles = (workflowId) =>
  apiWrapper("post", `/v1/workflows/${workflowId}/preview/subtitles`);

export const postResetWorkflow = ({ workflowId, keepWorkflow }) =>
  apiWrapper("post", `/v1/workflows/${workflowId}/builder/reset`, { keepWorkflow });

export const putWorkflowBuilder = (workflowId, payload) =>
  apiWrapper("put", `/v1/workflows/${workflowId}/builder`, payload);

export const putUpdateTranscription = ({ workflowId, title, sentences, terms, nextStep }) =>
  apiWrapper("put", `/v1/workflows/${workflowId}/transcription`, { title, sentences, terms, nextStep });

export const putUpdateSegmentation = ({ workflowId, title, sentences, steps, nextStep }) =>
  apiWrapper("put", `/v1/workflows/${workflowId}/segmentation`, { title, sentences, steps, nextStep });

export const putReTranscription = ({ workflowId, languageCode }) =>
  apiWrapper("put", `/v1/workflows/${workflowId}/language-code`, { languageCode });

export const postAssignUsersAndTeamsToPlaylist = ({ playlistId, users, teams }) =>
  apiWrapper("post", `/v1/playlists/${playlistId}/members/assign`, { users, teams });

export const deleteUsersOrTeamsFromPlaylist = ({ playlistId, users, teams }) =>
  apiWrapper("delete", `/v1/playlists/${playlistId}/members/remove`, { data: { users, teams } });

export const postTeamListByWorkspaceId = ({ groupId }) => apiWrapper("post", `/v1/teams/teaminfo`, { groupId });

export const fetchPlaylistMemberInfo = ({ playlistId }) => apiWrapper("get", `/v1/playlists/${playlistId}/memberinfo`);

export const deleteWorkflowReaction = ({ workflowId }) =>
  apiWrapper("delete", `/v1/workflows/${workflowId}/reactions`, { data: { workflowId } });

export const updateWorkflowReaction = ({ workflowId, reaction }) =>
  apiWrapper("put", `/v1/workflows/${workflowId}/reactions`, { reaction });

export const fetchWorkflowReactions = ({ workflowId }) => apiWrapper("get", `/v1/workflows/${workflowId}/reactions`);

export const fetchUserCreatedWorkflows = ({ uid, page, size }) =>
  apiWrapper("get", `/v1/workflows/${uid}/published?page=${page}&size=${size}`);

export const postInitializeWorkflow = (workflow) => apiWrapper("post", `/v1/workflows/initialize`, { workflow });

export const postWorkflowFeedback = ({ workflowId, feedback, languageCode, baseUrl }) =>
  apiWrapper("post", `/v1/workflows/${workflowId}/feedback`, { feedback, languageCode, baseUrl });

export const createTextToSpeechs = ({ workflowId, text2Speechs }) =>
  apiWrapper("post", `/v1/workflows/${workflowId}/text-2-speech`, { text2Speechs });

// categories
export const fetchCategories = (params) => apiWrapper("get", "/v1/categories", { params });

export const fetchSubCategories = (params) => apiWrapper("get", "/v1/subcategories", { params });

// Workflow comments:
export const fetchCommentsById = ({ level, id, withReactions = false, page = 0, size = 100 }) =>
  apiWrapper("post", `/v1/comments?page=${page}&size=${size}`, { level, id, withReactions });

export const postCreateNewComment = ({ id, level, comment }) =>
  apiWrapper("post", `/v1/comments/${id}`, { level, comment });

export const deleteCommentByCommentId = ({ commentId }) => apiWrapper("delete", `/v1/comments/${commentId}`);

export const updateCommentByCommentId = ({ commentId, comment }) =>
  apiWrapper("put", `/v1/comments/${commentId}`, { comment });

// Workflow comment reactions:
export const fetchCommentReactions = ({ commentId }) => apiWrapper("get", `/v1/comments/${commentId}/reactions`);

export const createCommentReaction = ({ commentId, emojiChar, emojiCode }) =>
  apiWrapper("post", `/v1/comments/${commentId}/reactions`, { emojiChar, emojiCode });

export const deleteCommentReaction = ({ commentId, reactionId }) =>
  apiWrapper("delete", `/v1/comments/${commentId}/${reactionId}`);

export const getUserProfileApi = () => apiWrapper("get", "/v1/profile/me");

export const createUserConsents = async (consents) => apiWrapper("post", "/v1/userConsents", { consents });
export const updateUserConsents = async (consents) => apiWrapper("put", "/v1/userConsents", { consents });
export const getUserConsents = async () => apiWrapper("get", "/v1/userConsents");

export default apiWrapper;
