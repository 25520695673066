import { getUuidv4 } from "@/server/upload-server.js";
import { getOrganizationSettings, updateOrganizationSettings } from "@/server/organization-settings-server.js";
import { updateIpRestrictionSettings } from "@/server/custom-features-server.js";

export const namespaced = true;

export const state = {
  textToSpeechEnabled: false,
  nameChangeEnabled: true,
  workflowCommentsEnabled: false,
  zoomIntegrationEnabled: false,
  scormDownloadEnabled: false,
  ulIntegrationEnabled: false,
  publicSharingEnabled: true,
  ipRestrictionFeatureEnabled: false,
  ipRestrictionEnabled: false,
  whitelistedIpList: [],
  isRedirectedByIpRestriction: false,
  hasEmployeeIdLogin: false,
  hasSharedAccountLogin: false,
  dhLoginEnabled: true,
  employeeCreationEnabled: false,
  mavenEnable: false,
};

export const getters = {
  getter_text_to_speech_enabled(state) {
    return state.textToSpeechEnabled;
  },
  getter_name_change_enabled(state) {
    return state.nameChangeEnabled;
  },
  getter_workflow_comments_enabled(state) {
    return state.workflowCommentsEnabled;
  },
  getter_scorm_download_enabled(state) {
    return state.scormDownloadEnabled;
  },
  getter_zoom_integration_enabled(state) {
    return state.zoomIntegrationEnabled;
  },
  getter_ul_integration_enabled(state) {
    return state.ulIntegrationEnabled;
  },
  getter_public_sharing_enabled(state) {
    return state.publicSharingEnabled;
  },
  getter_ip_restriction_feature_enabled(state) {
    return state.ipRestrictionFeatureEnabled;
  },
  getter_ip_restriction_enabled(state) {
    return state.ipRestrictionEnabled;
  },
  getter_whitelisted_ip_list_with_id(state) {
    return state.whitelistedIpList.map((ip) => ({ id: getUuidv4(), ...ip }));
  },
  getter_is_redirected_by_ip_restriction(state) {
    return state.isRedirectedByIpRestriction;
  },
  getter_has_employee_id_login(state) {
    return state.hasEmployeeIdLogin;
  },
  getter_shared_account_enabled(state) {
    return state.hasSharedAccountLogin && state.hasEmployeeIdLogin;
  },
  getter_dh_login_enabled(state) {
    return state.dhLoginEnabled;
  },
  getter_employee_creation_enabled(state) {
    return state.employeeCreationEnabled;
  },
};

export const mutations = {
  SET_TEXT_TO_SPEECH_SETTING(state, enabled) {
    state.textToSpeechEnabled = enabled;
  },
  SET_NAME_CHANGE_SETTING(state, enabled) {
    state.nameChangeEnabled = enabled;
  },
  SET_WORKFLOW_COMMENTS_SETTING(state, enabled) {
    state.workflowCommentsEnabled = enabled;
  },
  SET_ZOOM_INTEGRATION_SETTING(state, enabled) {
    state.zoomIntegrationEnabled = enabled;
  },
  SET_SCORM_DOWNLOAD_SETTING(state, enabled) {
    state.scormDownloadEnabled = enabled;
  },
  SET_UL_INTEGRATION_SETTING(state, enabled) {
    state.ulIntegrationEnabled = enabled;
  },
  SET_PUBLIC_SHARING_SETTING(state, enabled) {
    state.publicSharingEnabled = enabled;
  },
  SET_IP_RESTRICTION_FEATURE_SETTING(state, enabled) {
    state.ipRestrictionFeatureEnabled = enabled;
  },
  SET_IP_RESTRICTION_SETTING(state, enabled) {
    state.ipRestrictionEnabled = enabled;
  },
  SET_IP_RESTRICTION_WHITELIST(state, rules) {
    state.whitelistedIpList = rules;
  },
  SET_IS_REDIRECTED_BY_IP_RESTRICTION(state, bool) {
    state.isRedirectedByIpRestriction = bool;
  },
  SET_HAS_EMPLOYEE_ID_LOGIN(state, enabled) {
    state.hasEmployeeIdLogin = enabled;
  },
  SET_HAS_SHARED_ACCOUNT_LOGIN(state, enabled) {
    state.hasSharedAccountLogin = enabled;
  },
  SET_DH_LOGIN_SETTING(state, enabled) {
    state.dhLoginEnabled = enabled;
  },
  SET_EMPLOYEE_CREATION_SETTING(state, enabled) {
    state.employeeCreationEnabled = enabled;
  },
  SET_MAVEN_ENABLED(state, enabled) {
    state.mavenEnable = enabled;
  },
};

export const actions = {
  async getOrgPermissionsSettings({ commit }, option) {
    const settings = await getOrganizationSettings(option);
    const { userPermissions, customizedPermissions, customFeatures, sharedDevice } = settings;

    const disableDHLogin = sharedDevice?.disableDHLogin ?? false;
    commit("SET_DH_LOGIN_SETTING", !disableDHLogin);

    const employeeCreationEnabled = sharedDevice?.enableEmployeeCreation ?? false;
    commit("SET_EMPLOYEE_CREATION_SETTING", employeeCreationEnabled);

    // User permissions settings.
    // Set `nameChangeEnabled` default to `true`.
    const changeNameEnabled = userPermissions?.allowNameChange ?? true;
    commit("SET_NAME_CHANGE_SETTING", changeNameEnabled);

    // Customized permissions settings.
    const workflowCommentsEnabled = customizedPermissions?.enableComments ?? false;
    commit("SET_WORKFLOW_COMMENTS_SETTING", workflowCommentsEnabled);

    const restrictedIPEnabled = customizedPermissions?.enableRestrictedIP ?? false;
    commit("SET_IP_RESTRICTION_SETTING", restrictedIPEnabled);

    const allowedRules = customizedPermissions?.restrictedIP?.allowedRules ?? [];
    commit("SET_IP_RESTRICTION_WHITELIST", allowedRules);

    const hasSharedAccountLogin = settings.sharedAccountLogin ?? false;
    commit("SET_HAS_SHARED_ACCOUNT_LOGIN", hasSharedAccountLogin);

    const hasEmployeeIdLogin = settings.employeeIdLogin ?? false;
    commit("SET_HAS_EMPLOYEE_ID_LOGIN", hasEmployeeIdLogin);

    // Custom features settings.
    const zoomIntegrationEnabled = customFeatures?.enableZoomIntegration ?? false;
    commit("SET_ZOOM_INTEGRATION_SETTING", zoomIntegrationEnabled);

    const scormDownloadEnabled = customFeatures?.enableSCORM ?? false;
    commit("SET_SCORM_DOWNLOAD_SETTING", scormDownloadEnabled);

    const ulIntegrationEnabled = customFeatures?.enableUlIntegration ?? false;
    commit("SET_UL_INTEGRATION_SETTING", ulIntegrationEnabled);

    // Set `publicSharingEnabled` default to `true`.
    const publicSharingEnabled = customFeatures?.publicSharing ?? true;
    commit("SET_PUBLIC_SHARING_SETTING", publicSharingEnabled);

    const textToSpeechEnabled = customFeatures?.enableText2Speech ?? false;
    commit("SET_TEXT_TO_SPEECH_SETTING", textToSpeechEnabled);

    const ipRestrictionFeatureEnabled = customFeatures?.enableRestrictedIP ?? false;
    commit("SET_IP_RESTRICTION_FEATURE_SETTING", ipRestrictionFeatureEnabled);
    commit("SET_MAVEN_ENABLED", customFeatures?.maven ?? false);
  },
  async updateNameChangeSettings({ commit }, { organization, updateData }) {
    await updateOrganizationSettings({ organization, updateData });
    commit("SET_NAME_CHANGE_SETTING", !state.nameChangeEnabled);
  },
  async updateWorkflowCommentsSettings({ commit }, { organization, updateData }) {
    await updateOrganizationSettings({ organization, updateData });
    commit("SET_WORKFLOW_COMMENTS_SETTING", !state.workflowCommentsEnabled);
  },
  async updateSharedAccountSettings({ commit }, { organization, updateData }) {
    await updateOrganizationSettings({ organization, updateData });
    commit("SET_HAS_SHARED_ACCOUNT_LOGIN", !state.hasSharedAccountLogin);
  },
  async updateSharedDeviceSettings({ commit }, { organization, updateData, sharedDeviceType }) {
    await updateOrganizationSettings({ organization, updateData });
    const sharedDeviceTypeDictionary = {
      DH_LOGIN: () => commit("SET_DH_LOGIN_SETTING", !state.dhLoginEnabled),
      EMPLOYEE_CREATION: () => commit("SET_EMPLOYEE_CREATION_SETTING", !state.employeeCreationEnabled),
    };
    sharedDeviceTypeDictionary[sharedDeviceType]();
  },
  async updateRestrictedIpSettings({ commit }, payload) {
    const { ok, data, code } = await updateIpRestrictionSettings(payload);
    if (ok) {
      commit("SET_IP_RESTRICTION_SETTING", data.item.enableRestrictedIP);
      commit("SET_IP_RESTRICTION_WHITELIST", data.item.restrictedIP.allowedRules);
    }
    return { ok, code };
  },
  async setIsRedirectedByIpRestriction({ commit }, bool) {
    commit("SET_IS_REDIRECTED_BY_IP_RESTRICTION", bool);
  },
  setHasEmployeeIdLogin({ commit }, enabled = false) {
    commit("SET_HAS_EMPLOYEE_ID_LOGIN", enabled);
  },
};
