import { covertDbTimestampToString } from "@/js/data-table/data-table-time.js";

export const USER_DATA_TABLE_TYPE = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  INVITED: "invited",
};

export function getBaseDataForUserDataTable({ user, dataTableType }) {
  return {
    ...getLastActivityInfo({
      user,
      dataTableType,
    }),
    status: getStatusInfo({
      user,
      dataTableType,
    }),
  };
}

function getStatusInfo({ user, dataTableType }) {
  switch (dataTableType) {
    case USER_DATA_TABLE_TYPE.INVITED:
      if (user.status.toLowerCase().includes("error")) {
        return "could-not-be-invited";
      } else if (user.status == "expired") {
        return "invitation-expired";
      } else {
        return "invited";
      }
    default:
      return user.disabled === true ? "inactive" : "active";
  }
}

function getLastActivityInfo({ user, dataTableType }) {
  let lastActivity;

  switch (dataTableType) {
    case USER_DATA_TABLE_TYPE.INVITED:
      lastActivity = null;
      break;
    default:
      lastActivity = covertDbTimestampToString({
        timestampSeconds: getLastAcvitityTiemstamp({ user }),
      });
      break;
  }

  const lastActivityString = lastActivity ? lastActivity.toDateString() : null;
  const lastActivityInfo = {
    lastActivityTimestamp: lastActivity ? lastActivity.timestamp : null,
    lastActivityString: lastActivityString || "-",
  };

  return lastActivityInfo;
}

function getLastAcvitityTiemstamp({ user }) {
  if (!user.lastActivity) {
    // empty data
    return;
  } else if (typeof user.lastActivity === "number") {
    // china DB
    return user.lastActivity;
  } else if (typeof user.lastActivity.seconds === "number") {
    // global DB
    return user.lastActivity.seconds;
  }
}
