import { render, staticRenderFns } from "./UserPrivacyPolicyPopup.vue?vue&type=template&id=030a6afa&scoped=true&"
import script from "./UserPrivacyPolicyPopup.vue?vue&type=script&lang=js&"
export * from "./UserPrivacyPolicyPopup.vue?vue&type=script&lang=js&"
import style0 from "./UserPrivacyPolicyPopup.vue?vue&type=style&index=0&id=030a6afa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "030a6afa",
  null
  
)

export default component.exports