<template>
  <div class="d-admin-permissions">
    <div class="content-title">{{ $t("admin.masterPassword") }}</div>
    <div class="permissions-scroll">
      <div class="switch-control-div mt-3">
        <div class="products-title">{{ $t("admin.employeeIDAccess") }}</div>
        <p class="products-subtitle">{{ $t("admin.employeeIDAccessMsg") }}</p>
        <p v-if="isPending || isResting" class="pending-save-msg">
          <span>{{ isPending ? $t("admin.savePasswordMsg") : $t("admin.resetPasswordMsg") }}</span>
        </p>
        <div v-if="showRestBtn" class="password-input">
          <div class="password-form mr-3">
            <d-text-field
              v-model="currentPassword"
              readonly
              :append-icon="showCurrentPassword ? 'visibility' : 'visibility_off'"
              :type="showCurrentPassword ? 'text' : 'password'"
              :label="$t('admin.currentPassword')"
              @click-append="clickShowCurrentPassword"
              autocomplete="chrome-off"
              :key="'currentPassword' + showCurrentPassword"
            ></d-text-field>
          </div>
          <d-button
            depressed
            round
            class="body-2 text-capitalize white--text no-transform save-btn"
            color="#4689f4"
            light
            @click="resetPassword"
            >{{ $t("auth.resetPassword") }}</d-button
          >
        </div>
        <div v-if="isPending || isResting" class="password-input">
          <v-form autocomplete="off" v-model="formValid" class="password-form mr-3">
            <d-text-field
              v-model="employeeIdMP"
              :append-icon="showPassword ? 'visibility' : 'visibility_off'"
              :rules="[rules.validPassword, rules.noWhiteSpace, rules.min, rules.max]"
              :type="showPassword ? 'text' : 'password'"
              :label="$t('auth.password')"
              :disabled="!employeeMPEnabled"
              @click-append="showPassword = !showPassword"
              autocomplete="chrome-off"
              key="newPassword"
            ></d-text-field>
          </v-form>
          <d-button
            depressed
            round
            class="body-2 text-capitalize white--text no-transform save-btn"
            color="#4689f4"
            :disabled="!formValid"
            light
            @click="savePassword"
            >{{ $t("all.save") }}</d-button
          >
          <d-button
            v-if="isResting"
            depressed
            round
            outline
            class="body-2 text-capitalize no-transform save-btn ml-3"
            color="#4689f4"
            light
            @click="cancelResetPassword"
            >{{ $t("all.cancel") }}</d-button
          >
        </div>
        <div class="switch-div">
          <v-switch
            v-model="employeeMPEnabled"
            color="#4689F3"
            class="vertical-center"
            :readonly="true"
            @click.stop="clickEmployeeMPEnabledSwitch"
          ></v-switch>
        </div>
      </div>
    </div>

    <d-change-permission-confirmation
      v-if="showConfirmation == 'reset-password'"
      :title="$t('admin.resetMasterPassword')"
      :content="$t('admin.resetMasterPasswordMsg')"
      @close-popup="showConfirmation = ''"
      @change-permission="updateMasterPassword"
    ></d-change-permission-confirmation>

    <d-change-permission-confirmation
      v-if="showConfirmation == 'enable-password'"
      :title="$t('admin.enableMasterPassword')"
      :content="$t('admin.employeeIDAccessMsg')"
      :actionBtn="$t('admin.yesEnable')"
      @close-popup="showConfirmation = ''"
      @change-permission="enableMasterPassword"
    ></d-change-permission-confirmation>

    <d-change-permission-confirmation
      v-if="showConfirmation == 'disable-password'"
      :title="$t('admin.disableMasterPassword')"
      :content="$t('admin.disableMasterPasswordMsg')"
      :actionBtn="$t('admin.yesDisable')"
      @close-popup="showConfirmation = ''"
      @change-permission="disableMasterPassword"
    ></d-change-permission-confirmation>
  </div>
</template>

<script>
import Vue from "vue";
import IconBase from "@/components/IconBase.vue";
import DIconDone from "@/components/icons/DIconDone.vue";
import DChangePermissionConfirmation from "@/components/DAdmin/confirmations/DChangePermissionConfirmation.vue";
import DTextField from "@/components/ui_components/DTextField.vue";
import MixinUser from "@/components/MixinUser.vue";
import MixinDB from "@/components/MixinDB.vue";
import { updateOrganizationSettings } from "@/server/organization-settings-server.js";
import { isEnableMasterPassword } from "@/js/employeeid-login/employeeid-login-service.js";
import { getMasterPassword, resetMasterPassword } from "@/server/authentication-server.js";
import { i18nKeysDictionary } from "@/js/authentication";
import { mapMutations } from "vuex";

export default {
  name: "AdminMasterPassword",
  props: {},
  watch: {},
  mixins: [MixinUser, MixinDB],
  created() {
    this.customizedPermissions = this.getCustomizedPermissions();
    this.initPageDataAfterFetchingDataFromDB();
  },
  mounted() {},
  data() {
    return {
      showConfirmation: "",
      employeeMPEnabled: null,
      customizedPermissions: null,
      showPassword: false,
      showCurrentPassword: false,
      currentPassword: "******",
      currentPasswordInDb: "",
      employeeIdMP: "",
      formValid: false,
      rules: {
        noWhiteSpace: (value) => {
          const pattern = /^[^ ]+$/;
          return pattern.test(value) || this.$t("rules.noSpace");
        },
        noAllSpace: (value) => {
          const patt = /^[\s]*$/;
          return !patt.test(value) || this.$t("rules.noAllSpace");
        },
        required: (value) => !!value || this.$t("rules.required"),
        max: (v) => (v && v.length <= 25) || this.$t("rules.max25"),
        min: (v) => (v && v.length >= 10) || this.$t("rules.min10"),
        validPassword: (value) => {
          // Include lower and upper case letters, numbers, and special characters.
          const pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!-\/:-@[-\`{-~]).*$/;
          return pattern.test(value) || this.$t("rules.validPasswordCharacters");
        },
      },
      isPending: false,
      showRestBtn: false,
      isResting: false,
    };
  },
  components: {
    IconBase,
    DIconDone,
    DChangePermissionConfirmation,
    DTextField,
  },
  methods: {
    ...mapMutations("global", ["MUTATE_ALERT_INFO"]),
    clickShowCurrentPassword() {
      this.showCurrentPassword = !this.showCurrentPassword;
      this.currentPassword = this.showCurrentPassword ? this.currentPasswordInDb : "******";
    },
    cancelResetPassword() {
      this.isResting = false;
      this.showRestBtn = true;
    },
    resetPassword() {
      this.isResting = true;
      this.showRestBtn = false;
    },
    clickEmployeeMPEnabledSwitch() {
      if (this.isPending && this.employeeMPEnabled) {
        this.isPending = false;
        this.employeeMPEnabled = false;
        return;
      }
      if (this.employeeMPEnabled) {
        // show turn feature off popup
        this.showConfirmation = "disable-password";
      } else {
        // enable password field, Master Password won't set until password is saved;
        this.employeeMPEnabled = true;
        this.isPending = true;
      }
    },
    async initPageDataAfterFetchingDataFromDB({ recursiveCount } = {}) {
      if (recursiveCount > 10) {
        console.error("recursive count is over limit", recursiveCount);
        return;
      }

      const IsNeedToWaitUntilMixinUserHasInitialized = this.$clientKey === undefined;
      if (IsNeedToWaitUntilMixinUserHasInitialized) {
        setTimeout(() => {
          this.initPageDataAfterFetchingDataFromDB({
            recursiveCount: (recursiveCount || 0) + 1,
          });
        }, 300);
        return;
      }

      const masterPassword = await isEnableMasterPassword({
        clientKey: this.$clientKey,
      });

      try {
        const { ok, data } = await getMasterPassword();
        if (ok) {
          this.employeeMPEnabled = masterPassword.isEnabled;
          this.updateCurrentPasswordFromDB({ newPassword: data.item.employeeIdMP });
        }
      } catch (error) {
        console.log(error);
      }

      if (masterPassword.isEnabled) {
        this.showRestBtn = true;
      }
    },
    async updateMasterPassword() {
      try {
        const { ok } = await resetMasterPassword(this.employeeIdMP);
        if (!ok) {
          const i18nKey = i18nKeysDictionary[error.response.data.authType];
          const message = !!i18nKey ? this.$t(`auth.${i18nKey}`) : this.$t("admin.alertResetMasterPasswordFailed");
          this.MUTATE_ALERT_INFO({ message, type: "error" });
        }
        this.updateCurrentPasswordFromDB({
          newPassword: this.employeeIdMP,
        });
        this.resetStatesByMasterPasswordState({ employeeMPEnabled: true });
        const message = this.$t("admin.alertResetMasterPasswordSuccess");
        this.MUTATE_ALERT_INFO({ message, type: "success" });
      } catch (error) {
        console.log(error);
      }
    },
    savePassword() {
      if (this.isPending) {
        this.showConfirmation = "enable-password";
        return;
      }
      if (this.isResting) {
        this.showConfirmation = "reset-password";
      }
    },
    updateCustomizedPermissionsToDb() {
      const self = this;
      return new Promise(function (resolve, reject) {
        self
          .updateDocument("settings", self.$organization, {
            customizedPermissions: self.customizedPermissions,
          })
          .then(() => {
            Vue.prototype.$clientSettings.customizedPermissions = self.customizedPermissions;
            self.updateIdleSetting(
              self.customizedPermissions.allowAutoLogout,
              self.customizedPermissions.autoLogoutTimeout
            );
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async disableMasterPassword() {
      const response = await updateOrganizationSettings({
        organization: this.$organization,
        updateData: {
          employeeIdMPEnabled: false,
          employeeIdMP: null,
        },
      });
      if (response) {
        this.resetStatesByMasterPasswordState({ employeeMPEnabled: false });
        const message = this.$t("admin.alertDisablePasswordSuccess");
        this.MUTATE_ALERT_INFO({ message, type: "success" });
      } else {
        const message = this.$t("admin.alertDisablePasswordFailed");
        this.MUTATE_ALERT_INFO({ message, type: "error" });
      }
    },
    updateCurrentPasswordFromDB({ newPassword }) {
      this.currentPasswordInDb = newPassword;
      if (this.showCurrentPassword) {
        this.currentPassword = newPassword;
      }
    },
    async enableMasterPassword() {
      const newPassword = this.employeeIdMP;
      const response = await updateOrganizationSettings({
        organization: this.$organization,
        updateData: {
          employeeIdMPEnabled: true,
          employeeIdMP: this.employeeIdMP,
        },
      });
      if (response) {
        this.resetStatesByMasterPasswordState({ employeeMPEnabled: true });
        this.updateCurrentPasswordFromDB({ newPassword });
        const message = this.$t("admin.alertEnablePasswordSuccess");
        this.MUTATE_ALERT_INFO({ message, type: "success" });
      } else {
        const message = this.$t("admin.alertEnablePasswordFailed");
        this.MUTATE_ALERT_INFO({ message, type: "error" });
      }
    },
    resetStatesByMasterPasswordState({ employeeMPEnabled }) {
      this.employeeMPEnabled = employeeMPEnabled;
      this.showRestBtn = employeeMPEnabled;
      this.isPending = false;
      this.isResting = false;
      this.showConfirmation = "";
      this.employeeIdMP = "";
    },
  },
  computed: {},
};
</script>

<style scoped>
.d-admin-permissions {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 32px;
  text-align: left;
}
.permissions-scroll {
  width: 100%;
  overflow: auto;
  padding-bottom: 64px;
}
.permissions-scroll:hover::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
}
.content-title {
  color: #3f3f3f;
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 20px;
}
.products-title {
  color: #3f3f3f;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 4px;
}
.products-subtitle {
  color: #919191;
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 4px 0;
}
.pending-save-msg {
  height: 20px;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  color: #4689f3;
}
.switch-control-div {
  position: relative;
  width: 100%;
  min-width: 760px;
  border: 1px solid #e7e7e7;
  background-color: #ffffff;
  box-shadow: 0 0 4px -2px rgba(0, 0, 0, 0.15);
  padding: 24px 88px 24px 24px;
}
.switch-div {
  position: absolute;
  right: 24px;
  top: 0;
  height: 80px;
  width: 36px;
}
.logout-switch-div {
  position: absolute;
  right: 24px;
  top: 12px;
  height: 50px;
  width: 36px;
}
.vertical-center {
  position: absolute;
  top: 50%;
  top: calc(50% - 6px);
  transform: translateY(-50%);
}
.password-input {
  display: inline-flex;
  height: 100px;
  width: 100%;
  margin-top: 52px;
}
.save-btn {
  min-width: 120px;
  margin: 28px 0 0 0;
}
.password-form {
  width: 320px;
}
</style>
