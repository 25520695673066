<template>
  <div class="DAllReactPopup" :class="{ 'drawer-open': isSideMenuOpen }" @click="$emit('close-popup')">
    <div
      class="DAllReactPopup__popup"
      :class="{ 'DAllReactPopup__popup--mobileFullscreen': isMobileFullscreen }"
      @click.stop
    >
      <div class="DAllReactPopup__title">
        <span>{{ $t("player.userReaction") }}</span>
        <v-spacer></v-spacer>
        <button @click="$emit('close-popup')" autotest="react-button-exit" class="mt-1">
          <icon-base color="#FFFFFF" width="16" height="16">
            <d-icon-close2 />
          </icon-base>
        </button>
      </div>
      <div class="DAllReactPopup__tabs">
        <button
          class="DAllReactPopup__tabs--tab"
          :class="{ 'DAllReactPopup__tabs--currentTab': currentTab === 'all' }"
          @click="currentTab = 'all'"
        >
          {{ $t("all.all") }}
        </button>
        <button
          v-for="reaction in reactionSet"
          :key="reaction.id"
          class="DAllReactPopup__tabs--tab"
          :class="{ 'DAllReactPopup__tabs--currentTab': currentTab === reaction.id }"
          @click="currentTab = reaction.id"
        >
          <img :src="reaction.icon" :alt="reaction.name" height="20" class="DAllReactPopup__tabs--tabIcon" />
          {{ getReactionName(reaction.name) }}
        </button>
      </div>

      <div class="DAllReactPopup__list">
        <div v-for="item in displayUserReactions" :key="item.uid" class="DAllReactPopup__list--item">
          <router-link :to="{ name: 'UserProfileMain', params: { uid: item.uid } }" target="_blank">
            <d-avatar :size="40" :userId="item.uid" />
          </router-link>
          <span class="ml-3 mr-3 text-truncate text-no-wrap">{{ item.displayName }}</span>
          <v-spacer></v-spacer>
          <div class="DAllReactPopup__list--itemIcon">
            <img :src="getReactionSrc(item.reaction)" height="24" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconClose2 from "@/components/icons/DIconClose2.vue";
import DAvatar from "@/components/ui_components/DAvatar.vue";
import { getReactionDisplayName } from "@/js/reactions/reactions.js";
import { mapState, mapGetters } from "vuex";

export default {
  name: "DAllReactPopup",
  components: { IconBase, DIconClose2, DAvatar },
  props: {
    isMobileFullscreen: Boolean,
  },
  data() {
    return {
      currentTab: "all",
    };
  },
  methods: {
    getReactionSrc(id) {
      const reaction = this.reactionSet.find((item) => item.id === id);
      return reaction ? reaction.icon : "";
    },
    getReactionName(name) {
      return getReactionDisplayName({ name, vueInstanceRef: this });
    },
  },
  computed: {
    ...mapState("workflowReactions", ["reactionSet", "reactionsByWorkflow"]),
    ...mapGetters("global", ["getter_is_adjusted_position_by_opened_side_menu"]),
    isSideMenuOpen() {
      return this.getter_is_adjusted_position_by_opened_side_menu(this.$route.name);
    },
    displayUserReactions() {
      return this.reactionsByWorkflow
        .filter((item) => {
          if (this.currentTab === "all") {
            return true;
          } else {
            return item.reaction === this.currentTab;
          }
        })
        .sort(function (a, b) {
          const ATime = new Date(a.timestamp).getTime();
          const BTime = new Date(b.timestamp).getTime();
          return BTime - ATime;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
$iconSize: 24px;
.DAllReactPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  &.drawer-open {
    left: calc(var(--side-bar-width) * -1);
    width: 100vw;
  }
  &__popup {
    position: relative;
    height: 480px;
    width: 100%;
    max-width: 636px;
    background-color: #1d1e21;
    margin: 0 auto;
    border-radius: 8px;
    overflow: hidden;
    @media screen and (max-width: 599px) {
      position: absolute;
      bottom: 0;
      left: 0;
      min-height: 480px;
      height: 80%;
    }
    &--mobileFullscreen {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      max-width: unset;
      min-height: unset;
    }
  }
  &__title {
    height: 54px;
    padding: 0 20px 0 16px;
    background: #33353a;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #9397a6;
  }
  &__tabs {
    height: 54px;
    padding: 0 16px;
    display: flex;
    background: #28292e;
    overflow: auto;
    &--tab {
      position: relative;
      height: 100%;
      padding: 2px 8px 0 8px;
      margin-right: 16px;
      border-bottom: 2px solid transparent;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      white-space: nowrap;
    }
    &--currentTab {
      border-bottom: 2px solid #4689f3;
    }
    &--tabIcon {
      margin-bottom: -4px;
      margin-right: 4px;
    }
  }
  &__tabs::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &__tabs:hover::-webkit-scrollbar-thumb {
    background-color: #33353a;
  }
  &__list {
    height: calc(100% - 140px);
    width: calc(100% - 20px);
    margin-top: 16px;
    overflow: auto;
    &--item {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      height: 72px;
      padding: 0 24px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }
    &--itemIcon {
      height: 40px;
      min-width: 40px;
      padding-top: 8px;
      border-radius: 50%;
      background: #28292e;
    }
  }
  &__list::-webkit-scrollbar {
    width: 4px;
  }
  &__list::-webkit-scrollbar-track {
    border-radius: 4px;
    background: #33353a;
  }
  &__list::-webkit-scrollbar-thumb {
    background-color: #9397a6;
  }
}
</style>
