<template lang="html">
  <section class="mixin-admin"></section>
</template>

<script lang="js">
import MixinDB from "./MixinDB.vue";
import MixinUser from "./MixinUser.vue";
import MixinAnalytics from "./MixinAnalytics.vue"
import axios from "axios";
import searchServer from "@/server/search-server";
import { updateSharedAccount } from "@/server/authentication-server.js";
import { mapActions } from "vuex";

export default  {
  name: 'mixin-admin',
  props: [],
  mixins: [MixinDB, MixinUser, MixinAnalytics],
  mounted() {},
  data() {
    return {}
  },
  methods: {
    ...mapActions(['updateUser']),
    deleteUserInvite(invite){
      const self = this;
      return new Promise(function(resolve, reject){
        self.deleteDocument("invitations", invite.id).then(()=>{
          resolve();
        }).catch(err=>{
          reject();
        })
      })
    },
    getUserEmailFormat(user){
      if(user.employeeId){
        return user.employeeId;
      }else{
        return user.email;
      }
    },
    formatUserSearchResults(list){
      const hasInviteDate = (user) => {
        let status = user.status;
        if (user.status !== "finished") {
          if (user.status.toLowerCase().includes("error")) {
            status = "could-not-be-invited";
          } else if (user.status == "expired") {
            status = "invitation-expired";
          } else {
            status = "invited";
          }
        }
        return {
          ...user,
          invitations: true,
          status,
        };
      };
      const noInviteDate = (user) => {
        let employeeId = user.employeeId
        if (!user.employeeId) {
          const re = /\.(.*?)\@/;
          let id = (user.email.includes("@deephow.ai") && re.exec(user.email))? re.exec(user.email)[1] : '';
          id = user.shared && id.substring(0, 7)==="shared."? id.slice(7) : id;
          employeeId = id;
        }
        const status = (user.disabled === true) ? "inactive" : "active";
        return {
          ...user,
          status,
          employeeId
        };
      };
      const result = list.map(user => {
        return user.inviteDate ? hasInviteDate(user) : noInviteDate(user);
      })
      return result;
    },
    findUsersWithRole({ input, group, role, apiQueryPage }) {
      const self = this;
      apiQueryPage++;
      const paginate = { page: apiQueryPage, size: 200 };

      return new Promise(async function(resolve, reject) {
        try {
          const payload = {
            published: true,
            text: input,
            locale: "en-us",
            token: await self.getIdToken(),
            organization: self.$organization,
            index: "users",
            group,
            role,
            paginate,
          };
          const response = await searchServer.post("search", payload);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      })
    },
    findUser(input, group, invitations){
      const self = this;
      return new Promise(async function(resolve, reject) {
        try {
          const payload = {
            published: true,
            text: input,
            locale: "en-us",
            token: await self.getIdToken(),
            organization: self.$organization,
            index: "users"
          };
          if (group) {
            payload.group = group;
          }
          if (invitations) {
            payload.invitations = true;
          }
          const response = await searchServer.post("search", payload);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      })
    },
    sendAdminRequest(action, type, data, route){
      const self = this;
      if(!route){
        route = "admin";
      }
      return new Promise(async function(resolve, reject){
        data["idToken"] = await self.getIdToken();
        data["organization"] = self.$organization;
        axios
        .post(self.$service.admin + route, {
          token: "rG5kXk0CDbhgF4RBlNoV",
          action: action,
          type: type,
          data: data
        })
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
      })
    },
    updateGroupsUsersCount(groupsData) {
      const self = this;
      return new Promise(function (resolve, reject) {
        let groups = [];
        groupsData.forEach((group) => {
          groups.push(group.id)
        });
        self.getGroupsStats(groups)
          .then((data) => {
            groupsData.forEach((group) => {
              const target = data.find(x => x.id === group.id);
              if (target) {
                group.numberOfUsers = target.numberOfUsers;
              }
            });
            resolve(groupsData);
          })
          .catch((err) => {reject(err)});
      });
    },
    updateGroupUsersCount(groupData) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self.getGroupUserStats(groupData.id)
          .then((data) => {
            groupData.numberOfUsers = data.total;
            resolve(groupData);
          })
          .catch((err) => {reject(err)});
      });
    },
    async getUserSettings(email) {
      const data = {
        idToken: await this.getIdToken(),
        email
      };
      try {
        const result_axios = await axios
          .post(this.$service.admin + 'user/user-settings', {
            token: "rG5kXk0CDbhgF4RBlNoV",
            action: 'get',
            data
          });
        return result_axios;
      } catch(e) {
        console.log(e);
      }
    },
    async updateUserSettings(email, userSettings) {
      const data = {
        idToken: await this.getIdToken(),
        email,
        userSettings
      }
      try {
        const result_axios = await axios
          .post(this.$service.admin + 'user/user-settings', {
            token: "rG5kXk0CDbhgF4RBlNoV",
            action: 'update',
            data
          });
        return result_axios;
      } catch(e) {
        console.log(e);
      }
    },
    async updateUserName(payload) {
      const { formData, fullName: displayName, email, groupId, isActive } = payload;
      if (formData.displayName === displayName) return { ok: true };
      const { ok } = !!formData.shared
        ? await this.editSharedAccountUserName({ formData, displayName })
        : await this.editUserName({ email, groupId, displayName });
      if (ok) {
        await this.getUpdatedUser({ formData, displayName, isActive });
        return { ok };
      }
      return { ok: false };
    },
    async editSharedAccountUserName({ formData, displayName }) {
      const { ok } = await updateSharedAccount({
        id: formData.id,
        displayName,
      });
      return { ok };
    },
    async editUserName({ email, groupId, displayName }) {
      try {
        const id = this.userRole === "admin-group" ? groupId : null;
        const data = {
          email,
          displayName,
          ...(!!id ? { groupId: id } : {}),
        };
        await this.sendAdminRequest("change", "user-name-change", data);
        return { ok: true };
      } catch (error) {
        return { ok: false };
      }
    },
    async getUpdatedUser({ formData, displayName, isActive }) {
      return await this.updateUser({
        user: formData,
        update: {
          displayName,
          status: isActive ? "active" : "inactive",
        },
        level: "org",
        action: "update",
      });
    },
  }
}
</script>

<style scoped></style>
