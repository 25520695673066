<template>
  <div class="ControlsMobileFullscreen">
    <div
      class="elevation-0 ControlsMobileFullscreen__toolbar"
      :class="{ ControlsMobileFullscreen__hide: hideControls }"
    >
      <button v-show="!getter_is_window_mode" @click="$emit('click-menu-btn', $event)">
        <icon-base color="white" height="22" width="22">
          <d-icon-menu />
        </icon-base>
      </button>
      <v-spacer></v-spacer>
      <button v-if="!isPublic && !isSearchPlayer" @click.stop="onclickCloseButton">
        <icon-base color="white" height="22" width="22">
          <d-icon-player-close />
        </icon-base>
      </button>
    </div>

    <div
      class="ControlsMobileFullscreen__center"
      :class="{ ControlsMobileFullscreen__hide: hideControls }"
      @click.self="$emit('show-or-hide-icons')"
    >
      <div class="ControlsMobileFullscreen__btns">
        <button
          class="ControlsMobileFullscreen__btns--btn"
          style="transform: scaleX(-1)"
          @click="$emit('play-previous-step')"
        >
          <icon-base color="white" height="24" width="24" viewBox="0 0 10 14">
            <d-icon-player-next />
          </icon-base>
        </button>
        <button class="ControlsMobileFullscreen__btns--btn" @click="$emit('play-pause-video')">
          <icon-base color="white" height="40" width="40">
            <component :is="playing ? 'd-icon-player-pause-2' : 'd-icon-player-play-2'" />
          </icon-base>
        </button>
        <button class="ControlsMobileFullscreen__btns--btn" @click="$emit('play-next-step')">
          <icon-base color="white" height="24" width="24" viewBox="0 0 10 14">
            <d-icon-player-next />
          </icon-base>
        </button>
      </div>
      <div class="ControlsMobileFullscreen__shadow"></div>
    </div>

    <div class="ControlsMobileFullscreen__bottom">
      <div
        class="ControlsMobileFullscreen__bottom--timeWrapper"
        :class="{ 'ControlsMobileFullscreen__bottom--timeWrapperLower': hideControls }"
      >
        <slot />
      </div>

      <div class="ControlsMobileFullscreen__bottom--btns" :class="{ ControlsMobileFullscreen__hide: hideControls }">
        <button @click="$emit('click-serach')" v-if="!getter_is_gen2 && !isMobileFullscreen">
          <icon-base color="white" height="20" width="20">
            <d-icon-search />
          </icon-base>
        </button>
        <v-spacer></v-spacer>
        <controls-repeat-button class="mr-4 pr-2" size="medium" disableTooltip />
        <button v-if="!isMobileFullscreen" class="mr-4 pr-2" @click="$emit('click-setting')">
          <icon-base color="white" height="24" width="24">
            <d-icon-player-settings />
          </icon-base>
        </button>
        <button v-if="!isMobileFullscreen" @click="enterMobileFullscreen">
          <icon-base color="white" height="22" width="22">
            <d-icon-player-fullscreen />
          </icon-base>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconSearch from "@/components/icons/DIconSearch.vue";
import DIconMenu from "@/components/icons/player/DIconMenu.vue";
import DIconPlayerClose from "@/components/icons/player/DIconPlayerClose.vue";
import DIconPlayerPlay2 from "@/components/icons/player/DIconPlayerPlay2.vue";
import DIconPlayerPause2 from "@/components/icons/player/DIconPlayerPause2.vue";
import DIconPlayerNext from "@/components/icons/player/DIconPlayerNext.vue";
import DIconPlayerSettings from "@/components/icons/player/DIconPlayerSettings.vue";
import DIconPlayerFullscreen from "@/components/icons/player/DIconPlayerFullscreen.vue";
import ControlsRepeatButton from "@/components/DWorkflowPlayer/controls/ControlsRepeatButton.vue";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  name: "ControlsMobileSmall",
  components: {
    IconBase,
    DIconSearch,
    DIconMenu,
    DIconPlayerClose,
    DIconPlayerPlay2,
    DIconPlayerPause2,
    DIconPlayerNext,
    DIconPlayerSettings,
    DIconPlayerFullscreen,
    ControlsRepeatButton,
  },
  props: {
    hideControls: Boolean,
    isPublic: Boolean,
    isSearchPlayer: Boolean,
    playing: Boolean,
    formatedCurrentTime: String,
    formatedDuration: String,
  },
  methods: {
    ...mapActions("workflowPlayer", ["enterMobileFullscreen", "exitMobileFullscreen"]),
    onclickCloseButton() {
      if (this.isMobileFullscreen) {
        this.exitMobileFullscreen();
      } else {
        this.$emit("close-player");
      }
    },
  },
  computed: {
    ...mapGetters(["getter_is_gen2"]),
    ...mapState("workflowPlayer", ["isMobileFullscreen"]),
    ...mapGetters("workflowPlayer", ["getter_is_window_mode"]),
  },
};
</script>

<style lang="scss" scoped>
.ControlsMobileFullscreen {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: 0.3s;
  &__hide {
    opacity: 0;
    transition-duration: 0.3s;
  }
  &__toolbar {
    z-index: 1;
    position: absolute;
    display: flex;
    top: 0;
    width: 100%;
    padding: 20px 20px 0 20px;
  }
  &__center {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__btns {
    padding-top: 6px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &--btn {
      margin: 0 32px;
    }
  }
  &__shadow {
    z-index: -2;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    pointer-events: none;
  }
  &__bottom {
    position: absolute;
    height: 46px;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 1;
    &--timeWrapper {
      z-index: 2;
      position: absolute;
      left: 16px;
      width: calc(100% - 32px);
      bottom: -2px;
      transition: 0.3s;
    }
    &--timeWrapperLower {
      width: 100%;
      left: 0;
      bottom: -10px;
    }
    &--btns {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      display: flex;
      padding: 0 16px;
    }
  }
}
</style>
