<template>
  <v-menu content-class="settingsMenu" transition="slide-y-transition" bottom offset-y click-on-hover>
    <button slot="activator" :autotest="autotest">
      <span class="text-no-wrap filter-text">
        {{ $t("admin.settings") }}
        <span style="color: #3f3f3f; width: 50%"> </span>
        <v-icon color="#8D909F" class="sort-by-arrow">keyboard_arrow_down</v-icon>
      </span>
    </button>
    <v-list dense light>
      <v-list-tile v-for="(item, itemKey) in menu" :key="itemKey" @click="setSettingPage(itemKey)">
        <v-list-tile-title class="body-1" :class="{ 'current-list-item': settingPage === itemKey }">
          {{ item }}
        </v-list-tile-title>
      </v-list-tile>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AdminSettingsMenu",
  props: {
    autotest: String,
    filterStatus: Array,
    currentStatus: String,
    statusDictionary: Object,
    settingPage: String,
  },
  computed: {
    ...mapGetters("permission", ["getter_has_employee_id_login", "getter_ip_restriction_feature_enabled"]),
    menu() {
      const conditions = {
        "sso-mapping":
          this.$clientSettings.products && this.$clientSettings.products.ssoMapping && this.$access.ssoMapping,
        "master-password": this.getter_has_employee_id_login,
        "ip-restriction": this.getter_ip_restriction_feature_enabled,
      };

      const defaultMenu = {
        categories: this.$t("admin.workflowCategories"),
        languages: this.$t("all.defaultInterfaceLanguage"),
        permissions: this.$t("admin.permissions"),
        ...(conditions["master-password"] && { "master-password": this.$t("admin.masterPassword") }),
        ...(conditions["ip-restriction"] && { "ip-restriction": this.$t("admin.ipRestriction") }),
        ...(conditions["sso-mapping"] && { "sso-mapping": this.$t("admin.ssoMapping") }),
        "company-logo": this.$t("admin.qrCodeAndWatermark"),
      };
      return defaultMenu;
    },
  },
  methods: {
    setSettingPage(item) {
      this.$emit("setSettingPage", item);
    },
  },
};
</script>

<style scoped>
.settingsMenu {
  top: 225px !important;
  margin-left: -10px;
}
.sort-by-arrow {
  position: relative;
  margin-bottom: -4px;
  width: 24px;
}
.filter-text {
  color: #52545d;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: right;
}
.current-list-item {
  color: #4689f3;
}
</style>
