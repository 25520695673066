<template>
  <div class="d-admin-permissions">
    <div class="tab-title-row center-horizontally">
      <div class="text-no-wrap content-title text-style text-truncate" autotest="categories-count">
        <b>{{ categoriesData.length }}</b>
        {{ $t("admin.categories") }}
      </div>
      <v-spacer></v-spacer>
      <div class="create-btn">
        <d-button
          round
          color="#4689f4"
          class="white--text elevation-0 body-2 text-capitalize btn-width mx-0"
          @click="showPopupType = 'create'"
          light
          autotest="create-category-btn"
          >{{ $t("admin.createCategory") }}</d-button
        >
      </div>
    </div>

    <div class="table-div">
      <d-data-table
        add-search
        local-search
        initial-sort
        :headers="tableHeader"
        :items="categoriesData"
        :loading="isLoading"
        :search="searchInput"
        item-key="id"
        :placeholder="$t('admin.searchForCategories')"
        searchAutotest="category-search-bar"
      >
        <v-progress-linear slot="progress" color="dBlue" height="2" indeterminate></v-progress-linear>
        <template slot="headerCell" slot-scope="props">
          <span class="head-text">{{ props.header.text }}</span>
        </template>
        <template slot="items" slot-scope="props">
          <td class="text-xs-left text-capitalize" :style="{ width: '30%' }">
            <span
              class="name-text text-no-wrap"
              @click="(showCategory = true), (showCategoryData = props.item)"
              autotest="category-name-display"
              >{{ props.item.name }}</span
            >
          </td>
          <td class="text-xs-left" :style="{ width: '30%' }" autotest="category-item-number-display">
            {{ props.item.counter }}
          </td>
          <td class="text-xs-right text-no-wrap">
            <button
              class="mr-3 table-btn"
              @click="(editCategoryData = props.item), (showPopupType = 'edit')"
              autotest="edit-category-btn"
            >
              <icon-base height="22" width="22">
                <d-icon-pen />
              </icon-base>
            </button>
            <button
              class="table-btn"
              @click="(editCategoryData = props.item), (showDeleteCategory = true)"
              autotest="delete-category-btn"
            >
              <icon-base height="22" width="22">
                <d-icon-trash />
              </icon-base>
            </button>
          </td>
        </template>
        <template slot="no-data">
          <span>{{ $t("admin.noCategoriesCreatedYet") }}</span>
        </template>
        <template slot="no-results">
          <span>{{ $t("analytics.msgNoSearchResults") }}</span>
        </template>
      </d-data-table>
    </div>

    <admin-setting-categories-popup
      v-if="showPopupType === 'create' || showPopupType === 'edit'"
      :showPopupType="showPopupType"
      :categoriesData="categoriesData"
      :editCategoryData="editCategoryData"
      @close-popup="(showPopupType = ''), (editCategoryData = {})"
      @add-category="addCategory($event)"
      @update-category="updateCategory($event)"
    ></admin-setting-categories-popup>

    <d-confirmation-popup
      v-model="showDeleteCategory"
      light
      :title="$t('admin.deleteCategory')"
      :cancelBtnName="$t('all.cancel')"
      :actionBtnName="$t('all.delete')"
      :content="$t('admin.popupMsgDeleteCategory') + $escapeHtml(editCategoryData.name) + '?'"
      @clickActionBtn="deleteCategory(editCategoryData.id)"
      :autotest="{
        close: 'delete-category-popup-close-btn',
        action: 'delete-category-popup-delete-btn',
        cancel: 'delete-category-popup-cancel-btn',
      }"
    ></d-confirmation-popup>
    <div v-if="showCategory" class="setting-content-div">
      <admin-sub-category
        :showCategoryData="showCategoryData"
        @update-category="updateCategory($event)"
        @close-category="(showCategory = false), (showCategoryData = {})"
      ></admin-sub-category>
    </div>

    <d-alert v-model="alert.isSuccess" type="success" :message="alert.alertText"></d-alert>
    <d-alert v-model="alert.isFailed" type="error" :message="alert.alertText"></d-alert>
  </div>
</template>

<script>
import MixinUser from "@/components/MixinUser.vue";
import MixinDB from "@/components/MixinDB.vue";
import IconBase from "@/components/IconBase.vue";
import DIconDone from "@/components/icons/DIconDone.vue";
import DIconTrash from "@/components/icons/DIconTrash.vue";
import DIconPen from "@/components/icons/DIconPen.vue";
import AdminSettingCategoriesPopup from "@/components/DAdmin/AdminSettingCategoriesPopup.vue";
import DConfirmationPopup from "@/components/DPopup/DConfirmationPopup.vue";
import AdminSubCategory from "@/components/DAdmin/AdminSubCategory.vue";
import DAlert from "@/components/ui_components/DAlert.vue";
import DDataTable from "@/components/ui_components/DDataTable.vue";
import { deleteMainCategory } from "../../server/delete-category-server.js";

export default {
  name: "AdminCategories",
  props: {},
  watch: {},
  mixins: [MixinUser, MixinDB],
  mounted() {
    this.$store.commit("clearCategories");

    this.getCategories();
  },
  data() {
    return {
      searchInput: "",
      tableHeader: [
        {
          text: this.$t("admin.categoryName"),
          align: "left",
          sortable: true,
          value: "name",
          autotest: "category-name-sort",
        },
        {
          text: this.$t("admin.categoryItemsNum"),
          align: "left",
          sortable: true,
          value: "counter",
          autotest: "category-item-sort",
        },
        {
          text: "",
          sortable: false,
        },
      ],
      categoriesData: [],
      showPopupType: "",
      isLoading: false,
      editCategoryData: {},
      showDeleteCategory: false,
      showCategory: false,
      showCategoryData: {},
      alert: {
        isSuccess: false,
        isFailed: false,
        alertText: "",
      },
    };
  },
  components: {
    IconBase,
    DIconDone,
    DIconTrash,
    DIconPen,
    AdminSettingCategoriesPopup,
    DConfirmationPopup,
    AdminSubCategory,
    DAlert,
    DDataTable,
  },
  methods: {
    getCategories() {
      this.isLoading = true;
      this.fetchCategories().then((data) => {
        this.categoriesData = data;
        this.isLoading = false;
      });
    },
    fetchCategories() {
      const self = this;
      return new Promise(function (resolve, reject) {
        const comparisons = [
          {
            field: "organization",
            comparison: "==",
            value: self.$organization,
          },
        ];
        self
          .getDocumentByQuery("categories", comparisons, [
            {
              type: "limit",
              value: 1000,
            },
          ])
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateCategory(update) {
      if (update.saveToDb) {
        this.updateDocument("categories", update.id, update.saveToDb)
          .then(() => {
            // console.log("category successfully updated to db")
            this.alert.alertText = this.$t("builder.alertCategoriesSaved");
            this.alert.isSuccess = true;
          })
          .catch((err) => {
            // alert("error updating category.")
            this.alert.alertText = this.$t("builder.alertCategoriesSaveFailed");
            this.alert.isFailed = true;
          });
      }
      for (let i = 0; i < this.categoriesData.length; i++) {
        if (this.categoriesData[i].id == update.id) {
          this.categoriesData.splice(i, 1, {
            ...this.categoriesData[i],
            ...update,
          });
        }
      }
    },
    addCategory(category) {
      const self = this;
      return new Promise(function (resolve, reject) {
        const id = self.generateDocId("categories");
        const data = {
          organization: self.$organization,
          id: id,
          name: category.name,
          counter: 0,
          subcategories: [],
        };
        self
          .addDocument("categories", id, data)
          .then(() => {
            self.categoriesData.splice(0, 0, data);
            self.alert.alertText = self.$t("builder.alertCategoriesSaved");
            self.alert.isSuccess = true;
            resolve();
          })
          .catch((err) => {
            self.alert.alertText = self.$t("builder.alertCategoriesSaveFailed");
            self.alert.isFailed = true;
            reject();
          });
      });
    },
    hideCategoryRow({ id }) {
      const targetRowIndex = this.categoriesData.findIndex((category) => category.id === id);
      const noDataCanDelete = targetRowIndex < 0;
      if (noDataCanDelete) {
        return;
      }

      this.categoriesData.splice(targetRowIndex, 1);
    },
    showSucessMessage({ text }) {
      this.alert.isFailed = false;
      this.alert.isSuccess = true;

      this.alert.alertText = text;
    },
    showFailedMessage({ text }) {
      this.alert.isFailed = true;
      this.alert.isSuccess = false;

      this.alert.alertText = text;
    },
    deleteCategory(id) {
      deleteMainCategory({
        id,
      })
        .then(() => {
          this.showSucessMessage({
            text: this.$t("admin.alertSuccessfullyDeletedCategory"),
          });

          this.hideCategoryRow({
            id,
          });
        })
        .catch(() => {
          this.showFailedMessage({
            text: this.$t("admin.alertCategoryDeleteFailed"),
          });
        });
    },
  },
  computed: {},
};
</script>

<style scoped>
.d-admin-permissions {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 32px;
  text-align: left;
  z-index: 0;
  flex-direction: column;
  display: flex;
}
.tab-title-row {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}
.center-horizontally {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.table-div {
  height: 100%;
  width: 100%;
  overflow: auto;
}
.table-div:hover::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
}
.text-style {
  font-family: Roboto;
  letter-spacing: 0;
}
.content-title {
  color: #3f3f3f;
  font-size: 32px;
  line-height: 32px;
}
.head-text {
  text-transform: uppercase;
  color: #4e4e4e;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
}
.table-btn {
  color: #78767a;
}
.table-btn:hover {
  color: #2d2e32;
}
.create-btn {
  position: relative;
}
.btn-width {
  width: 140px;
  text-transform: none !important;
}
.name-text {
  color: #3f3f3f;
}
.name-text:hover {
  cursor: pointer;
  font-weight: 500;
}
.setting-content-div {
  position: absolute;
  top: 4px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fbfbfb;
  overflow: hidden;
  z-index: 2;
}
</style>
