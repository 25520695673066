<template>
  <section class="user-invite-create">
    <div class="user-invite-content fullscreen-center">
      <deephow-logo width="190" height="40" />
      <v-card v-if="!isSuccess" dark color="#17181A" class="invite-box">
        <p class="create-account-title">{{ $t("password.createNewPassword") }}</p>
        <p class="create-account-subtitle">{{ $t("password.selectNewPassword") }}</p>
        <v-form v-model="formValid" autocomplete="off" :style="{ margin: '56px 0 56px 0' }">
          <v-text-field
            v-model="newPassword"
            :label="$t('password.newPassword')"
            class="input-field mb-3"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            autocomplete="chrome-off"
            :rules="[rules.validPassword, rules.noWhiteSpace, rules.max, rules.min]"
            required
          ></v-text-field>
          <v-text-field
            v-model="newPassword2"
            :label="$t('password.confirmNewPassword')"
            class="input-field"
            :append-icon="showPassword2 ? 'visibility' : 'visibility_off'"
            :type="showPassword2 ? 'text' : 'password'"
            @click:append="showPassword2 = !showPassword2"
            autocomplete="chrome-off"
            :rules="[rules.required]"
            required
          ></v-text-field>
          <p v-if="newPassword != newPassword2 && newPassword2" class="error-text">
            {{ $t("password.passwordMustMatch") }}
          </p>
        </v-form>
        <d-button
          :disabled="!formValid || newPassword != newPassword2"
          @click="setNewPassword"
          depressed
          round
          block
          class="body-2 text-capitalize white--text"
          dark
          color="#4689f4"
          >{{ $t("password.confirm") }}</d-button
        >
      </v-card>
      <v-card v-else dark color="#17181A" class="invite-box" height="420">
        <icon-base height="108" width="108" color="#30548a" class="mt-2 mb-3">
          <d-icon-success />
        </icon-base>
        <p class="create-account-title">{{ $t("all.success") }}!</p>
        <p class="create-account-subtitle" v-html="$t('password.passwordCreatedMsg')">
          <!-- Your new password was created, <br>please wait while we redirect you… -->
        </p>
        <d-button
          depressed
          round
          block
          class="body-2 text-capitalize white--text success-btn no-transform"
          color="#4689f4"
          dark
          @click="goToPlayer"
          >{{ $t("password.goToDeepHowPlayer") }}</d-button
        >
      </v-card>
    </div>
    <div class="language-btn">
      <user-language-menu
        userNewPassword
        :isMobileDevice="false"
        :isTabletDevice="false"
        @change-lang-url="changeLangUrl"
      ></user-language-menu>
    </div>
  </section>
</template>

<script>
import MixinUser from "./MixinUser.vue";
import IconBase from "./IconBase.vue";
import DeephowLogo from "./icons/logo/DeephowLogo.vue";
import DIconSuccess from "./icons/colored/DIconSuccess.vue";
import UserLanguageMenu from "@/components/DUser/UserLanguageMenu";
import { resetNewPassword } from "@/server/basic-authentication-server.js";
import { i18nKeysDictionary } from "@/js/authentication";
import { mapMutations } from "vuex";

export default {
  name: "UserNewPassword",
  props: {
    isMobileDevice: Boolean,
    isTabletDevice: Boolean,
  },
  data() {
    return {
      newPassword: "",
      newPassword2: "",
      showPassword: false,
      showPassword2: false,
      email: "",
      token: "",
      rules: {
        noWhiteSpace: (value) => {
          const pattern = /^[^ ]+$/;
          return pattern.test(value) || this.$t("rules.noSpace");
        },
        required: (value) => !!value || this.$t("rules.required"),
        min: (value) => (value && value.length >= 10) || this.$t("rules.min10"),
        max: (value) => (value && value.length <= 25) || this.$t("rules.max25"),
        validPassword: (value) => {
          // Include lower and upper case letters, numbers, and special characters.
          const pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!-\/:-@[-\`{-~]).*$/;
          return pattern.test(value) || this.$t("rules.validPasswordCharacters");
        },
      },
      formValid: false,
      isSuccess: false,
    };
  },
  components: {
    IconBase,
    DeephowLogo,
    DIconSuccess,
    UserLanguageMenu,
  },
  mixins: [MixinUser],
  created() {
    this.token = this.$route.params.token;
    if (this.$route.params.lang) {
      this.$i18n.locale = this.$route.params.lang;
    }
    this.setAuthState();
  },
  mounted() {},
  methods: {
    ...mapMutations("global", ["MUTATE_ALERT_INFO"]),
    changeLangUrl() {
      const newUrl = "/usernewpassword/" + this.$route.params.token + "/" + this.$i18n.locale;
      this.$router.push(newUrl);
    },
    goToPlayer() {
      this.signInWithEmail(this.email, this.newPassword);
    },
    setAuthState() {
      this.auth().onAuthStateChanged((user) => {
        if (user) {
          this.$router.push("/navigator/home");
        }
      });
    },
    async setNewPassword() {
      const newPassword = {
        token: this.token,
        password: encodeURIComponent(this.newPassword),
      };
      try {
        const { data } = await resetNewPassword(newPassword);
        this.email = data.email;
        this.isSuccess = true;
        setTimeout(() => this.goToPlayer(), 1000);
      } catch (error) {
        const i18nKey = i18nKeysDictionary[error.response.data.authType];
        const message = !!i18nKey ? this.$t(`auth.${i18nKey}`) : this.$t("password.alertFailedResetPassword");
        this.MUTATE_ALERT_INFO({ message, type: "error" });
      }
    },
  },
};
</script>

<style scoped>
.user-invite-create {
  z-index: 2;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #0c0c0e;
}
.user-invite-content {
  max-width: 472px;
  margin: 0 auto;
}
.invite-box {
  position: relative;
  margin-top: 36px;
  padding: 48px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.35);
}
.create-account-title {
  color: #ffffff;
  font-family: Roboto;
  font-size: 32px;
  line-height: 44px;
  margin: 0;
}
.create-account-subtitle {
  color: #8d909f;
  font-family: Roboto;
  font-size: 20px;
  line-height: 30px;
}
.success-btn {
  position: relative;
  margin-top: 48px;
}
/* for fixing Chrome auto-filling bug of Vuetify */
.input-field ::v-deep input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #17181a inset !important;
}
.input-field ::v-deep input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}
.error-text {
  color: #888888 !important;
  caret-color: #888888 !important;
  font-size: 12px;
  text-align: left;
  margin-top: -14px;
}
.no-transform {
  text-transform: none !important;
}
.language-btn {
  position: absolute;
  top: 32px;
  right: 48px;
}
@media (max-width: 436px) {
  .user-invite-create {
    overflow: auto;
    padding: 0 12px 36px 12px;
  }
  .user-invite-content {
    margin-top: 100px;
  }
  .language-btn {
    right: 12px;
  }
}
@media screen and (min-width: 437px) {
  .fullscreen-center {
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
</style>
